import { Route } from '@angular/router';
import { TransactionType } from '@fishonline2023/webapps/model/fa2023';

export const appRoutes: Route[] = [
  {
    path: '',
    loadChildren: () =>
      import('./layout/layout.routes').then((mod) => mod.layoutRoutes),
  },
  {
    path: '',
    data: {
      transactionType: TransactionType.AmendCustomer,
    },
    loadChildren: () =>
      import(
        '@fishonline2023/webapps/transaction/fa2023/feature/transaction'
      ).then((mod) => mod.transactionRoutes),
  },
];
