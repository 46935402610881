<div class="mb-3">
  <sv-ui-loading
    *ngIf="(loadCustomerListViewStatus$ | async) === ViewStatus.Loading"
    [message]="Message.LoadingCustomers"
  ></sv-ui-loading>
  <sv-ui-loading
    *ngIf="(createFishingBusinessViewStatus$ | async) === ViewStatus.Loading"
    [message]="Message.CreateFishingBusiness"
  ></sv-ui-loading>
  <div class="row g-3">
    <div class="col-md-6 col-sm-12">
      <sv-ui-two-party-transaction-entity-selection-form
        [control]="selectionFormGroup.controls.from"
        [isReadOnly]="isReadOnly"
        [optionList]="fromFishingBusinessList"
        [selectionType]="TwoPartyTransactionEntitySelectionType.FishingBusiness"
        description="Select business by name or number"
        label="From"
      ></sv-ui-two-party-transaction-entity-selection-form>
    </div>
    <div
      *ngIf="selectionFormGroup.controls.from.valid"
      class="col-md-6 col-sm-12"
    >
      <sv-ui-two-party-transaction-entity-selection-form
        (fishingBusinessCreated)="createFishingBusiness($event)"
        [control]="selectionFormGroup.controls.to"
        [isReadOnly]="isReadOnly"
        [optionList]="toCustomerList"
        [secondaryOptionList]="fishingBusinessByCustomerId"
        [selectionType]="
          TwoPartyTransactionEntitySelectionType.FishingBusinessAndCustomer
        "
        description="Select customer by name or number"
        label="To"
        secondaryDescription="Select business by name or number"
      ></sv-ui-two-party-transaction-entity-selection-form>
    </div>
  </div>
</div>
