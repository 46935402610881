<amplify-authenticator [hideSignUp]="true">
  <ng-template amplifySlot="header">
    <div class="main-header"></div>
  </ng-template>
  <ng-template amplifySlot="sign-in-header">
    <div class="d-flex justify-content-between">
      <div class="logo-container">
        <img
          class="amplify-image nsw-logo"
          alt="Amplify logo"
          src="https://www.nsw.gov.au/themes/custom/dcs_theme/favicons/sets/standard/logo.svg"
        />
      </div>
      <div class="logo-container">
        <img
          class="fish-logo"
          alt="Fish online logo"
          src="assets/fish_online.png"
        />
      </div>
    </div>
    <div class="text-header">
      <h3>FisherDirect Login</h3>
    </div>
  </ng-template>
  <ng-template amplifySlot="authenticated"></ng-template>
</amplify-authenticator>
