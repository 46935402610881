<div class="round-secondary-border-box mb-4" *ngIf="currentFisher">
  <h4 class="mb-3">Fisher History for this Certificate</h4>
  <p>
    <strong>Customer {{ currentFisher.fisher.fullName }}</strong> is the current
    authorised fisher
  </p>
  <div class="row mt-1" *ngIf="fisherHistoryList.length > 0">
    <div class="col">
      <table class="table align-middle table-striped mt-3">
        <thead>
          <tr>
            <th scope="col">Fisher Reg. No.</th>
            <th scope="col">Fisher Name</th>
            <th scope="col">Authorisation Date/Time</th>
            <th scope="col">Revoked Date/Time</th>
          </tr>
        </thead>
        <tbody class="table-group-divider">
          <tr *ngFor="let fisherHistory of fisherHistoryList">
            <td>{{ fisherHistory.fisher.id }}</td>
            <td>{{ fisherHistory.fisher.fullName }}</td>
            <td>
              {{
                fisherHistory.authorisationDate
                  | date : FDDateTimeAngularPipeFormat
              }}
            </td>
            <td>
              {{
                fisherHistory.revokedDate
                  ? (fisherHistory.revokedDate
                    | date : FDDateTimeAngularPipeFormat)
                  : 'Current'
              }}
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</div>
