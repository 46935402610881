import {
  ChangeDetectionStrategy,
  Component,
  Input,
  OnDestroy,
  OnInit,
} from '@angular/core';
import { CommonModule } from '@angular/common';
import { InputField } from '@fishonline2023/shared/models';
import { FormControl, ReactiveFormsModule } from '@angular/forms';
import { equals, isNil } from '@fishonline2023/shared/ramda';
import { filter, Subject, takeUntil } from 'rxjs';

@Component({
  selector: 'sv-ui-input-field-price',
  standalone: true,
  imports: [CommonModule, ReactiveFormsModule],
  templateUrl: './input-field-price.component.html',
  styleUrls: ['./input-field-price.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class InputFieldPriceComponent implements OnInit, OnDestroy {
  @Input() public inputField!: InputField;
  @Input() public form!: FormControl;
  private destroy$ = new Subject<void>();

  public ngOnInit() {
    this.formatPrice();
    this.resetDefaultValueOnClear();
  }

  public ngOnDestroy() {
    this.destroy$.next();
    this.destroy$.complete();
  }

  protected formatPrice() {
    if (isNil(this.form.value) || !equals(typeof this.form.value, 'number')) {
      return;
    }
    const formattedValue = this.form.value.toFixed(2);
    this.form?.setValue(formattedValue);
  }

  private resetDefaultValueOnClear() {
    this.form.valueChanges
      .pipe(takeUntil(this.destroy$), filter(isNil))
      .subscribe(() => this.form.patchValue(0, { emitEvent: false }));
  }
}
