import { inject, Injectable } from '@angular/core';
import {
  APIService,
  Channel,
  SearchableActivityLogFilterInput,
  SearchableActivityLogSortableFields,
  SearchableActivityLogSortInput,
} from '@fishonline2023/webapps/shared/feature/appsync';
import * as dayjs from 'dayjs';
import {
  complement,
  equals,
  includes,
  isNil,
  mergeAll,
  prop,
} from '@fishonline2023/shared/ramda';
import {
  ActivityTypesByChannel,
  AgentCustomer,
  CompositeSearchField,
  CREATED_AT,
  FishingBusiness,
  FlatSearchField,
  SearchActivityLogResultProperty,
  SearchField,
  SearchFieldType,
  SearchFieldValue,
  SearchFieldValuesFrom,
  SearchFieldValuesFromInDeriveValueContext,
  SearchFilterOptionMap,
  searchMetadata,
  SearchResult,
  SearchResultItem,
  SearchType,
} from '@fishonline2023/shared/models';
import {
  combineLatest,
  filter,
  forkJoin,
  map,
  Observable,
  of,
  switchMap,
  take,
  tap,
  withLatestFrom,
} from 'rxjs';
import { UserProfile } from '@fishonline2023/webapps/model/fd2023';
import { Store } from '@ngrx/store';
import {
  getActAsCustomer,
  getCustomerAgentList,
  getUserProfile,
} from '@fishonline2023/webapps/user-portal/fd2023/store/user-profile';
import { HttpClient } from '@angular/common/http';
import { selectSearchType } from './search.selectors';
import { flattenSearchFilter } from '@fishonline2023/shared/utils';
import { getMyFishingBusinessList } from '@fishonline2023/webapps/homepage/fd2023/store/home';
import { GRAPHQL_AUTH_MODE } from '@aws-amplify/api';
import { environment as FDEnvironment } from '@env/fd/environment';
import { environment as FAEnvironment } from '@env/fa/environment';
import { download, generateCsv, mkConfig } from 'export-to-csv';

@Injectable()
export class SearchService {
  private apiService = inject(APIService);
  private httpClient = inject(HttpClient);
  private store = inject(Store);

  private searchType$ = this.store
    .select(selectSearchType)
    .pipe(filter(complement(isNil)), take(1));

  public searchFilterOptions$(): Observable<SearchFilterOptionMap> {
    return this.searchType$.pipe(
      switchMap((searchType: SearchType) => {
        if (isNil(searchMetadata[searchType].searchFilterOptionsEndpoint)) {
          return of({});
        }
        const channel = searchMetadata[searchType].channel;
        return this.httpClient.get<SearchFilterOptionMap>(
          `${this.apiBaseUrlByChannel(channel)}/${
            searchMetadata[searchType].searchFilterOptionsEndpoint
          }`,
          {}
        );
        // return of(CustomerDetailSearchFilterOption);
      })
    );
  }

  public executeSearch({
    searchFilter,
    fromDownload,
  }: {
    searchFilter: Record<string, unknown>;
    fromDownload: boolean;
  }): Observable<SearchResult> {
    return this.searchType$.pipe(
      switchMap((searchType: SearchType) => {
        if (this.isActivityLogSearch(searchType)) {
          return this.searchActivityLogs(
            flattenSearchFilter(searchFilter) as SearchActivityLogResultProperty
          );
        }
        return this.nonActivitySearchResult$({
          searchType,
          searchFilter,
          searchWithLimit:
            searchMetadata[searchType].shouldReturnLimitedNumberOfSearchResult,
          limit: !fromDownload,
        });
        // return timer(1000).pipe(map(() => customerDetail()));
      })
    );
  }

  public download(
    searchFilter: Record<string, unknown>,
    downloadCSVName?: string
  ): Observable<SearchResult> {
    return this.executeSearch({ searchFilter, fromDownload: true }).pipe(
      tap((result) => {
        const config = mkConfig({
          useKeysAsHeaders: true,
          filename: downloadCSVName,
        });
        const csv = generateCsv(config)(result.resultList);
        download(config)(csv);
      })
    );
  }

  public search(
    searchFilter: Record<string, unknown>
  ): Observable<SearchResult> {
    return this.executeSearch({ searchFilter, fromDownload: false });
  }

  public searchFieldValues$() {
    return this.searchType$.pipe(
      map((searchType: SearchType) =>
        this.searchFieldsWithSelectionValues(
          searchMetadata[searchType].searchFields
        )
      ),
      switchMap((searchFieldsWithSelectionValues) =>
        combineLatest([
          of(searchFieldsWithSelectionValues),
          this.searchFilterOptions$(),
        ])
      ),
      switchMap(this.constructSearchFieldValues$)
    );
  }

  private isActivityLogSearch(searchType: SearchType): boolean {
    return includes(searchType, [
      SearchType.AccountActivity,
      SearchType.UserActivityLog,
    ]);
  }

  private nonActivitySearchResult$({
    searchType,
    searchFilter,
    limit,
    searchWithLimit,
  }: {
    searchType: SearchType;
    searchFilter: Record<string, unknown>;
    limit: boolean;
    searchWithLimit: boolean;
  }): Observable<SearchResult> {
    const { channel } = searchMetadata[searchType];
    const params = flattenSearchFilter(searchFilter);
    if (searchWithLimit) {
      return this.httpClient.get<SearchResult>(
        `${this.apiBaseUrlByChannel(channel)}/${
          searchMetadata[searchType].searchEndpoint
        }`,
        { params: { ...params, limit } }
      );
    }
    return this.httpClient
      .get<SearchResultItem[]>(
        `${this.apiBaseUrlByChannel(channel)}/${
          searchMetadata[searchType].searchEndpoint
        }`,
        { params }
      )
      .pipe(map((resultList) => ({ resultList, total: resultList.length })));
  }

  private apiBaseUrlByChannel(channel: Channel) {
    const apiBaseUrlMap = {
      [Channel.FA]: FAEnvironment.apiUrl,
      [Channel.FD]: FDEnvironment.apiUrl,
      // todo: TODO FM and FI api base URL
      [Channel.FM]: '',
      [Channel.FI]: '',
    };
    return apiBaseUrlMap[channel];
  }

  private searchActivityLogs(
    value: SearchActivityLogResultProperty
  ): Observable<SearchResult> {
    return this.searchType$.pipe(
      map((searchType) => searchMetadata[searchType as SearchType].channel),
      switchMap(this.activityLogCustomerSearchFilter),
      map(([channel, personaCustomerId]) =>
        this.getSearchActivityLogOptions(value, [channel, personaCustomerId])
      ),
      switchMap((searchOptions) =>
        this.apiService.SearchActivityLogs(...searchOptions)
      ),
      map((searchActivityLogsQuery) => ({
        resultList: searchActivityLogsQuery.items.filter(
          complement(isNil)
        ) as Array<SearchResultItem>,
        total: searchActivityLogsQuery.total as number,
      }))
    );
  }

  private getSearchActivityLogOptions(
    value: SearchActivityLogResultProperty,
    [channel, personaCustomerId]: [Channel, Record<string, string>]
  ) {
    const filter = this.mapSearchFormToSearchableActivityLogFilterInput({
      ...value,
      channel,
      ...personaCustomerId,
    });
    return [
      filter,
      [{ field: SearchableActivityLogSortableFields.createdAt }],
      ...(equals(channel, Channel.FA)
        ? [GRAPHQL_AUTH_MODE.AWS_LAMBDA, 'custom-authorized']
        : []),
    ] as [
      SearchableActivityLogFilterInput,
      Array<SearchableActivityLogSortInput | null>,
      GRAPHQL_AUTH_MODE,
      string
    ];
  }

  private activityLogCustomerSearchFilter = (
    channel: Channel
  ): Observable<[Channel, Record<string, string>]> =>
    equals(channel, Channel.FA)
      ? of([channel, {}])
      : combineLatest([
          of(channel),
          this.store.select(getActAsCustomer).pipe(
            filter(complement(isNil)),
            take(1),
            map(({ id }: AgentCustomer) => ({
              personaCustomerId: String(id),
            }))
          ),
        ]);

  private mapSearchFormToSearchableActivityLogFilterInput(
    value: SearchActivityLogResultProperty
  ): SearchableActivityLogFilterInput {
    return mergeAll(
      Object.entries(value).map(([key, value]) => {
        switch (key) {
          case CREATED_AT:
            return {
              createdAt: {
                gt: dayjs().subtract(Number(value), 'day').format(),
              },
            };
          default:
            return {
              [key as keyof SearchableActivityLogFilterInput]: { eq: value },
            };
        }
      })
    );
  }

  private searchFieldsWithSelectionValues(
    searchFields: Array<SearchField>
  ): Array<FlatSearchField> {
    return searchFields.reduce(
      (accumulator: FlatSearchField[], searchField: SearchField) => [
        ...accumulator,
        ...this.searchFieldToAppend(searchField),
      ],
      []
    );
  }

  private searchFieldToAppend(searchField: SearchField) {
    if (
      includes(searchField.fieldType, [
        SearchFieldType.RadioButtonPreFilter,
        SearchFieldType.Dependent,
      ])
    ) {
      return this.searchFieldsWithSelectionValues(
        (searchField as CompositeSearchField).fields
      );
    }
    if (
      includes(searchField.fieldType, [
        SearchFieldType.Text,
        SearchFieldType.Date,
        SearchFieldType.DateRange,
      ])
    ) {
      return [];
    }
    return [searchField as FlatSearchField];
  }

  private constructSearchFieldValues$ = ([
    searchFieldsWithSelectionValues,
    searchFilterOptions,
  ]: [FlatSearchField[], SearchFilterOptionMap]) =>
    forkJoin(
      mergeAll<Record<string, Observable<Array<SearchFieldValue>>>>(
        searchFieldsWithSelectionValues.map((searchField) => {
          if (
            equals(
              searchField.valuesFrom,
              SearchFieldValuesFrom.DependentParent
            )
          ) {
            return {};
          }
          if (!isNil(searchField.values)) {
            return {
              [searchField.formControlName]: of(searchField.values),
            };
          }
          const searchFieldValueFromMap: Record<
            SearchFieldValuesFromInDeriveValueContext,
            Observable<Array<SearchFieldValue>>
          > = {
            [SearchFieldValuesFrom.APIResponseByFormControlName]: of(
              searchFilterOptions[<string>searchField.formControlName]
            ),
            [SearchFieldValuesFrom.MyAgent]: this.agentValues$(),
            [SearchFieldValuesFrom.FishingBusinesses]:
              this.fishingBusinessList$(),
            [SearchFieldValuesFrom.ActivityTypeByChannel]: of(
              ActivityTypesByChannel
            ),
          };
          const searchFieldValues$: Observable<Array<SearchFieldValue>> =
            searchFieldValueFromMap[
              <SearchFieldValuesFromInDeriveValueContext>searchField.valuesFrom
            ];
          return { [searchField.formControlName]: searchFieldValues$ };
        })
      )
    );

  private agentValues$() {
    return this.store.select(getUserProfile).pipe(
      filter(complement(isNil)),
      take(1),
      withLatestFrom<UserProfile, AgentCustomer[][]>(
        this.store.select(getCustomerAgentList).pipe(filter(complement(isNil)))
      ),
      map(([userProfile, customerAgentList]) => {
        const agentValues = customerAgentList.map(({ id, fullName }) => ({
          id,
          label: fullName,
        }));
        if (agentValues.map<number>(prop('id')).includes(userProfile.id)) {
          return agentValues;
        }
        const loggedInUser = {
          id: userProfile.id,
          label: `${userProfile.firstName} ${userProfile.lastName}`,
        };
        return [loggedInUser, ...agentValues];
      })
    );
  }

  private fishingBusinessList$() {
    return this.store.select(getMyFishingBusinessList).pipe(
      filter(complement(isNil)),
      take(1),
      map((fishingBusinessList: FishingBusiness[]) => {
        return fishingBusinessList.map(({ id, owner }) => ({
          id,
          label: `FB ${id} - ${owner.fullName}`,
        }));
      })
    );
  }
}
