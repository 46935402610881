<form [formGroup]="amendCustomerForm">
  <div class="contact-detail-container p-1">
    <hr />
    <h4>Contact Details</h4>
    <div class="row">
      <div
        *ngFor="let contactField of contactFieldMetadataList"
        class="col-lg-4 col-md-6 col-sm-12 mb-3"
      >
        <label class="col-form-label"
          >{{ LabelNameMap[contactField.formControlName] }}
          <span
            *ngIf="
              (contactField.shouldDynamicDecideIfRequired &&
                isContactMethodRequired(contactField.formControlName)) ||
              contactField.isRequired
            "
            class="text-danger"
            >*</span
          ></label
        >
        <div>
          <input
            [attr.aria-label]="contactField.formControlName"
            [attr.data-cy]="contactField.formControlName"
            [formControlName]="contactField.formControlName"
            class="form-control form-control-lg"
            type="text"
          />
        </div>
        <ng-container
          *ngIf="
            amendCustomerForm.controls[contactField.formControlName].touched
          "
        >
          <small
            *ngIf="
              amendCustomerForm.controls[contactField.formControlName].hasError(
                'email'
              )
            "
            class="text-danger"
            >Invalid email address</small
          >
          <small
            *ngIf="
              amendCustomerForm.controls[contactField.formControlName].hasError(
                'required'
              )
            "
            class="text-danger"
            >{{ LabelNameMap[contactField.formControlName] }} is required</small
          >
        </ng-container>
      </div>
    </div>
    <p class="col-form-label">Preferred Contact Method</p>
    <div
      *ngFor="let preferredMethodField of preferredMethodMetadataList"
      class="form-check form-check-inline form-control-lg"
    >
      <input
        [value]="preferredMethodField"
        aria-label="Preferred method"
        class="form-check-input radio-button"
        formControlName="preferredMethod"
        type="radio"
      />
      <label class="form-check-label">{{
        LabelNameMap[preferredMethodField]
      }}</label>
    </div>
  </div>
  <div class="address-container p-1">
    <hr />
    <div class="row">
      <div
        *ngFor="let addressField of addressMetadataList"
        [formGroupName]="addressField.formGroupName"
        class="col-lg-4 col-md-12 mb-3"
        data-cy="address-item"
      >
        <h4>{{ addressField.title }}</h4>
        <small
          *ngIf="
            amendCustomerForm.controls[addressField.formGroupName].hasError(
              'invalidAddress'
            )
          "
          class="text-danger"
          >{{ addressField.title }} is invalid</small
        >
        <small
          *ngIf="
            amendCustomerForm.controls[addressField.formGroupName].hasError(
              'incompleteAddress'
            )
          "
          class="text-danger"
          >{{ addressField.title }} is incomplete</small
        >
        <div *ngFor="let field of addressField.fieldLists" class="mb-3">
          <label class="col-form-label"
            >{{ LabelNameMap[field.formControlName] }}
            <span *ngIf="field.isRequired" class="text-danger">*</span></label
          >
          <div class="address-field">
            <input
              *ngIf="!field.shouldDisplayTypeahead"
              [attr.aria-label]="field.formControlName"
              [formControlName]="field.formControlName"
              [readOnly]="field.isReadonly"
              class="form-control form-control-lg"
              type="text"
            />
            <sv-ui-auto-complete
              (inputBlurred)="
                addressFieldBlurred({
                  formControlName: field.formControlName,
                  addressType: addressField.formGroupName
                })
              "
              (inputFocused)="
                updateAddressOptions({
                  formControlName: field.formControlName,
                  addressType: addressField.formGroupName
                })
              "
              (optionSelected)="addressOptionSelected($event)"
              *ngIf="field.shouldDisplayTypeahead"
              [attr.aria-label]="field.formControlName"
              [formControlName]="field.formControlName"
              [isReadOnly]="field.isReadonly"
              [options]="
                focusedAddressField?.addressType ===
                  addressField.formGroupName &&
                focusedAddressField?.formControlName === field.formControlName
                  ? addressOptionList
                  : []
              "
            ></sv-ui-auto-complete>
            <ng-container
              *ngIf="
                amendCustomerForm.controls[addressField.formGroupName].touched
              "
            >
              <small
                *ngIf="
                  addressFieldHasError(
                    'required',
                    addressField.formGroupName,
                    field.formControlName
                  )
                "
                class="text-danger"
                >{{ LabelNameMap[field.formControlName] }} is required</small
              >
              <small
                *ngIf="
                  addressFieldHasError(
                    'maxlength',
                    addressField.formGroupName,
                    field.formControlName
                  )
                "
                class="text-danger"
                >{{ LabelNameMap[field.formControlName] }} must be limited to a
                maximum of {{ field.maxLength }} characters</small
              >
            </ng-container>
          </div>
        </div>
        <button
          (click)="addressField.copyMethod()"
          *ngIf="
            addressField.shouldDisplayCopyButton && addressField.copyMethod
          "
          class="btn btn-lg btn-primary d-flex align-items-center"
          data-cy="copy-address-button"
        >
          {{ addressField.copyButtonLabel }}
          <i class="material-icons-round">arrow_forward</i>
        </button>
      </div>
    </div>
  </div>
</form>
