<ng-container *ngIf="transactionData">
  <div
    class="confirmation-container round-secondary-border-box mb-3"
    *ngIf="userProfile"
  >
    <h6>Receipt Number</h6>
    <p>{{ transactionData.transactionHeader.receiptNumber }}</p>
    <h6>Transaction type</h6>
    <p>{{ transactionData.transactionHeader.type }}</p>
    <h6>Effective date</h6>
    <p>
      {{
        transactionData.transactionHeader.effectiveDate
          | date : FDDateAngularPipeFormat
      }}
    </p>
    <h6>Contact</h6>
    <p>{{ userProfile.lastName }}, {{ userProfile.firstName }}</p>
  </div>
  <sv-ui-contact-address-details
    [transactionData]="transactionData"
  ></sv-ui-contact-address-details>
</ng-container>
