import {
  equals,
  has,
  includes,
  isNil,
  prop,
} from '@fishonline2023/shared/ramda';
import {
  CompositeSearchField,
  FlatSearchField,
  NestedSearchFieldValue,
  SearchField,
  SearchFieldType,
  SearchFieldValue,
  SearchMetadata,
} from '@fishonline2023/shared/models';

export const findSearchFilterWithLabel = (
  flattenSearchFilter: Record<string, string | number>,
  searchMetadata: SearchMetadata,
  searchFieldValues?: Record<
    string,
    Array<SearchFieldValue> | Array<NestedSearchFieldValue>
  >
) =>
  Object.entries(flattenSearchFilter).map(([key, value]) => {
    const label = findSearchFilterLabel(searchMetadata.searchFields, key);
    const searchField = findSearchField(key, searchMetadata);
    const isFlatTextDateSearchField =
      !isNil(searchField) &&
      includes(searchField.fieldType, [
        SearchFieldType.Text,
        SearchFieldType.Date,
      ]);
    if (isFlatTextDateSearchField) {
      return {
        label,
        value,
      };
    }
    const isFlatSearchFieldValuesField = has(key, searchFieldValues);
    if (isFlatSearchFieldValuesField) {
      return {
        label,
        value: searchFieldValues?.[key].find(({ id }) =>
          equals(String(id), String(value))
        )?.label,
      };
    }
    return dependentFilterWithLabel(
      key,
      label,
      value,
      searchMetadata,
      searchFieldValues
    );
  });

export const findSearchFilterLabel = (
  searchFields: Array<SearchField>,
  property: string
): string | undefined => {
  for (const searchField of searchFields) {
    if (
      includes(searchField.fieldType, [
        SearchFieldType.RadioButtonPreFilter,
        SearchFieldType.Dependent,
        SearchFieldType.DateRange,
      ])
    ) {
      const label = findSearchFilterLabel(
        (searchField as CompositeSearchField).fields,
        property
      );
      if (isNil(label)) {
        continue;
      }
      return `${searchField.label ? `${searchField.label} ` : ''}${label}`;
    }
    if (equals((searchField as FlatSearchField).formControlName, property)) {
      return (searchField as FlatSearchField).label;
    }
  }
  return undefined;
};

export const findSearchField = (
  key: string,
  searchMetadata: SearchMetadata
): SearchField | undefined => {
  const searchFieldHasControlName = (searchField: SearchField) =>
    equals((searchField as FlatSearchField).formControlName, key);

  const isDateRangeAndHasControlName = (searchField: SearchField) =>
    equals(
      (searchField as CompositeSearchField).fieldType,
      SearchFieldType.DateRange
    ) &&
    (searchField as CompositeSearchField).fields.some(
      searchFieldHasControlName
    );

  const flatSearchField = searchMetadata.searchFields.find(
    searchFieldHasControlName
  );
  if (!isNil(flatSearchField)) {
    return flatSearchField;
  }
  const dateRangeSearchFields = searchMetadata.searchFields.find(
    isDateRangeAndHasControlName
  );
  if (isNil(dateRangeSearchFields)) {
    return undefined;
  }
  return (dateRangeSearchFields as CompositeSearchField).fields.find(
    searchFieldHasControlName
  );
};

export const dependentFilterWithLabel = (
  key: string,
  label: string | undefined,
  value: string | number,
  searchMetadata: SearchMetadata,
  searchFieldValues?: Record<
    string,
    Array<SearchFieldValue> | Array<NestedSearchFieldValue>
  >
) => {
  const dependentFields: CompositeSearchField = <CompositeSearchField>(
    searchMetadata.searchFields.find((searchField) =>
      equals(searchField.fieldType, SearchFieldType.Dependent)
    )
  );
  const matchedDependentFieldIndex: number = <number>(
    dependentFields?.fields.findIndex((field) =>
      equals(field.formControlName, key)
    )
  );
  const firstLevelSearchFieldValues = searchFieldValues?.[
    dependentFields?.fields[0].formControlName
  ] as Array<NestedSearchFieldValue>;
  const matchedValue = findDependentValueByIndex(
    firstLevelSearchFieldValues,
    matchedDependentFieldIndex,
    value
  );
  return { label, value: matchedValue };
};

export const findDependentValueByIndex = (
  searchFieldValues: Array<NestedSearchFieldValue>,
  index: number,
  value: string | number
): string => {
  const children = searchFieldValues?.flatMap<NestedSearchFieldValue>(
    prop('children')
  );
  while (index > 1) {
    index--;
    return findDependentValueByIndex(children, index, value);
  }
  return <string>(
    children?.find(({ id }) => equals(String(id), String(value)))?.label
  );
};
