export const transformKeysToTitleCase = (obj: any): any => {
  if (typeof obj !== 'object' || obj === null) {
    return obj;
  }

  if (Array.isArray(obj)) {
    return obj.map(transformKeysToTitleCase);
  }

  return Object.keys(obj).reduce((transformedObj: any, key: string) => {
    const transformedKey = transformStringToTitleCase(key);
    transformedObj[transformedKey] = transformKeysToTitleCase(obj[key]);
    return transformedObj;
  }, {});
};

export const transformStringToTitleCase = (key: string): string => {
  let transformedKey = key.replace(/([A-Z])/g, ' $1').toLowerCase();
  transformedKey = transformedKey.replace(/ id/g, ' ID'); // specifically handle 'Id' case
  let splitStrings = transformedKey.split(' ');
  splitStrings = splitStrings.map((str) => {
    return str.charAt(0).toUpperCase() + str.slice(1);
  });
  return splitStrings.join(' ');
};
