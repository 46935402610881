export const Message = {
  LoadingSearchResult: 'Loading Search Result...Please wait!',
  DownloadingSearchResult: 'Downloading Search Result...Please wait!',
  LoadingSearchFilterOptionsResult: 'Loading Search Filters...Please wait!',
  LoadSearchResultFailed:
    'Failed to load search result, please try again later',
  DownloadSearchResultFailed:
    'Failed to download search result, please try again later',
  LoadSearchFilterOptionsResultFailed:
    'Failed to load search filter, please try again later',
  LoadingPrintPrawnBallotReport:
    'Loading Print Prawn Ballot Report...Please wait!',
  TransactionPreventingError: 'There are one or more preventing errors.',
};
