import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  inject,
  Input,
  OnDestroy,
  OnInit,
  Output,
} from '@angular/core';
import { CommonModule } from '@angular/common';
import {
  FormControl,
  FormGroup,
  ReactiveFormsModule,
  Validators,
} from '@angular/forms';
import {
  ButtonLinkComponent,
  LoadingComponent,
  SearchDetailWrapperComponent,
  ToastService,
} from '@fishonline2023/webapps/shared/ui/base-components';
import {
  AdDetail,
  Message,
  Purpose,
  SaleAndTradeEditForm,
} from '@fishonline2023/webapps/model/fd2023';
import {
  BehaviorSubject,
  catchError,
  Subject,
  take,
  takeUntil,
  tap,
} from 'rxjs';
import {
  FDRoute,
  InputField,
  InputType,
  SaleAndTradeType,
  ViewStatus,
} from '@fishonline2023/shared/models';
import { ForSaleAndTradeService } from '@fishonline2023/webapps/notice-and-extract/fd2023/store';
import { Router, RouterLink } from '@angular/router';
import { EnumToStringPipe } from './enum-to-string.pipe';
import { forSaleAndTradeEditForm } from '../for-sale-and-trade-view-edit/for-sale-and-trade-edit-form';
import * as dayjs from 'dayjs';
import { InputFieldComponent } from '@fishonline2023/webapps/shared/ui/input-field';

@Component({
  selector: 'sv-ui-create-edit-ad',
  standalone: true,
  imports: [
    CommonModule,
    ReactiveFormsModule,
    ButtonLinkComponent,
    LoadingComponent,
    SearchDetailWrapperComponent,
    EnumToStringPipe,
    InputFieldComponent,
    RouterLink,
  ],
  templateUrl: './create-edit-ad.component.html',
  styleUrls: ['./create-edit-ad.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [ForSaleAndTradeService],
})
export class CreateEditAdComponent implements OnInit, OnDestroy {
  @Input() public isNewAd = true;
  @Input() public saleAndTradeEditForm: FormGroup<SaleAndTradeEditForm> =
    forSaleAndTradeEditForm();
  @Input() public saleAndTradeViewStatus$$: BehaviorSubject<ViewStatus> =
    new BehaviorSubject<ViewStatus>(ViewStatus.Initial);
  @Input() public message = '';
  @Input() public submitSubject: Subject<void> = new Subject<void>();
  @Output() public agreeTermsAndConditionsClicked = new EventEmitter<boolean>();
  public readonly ViewStatus = ViewStatus;
  public saleAndTradeViewStatus: ViewStatus = ViewStatus.Initial;
  public agreeTermsAndConditions: FormControl = new FormControl(false, {
    nonNullable: true,
    validators: [Validators.requiredTrue],
  });
  public purposeList: Purpose[] = [Purpose.Sale, Purpose.Buy];
  public typeList = Object.entries(SaleAndTradeType)
    .map(([id, label]) => ({
      id,
      label,
    }))
    .sort((a, b) => a.label.localeCompare(b.label));
  protected adPriceInputField: InputField = {
    formControlName: 'price',
    label: 'Price',
    type: InputType.Price,
  };
  protected readonly FDRoute = FDRoute;
  private cdr = inject(ChangeDetectorRef);
  private toastService = inject(ToastService);
  private saleAndTradeService = inject(ForSaleAndTradeService);
  private readonly router = inject(Router);
  private destroy$ = new Subject<void>();

  public ngOnInit() {
    // Set the default value for the expiry date to be 60 days from today for new ads only
    if (this.isNewAd) {
      const currentDate = dayjs();
      const futureDate = currentDate.add(60, 'day').format('YYYY-MM-DD');
      this.saleAndTradeEditForm.patchValue({
        expiryDate: futureDate,
      });
    }
    this.agreeTermsAndConditions.valueChanges
      .pipe(takeUntil(this.destroy$))
      .subscribe((value) => {
        this.agreeTermsAndConditionsClicked.emit(value);
      });

    this.saleAndTradeViewStatus$$
      ?.pipe(takeUntil(this.destroy$))
      .subscribe((saleAndTradeViewStatus) => {
        this.saleAndTradeViewStatus = saleAndTradeViewStatus;
        this.cdr.detectChanges();
      });

    this.submitSubject?.pipe(take(1)).subscribe(() => {
      this.handleSubmitClick();
    });
  }

  public ngOnDestroy() {
    this.destroy$.next();
    this.destroy$.complete();
  }

  public handleSubmitClick(): void {
    const adDetail = this.saleAndTradeEditForm.value as AdDetail;
    adDetail.expiryDate = new Date(adDetail.expiryDate).getTime();
    if (this.isNewAd) {
      this.message = Message.CreatingNewAd;
      this.saleAndTradeViewStatus$$?.next(ViewStatus.Loading);
      this.saleAndTradeService
        .createNewAd(adDetail)
        .pipe(
          tap((adId: number) => {
            this.saleAndTradeViewStatus$$?.next(ViewStatus.Success);
            this.saleAndTradeEditForm.reset();
            this.router.navigate(
              [`/${FDRoute.ForSaleAndTradeSearch}/detail/ad-detail`],
              {
                queryParams: {
                  id: adId,
                },
              }
            );
          }),
          catchError((error) => {
            this.saleAndTradeViewStatus$$?.next(ViewStatus.Failure);
            this.toastService.showError(Message.CreatingNewAdError);
            this.cdr.detectChanges();
            throw error;
          })
        )
        .subscribe();
    }
  }
}
