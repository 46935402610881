import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { CommonModule } from '@angular/common';
import { isEmpty } from '@fishonline2023/shared/ramda';

@Component({
  selector: 'sv-ui-transaction-message',
  standalone: true,
  imports: [CommonModule],
  templateUrl: './transaction-message.component.html',
  styleUrls: ['./transaction-message.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TransactionMessageComponent {
  @Input() public messageTitle!: string;
  @Input() public errorList: string[] = [];
  @Input() public warningList: string[] = [];
  @Input() public informationList: string[] = [];

  protected get isErrorMessage() {
    return !isEmpty(this.errorList);
  }
}
