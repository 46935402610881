import { createFeatureSelector, createSelector } from '@ngrx/store';
import {
  DATA_LOADER_FEATURE_KEY,
  DataLoaderState,
} from './data-loader.reducers';

const dataLoaderFeature = createFeatureSelector<DataLoaderState>(
  DATA_LOADER_FEATURE_KEY
);

export const getDataLoaderData = createSelector(
  dataLoaderFeature,
  (state: DataLoaderState) => state.dataLoaderData
);

export const getDataLoaderType = createSelector(
  dataLoaderFeature,
  (state: DataLoaderState) => state.dataLoaderType
);

export const getDataLoaderChannel = createSelector(
  dataLoaderFeature,
  (state: DataLoaderState) => state?.channel
);

export const getViewStatus = createSelector(
  dataLoaderFeature,
  (state: DataLoaderState) => state.viewStatus
);

export const getErrorMessage = createSelector(
  dataLoaderFeature,
  (state: DataLoaderState) => state?.errorMessage
);
