import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { CommonModule } from '@angular/common';

type MessageSize = 'SMALL' | 'NORMAL' | 'LARGE';
type MessageType = 'INFO' | 'WARNING' | 'ERROR' | 'SUCCESS';
type IconType = 'info' | 'error' | 'cancel' | 'check_circle';
type IconColor = 'info' | 'warning' | 'danger' | 'success';
type FontSize = 'fs-1' | 'fs-2' | 'fs-3' | 'fs-4' | 'fs-5' | 'fs-6';

@Component({
  selector: 'sv-ui-message',
  standalone: true,
  imports: [CommonModule],
  templateUrl: './message.component.html',
  styleUrls: ['./message.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class MessageComponent {
  icon: IconType = 'info';
  iconColor: IconColor = 'info';
  iconSize: FontSize = 'fs-1';
  messageSize: FontSize = 'fs-3';

  @Input() message = '';
  @Input() set type(type: MessageType) {
    switch (type) {
      case 'INFO':
        this.icon = this.iconColor = 'info';
        break;
      case 'WARNING':
        this.icon = 'error';
        this.iconColor = 'warning';
        break;
      case 'ERROR':
        this.icon = 'cancel';
        this.iconColor = 'danger';
        break;
      case 'SUCCESS':
        this.icon = 'check_circle';
        this.iconColor = 'success';
        break;
    }
  }
  @Input() set size(size: MessageSize) {
    switch (size) {
      case 'SMALL':
        this.iconSize = 'fs-3';
        this.messageSize = 'fs-4';
        break;
      case 'NORMAL':
        this.iconSize = 'fs-2';
        this.messageSize = 'fs-3';
        break;
      case 'LARGE':
      default:
        this.iconSize = 'fs-1';
        this.messageSize = 'fs-3';
        break;
    }
  }
}
