<div
  *ngIf="transactionActionButtons && transactionActionButtons.length > 0"
  class="transaction-action-buttons-container rounded-2 p-3 d-print-none"
>
  <div *ngIf="checkboxFormArray && checkboxList" class="mb-3">
    <ng-container *ngFor="let termCondition of checkboxList; index as i">
      <div class="form-check form-control-lg">
        <input
          [formControl]="checkboxFormArray.controls[i]"
          class="form-check-input"
          type="checkbox"
          value=""
        />
        <label [innerHTML]="termCondition" class="form-check-label"> </label>
      </div>
    </ng-container>
  </div>
  <div class="d-flex justify-content-between align-items-center">
    <div class="start-align-buttons d-flex gap-2">
      <ng-container *ngFor="let button of startTransactionActionButtons">
        <button
          (click)="transactionActionButtonClicked.emit(button)"
          [attr.data-cy]="button.buttonType"
          [disabled]="button.disabled"
          [ngClass]="button.className"
          class="btn"
        >
          {{ button.label }}
        </button>
      </ng-container>
    </div>
    <div class="end-align-buttons d-flex gap-2">
      <ng-container *ngFor="let button of endTransactionActionButtons">
        <button
          (click)="transactionActionButtonClicked.emit(button)"
          [attr.data-cy]="button.buttonType"
          [disabled]="button.disabled"
          [ngClass]="button.className"
          class="btn"
        >
          {{ button.label }}
        </button>
      </ng-container>
    </div>
  </div>
</div>
