import { TransactionType } from '@fishonline2023/webapps/model/fd2023';
import { EventType } from '@fishonline2023/webapps/shared/feature/appsync';

export const TransactionEventType: Record<TransactionType, EventType> = {
  [TransactionType.AmendCustomer]: EventType.UpdateContact,
  [TransactionType.NominateRevokeFisher]: EventType.NominateRevokeFisher,
  [TransactionType.RenewLicence]: EventType.RenewCFL,
  [TransactionType.TransferQuota]: EventType.TransferQuota,
  [TransactionType.TransferComponents]: EventType.TransferComponents,
  [TransactionType.TransferFishingBusiness]: EventType.TransferFishingBusiness,
};

export const TransactionTypeReadable: Record<TransactionType, string> = {
  [TransactionType.AmendCustomer]: 'Amend Customer',
  [TransactionType.NominateRevokeFisher]: 'Nominate Revoke Fisher',
  [TransactionType.RenewLicence]: 'Renew CFL',
  [TransactionType.TransferComponents]: 'Transfer Components',
  [TransactionType.TransferFishingBusiness]: 'Transfer Fishing Business',
  [TransactionType.TransferQuota]: 'Transfer quota',
};

export enum TransactionPaymentStatus {
  Successful = 'SUCCESSFUL',
  Failed = 'FAILED',
}
