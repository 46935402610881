import {
  AfterViewInit,
  ChangeDetectionStrategy,
  Component,
  ElementRef,
  inject,
  Input,
  OnDestroy,
  ViewChild,
} from '@angular/core';
import { CommonModule } from '@angular/common';
import { ModalService } from './modal.service';
import * as bootstrap from 'bootstrap';
import { Modal, ModalId, ModalSize } from '@fishonline2023/shared/models';

@Component({
  selector: 'sv-ui-modal',
  standalone: true,
  imports: [CommonModule],
  templateUrl: './modal.component.html',
  styleUrls: ['./modal.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ModalComponent implements AfterViewInit, OnDestroy {
  @Input() modalId!: ModalId;
  @Input() modalSize!: ModalSize;
  @ViewChild('modalContainer')
  protected modalContainer!: ElementRef;
  private modal!: bootstrap.Modal;
  private modalService = inject(ModalService);

  public ngOnDestroy() {
    this.modalService.removeModal(this.getModal());
  }

  public ngAfterViewInit() {
    this.modal = new bootstrap.Modal(this.modalContainer.nativeElement, {
      backdrop: 'static',
      keyboard: false,
    });
    this.modalService.addModal(this.getModal());
  }

  private getModal(): Modal {
    return { modal: this.modal, id: this.modalId };
  }
}
