import { FormControl } from '@angular/forms';
import { Customer } from '@fishonline2023/shared/models';
import { FisherAction } from './fisher-action.enum';

export interface NominateRevokeTransactionDetail {
  action: FisherAction;
  nominatedFisher: Customer;
  previousFisher: Customer;
}

export interface NominateRevokeTransactionReference {
  currentAuthorisedFisher: Customer;
  availableActionList: FisherAction[];
  candidateFisherList: Customer[];
}

export interface NominateRevokeFisherForm {
  action: FormControl<FisherAction>;
  previousFisher: FormControl<Customer | undefined | null>;
  nominatedFisher?: FormControl<Customer | undefined | null>;
}

export interface FisherOption {
  id: number;
  fullName: string;
  label: string;
}
