import { inject, Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { filter, pipe, switchMap, take, withLatestFrom } from 'rxjs';
import { FDAnalyticsService } from '../services/fd-analytics.service';
import {
  selectConfirmPath,
  selectTransactionCreditCardDetail,
  selectTransactionDataFromGET,
  selectTransactionDataFromSubmit,
  selectTransactionRequestParams,
  transactionAbandonSuccessful,
  transactionDataAcceptSuccessful,
  transactionDataConfirmSuccessful,
  transactionDataLoadSuccessful,
  transactionDataPayAndConfirmFailed,
  transactionDataPayAndConfirmSuccessful,
  transactionDataPayLaterSuccessful,
  transactionDataRejectSuccessful,
  transactionDataSubmitSuccessful,
  transactionDataWithDrawSuccessful,
  transactionPayLaterPrint,
  transactionPaymentCreditCardDetailValidatedSuccessful,
  transactionProvideCardDetail,
} from '@fishonline2023/webapps/transaction/fd2023/feature/transaction-store';
import { loginSuccessful } from '@fishonline2023/webapps/auth/fd2023/feature/login';
import { Store } from '@ngrx/store';
import {
  agentRoleSwitchedSuccessful,
  getActAsCustomer,
  getCustomerPermissionList,
} from '@fishonline2023/webapps/user-portal/fd2023/store/user-profile';
import { complement, equals, isNil } from '@fishonline2023/shared/ramda';
import {
  TransactionCreditCardDetail,
  TransactionData,
  TransactionType,
} from '@fishonline2023/webapps/model/fd2023';
import { AgentCustomer } from '@fishonline2023/shared/models';
import {
  deleteAgentSuccess,
  saveAgentSuccess,
} from '@fishonline2023/webapps/my-account/fd2023/store/manage-agents-store';

@Injectable()
export class FDAnalyticsEffects {
  private actions$ = inject(Actions);
  private store = inject(Store);
  private analyticsService = inject(FDAnalyticsService);

  loginSuccessful$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(loginSuccessful),
        switchMap(() => this.analyticsService.recordLoginSuccessfulEvent())
      ),
    { dispatch: false }
  );

  transactionSubmitted$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(transactionDataSubmitSuccessful),
        withLatestFrom(
          this.store.select(selectTransactionRequestParams),
          this.store.select(selectTransactionDataFromGET)
        ),
        switchMap(
          ([{ transactionData }, requestParam, transactionDataFromGET]) =>
            this.analyticsService.recordTransactionDataSubmitSuccessfulEvent(
              transactionData,
              requestParam,
              transactionDataFromGET
            )
        )
      ),
    { dispatch: false }
  );

  transactionDataPayLaterSuccessful$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(transactionDataPayLaterSuccessful),
        switchMap(({ transactionData }) =>
          this.analyticsService.recordTransactionPayLaterSuccessfulEvent(
            transactionData
          )
        )
      ),
    { dispatch: false }
  );

  transactionProvideCardDetail$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(transactionProvideCardDetail),
        switchMap(() =>
          this.store
            .select(selectTransactionDataFromSubmit)
            .pipe(filter(complement(isNil)), take(1))
        ),
        switchMap((transactionData) =>
          this.analyticsService.recordTransactionProvideCardDetailEvent(
            transactionData as TransactionData
          )
        )
      ),
    { dispatch: false }
  );

  transactionPaymentCreditCardDetailValidatedSuccessful$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(transactionPaymentCreditCardDetailValidatedSuccessful),
        withLatestFrom(
          this.store
            .select(selectTransactionDataFromSubmit)
            .pipe(filter(complement(isNil)))
        ),
        switchMap(([{ transactionCreditCardDetail }, transactionData]) =>
          this.analyticsService.recordTransactionConfirmPaymentDetailSuccessfulEvent(
            transactionCreditCardDetail,
            transactionData as TransactionData
          )
        )
      ),
    { dispatch: false }
  );

  transactionDataPayAndConfirmSuccessful$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(transactionDataPayAndConfirmSuccessful),
        withLatestFrom(
          this.store
            .select(selectTransactionCreditCardDetail)
            .pipe(filter(complement(isNil))),
          this.store
            .select(selectTransactionDataFromGET)
            .pipe(filter(complement(isNil)))
        ),
        switchMap(
          ([
            { transactionData },
            transactionCreditCardDetail,
            transactionDataFromGET,
          ]) =>
            this.analyticsService.recordTransactionPayAndConfirmSuccessfulEvent(
              transactionCreditCardDetail as TransactionCreditCardDetail,
              transactionData,
              transactionDataFromGET
            )
        )
      ),
    { dispatch: false }
  );

  transactionDataPayAndConfirmFailed$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(transactionDataPayAndConfirmFailed),
        withLatestFrom(
          this.store
            .select(selectTransactionDataFromSubmit)
            .pipe(filter(complement(isNil))),
          this.store
            .select(selectTransactionCreditCardDetail)
            .pipe(filter(complement(isNil)))
        ),
        switchMap(
          ([
            { transactionPaymentConfirmError },
            transactionData,
            transactionCreditCardDetail,
          ]) =>
            this.analyticsService.recordTransactionPayAndConfirmFailedEvent(
              transactionCreditCardDetail as TransactionCreditCardDetail,
              transactionData as TransactionData,
              transactionPaymentConfirmError.errorMessage
            )
        )
      ),
    { dispatch: false }
  );

  transactionPayLaterPrint$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(transactionPayLaterPrint),
        switchMap(() =>
          this.store
            .select(selectTransactionDataFromSubmit)
            .pipe(filter(complement(isNil)), take(1))
        ),
        switchMap((transactionData) =>
          this.analyticsService.recordTransactionPayLaterPrintEvent(
            transactionData as TransactionData
          )
        )
      ),
    { dispatch: false }
  );

  transactionConfirmed$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(transactionDataConfirmSuccessful),
        withLatestFrom(
          this.store.select(selectTransactionRequestParams),
          this.store.select(selectConfirmPath),
          this.store.select(selectTransactionDataFromGET)
        ),
        switchMap(
          ([
            { transactionData },
            requestParam,
            confirmPath,
            transactionDataFromGET,
          ]) =>
            this.analyticsService.recordTransactionDataConfirmSuccessfulEvent({
              transactionData,
              confirmPath,
              requestParam,
              transactionDataFromGET,
            })
        )
      ),
    { dispatch: false }
  );

  transactionWithdrew$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(transactionDataWithDrawSuccessful),
        this.analyticsService.withAcceptWithdrawRejectTransactionData(
          this.analyticsService.recordTransactionDataWithdrawSuccessfulEvent
        )
      ),
    { dispatch: false }
  );

  transactionReject$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(transactionDataRejectSuccessful),
        this.analyticsService.withAcceptWithdrawRejectTransactionData(
          this.analyticsService.recordTransactionDataRejectSuccessfulEvent
        )
      ),
    { dispatch: false }
  );

  transactionAccept$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(transactionDataAcceptSuccessful),
        this.analyticsService.withAcceptWithdrawRejectTransactionData(
          this.analyticsService.recordTransactionDataAcceptSuccessfulEvent
        )
      ),
    { dispatch: false }
  );

  transactionAbandoned$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(transactionAbandonSuccessful),
        switchMap(() =>
          this.store
            .select(selectTransactionDataFromGET)
            .pipe(filter(complement(isNil)), take(1))
        ),
        withLatestFrom(this.store.select(selectTransactionRequestParams)),
        switchMap(([transactionData, requestParam]) =>
          this.analyticsService.recordTransactionAbandonedEvent(
            transactionData,
            requestParam
          )
        )
      ),
    { dispatch: false }
  );

  deleteAgentSuccessful$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(deleteAgentSuccess),
        withLatestFrom(
          this.store.select(getActAsCustomer).pipe(filter(complement(isNil)))
        ),
        switchMap(([{ agent }, actAsCustomer]) =>
          this.analyticsService.recordAgentDeleteSuccessfulEvent(
            agent,
            actAsCustomer
          )
        )
      ),
    { dispatch: false }
  );
  saveAgentSuccessful$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(saveAgentSuccess),
        withLatestFrom(
          this.store.select(getActAsCustomer).pipe(filter(complement(isNil)))
        ),
        switchMap(([{ agent, saveEventType }, actAsCustomer]) =>
          this.analyticsService.recordAgentSaveSuccessfulEvent(
            agent,
            saveEventType,
            actAsCustomer
          )
        )
      ),
    { dispatch: false }
  );
  private switchMapToActAsCustomer = pipe(
    switchMap(() => this.store.select(getActAsCustomer).pipe(take(1))),
    filter(complement(isNil))
  );
  transactionLoaded$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(transactionDataLoadSuccessful),
        filter(({ transactionData }) =>
          equals(
            transactionData.transactionHeader.type,
            TransactionType.AmendCustomer
          )
        ),
        this.switchMapToActAsCustomer,
        switchMap((actAsCustomer: AgentCustomer) =>
          this.analyticsService.recordViewContactDetailEvent(actAsCustomer)
        )
      ),
    { dispatch: false }
  );
  private switchMapToActAsCustomerAndPermissionList = pipe(
    switchMap(() =>
      this.store
        .select(getActAsCustomer)
        .pipe(
          take(1),
          filter(complement(isNil)),
          withLatestFrom(this.store.select(getCustomerPermissionList))
        )
    )
  );
  agentRoleSwitchedSuccessful$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(agentRoleSwitchedSuccessful),
        this.switchMapToActAsCustomerAndPermissionList,
        switchMap(([actAsCustomer, permissionList]) =>
          this.analyticsService.recordAgentRoleSwitchedSuccessfulEvent(
            actAsCustomer,
            permissionList
          )
        )
      ),
    { dispatch: false }
  );
}
