import {
  ChangeDetectionStrategy,
  Component,
  Input,
  OnInit,
} from '@angular/core';
import { CommonModule } from '@angular/common';
import { InputField } from '@fishonline2023/shared/models';
import { FormControl, ReactiveFormsModule } from '@angular/forms';
import * as dayjs from 'dayjs';
import { isNilOrEmpty } from '@fishonline2023/shared/ramda';

@Component({
  selector: 'sv-ui-input-field-date',
  standalone: true,
  imports: [CommonModule, ReactiveFormsModule],
  templateUrl: './input-field-date.component.html',
  styleUrls: ['./input-field-date.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class InputFieldDateComponent implements OnInit {
  @Input() public inputField!: InputField;
  @Input() public form!: FormControl;
  private readonly HTML_DATE_FORMAT = 'YYYY-MM-DD';

  public ngOnInit() {
    if (isNilOrEmpty(this.form.value)) {
      return;
    }
    this.form.patchValue(dayjs(this.form.value).format(this.HTML_DATE_FORMAT), {
      emitEvent: false,
    });
  }
}
