import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TransactionData } from '@fishonline2023/webapps/model/fd2023';
import {
  AddressForm,
  BillingAddressForm,
  FDCustomerDetail,
  LabelNameMap,
} from '@fishonline2023/shared/models';
import { equals } from '@fishonline2023/shared/ramda';
import {
  AddressDetail,
  addressDetails,
  contactDetailKeys,
} from './contact-address-details.constant';

@Component({
  selector: 'sv-ui-contact-address-details',
  standalone: true,
  imports: [CommonModule],
  templateUrl: './contact-address-details.component.html',
  styleUrls: ['./contact-address-details.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ContactAddressDetailsComponent {
  @Input() public transactionData!: TransactionData;
  public LabelNameMap = LabelNameMap;
  public contactDetailKeys = contactDetailKeys;
  public addressDetails = addressDetails;

  public contactDetailValue(key: keyof FDCustomerDetail) {
    const customerDetail = this.transactionData
      ?.transactionDetail as FDCustomerDetail;
    return customerDetail[key];
  }

  public formGroupValueByName(addressDetail: AddressDetail) {
    const customerDetail = this.transactionData
      ?.transactionDetail as FDCustomerDetail;
    const { streetAddress, suburb, state, postcode, ...details } =
      customerDetail[addressDetail.formGroup];
    if (equals(addressDetail.addressOrDetail, 'address')) {
      return Object.entries({ streetAddress, suburb, state, postcode }) as [
        keyof AddressForm,
        string
      ][];
    }
    return Object.entries(details) as [keyof BillingAddressForm, string][];
  }

  public shouldDisplayPreferredMethod(key: keyof FDCustomerDetail) {
    return equals(
      (this.transactionData.transactionDetail as FDCustomerDetail)
        .preferredMethod,
      key
    );
  }
}
