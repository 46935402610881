import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ItemList } from '@fishonline2023/shared/models';
import { FullWidthItemListComponent } from '../full-width-item-list/full-width-item-list.component';
import { LicenceHeader } from '@fishonline2023/webapps/model/fd2023';

@Component({
  selector: 'sv-ui-licence-detail-info',
  standalone: true,
  imports: [CommonModule, FullWidthItemListComponent],
  templateUrl: './licence-detail-info.component.html',
  styleUrls: ['./licence-detail-info.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class LicenceDetailInfoComponent {
  @Input() public licenceHeader!: LicenceHeader;

  get licenceDetailMap(): ItemList {
    return [
      {
        key: 'Licence Holder',
        value: this.licenceHeader.holder,
      },
      {
        key: 'Licence Number',
        value: this.licenceHeader.number,
      },
      {
        key: 'Issued Date',
        value: this.licenceHeader.issuedDate,
      },
      {
        key: 'Expiry Date',
        value: this.licenceHeader.expiryDate,
      },
      {
        key: 'Status',
        value: this.licenceHeader.status,
      },
    ];
  }
}
