export type TableRowData = {
  value: string | number;
  link?: string;
};

export type TableData = Record<string, TableRowData>[];

export const tableRow = (
  value: string | number,
  link?: string
): TableRowData => ({
  value,
  link,
});
