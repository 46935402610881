<ng-container [formGroup]="searchForm" [ngSwitch]="searchField.fieldType">
  <div *ngSwitchCase="SearchFieldType.Text">
    <sv-ui-input-field
      [form]="searchForm.controls[flatSearchField.formControlName]"
      [inputField]="searchTextInputField"
    ></sv-ui-input-field>
  </div>
  <div *ngSwitchCase="SearchFieldType.Select">
    <sv-ui-input-field
      [form]="searchForm.controls[flatSearchField.formControlName]"
      [inputField]="searchSelectInputField"
    ></sv-ui-input-field>
  </div>
  <div *ngSwitchCase="SearchFieldType.TypeAhead">
    <label class="col-form-label">{{ flatSearchField.label }}</label>
    <sv-ui-typeahead
      [form]="searchForm.controls[flatSearchField.formControlName]"
      [options]="searchFieldValues[flatSearchField.formControlName]"
      [valuePath]="['id']"
      [withSearchIcon]="true"
    ></sv-ui-typeahead>
  </div>
  <div *ngSwitchCase="SearchFieldType.Date">
    <sv-ui-input-field
      [form]="this.searchForm.controls[flatSearchField.formControlName]"
      [inputField]="searchDateRangeInputField"
    ></sv-ui-input-field>
  </div>
  <div *ngSwitchCase="SearchFieldType.DateRange">
    <label class="col-form-label">{{ compositeSearchField.label }}</label>
    <div class="row">
      <div
        *ngFor="let field of compositeSearchField.fields"
        [class]="'col-sm-12 col-lg-' + field.width"
      >
        <sv-ui-search-field
          [searchField]="field"
          [searchForm]="searchForm"
        ></sv-ui-search-field>
      </div>
    </div>
  </div>
  <div *ngSwitchCase="SearchFieldType.RadioButtonPreFilter">
    <ng-container>
      <div
        *ngFor="let searchField of compositeSearchField.fields"
        class="form-check form-check-inline form-control-lg"
      >
        <input
          [attr.aria-label]="searchField.label"
          [attr.data-cy]="searchField.formControlName"
          [value]="searchField.formControlName"
          class="form-check-input radio-button"
          formControlName="filter"
          type="radio"
        />
        <label class="form-check-label">{{ searchField.label }}</label>
      </div>
      <sv-ui-search-field
        [searchFieldValues]="searchFieldValues"
        [searchField]="preFilterSearchField"
        [searchForm]="searchForm"
      ></sv-ui-search-field>
    </ng-container>
  </div>
  <div *ngSwitchCase="SearchFieldType.Dependent">
    <sv-ui-search-field
      [searchFieldValues]="firstLevelDependentSearchFieldValues"
      [searchField]="compositeSearchField.fields[0]"
      [searchForm]="searchForm"
    ></sv-ui-search-field>
    <ng-container *ngIf="isFirstLevelDependentFieldValid">
      <sv-ui-search-field
        [searchFieldValues]="nextCompositeSearchFieldValues"
        [searchField]="nextCompositeFields"
        [searchForm]="searchForm"
      ></sv-ui-search-field>
    </ng-container>
  </div>
  <div *ngSwitchCase="SearchFieldType.RadioButtonGroup">
    <label class="col-form-label">{{ flatSearchField.label }}</label>
    <br />
    <div
      aria-label="Basic radio toggle button group"
      class="btn-group btn-group-lg"
      role="group"
    >
      <ng-container
        *ngFor="let value of searchFieldValues[flatSearchField.formControlName]"
      >
        <input
          [attr.aria-label]="flatSearchField.label"
          [formControlName]="flatSearchField.formControlName"
          [id]="value.id"
          [value]="value.id"
          autocomplete="off"
          checked
          class="btn-check"
          type="radio"
        />
        <label [for]="value.id" class="btn btn-outline-primary">{{
          value.label
        }}</label>
      </ng-container>
    </div>
  </div>
</ng-container>
