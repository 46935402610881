import { HttpClient } from '@angular/common/http';
import { inject, Injectable } from '@angular/core';
import { environment as FDEnvironment } from '@env/fd/environment';
import { environment as FAEnvironment } from '@env/fa/environment';
import { DataLoaderType } from '@fishonline2023/shared/models';
import { combineLatest, filter, Observable, switchMap, take } from 'rxjs';
import { Store } from '@ngrx/store';
import {
  getDataLoaderChannel,
  getDataLoaderType,
} from './data-loader.selectors';
import { complement, equals, isNil, merge } from '@fishonline2023/shared/ramda';
import { Params } from '@angular/router';
import {
  FDDataLoaderData,
  FDDataLoaderMetadata,
} from '@fishonline2023/webapps/model/fd2023';
import {
  FADataLoaderData,
  FADataLoaderMetadata,
} from '@fishonline2023/webapps/model/fa2023';
import { Channel } from '@fishonline2023/webapps/shared/feature/appsync';

@Injectable()
export class DataLoaderService {
  private http = inject(HttpClient);
  private store = inject(Store);

  private dataLoaderType$ = this.store
    .select(getDataLoaderType)
    .pipe(filter(complement(isNil)), take(1));
  private dataLoaderChannel$ = this.store
    .select(getDataLoaderChannel)
    .pipe(filter(complement(isNil)), take(1));

  public loadDataLoaderData(
    params?: Params
  ): Observable<FDDataLoaderData | FADataLoaderData> {
    return combineLatest([this.dataLoaderType$, this.dataLoaderChannel$]).pipe(
      switchMap(
        ([dataLoaderType, dataLoaderChannel]: [DataLoaderType, Channel]) => {
          const environment = equals(dataLoaderChannel, Channel.FD)
            ? FDEnvironment
            : FAEnvironment;
          const dataLoaderAPIRoute = merge(
            FDDataLoaderMetadata,
            FADataLoaderMetadata
          )[dataLoaderType]?.apiRoute;
          return this.http.get<FDDataLoaderData | FADataLoaderData>(
            `${environment.apiUrl}/${dataLoaderAPIRoute}`,
            { params }
          );
        }
      )
    );
  }
}
