import { FormArray, FormControl, FormGroup } from '@angular/forms';
import { PaymentOption } from './transaction.model';

export interface TransactionPaymentFormGroup {
  paymentOption: FormControl<PaymentOption>;
  paymentFee: FormControl<number>;
  paymentHistory: FormArray<FormGroup<TransactionHistoryFormGroup>>;
}

export interface TransactionHistoryFormGroup {
  amount: FormControl<number | null>;
  reference: FormControl<string>;
  method: FormControl<string>;
  date: FormControl<string>;
}
