<div class="d-flex p-2 full-viewport-bg">
  <span class="fs-6 text-white">A NSW Goverment website</span>
</div>
<div class="d-flex flex-wrap py-3">
  <div class="d-flex align-items-center p-2">
    <a href="" routerLink="/home" data-cy="home"
      ><img class="logo" [src]="logo" alt="New South Wales Government logo"
    /></a>
  </div>
  <div class="d-flex align-items-center p-2 me-5">
    Department of
    <br />
    Primary Industries
  </div>
  <div class="d-flex flex-grow-1 fish-logo">
    <img alt="Fish online logo" src="assets/fish_online.png" />
  </div>
  <div class="d-flex d-print-none align-items-center p-2">
    <div class="logged-in-container">
      <div data-cy="logged-in-as">Logged in as {{ name }}</div>
      <div *ngIf="agentCustomerName && agentCustomerName !== name">
        Acting for {{ agentCustomerName }}
      </div>
    </div>
  </div>
  <div class="d-flex d-print-none align-items-center p-2">
    <sv-ui-button-link
      *ngFor="let button of buttons; let i = index"
      [text]="button.label"
      (click)="onButtonClick(button.id)"
      [ngClass]="{ 'ms-3': i > 0 }"
    ></sv-ui-button-link>
  </div>
</div>
