import {
  accountSummaryMenu,
  catchEffortHistoryMenu,
  contactDetailsMenu,
  customerFinanceDetailsMenu,
  fishingActivityHistoryMenu,
  fishingBusinessHistoryMenu,
  forSaleTradeMenu,
  licenceDetailsMenu,
  manageAgentsMenu,
  Menu,
  MenuName,
  myFishingBusinessAuthorisationsMenu,
  myFishonlineAccountActivityMenu,
  myPersonalAuthorisationsMenu,
  myReceiptsMenu,
  nominateRevokeFisherMenu,
  Permission,
  printPrawnBallotMenu,
  printShareExtractMenu,
  quotaTransactionMenu,
  quotaTransferMenu,
  SystemControl,
  tagTransactionHistoryMenu,
  tagTransactionsMenu,
  transferComponentsMenu,
  transferFishingBusinessMenu,
} from '@fishonline2023/webapps/model/fd2023';

export interface PermissionMenuValue {
  systemControl?: SystemControl;
  subMenu: Menu;
  parentMenuName: MenuName;
}

export const PermissionsMap: Partial<
  Record<Permission, Array<PermissionMenuValue>>
> = {
  [Permission.CATCH_EFFORT_HISTORY_INQUIRIES]: [
    {
      systemControl: SystemControl.CATCH_HISTORY_INQUIRIES,
      subMenu: catchEffortHistoryMenu,
      parentMenuName: MenuName.CATCH_EFFORT,
    },
    {
      systemControl: SystemControl.CATCH_HISTORY_INQUIRIES,
      subMenu: fishingActivityHistoryMenu,
      parentMenuName: MenuName.CATCH_EFFORT,
    },
  ],
  [Permission.QUOTA_TRANSFERS]: [
    {
      systemControl: SystemControl.QUOTA_TRANSFER,
      subMenu: quotaTransferMenu,
      parentMenuName: MenuName.QUOTA_FISHERIES,
    },
  ],
  [Permission.QUOTA_TRANSACTION_HISTORY_INQUIRIES]: [
    {
      systemControl: SystemControl.QUOTA_INQUIRIES,
      subMenu: quotaTransactionMenu,
      parentMenuName: MenuName.QUOTA_FISHERIES,
    },
  ],
  [Permission.TAG_TRANSACTION_HISTORY_INQUIRIES]: [
    {
      systemControl: SystemControl.TAGS_TRANSACTIONS,
      subMenu: tagTransactionsMenu,
      parentMenuName: MenuName.QUOTA_FISHERIES,
    },
  ],
  [Permission.TAG_TRANSACTION_HISTORY_INQUIRIES]: [
    {
      systemControl: SystemControl.TAGS_TRANSACTIONS,
      subMenu: tagTransactionHistoryMenu,
      parentMenuName: MenuName.QUOTA_FISHERIES,
    },
  ],
  [Permission.ENDORSEMENT_HISTORY_INQUIRIES]: [
    {
      subMenu: fishingBusinessHistoryMenu,
      parentMenuName: MenuName.FISHING_BUSINESS,
    },
  ],
  [Permission.TRANSFER_FB_OFFER_COMPONENT_OFFER_RECEIVE]: [
    {
      systemControl: SystemControl.FB_COMPONENT_TRANSFER,
      subMenu: transferComponentsMenu,
      parentMenuName: MenuName.FISHING_BUSINESS,
    },
    {
      systemControl: SystemControl.FB_COMPONENT_TRANSFER,
      subMenu: transferFishingBusinessMenu,
      parentMenuName: MenuName.FISHING_BUSINESS,
    },
  ],
  [Permission.AUTHORISE_REVOKE_FISHER]: [
    {
      systemControl: SystemControl.AUTHORISE_REVOKE_FISHER,
      subMenu: nominateRevokeFisherMenu,
      parentMenuName: MenuName.AUTHORISED_FISHERS,
    },
  ],
  [Permission.MY_PERSONAL_AUTHORISATIONS]: [
    {
      subMenu: myPersonalAuthorisationsMenu,
      parentMenuName: MenuName.AUTHORISED_FISHERS,
    },
  ],
  [Permission.MY_FISHING_BUSINESS_AUTHORISATIONS]: [
    {
      subMenu: myFishingBusinessAuthorisationsMenu,
      parentMenuName: MenuName.AUTHORISED_FISHERS,
    },
  ],
  [Permission.VIEW_LICENCE_DETAILS]: [
    {
      systemControl: SystemControl.LICENCE_DETAILS,
      subMenu: licenceDetailsMenu,
      parentMenuName: MenuName.LICENCE,
    },
  ],
  [Permission.MESSAGES]: [
    {
      systemControl: SystemControl.MESSAGING,
      subMenu: myReceiptsMenu,
      parentMenuName: MenuName.NOTICE_AND_EXTRACT,
    },
  ],
  [Permission.FOR_SALE_TRADE]: [
    {
      systemControl: SystemControl.BULLETIN_BOARD,
      subMenu: forSaleTradeMenu,
      parentMenuName: MenuName.NOTICE_AND_EXTRACT,
    },
    {
      subMenu: printShareExtractMenu,
      parentMenuName: MenuName.NOTICE_AND_EXTRACT,
    },
    {
      subMenu: printPrawnBallotMenu,
      parentMenuName: MenuName.NOTICE_AND_EXTRACT,
    },
  ],
  [Permission.ACCOUNT_MANAGEMENT]: [
    {
      systemControl: SystemControl.CUSTOMER_DETAILS,
      subMenu: accountSummaryMenu,
      parentMenuName: MenuName.MY_ACCOUNT,
    },
  ],
  [Permission.VIEW_EDIT_CONTACT_DETAILS]: [
    {
      subMenu: contactDetailsMenu,
      parentMenuName: MenuName.MY_ACCOUNT,
    },
  ],
  [Permission.USER_ACTIVITY_MONITOR]: [
    {
      subMenu: myFishonlineAccountActivityMenu,
      parentMenuName: MenuName.MY_ACCOUNT,
    },
  ],
  [Permission.AGENT_MANAGEMENT]: [
    {
      subMenu: manageAgentsMenu,
      parentMenuName: MenuName.MY_ACCOUNT,
    },
  ],
  [Permission.MANAGE_FINANCE]: [
    {
      systemControl: SystemControl.FINANCE_DETAIL,
      subMenu: customerFinanceDetailsMenu,
      parentMenuName: MenuName.MY_ACCOUNT,
    },
  ],
};
