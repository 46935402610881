import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ItemList } from '@fishonline2023/shared/models';

@Component({
  selector: 'sv-ui-dual-column-items-list-component',
  standalone: true,
  imports: [CommonModule],
  templateUrl: './dual-column-items-list.component.html',
  styleUrls: ['./dual-column-items-list.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DualColumnItemsListComponent {
  @Input() public itemList!: ItemList;
}
