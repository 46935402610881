import {
  Channel,
  UserType,
} from '@fishonline2023/webapps/shared/feature/appsync';
import { UserProfile } from '@fishonline2023/webapps/model/fd2023';
import { equals, isNil } from '@fishonline2023/shared/ramda';
import { AgentCustomer } from '@fishonline2023/shared/models';

export const getUserType = (
  channel?: Channel,
  userProfile?: UserProfile,
  actAsCustomer?: AgentCustomer
) => {
  if (equals(channel, Channel.FA)) {
    return UserType.Department;
  }
  if (equals(userProfile?.id, actAsCustomer?.id)) {
    return UserType.Customer;
  }
  return UserType.Agent;
};

export const formatTwoDecimalPlaces = (num: number): string => {
  return !isNil(num) ? `$${num.toFixed(2)}` : '';
};
