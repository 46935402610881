<sv-ui-search-detail-wrapper
  *ngIf="shareholderReport$ | async as shareholderReport"
>
  <div class="d-flex mt-4 justify-content-between align-items-center mb-4">
    <h3>Shareholder's extracts</h3>
    <button class="btn btn-primary d-print-none" (click)="window.print()">
      Print
    </button>
  </div>
  <h4 class="shade">SHAREHOLDER</h4>
  <sv-ui-full-width-item-list
    [isLongLabel]="true"
    [itemList]="shareholderSummary(shareholderReport)"
  ></sv-ui-full-width-item-list>
  <hr />
  <h4 class="shade">THE SHAREHOLDER IS THE CURRENT AUTHORISED FISHER FOR:</h4>
  <ng-container
    *ngIf="shareholderReport.currentAuthorisedFisherFor.length > 0; else none"
  >
    <sv-ui-table
      [tableData]="fishingBusinessAuthorisedFor(shareholderReport)"
    ></sv-ui-table>
  </ng-container>
  <h4 class="shade">SHAREHOLDER'S AUTHORISATION HISTORY:</h4>
  <ng-container
    *ngIf="
      shareholderReport.shareholderAuthorisationHistory.length > 0;
      else none
    "
  >
    <sv-ui-table
      [tableData]="shareHolderAuthorisationHistory(shareholderReport)"
    ></sv-ui-table>
  </ng-container>

  <p class="shade">
    Note: The information shown in the “Shareholder Authorisation history” table
    above commences from 24/8/2012. Any information prior to this date can be
    obtained by contacting the Department.
  </p>
  <h4 class="shade">
    SHAREHOLDER'S DEMERIT POINTS: display all demerit point activities
  </h4>
  <ng-container
    *ngIf="
      shareholderReport.shareholderDemeritPointActivityList.length > 0;
      else none
    "
  >
    <sv-ui-table
      [tableData]="shareholderDemeritPointActivityList(shareholderReport)"
    ></sv-ui-table>
  </ng-container>
  <hr />
  <sv-ui-fishing-business-section
    *ngFor="
      let fishingBusiness of shareholderReport.currentlyOwnedFishingBusinessList
    "
    title="CURRENTLY OWNED FISHING BUSINESS(ES)"
    [fishingBusiness]="fishingBusiness"
  ></sv-ui-fishing-business-section>
  <hr />
  <sv-ui-fishing-business-section
    *ngFor="
      let fishingBusiness of shareholderReport.previouslyOwnedFishingBusiness
    "
    title="PREVIOUSLY OWNED FISHING BUSINESS(ES)"
    [fishingBusiness]="fishingBusiness"
  ></sv-ui-fishing-business-section>
  <hr />
  <ng-template #none>
    <p>None</p>
  </ng-template>
</sv-ui-search-detail-wrapper>
