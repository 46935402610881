import {
  Menu,
  MenuName,
  Permission,
  SystemStatus,
} from '@fishonline2023/webapps/model/fd2023';
import { groupBy, isNil, prop } from '@fishonline2023/shared/ramda';
import { PermissionMenuValue, PermissionsMap } from './permission-map';

export const getEnabledMenuListFromSystemStatusAndPermissions = (
  systemStatus: SystemStatus | undefined,
  permissionList: Permission[]
): PermissionMenuValue[] => {
  return Object.entries(PermissionsMap).flatMap(
    ([permission, permissionMenuValues]) => {
      if (!permissionList.includes(<Permission>permission)) {
        return [];
      }
      return permissionMenuValues.filter((permissionMenuValue) => {
        if (isNil(permissionMenuValue.systemControl)) {
          return true;
        }
        return systemStatus?.[permissionMenuValue.systemControl];
      });
    }
  );
};

export const getMenuGroupByParentMenuName = (
  enabledMenuList: PermissionMenuValue[]
) => {
  return groupBy(prop('parentMenuName'), enabledMenuList) as Partial<
    Record<MenuName, PermissionMenuValue[]>
  >;
};

export const generateMenuFromMenuGroupedByParentMenuName = (
  menus: Partial<Record<MenuName, PermissionMenuValue[]>>
): Menu[] => {
  return Object.entries(menus).map(
    ([menuName, permissionMenuValueList]): Menu => ({
      name: menuName as MenuName,
      subMenuList: permissionMenuValueList.map(
        (permissionMenuValue) => permissionMenuValue.subMenu
      ),
    })
  );
};

export const createMenuFromSystemStatusAndPermissions = (
  systemStatus: SystemStatus | undefined,
  permissionList: Permission[]
) => {
  const enabledMenuList = getEnabledMenuListFromSystemStatusAndPermissions(
    systemStatus,
    permissionList
  );
  const menuGroupedByParentMenuName =
    getMenuGroupByParentMenuName(enabledMenuList);
  return generateMenuFromMenuGroupedByParentMenuName(
    menuGroupedByParentMenuName
  );
};
