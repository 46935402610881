import { inject, Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { FAAnalyticsService } from '../services/fa-analytics.service';
import { switchMap } from 'rxjs';
import {
  transactionApprovedSuccessful,
  transactionDeletedSuccessful,
  transactionNotApprovedSuccessful,
  transactionSubmittedSuccessful,
} from '@fishonline2023/webapps/transaction/fa2023/feature/transaction-store';

@Injectable()
export class FAAnalyticsEffects {
  private actions$ = inject(Actions);
  private analyticsService = inject(FAAnalyticsService);

  transactionSubmitted$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(transactionSubmittedSuccessful),
        switchMap(({ transactionData, fromButtonType }) =>
          this.analyticsService.recordTransactionSubmitSuccessfulEvent(
            transactionData,
            fromButtonType
          )
        )
      ),
    { dispatch: false }
  );

  transactionDeleted$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(transactionDeletedSuccessful),
        switchMap(({ transactionData }) =>
          this.analyticsService.recordTransactionDeleteSuccessfulEvent(
            transactionData
          )
        )
      ),
    { dispatch: false }
  );

  transactionApproved$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(transactionApprovedSuccessful),
        switchMap(({ transactionData }) =>
          this.analyticsService.recordTransactionApproveSuccessfulEvent(
            transactionData
          )
        )
      ),
    { dispatch: false }
  );

  transactionNotApproved$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(transactionNotApprovedSuccessful),
        switchMap(({ transactionData }) =>
          this.analyticsService.recordTransactionNotApproveSuccessfulEvent(
            transactionData
          )
        )
      ),
    { dispatch: false }
  );
}
