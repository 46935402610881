import { Customer } from '@fishonline2023/shared/models';
import { TransactionType } from './transaction.enum';

export enum PendingTransactionAction {
  Withdraw = 'Withdraw',
  Accept = 'Accept',
  Submit = 'Submit',
}

export interface PendingTransaction {
  entityId: number;
  toEntityId?: number;
  action?: PendingTransactionAction;
  type: TransactionType;
  date: string;
  customer: Customer;
}

export interface PendingTransactionForCardInput extends PendingTransaction {
  title: string;
  description: string;
}
