import { FormArray, FormControl, FormGroup } from '@angular/forms';
import { InputField } from './input.model';
import { SelectionOption } from './selection-option.model';
import { BootstrapWidth } from './bootstrap.model';

export interface RegistrationDetail {
  registrationNumber: number;
  registrationStatus: RegistrationStatus;
  customerType: CustomerType;
  whfFolderNumber: string;
  sapCustomerNumber: string;
  repositoryName?: string;
  mortgageAll?: boolean;
  customerName?: string;
  tradingAs?: string;
  abn?: string;
  acn?: string;
  asic?: ASICStatus;
  fisheriesOffice?: string;
  fisheriesOfficePhoneNumber?: string;
  registrationDate?: string;
}

export interface CustomerName {
  firstName?: string;
  otherName?: string;
  lastName?: string;
  dob?: string;
  gender?: Gender;
}

export interface Partner {
  firstName: string;
  otherName: string;
  lastName: string;
  dob: string;
}

export interface FDCustomerDetail {
  [ContactDetail.EmailAddress]: string;
  [ContactDetail.HomePhone]: string;
  [ContactDetail.WorkPhone]: string;
  [ContactDetail.Mobile]: string;
  fax: string;
  contactName: string;
  preferredMethod: ContactDetail;
  physicalAddress: Address;
  postalAddress: Address;
  billingAddress: BillingAddress;
}

export type FACustomerDetail = RegistrationDetail & {
  partnerList: Partner[];
} & CustomerName &
  FDCustomerDetail;

export interface CustomerTransactionReference {
  addressList: AddressReferenceData[];
  fisheriesOfficeList: SelectionOption[];
}

export enum CustomerType {
  Individual = 'INDIVIDUAL',
  Company = 'COMPANY',
  Partnership = 'PARTNERSHIP',
  Repository = 'REPOSITORY',
  Body = 'BODY',
}

export enum RegistrationStatus {
  Activated = 'Activated',
  Deactivated = 'Deactivated',
}

export enum ASICStatus {
  Registered = 'REGISTERED',
  Deregistered = 'DEREGISTERED',
}

export enum Gender {
  M = 'Male',
  F = 'Female',
  X = 'Indeterminate / Intersex / Unspecified',
}

export enum ContactDetail {
  EmailAddress = 'emailAddress',
  HomePhone = 'homePhone',
  WorkPhone = 'workPhone',
  Mobile = 'mobile',
}

export interface Address {
  streetAddress: string;
  suburb: string;
  postcode: string;
  state: string;
}

export interface BillingAddress extends Address {
  customerName: string;
  nameExtension: string;
  contactNumber: string;
}

export interface AddressReferenceData {
  suburb: string;
  postcode: string;
  state: string;
}

export interface AddressOption {
  label: string;
  suburb?: string;
  postcode?: string;
  state: string;
}

export interface AddressForm {
  streetAddress: FormControl<string>;
  suburb: FormControl<string>;
  postcode: FormControl<string>;
  state: FormControl<string>;
}

export interface BillingAddressForm extends AddressForm {
  customerName: FormControl<string>;
  nameExtension: FormControl<string>;
  contactNumber: FormControl<string>;
}

export interface RegistrationDetailForm {
  registrationNumber: FormControl<number>;
  registrationStatus: FormControl<string>;
  customerType: FormControl<CustomerType>;
  whfFolderNumber: FormControl<string>;
  sapCustomerNumber: FormControl<string>;
  repositoryName: FormControl<string>;
  mortgageAll: FormControl<boolean>;
  customerName: FormControl<string>;
  tradingAs: FormControl<string>;
  abn: FormControl<string>;
  acn: FormControl<string>;
  asic: FormControl<ASICStatus>;
  fisheriesOffice: FormControl<string>;
}

export interface CustomerNameForm extends PartnerForm {
  gender: FormControl<Gender>;
}

export interface PartnerForm {
  firstName: FormControl<string>;
  otherName: FormControl<string>;
  lastName: FormControl<string>;
  dob: FormControl<string>;
}

export interface PartnerFormArray {
  partnerList: FormArray<FormGroup<PartnerForm>>;
}

export interface ContactDetailForm {
  [ContactDetail.EmailAddress]: FormControl<string>;
  [ContactDetail.HomePhone]: FormControl<string>;
  [ContactDetail.WorkPhone]: FormControl<string>;
  [ContactDetail.Mobile]: FormControl<string>;
  fax: FormControl<string>;
  contactName: FormControl<string>;
}
export interface PreferredMethodForm {
  preferredMethod: FormControl<ContactDetail>;
}
export interface AddressDetailForm {
  physicalAddress: FormGroup<AddressForm>;
  postalAddress: FormGroup<AddressForm>;
  billingAddress: FormGroup<BillingAddressForm>;
}

export type SharedAmendCustomerForm = ContactDetailForm &
  PreferredMethodForm &
  AddressDetailForm;

export type FAAmendCustomerForm = RegistrationDetailForm &
  PartnerFormArray &
  CustomerNameForm &
  SharedAmendCustomerForm;

export interface ContactFieldMetadata {
  formControlName: keyof SharedAmendCustomerForm;
  isRequired?: boolean;
  shouldDynamicDecideIfRequired?: boolean;
}

export interface RegistrationFieldMetadata extends InputField {
  formControlName: keyof RegistrationDetailForm;
  width?: BootstrapWidth;
}

export interface PartnerFieldMetadata extends InputField {
  formControlName: keyof PartnerForm;
  width?: BootstrapWidth;
}

export interface CustomerFieldMetadata extends InputField {
  formControlName: keyof CustomerNameForm;
  width?: BootstrapWidth;
}

export interface AddressMetadata {
  formGroupName: 'physicalAddress' | 'postalAddress' | 'billingAddress';
  title: string;
  shouldDisplayCopyButton: boolean;
  copyButtonLabel?: string;
  copyMethod?: () => void;
  fieldLists: Array<AddressFieldMetadata | BillingAddressFieldMetadata>;
}

interface CommonAddressFieldMetadata {
  shouldDisplayTypeahead: boolean;
  isRequired?: boolean;
  isReadonly?: boolean;
  maxLength?: number;
}

export interface AddressFieldMetadata extends CommonAddressFieldMetadata {
  formControlName: keyof AddressForm;
}

export interface BillingAddressFieldMetadata
  extends CommonAddressFieldMetadata {
  formControlName: keyof BillingAddressForm;
}

export interface FocusedAddressField {
  addressType: 'physicalAddress' | 'postalAddress' | 'billingAddress';
  formControlName: keyof BillingAddressForm | keyof AddressForm;
}
