import { CommonModule } from '@angular/common';
import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  HostListener,
  inject,
  Input,
} from '@angular/core';
import { RouterLink } from '@angular/router';
import { Menu } from '@fishonline2023/webapps/model/fd2023';
import { Observable, take } from 'rxjs';

@Component({
  selector: 'sv-ui-menu',
  standalone: true,
  imports: [CommonModule, RouterLink],
  templateUrl: './menu.component.html',
  styleUrls: ['./menu.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class MenuComponent {
  private cdr = inject(ChangeDetectorRef);

  public menuClickedMap: Map<number, boolean> = new Map<number, boolean>();
  public menuClicked = false;

  @Input() menuList$!: Observable<Menu[]>;

  @HostListener('window:resize', ['$event'])
  public onResize(): void {
    // Reset the menus that have been opened
    this.menuClicked = false;
    this.menuList$.pipe(take(1)).subscribe((menuList) => {
      for (let i = 0; i < menuList.length; i++) {
        this.menuClickedMap.set(i, false);
      }
    });
  }

  public onMenuButtonClick(index: number): void {
    this.menuClickedMap.set(index, !this.menuClickedMap.get(index));
    this.menuList$.pipe(take(1)).subscribe((menuList) => {
      for (let i = 0; i < menuList.length; i++) {
        if (i !== index) {
          this.menuClickedMap.set(i, false);
        }
      }
    });
  }

  public onMenuOpen(): void {
    this.menuClicked = !this.menuClicked;
  }

  public onMenuItemClick(index: number): void {
    this.menuClicked = false;
    this.menuClickedMap.set(index, false);
  }

  public onMouseLeave(): void {
    this.menuClicked = false;
    this.menuList$.pipe(take(1)).subscribe((menuList) => {
      for (let i = 0; i < menuList.length; i++) {
        this.menuClickedMap.set(i, false);
      }
      this.cdr.detectChanges();
    });
  }
}
