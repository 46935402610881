import { createFeatureSelector, createSelector } from '@ngrx/store';
import { BREADCRUMB_FEATURE_KEY, BreadcrumbState } from './breadcrumb.reducer';

export const selectBreadcrumbState = createFeatureSelector<BreadcrumbState>(
  BREADCRUMB_FEATURE_KEY
);

export const selectBreadCrumbList = createSelector(
  selectBreadcrumbState,
  (state) => state.breadCrumbList
);
