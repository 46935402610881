<div class="mb-3">
  <sv-ui-loading
    [message]="message"
    *ngIf="(viewStatus$ | async) === ViewStatus.Loading"
  ></sv-ui-loading>
  <div class="row g-3">
    <div class="col-md-6 col-sm-12">
      <sv-ui-two-party-transaction-entity-selection-form
        label="From"
        description="Select business by name or number"
        [optionList]="fromFishingBusinessList"
        [control]="selectionFormGroup.controls.from"
        [selectionType]="TwoPartyTransactionEntitySelectionType.FishingBusiness"
        [isReadOnly]="isReadOnly"
      ></sv-ui-two-party-transaction-entity-selection-form>
    </div>
    <div class="col-md-6 col-sm-12">
      <sv-ui-two-party-transaction-entity-selection-form
        label="To"
        description="Select customer by name or number"
        [optionList]="toCustomerList"
        [control]="selectionFormGroup.controls.to"
        [selectionType]="TwoPartyTransactionEntitySelectionType.Customer"
        [isReadOnly]="isReadOnly"
      ></sv-ui-two-party-transaction-entity-selection-form>
    </div>
  </div>
</div>
