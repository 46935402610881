import * as bootstrap from 'bootstrap';

export enum ModalSize {
  Default = '',
  Large = 'modal-lg',
  ExtraLarge = 'modal-xl',
}

export interface Modal {
  id: ModalId;
  modal: bootstrap.Modal;
}

export enum ModalId {
  LicenceDetailAnnotationModal = 'licence-detail-annotation-modal',
  ConfirmationModal = 'confirmation-modal',
  InfoModal = 'info-modal',
  FishingBusinessHistoryEndorsementConditionModal = 'fishing-business-history-endorsement-condition-modal',
}
