import { AbstractControl, ValidationErrors, ValidatorFn } from '@angular/forms';
import * as dayjs from 'dayjs';
import { CustomFormValidator } from '@fishonline2023/shared/models';

export const dateRangeValidator: (
  minDate: string,
  maxDate: string
) => ValidatorFn = (minDate, maxDate) => {
  return (control: AbstractControl): ValidationErrors | null => {
    const date = control.value;

    return dayjs(date).isBefore(minDate) || dayjs(date).isAfter(maxDate)
      ? { [CustomFormValidator.DateRange]: true }
      : null;
  };
};
