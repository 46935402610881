import { inject, Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import {
  TransactionMetadata,
  TransactionType,
} from '@fishonline2023/webapps/model/fd2023';
import { map, Observable } from 'rxjs';
import { environment } from '@env/fd/environment';
import { groupBy, uniq } from '@fishonline2023/shared/ramda';
import { AgentCustomer, FishingBusiness } from '@fishonline2023/shared/models';

@Injectable()
export class TwoPartySelectionService {
  private http = inject(HttpClient);

  public getToCustomerList(): Observable<AgentCustomer[]> {
    return this.http.get<AgentCustomer[]>(
      `${environment.apiUrl}/${
        TransactionMetadata[TransactionType.TransferFishingBusiness]
          .apiBaseRoute
      }/candidates`
    );
  }

  public getToFishingBusinessList(fromFishingBusinessId: number): Observable<{
    customerList: AgentCustomer[];
    fishingBusinessByCustomerId: Record<string, FishingBusiness[]>;
  }> {
    return this.http
      .get<FishingBusiness[]>(
        `${environment.apiUrl}/${
          TransactionMetadata[TransactionType.TransferComponents].apiBaseRoute
        }/candidates`,
        { params: { entityId: fromFishingBusinessId } }
      )
      .pipe(map(this.groupByOwner));
  }

  public createNewFishingBusiness(
    customerId: number,
    fishingBusinessTypeId?: number
  ) {
    return this.http.post<FishingBusiness>(
      `${environment.apiUrl}/fishing-business`,
      { customerId, fishingBusinessTypeId }
    );
  }

  private groupByOwner = (fishingBusinessList: FishingBusiness[]) => {
    const fishingBusinessByCustomerId = groupBy(
      (fishingBusiness: FishingBusiness) => fishingBusiness.owner.id.toString(),
      fishingBusinessList
    );
    const customerList = uniq(
      fishingBusinessList.flatMap((fishingBusiness) => fishingBusiness.owner)
    );
    return { fishingBusinessByCustomerId, customerList };
  };
}
