<table class="table align-middle table-bordered mt-3">
  <thead>
    <tr class="table-header">
      <th scope="col">FB No.</th>
      <th scope="col">FB Owner</th>
      <th scope="col">Authorised Fisher</th>
      <th scope="col">Share Class</th>
      <th scope="col">Notation</th>
      <th scope="col">No. of Priorities</th>
      <th scope="col">Allocation</th>
    </tr>
  </thead>
  <tbody>
    <tr *ngFor="let item of reportList">
      <td>{{ item.fishingBusiness.id }}</td>
      <td>{{ item.fishingBusiness.owner.fullName }}</td>
      <td>{{ item.authorisedFisher.fullName }}</td>
      <td>{{ item.shareClassShortName }}</td>
      <td>{{ item.notation }}</td>
      <td>{{ item.numberOfPriority }}</td>
      <td><div class="empty-box"></div></td>
    </tr>
  </tbody>
</table>
