<!-- Start of the menu for desktop -->
<div
  class="d-flex flex-fill full-viewport-bg"
  *ngIf="menuList$ | async as menuList"
>
  <div class="d-flex" *ngFor="let menu of menuList; let i = index">
    <div class="menu-container">
      <a
        [routerLink]="'/' + menu.url"
        class="text-brand-dark"
        *ngIf="!menu.subMenuList || menu.subMenuList.length === 0"
      >
        <button class="btn btn-gray border-0">
          {{ menu.name }}
        </button>
      </a>
      <button
        class="btn btn-gray border-0"
        *ngIf="menu.subMenuList && menu.subMenuList.length > 0"
        [ngClass]="{ 'menu-opened': menuClickedMap.get(i) }"
        (click)="onMenuButtonClick(i)"
      >
        {{ menu.name }}
        &nbsp;<span *ngIf="!menuClickedMap.get(i)"
          ><i class="material-icons">keyboard_arrow_down</i></span
        >
        <span *ngIf="menuClickedMap.get(i)"
          ><i class="material-icons">keyboard_arrow_up</i></span
        >
      </button>
    </div>
    <div *ngIf="menuClickedMap.get(i)">
      <ul class="menu-open-container" (mouseleave)="onMouseLeave()">
        <a
          [routerLink]="'/' + submenu.url"
          *ngFor="let submenu of menu.subMenuList"
          (click)="onMenuItemClick(i)"
        >
          <li>{{ submenu.name }}</li>
        </a>
      </ul>
    </div>
  </div>
  <!-- Start of menu for tablet -->
  <div class="menu-container-tablet" *ngIf="menuList && menuList.length > 0">
    <button class="btn btn-gray border-0 w-100" (click)="onMenuOpen()">
      <span *ngIf="!menuClicked"
        >Menu <i class="material-icons">keyboard_arrow_down</i></span
      >
      <span *ngIf="menuClicked"
        >Close Menu <i class="material-icons">keyboard_arrow_up</i></span
      >
    </button>
    <div class="break"></div>
    <div
      *ngIf="menuClicked"
      class="menu-tablet-opened"
      (mouseleave)="onMouseLeave()"
    >
      <div *ngFor="let menu of menuList | slice : 1; let i = index">
        <span>
          <a [routerLink]="'/' + menu.url">{{ menu.name }}</a>
          <ul>
            <a
              [routerLink]="'/' + submenu.url"
              *ngFor="let submenu of menu.subMenuList"
              (click)="onMenuItemClick(i)"
              class="text-brand-blue"
            >
              <li class="menu-open-item-tablet">{{ submenu.name }}</li>
            </a>
          </ul>
        </span>
      </div>
    </div>
  </div>
</div>
