export enum SystemControl {
  SYSTEM_ENABLE = 'systemEnable',
  QUOTA_TRANSFER = 'quotaTransfer',
  REPORTS = 'reports',
  BULLETIN_BOARD = 'bulletinBoard',
  MESSAGING = 'messaging',
  QUOTA_INQUIRIES = 'quotaInquiries',
  CATCH_HISTORY_INQUIRIES = 'catchHistoryInquiries',
  TAGS_TRANSACTIONS = 'tagsTransactions',
  QUOTA_LOGSHEETS = 'quotaLogsheets',
  AUTHORISE_REVOKE_FISHER = 'authoriseRevokeFisher',
  RENEW_CFL = 'renewCFL',
  RENEW_FBL = 'renewFBL',
  CUSTOMER_DETAILS = 'customerDetails',
  LICENCE_DETAILS = 'licenceDetails',
  REPLACE_FB_CARD = 'replaceFBC',
  REPLACE_LICENSE_CARD = 'replaceLicenceCard',
  PRINT_SHARE_EXTRACT = 'printShareExtract',
  FINANCE_DETAIL = 'financeDetail',
  FB_INQUIRIES = 'fbinquiries',
  AF_INQUIRIES = 'afinquiries',
  FB_COMPONENT_TRANSFER = 'fbcomponentTransfer',
}
