import { inject } from '@angular/core';
import { CanActivateFn, Router } from '@angular/router';
import { complement, isNil } from '@fishonline2023/shared/ramda';
import { Store } from '@ngrx/store';
import { filter, map, take, withLatestFrom } from 'rxjs';
import {
  selectSearchAppRoute,
  selectSelectedSearchResultItem,
} from '@fishonline2023/webapps/shared/feature/search-store';
import { FDRoute } from '@fishonline2023/shared/models';

export const searchDetailGuard: CanActivateFn = () => {
  const router = inject(Router);
  const store = inject(Store);

  return store.select(selectSelectedSearchResultItem).pipe(
    map(complement(isNil)),
    take(1),
    withLatestFrom(
      store.select(selectSearchAppRoute).pipe(filter(complement(isNil)))
    ),
    map(([searchResultSelected, searchRoute]: [boolean, FDRoute]) => {
      if (searchResultSelected) {
        return true;
      }
      return router.parseUrl(searchRoute);
    })
  );
};
