<div *ngIf="transactionData" data-cy="transaction-data">
  <div *ngIf="previousFisher" class="fishing-business-container mb-4">
    <h4>Previous Fisher</h4>
    <br />
    <h4>{{ previousFisher.fullName }}</h4>
    <p>Registration number {{ previousFisher.id }}</p>
    <div *ngIf="previousFisher && !nominatedFisher">
      <h4>Effective Date</h4>
      <p *ngIf="effectiveDate">
        {{ effectiveDate | date : FDDateTimeAngularPipeFormat }}
      </p>
    </div>
  </div>
  <div *ngIf="nominatedFisher" class="fishing-business-container mb-4">
    <h4>Nominated Fisher</h4>
    <br />
    <h4>{{ nominatedFisher.fullName }}</h4>
    <p>Registration number {{ nominatedFisher.id }}</p>
    <h4>Effective Date</h4>
    <p *ngIf="effectiveDate">
      {{ effectiveDate | date : FDDateTimeAngularPipeFormat }}
    </p>
  </div>
</div>
