import { FormArray, FormControl } from '@angular/forms';

export interface AgentPermission {
  id: number | null;
  name: string;
  type: number;
  enabled: boolean;
}

export interface AgentRole {
  id: number | null;
  permissions: AgentPermission[];
  name: string;
  type: number;
  fishingBusinessId?: number;
}

export interface Agent {
  id: number;
  name: string;
  generalRole: AgentRole;
  fisherRole?: AgentRole;
  fishingBusinessRoleList: AgentRole[];
}

export interface AgentCandidate {
  id: number;
  fullName: string;
}

export interface AgentPermissionsEditForm {
  agent: FormControl<Agent | undefined | null>;
}

export interface AgentCandidate {
  id: number;
  fullName: string;
}

export enum ManageAgentsButtonAction {
  Add = 'ADD',
  Edit = 'EDIT',
  Delete = 'DELETE',
}

export interface AgentPermissionFormGroup {
  permissions: FormArray<FormControl<boolean> | never>;
}
