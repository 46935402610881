import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  inject,
  Input,
  OnDestroy,
  OnInit,
} from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormGroup, ReactiveFormsModule, Validators } from '@angular/forms';
import {
  Address,
  AddressForm,
  AddressMetadata,
  addressMetadataList,
  AddressOption,
  AddressReferenceData,
  BillingAddressForm,
  ContactDetail,
  ContactFieldMetadata,
  contactFieldsMetadataList,
  FAAmendCustomerForm,
  FocusedAddressField,
  LabelNameMap,
  preferredMethodMetadataList,
  SharedAmendCustomerForm,
  tooShortAddressInputLength,
} from '@fishonline2023/shared/models';
import {
  debounceTime,
  filter,
  startWith,
  Subject,
  Subscription,
  takeUntil,
  timer,
} from 'rxjs';
import {
  complement,
  compose,
  equals,
  filter as ramdaFilter,
  includes,
  isNil,
  prop,
  toLower,
  uniqBy,
} from '@fishonline2023/shared/ramda';
import { AutoCompleteComponent } from '@fishonline2023/webapps/shared/ui/base-components';
import { addressValidator } from '../amend-customer-form';

@Component({
  selector: 'sv-ui-amend-customer-shared',
  standalone: true,
  imports: [CommonModule, ReactiveFormsModule, AutoCompleteComponent],
  templateUrl: './amend-customer-shared.component.html',
  styleUrls: ['./amend-customer-shared.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AmendCustomerSharedComponent implements OnInit, OnDestroy {
  @Input() public amendCustomerForm!:
    | FormGroup<FAAmendCustomerForm>
    | FormGroup<SharedAmendCustomerForm>;
  @Input() public AddressReferenceDataList!: AddressReferenceData[];
  public contactFieldMetadataList: ContactFieldMetadata[] =
    contactFieldsMetadataList;
  public LabelNameMap = LabelNameMap;
  public preferredMethodMetadataList: Array<keyof SharedAmendCustomerForm> =
    preferredMethodMetadataList;
  public addressMetadataList: AddressMetadata[] = addressMetadataList(
    this.copyToPostalAddress.bind(this),
    this.copyToBillingAddress.bind(this)
  );

  public addressOptionList: AddressOption[] = [];
  public focusedAddressField?: FocusedAddressField;
  public focusedAddressFieldValueChange$$?: Subscription;

  private cdr = inject(ChangeDetectorRef);
  private destroy$ = new Subject<void>();

  public ngOnInit() {
    this.initForm();
    this.updateAddressValidator();
  }

  public ngOnDestroy() {
    this.destroy$.next();
    this.destroy$.complete();
  }

  public isContactMethodRequired = (contactDetail: ContactDetail | string) =>
    equals(this.amendCustomerForm.value.preferredMethod, contactDetail);

  public copyToPostalAddress() {
    this.amendCustomerForm.controls.postalAddress.patchValue(
      this.amendCustomerForm.value.physicalAddress as Address
    );
  }

  public copyToBillingAddress() {
    this.amendCustomerForm.controls.billingAddress.patchValue(
      this.amendCustomerForm.value.postalAddress as Address
    );
  }

  public addressOptionSelected(addressOption: AddressOption) {
    if (isNil(this.focusedAddressField)) {
      return;
    }
    const { addressType } = this.focusedAddressField;
    const { label, ...formValue } = addressOption;
    this.amendCustomerForm.controls[addressType].patchValue(formValue);
  }

  public updateAddressOptions(focusedAddressField: FocusedAddressField) {
    this.addressOptionList = [];
    this.focusedAddressField = focusedAddressField;
    if (equals(focusedAddressField.formControlName, 'state')) {
      this.addressOptionList = this.stateAddressOptions();
      return;
    }
    this.updateSuburbPostcodeOptions(focusedAddressField);
  }

  public addressFieldBlurred(addressFieldToBlur: FocusedAddressField) {
    timer(100)
      .pipe(filter(() => equals(addressFieldToBlur, this.focusedAddressField)))
      .subscribe(() => {
        this.focusedAddressField = undefined;
        this.focusedAddressFieldValueChange$$?.unsubscribe();
        this.cdr.detectChanges();
      });
  }

  public addressFieldHasError(
    error: 'required' | 'maxlength',
    formGroupName: 'physicalAddress' | 'postalAddress' | 'billingAddress',
    formControlName: keyof BillingAddressForm | keyof AddressForm
  ) {
    const formGroup = equals(formGroupName, 'billingAddress')
      ? (
          this.amendCustomerForm.controls[
            'billingAddress'
          ] as FormGroup<BillingAddressForm>
        ).controls
      : (
          this.amendCustomerForm.controls[
            formGroupName
          ] as FormGroup<AddressForm>
        ).controls;

    return formGroup[
      formControlName as keyof (BillingAddressForm | AddressForm)
    ].hasError(error);
  }

  private updateAddressValidator() {
    [
      this.amendCustomerForm.controls.physicalAddress,
      this.amendCustomerForm.controls.postalAddress,
      this.amendCustomerForm.controls.billingAddress,
    ].forEach((formGroup) => {
      formGroup.clearValidators();
      formGroup.addValidators(addressValidator(this.AddressReferenceDataList));
      formGroup.updateValueAndValidity();
    });
  }

  private initForm = () => {
    this.amendCustomerForm.controls.preferredMethod.valueChanges
      .pipe(takeUntil(this.destroy$))
      .subscribe(this.updatePreferredMethodValidators);
  };

  private updatePreferredMethodValidators = (
    preferredMethod: ContactDetail
  ) => {
    this.amendCustomerForm.controls[preferredMethod].addValidators(
      Validators.required
    );
    this.amendCustomerForm.controls[preferredMethod].updateValueAndValidity();
    Object.values(ContactDetail)
      .filter(complement(equals(preferredMethod)))
      .forEach((contactDetail) => {
        this.amendCustomerForm.controls[contactDetail].removeValidators(
          Validators.required
        );
        this.amendCustomerForm.controls[contactDetail].updateValueAndValidity();
      });
  };

  private stateAddressOptions(): AddressOption[] {
    return uniqBy(prop('state'), this.AddressReferenceDataList).map(
      (state): AddressOption => ({
        label: state.state,
        state: state.state,
      })
    );
  }

  private updateSuburbPostcodeOptions(
    focusedAddressField: FocusedAddressField
  ) {
    const { formControlName, addressType } = focusedAddressField;
    type SuburbPostcode = 'suburb' | 'postcode';
    const addressFormControl =
      this.amendCustomerForm.controls[addressType].controls[
        formControlName as SuburbPostcode
      ];
    this.focusedAddressFieldValueChange$$ = addressFormControl.valueChanges
      .pipe(startWith(addressFormControl.value), debounceTime(200))
      .subscribe((value) => {
        if (
          value.length <
          tooShortAddressInputLength[formControlName as SuburbPostcode]
        ) {
          this.addressOptionList = [];
          this.cdr.detectChanges();
          return;
        }
        this.addressOptionList = ramdaFilter(
          compose(
            includes(value.toLowerCase()),
            toLower,
            prop(formControlName)
          ),
          this.AddressReferenceDataList
        ).map(
          ({ suburb, postcode, state }): AddressOption => ({
            label: `${suburb} - ${postcode} - ${state}`,
            suburb,
            state,
            postcode,
          })
        );
        this.cdr.detectChanges();
      });
  }
}
