import { createAction, props } from '@ngrx/store';
import { SystemStatus } from '@fishonline2023/webapps/model/fd2023';

export const loadSystemStatus = createAction(
  `[System Status] Load System Status`
);

export const loadSystemStatusSuccess = createAction(
  `[System Status] Load System Status Success`,
  props<{ systemStatus: SystemStatus }>()
);

export const loadSystemStatusFailure = createAction(
  `[System Status] Load System Status Failure`,
  props<{ errorMessage: string }>()
);
