import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { CommonModule } from '@angular/common';
import {
  TransferDetail,
  TransferFishingBusinessComponentsTransactionReference,
} from '@fishonline2023/webapps/model/fd2023';
import { equals } from '@fishonline2023/shared/ramda';

@Component({
  selector: 'sv-ui-transfer-fishing-business-components-transfer-detail',
  standalone: true,
  imports: [CommonModule],
  templateUrl:
    './transfer-fishing-business-components-transfer-detail.component.html',
  styleUrls: [
    './transfer-fishing-business-components-transfer-detail.component.scss',
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TransferFishingBusinessComponentsTransferDetailComponent {
  @Input() public transferDetail!: TransferDetail;
  @Input() public withUnit = false;
  @Input()
  public transactionReference: TransferFishingBusinessComponentsTransactionReference =
    {
      componentList: [],
      quotaList: [],
    };

  protected get unit(): string {
    if (!this.withUnit) {
      return '';
    }
    return this.transactionReference.quotaList.find(({ name }) =>
      equals(name, this.transferDetail.name)
    )?.unit as string;
  }
}
