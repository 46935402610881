import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  inject,
  Input,
  OnInit,
  Output,
} from '@angular/core';
import { CommonModule } from '@angular/common';
import { Observable, startWith, Subject, take, takeUntil } from 'rxjs';
import {
  Condition,
  DeterminationCertificate,
  FDDataLoaderData,
  FisherHistory,
  FishingBusinessHistory,
  FishingBusinessHistoryResponse,
} from '@fishonline2023/webapps/model/fd2023';
import {
  ErrorComponent,
  LoadingComponent,
} from '@fishonline2023/webapps/shared/ui/base-components';
import {
  equals,
  groupBy,
  isNil,
  path,
  prop,
} from '@fishonline2023/shared/ramda';
import {
  FormBuilder,
  FormControl,
  FormGroup,
  ReactiveFormsModule,
} from '@angular/forms';
import { FishingBusinessHistoryDeterminationCertificateComponent } from './fishing-business-history-determination-certificate/fishing-business-history-determination-certificate.component';
import { FishingBusinessHistoryFisherHistoryComponent } from './fishing-business-history-fisher-history/fishing-business-history-fisher-history.component';
import {
  getDateRange,
  getDeterminationCertificateList,
  getFishingBusiness,
  getFishingBusinessDeterminationDateList,
  getFishingBusinessList,
  getFormattedDate,
  sortFisherHistoryList,
} from './fisher-business-history.utils';
import { EventType } from '@fishonline2023/webapps/shared/feature/appsync';
import { transformKeysToTitleCase } from '@fishonline2023/shared/utils';
import {
  DataLoaderActivityLog,
  FishingBusiness,
} from '@fishonline2023/shared/models';

@Component({
  selector: 'sv-ui-fishing-business-history',
  standalone: true,
  imports: [
    CommonModule,
    ErrorComponent,
    LoadingComponent,
    ReactiveFormsModule,
    FishingBusinessHistoryDeterminationCertificateComponent,
    FishingBusinessHistoryFisherHistoryComponent,
  ],
  templateUrl: './fishing-business-history.component.html',
  styleUrls: ['./fishing-business-history.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class FishingBusinessHistoryComponent implements OnInit {
  @Input() public queryParams: Record<string, unknown> = {};
  @Input()
  public dataLoaderData$!: Observable<FDDataLoaderData>;
  @Output() public activityLogTriggered =
    new EventEmitter<DataLoaderActivityLog>();
  public fishingBusinessHistoryList: FishingBusinessHistory[] = [];
  public fishingBusinessHistoryReference?: { conditionList: Condition[] };
  public fishingBusinessList: string[] = [];
  public determinationDateList: string[] = [];
  public fishingBusinessHistoryForm!: FormGroup;
  public holdingListByDeterminationDateMap: Record<
    string,
    DeterminationCertificate
  > = {};
  public fishingIdAndDeterminationDateMap: Record<
    string,
    Record<string, DeterminationCertificate>
  > = {};
  public determinationCertificate?: DeterminationCertificate;
  public selectedFishingBusiness?: FishingBusiness;
  public fisherHistoryList!: FisherHistory[];
  public currentFisher?: FisherHistory;
  private cdr = inject(ChangeDetectorRef);
  private formBuilder = inject(FormBuilder);
  private destroy$ = new Subject<void>();

  public get fishingBusinessHistory$() {
    return this.dataLoaderData$ as Observable<FishingBusinessHistoryResponse>;
  }

  public ngOnInit(): void {
    this.initForm();
    this.fishingBusinessHistory$
      .pipe(take(1))
      .subscribe((fishingBusinessHistory) => {
        this.fishingBusinessHistoryList =
          fishingBusinessHistory.fishingBusinessHistoryList;
        this.fishingBusinessHistoryReference =
          fishingBusinessHistory.fishingBusinessHistoryReference;
        this.fishingBusinessList = getFishingBusinessList(
          fishingBusinessHistory.fishingBusinessHistoryList
        );
        const initFishingBusinessId = this.getInitFishingBusinessId(
          this.fishingBusinessList
        );
        this.fishingBusinessHistoryForm
          .get('fishingBusinessId')
          ?.patchValue(initFishingBusinessId);
        const determinationCertificateList = getDeterminationCertificateList(
          fishingBusinessHistory.fishingBusinessHistoryList,
          initFishingBusinessId
        );
        this.buildFishingIdAndDeterminationDateMap(
          determinationCertificateList,
          initFishingBusinessId
        );
        this.determinationDateList = getFishingBusinessDeterminationDateList(
          determinationCertificateList
        );
        this.fishingBusinessHistoryForm
          .get('determinationDate')
          ?.patchValue(this.determinationDateList[0]);
      });
    this.handleFishingBusinessIdChanges();
    this.handleDeterminationDateChanges();
  }

  private getInitFishingBusinessId(fishingBusinessList: string[]) {
    const fishingBusinessIdFromQueryParams =
      this.queryParams['fishingBusinessId'];
    return isNil(fishingBusinessIdFromQueryParams)
      ? fishingBusinessList[0]
      : getFishingBusiness(fishingBusinessIdFromQueryParams as string);
  }

  private buildFishingIdAndDeterminationDateMap(
    determinationCertList: DeterminationCertificate[],
    fishingId: string
  ): void {
    const groupedByDeterminationDate = groupBy(
      prop('determinationDate'),
      determinationCertList
    );
    for (const value of Object.values(groupedByDeterminationDate)) {
      const startDate = getFormattedDate(path(['issueDate'], value[0]));
      const endDate = getFormattedDate(path(['revokedDate'], value[0]));
      const dateRange = getDateRange(startDate, endDate);
      this.holdingListByDeterminationDateMap[dateRange] = value[0];
    }
    this.fishingIdAndDeterminationDateMap[fishingId] =
      this.holdingListByDeterminationDateMap;
  }

  private initForm(): void {
    this.fishingBusinessHistoryForm = this.formBuilder.group({
      fishingBusinessId: new FormControl('', { nonNullable: true }),
      determinationDate: new FormControl('', { nonNullable: true }),
    });
  }

  private handleFishingBusinessIdChanges(): void {
    const fishingBusinessIdFormControl =
      this.fishingBusinessHistoryForm.get('fishingBusinessId');
    fishingBusinessIdFormControl?.valueChanges
      .pipe(takeUntil(this.destroy$))
      .subscribe((value) => {
        this.selectedFishingBusiness = this.fishingBusinessHistoryList.find(
          (fbHistory) => {
            const fishingBusinessId = path(
              ['fishingBusiness', 'id'],
              fbHistory
            );
            const fbId = +value.split(' ')[1];
            return equals(fishingBusinessId, fbId);
          }
        )?.fishingBusiness;
        this.triggerActivityLog(this.selectedFishingBusiness);
        const determinationCertificateList = getDeterminationCertificateList(
          this.fishingBusinessHistoryList,
          value
        );
        this.buildFishingIdAndDeterminationDateMap(
          determinationCertificateList,
          value
        );
        this.determinationDateList = getFishingBusinessDeterminationDateList(
          determinationCertificateList
        );
        this.fishingBusinessHistoryForm
          .get('determinationDate')
          ?.patchValue(this.determinationDateList[0]);
        this.renderFishingBusinessHistory();
      });
  }

  private triggerActivityLog(selectedFishingBusiness?: FishingBusiness) {
    this.activityLogTriggered.emit({
      eventType: EventType.ViewFishingBusinessHistory,
      detail: JSON.stringify({
        'Fishing Business': transformKeysToTitleCase({
          id: selectedFishingBusiness?.id,
          owner: selectedFishingBusiness?.owner.fullName,
        }),
      }),
    });
  }

  private handleDeterminationDateChanges(): void {
    const determinationDateFormControl =
      this.fishingBusinessHistoryForm.get('determinationDate');
    determinationDateFormControl?.valueChanges
      .pipe(
        startWith(
          this.fishingBusinessHistoryForm.get('determinationDate')?.value
        ),
        takeUntil(this.destroy$)
      )
      .subscribe(() => {
        this.renderFishingBusinessHistory();
      });
  }

  private renderFishingBusinessHistory(): void {
    const fishingBusinessId =
      this.fishingBusinessHistoryForm.get('fishingBusinessId')?.value;
    const determinationDate =
      this.fishingBusinessHistoryForm.get('determinationDate')?.value;
    this.determinationCertificate = this.fishingIdAndDeterminationDateMap[
      fishingBusinessId
    ]
      ? this.fishingIdAndDeterminationDateMap[fishingBusinessId][
          determinationDate
        ]
      : undefined;
    this.currentFisher = this.determinationCertificate?.currentFisher;
    this.fisherHistoryList = sortFisherHistoryList(
      this.determinationCertificate?.fisherHistoryList
    );
    this.cdr.detectChanges();
  }
}
