import { FormControl } from '@angular/forms';

export enum AdType {
  All = 'All',
  Boat = 'Boat',
  BoatLicence = 'BoatLicence',
  FishingBusiness = 'FishingBusiness',
  FishingGear = 'FishingGear',
  InlandEndorsements = 'InlandEndorsements',
  Misc = 'Misc',
  Shares = 'Shares',
  SouthernFisheriesEndorsements = 'SouthernFisheriesEndorsements',
  SutsEndorsements = 'SutsEndorsements',
  Quota = 'Quota',
}

export enum Purpose {
  Buy = 'Buy',
  Sale = 'Sale',
}

export interface AdDetail {
  id: number;
  title: string;
  purpose: Purpose;
  type: AdType;
  price: number;
  description?: string;
  contactNamePrimary: string;
  contactNumberPrimary: string;
  contactNameSecondary?: string;
  contactNumberSecondary?: string;
  expiryDate: string | number;
  isEditable?: boolean;
}

export interface AdSearchResult {
  adList: AdDetail[];
}

export interface SaleAndTradeEditForm {
  title: FormControl<string | undefined | null>;
  purpose: FormControl<Purpose>;
  type: FormControl<AdType | null>;
  price: FormControl<number | string | undefined | null>;
  description: FormControl<string | undefined | null>;
  contactNamePrimary: FormControl<string | undefined | null>;
  contactNumberPrimary: FormControl<string | undefined | null>;
  contactNameSecondary: FormControl<string | undefined | null>;
  contactNumberSecondary: FormControl<string | undefined | null>;
  expiryDate: FormControl<string | number | undefined | null>;
}
