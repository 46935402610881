import { MenuName } from './menu-name.enum';
import { FARoute } from '@fishonline2023/shared/models';

export const catchEffortHistoryMenu = {
  name: MenuName.CATCH_EFFORT_HISTORY,
  url: '/catch-effort-history',
};

export const fishingActivityHistoryMenu = {
  name: MenuName.FISHING_ACTIVITY_HISTORY,
  url: '/fishing-activity-history',
};

export const quotaTransferMenu = {
  name: MenuName.QUOTA_TRANSFER,
  url: '/quota-transfer',
};

export const quotaTransactionMenu = {
  name: MenuName.QUOTA_TRANSACTIONS,
  url: '/quota-transactions',
};

export const tagTransactionsMenu = {
  name: MenuName.TAG_TRANSACTIONS,
  url: '/tag-transactions',
};

export const tagTransactionHistoryMenu = {
  name: MenuName.TAG_TRANSACTION_HISTORY,
  url: '/tag-transaction-history',
};

export const myPersonalAuthorisationsMenu = {
  name: MenuName.MY_PERSONAL_AUTHORISATIONS,
  url: '/my-personal-authorisations',
};

export const myFishingBusinessAuthorisationsMenu = {
  name: MenuName.MY_FISHING_BUSINESS_AUTHORISATIONS,
  url: '/my-fishing-business-authorisations',
};

export const myFishonlineAccountActivityMenu = {
  name: MenuName.SEARCH_USER_ACTIVITY_LOG,
  url: `/${FARoute.UserActivityLog}`,
};

export const customerDetailSearchMenu = {
  name: MenuName.CUSTOMER_DETAIL_SEARCH,
  url: `/${FARoute.CustomerDetailSearch}`,
};

export const messageMenu = {
  name: MenuName.MESSAGES,
  url: '/messaging',
};

export const amendCustomerMenu = {
  name: MenuName.AMEND_CUSTOMER,
  url: `/${FARoute.AmendCustomer}`,
};

export const createCustomerMenu = {
  name: MenuName.CREATE_CUSTOMER,
  url: `/${FARoute.CreateCustomer}`,
};

export const manageAgentsMenu = {
  name: MenuName.MANAGE_AGENTS,
  url: '/manage-agents',
};

export const customerFinanceDetailsMenu = {
  name: MenuName.CUSTOMER_FINANCE_DETAILS,
  url: '/finance-details',
};

export const homeMenu = {
  name: MenuName.HOME,
  url: `/${FARoute.Home}`,
};
