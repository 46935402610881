import { FishingBusiness } from './fishing-business.model';
import { AgentCustomer } from './agent-customer.model';

export interface PrawnBallotEntry {
  fishingBusiness: FishingBusiness;
  authorisedFisher: AgentCustomer;
  numberOfPriority: number;
  shareClassShortName: string;
  notation: string;
}

export interface PrawnBallotReport {
  regionBallotList: PrawnBallotEntry[];
  outsideAccessBallotList: PrawnBallotEntry[];
}

export type PrawnBallotRegionReport = Record<string, PrawnBallotReport>;
