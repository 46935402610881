import {
  AbstractControl,
  FormGroup,
  ValidationErrors,
  ValidatorFn,
} from '@angular/forms';
import { complement, isNil, isNilOrEmpty } from '@fishonline2023/shared/ramda';
import { SearchFormControls } from '@fishonline2023/shared/models';

export const searchFormValidator: ValidatorFn = (
  formGroup: AbstractControl
): ValidationErrors | null => {
  const { dependent, preFilter, ...flatFieldValues } = (
    formGroup as FormGroup<SearchFormControls>
  ).value;
  const isFlatFieldValuesValid = Object.values(flatFieldValues).some(
    complement(isNilOrEmpty)
  );
  const isDependentFieldValuesValid = isNil(dependent)
    ? false
    : Object.values(dependent).every(complement(isNilOrEmpty));
  const isPreFilterGroupValid = isNil(preFilter)
    ? false
    : !isNilOrEmpty(preFilter[<string>preFilter.filter]);
  const isSearchFormValid =
    isFlatFieldValuesValid ||
    isDependentFieldValuesValid ||
    isPreFilterGroupValid;
  return isSearchFormValid ? null : { invalidSearchForm: true };
};
