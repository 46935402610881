import { Component } from '@angular/core';
import { RouterModule } from '@angular/router';
import { ToastComponent } from '@fishonline2023/webapps/shared/ui/base-components';

@Component({
  standalone: true,
  imports: [RouterModule, ToastComponent],
  selector: 'fa2023-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent {}
