<sv-ui-modal [modalId]="ModalId.InfoModal">
  <div class="modal-header align-items-center">
    <h1 class="fs-4">{{ title }}</h1>
  </div>
  <div class="modal-body">
    <ng-container *ngIf="message">{{ message }}</ng-container>
    <sv-ui-dynamic-width-item-list
      *ngIf="body"
      [dynamicWidthItemList]="body"
    ></sv-ui-dynamic-width-item-list>
  </div>
  <div class="modal-footer">
    <button
      (click)="dismissModal()"
      aria-label="gotIt"
      class="btn btn-primary m-auto"
      type="button"
    >
      Got it
    </button>
  </div>
</sv-ui-modal>
