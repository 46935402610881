import {
  FDDateDayJSFormat,
  TermData,
} from '@fishonline2023/webapps/model/fd2023';
import * as dayjs from 'dayjs';

export const getRenewalToDate = (renewalTerm: TermData) =>
  dayjs(renewalTerm.startDate)
    .add(renewalTerm.renewalTermYear, 'year')
    .subtract(1, 'day')
    .format(FDDateDayJSFormat);
