<textarea
  *ngIf="inputField.isReadOnly; else editable"
  [attr.aria-label]="inputField.formControlName"
  [attr.data-cy]="inputField.formControlName"
  [disabled]="true"
  [placeholder]="inputField.placeholder || ''"
  [rows]="inputField.rows"
  [value]="form.value"
  class="form-control form-control-lg"
></textarea>
<ng-template #editable>
  <textarea
    [attr.aria-label]="inputField.formControlName"
    [attr.data-cy]="inputField.formControlName"
    [formControl]="form"
    [maxlength]="inputField.maxLength || 0"
    [placeholder]="inputField.placeholder || ''"
    [rows]="inputField.rows"
    class="form-control form-control-lg"
  ></textarea>
</ng-template>
