import { MenuName } from './menu-name.enum';
import { FDRoute } from '@fishonline2023/shared/models';

export const catchEffortHistoryMenu = {
  name: MenuName.CATCH_EFFORT_HISTORY,
  url: '/catch-effort-history',
};

export const fishingActivityHistoryMenu = {
  name: MenuName.FISHING_ACTIVITY_HISTORY,
  url: '/fishing-activity-history',
};

export const quotaTransferMenu = {
  name: MenuName.QUOTA_TRANSFER,
  url: '/quota-transfer',
};

export const quotaTransactionMenu = {
  name: MenuName.QUOTA_TRANSACTIONS,
  url: '/quota-transactions',
};

export const tagTransactionsMenu = {
  name: MenuName.TAG_TRANSACTIONS,
  url: '/tag-transactions',
};

export const tagTransactionHistoryMenu = {
  name: MenuName.TAG_TRANSACTION_HISTORY,
  url: '/tag-transaction-history',
};

export const transferFishingBusinessMenu = {
  name: MenuName.TRANSFER_FISHING_BUSINESS,
  url: `/${FDRoute.TransferFishingBusiness}`,
};

export const transferComponentsMenu = {
  name: MenuName.TRANSFER_COMPONENTS,
  url: `/${FDRoute.TransferComponents}`,
};

export const nominateRevokeFisherMenu = {
  name: MenuName.NOMINATE_REVOKE_FISHERS,
  url: `/${FDRoute.NominateRevokeFisher}`,
};

export const fishingBusinessHistoryMenu = {
  name: MenuName.FISHING_BUSINESS_HISTORY,
  url: `/${FDRoute.FishingBusinessHistory}`,
};

export const myPersonalAuthorisationsMenu = {
  name: MenuName.MY_PERSONAL_AUTHORISATIONS,
  url: '/my-personal-authorisations',
};

export const myFishingBusinessAuthorisationsMenu = {
  name: MenuName.MY_FISHING_BUSINESS_AUTHORISATIONS,
  url: '/my-fishing-business-authorisations',
};

export const licenceDetailsMenu = {
  name: MenuName.LICENCE_DETAILS,
  url: `/${FDRoute.LicenceDetails}`,
};

export const myReceiptsMenu = {
  name: MenuName.MY_RECEIPTS,
  url: '/my-receipts',
};

export const forSaleTradeMenu = {
  name: MenuName.FOR_SALE_TRADE,
  url: `/${FDRoute.ForSaleAndTradeSearch}`,
};

export const printShareExtractMenu = {
  name: MenuName.PRINT_SHARE_EXTRACT,
  url: `/${FDRoute.PrintShareExtract}`,
};

export const printPrawnBallotMenu = {
  name: MenuName.PRINT_PRAWN_BALLOT,
  url: `/${FDRoute.PrintPrawnBallot}`,
};

export const accountSummaryMenu = {
  name: MenuName.ACCOUNT_SUMMARY,
  url: `/${FDRoute.AccountSummary}`,
};

export const contactDetailsMenu = {
  name: MenuName.CONTACT_DETAILS,
  url: `/${FDRoute.AmendCustomer}`,
};

export const myFishonlineAccountActivityMenu = {
  name: MenuName.MY_FISHONLINE_ACCOUNT_ACTIVITY,
  url: `/${FDRoute.AccountActivity}`,
};

export const manageAgentsMenu = {
  name: MenuName.MANAGE_AGENTS,
  url: '/manage-agents',
};

export const customerFinanceDetailsMenu = {
  name: MenuName.CUSTOMER_FINANCE_DETAILS,
  url: '/customer-finance-details',
};

export const homeMenu = {
  name: MenuName.HOME,
  url: `/${FDRoute.Home}`,
};
