import { BootstrapWidth } from './bootstrap.model';

export type ItemList = Array<{
  key: string | undefined;
  value?: string | number | null;
}>;

export type DynamicWidthItem = {
  label: string;
  value: string | number;
  width: BootstrapWidth;
};

export type DynamicWidthItemList = Array<DynamicWidthItem>;
