import { inject, Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { catchError, filter, map, of, switchMap, withLatestFrom } from 'rxjs';
import {
  entityCreated,
  transactionApproved,
  transactionApprovedFailed,
  transactionDataRequested,
  transactionDataRequestedFailed,
  transactionDataRequestedSuccessful,
  transactionDeleted,
  transactionDeletedFailed,
  transactionDeletedSuccessful,
  transactionEntityDataLoadFailed,
  transactionEntityDataLoadSuccessful,
  transactionEntityDataRequested,
  transactionNotApproved,
  transactionNotApprovedFailed,
  transactionNotApprovedSuccessful,
  transactionReset,
  transactionSubmitted,
  transactionSubmittedFailed,
  transactionSubmittedSuccessful,
} from './transaction.actions';
import { TransactionService } from './transaction.service';
import { Message, TransactionData } from '@fishonline2023/webapps/model/fa2023';
import { ROUTER_REQUEST } from '@ngrx/router-store';
import { RouterRequestPayload } from '@ngrx/router-store/src/actions';
import { isNil, path } from '@fishonline2023/shared/ramda';
import { selectTransactionAppRoute } from './transaction.selectors';
import { Store } from '@ngrx/store';

@Injectable()
export class TransactionEffects {
  private actions$ = inject(Actions);
  private store = inject(Store);
  resetTransactionStoreWhenOutsideTransactionContext$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ROUTER_REQUEST),
      map<RouterRequestPayload, string | undefined>(
        path(['payload', 'event', 'url'])
      ),
      withLatestFrom(this.store.select(selectTransactionAppRoute)),
      filter(
        ([url, appRoute]) =>
          !isNil(url) && !isNil(appRoute) && !url.includes(appRoute)
      ),
      map(() => transactionReset())
    )
  );
  private transactionService = inject(TransactionService);
  loadEntityData$ = createEffect(() =>
    this.actions$.pipe(
      ofType(transactionEntityDataRequested),
      switchMap(() =>
        this.transactionService.getEntityData().pipe(
          map((transactionEntityData) =>
            transactionEntityDataLoadSuccessful({ transactionEntityData })
          ),
          catchError(({ error: { errorMessage } }) =>
            of(
              transactionEntityDataLoadFailed({
                errorMessage: errorMessage ?? Message.FailedToLoadEntityData,
              })
            )
          )
        )
      )
    )
  );

  loadTransactionData$ = createEffect(() =>
    this.actions$.pipe(
      ofType(transactionDataRequested),
      switchMap(({ queryParams, transactionId }) =>
        this.transactionService
          .getTransactionData({ queryParams, transactionId })
          .pipe(
            map((transactionData: TransactionData) =>
              transactionDataRequestedSuccessful({ transactionData })
            ),
            catchError(({ error: { errorMessage } }) =>
              of(
                transactionDataRequestedFailed({
                  errorMessage:
                    errorMessage ?? Message.FailedToLoadTransactionData,
                })
              )
            )
          )
      )
    )
  );

  createEntity$ = createEffect(() =>
    this.actions$.pipe(
      ofType(entityCreated),
      switchMap(() =>
        this.transactionService.createEntity().pipe(
          map((transactionData: TransactionData) =>
            transactionDataRequestedSuccessful({ transactionData })
          ),
          catchError(({ error: { errorMessage } }) =>
            of(
              transactionDataRequestedFailed({
                errorMessage:
                  errorMessage ?? Message.FailedToLoadTransactionData,
              })
            )
          )
        )
      )
    )
  );

  saveTransaction$ = createEffect(() =>
    this.actions$.pipe(
      ofType(transactionSubmitted),
      switchMap(({ transactionSubmitPayload, fromButtonType }) =>
        this.transactionService
          .submitTransaction(transactionSubmitPayload)
          .pipe(
            map((transactionData) =>
              transactionSubmittedSuccessful({
                transactionData,
                fromButtonType,
              })
            ),
            catchError(({ error: { errorMessage } }) =>
              of(
                transactionSubmittedFailed({
                  errorMessage: errorMessage ?? Message.FailedToSaveTransaction,
                })
              )
            )
          )
      )
    )
  );

  approveTransaction$ = createEffect(() =>
    this.actions$.pipe(
      ofType(transactionApproved),
      switchMap(() =>
        this.transactionService.approveTransaction().pipe(
          map(this.transactionService.parseApproveTransactionResponse),
          catchError(({ error: { errorMessage } }) =>
            of(
              transactionApprovedFailed({
                errorMessage:
                  errorMessage ?? Message.FailedToApproveTransaction,
              })
            )
          )
        )
      )
    )
  );

  notApproveTransaction$ = createEffect(() =>
    this.actions$.pipe(
      ofType(transactionNotApproved),
      switchMap(() =>
        this.transactionService.notApproveTransaction().pipe(
          map((transactionData) =>
            transactionNotApprovedSuccessful({ transactionData })
          ),
          catchError(({ error: { errorMessage } }) =>
            of(
              transactionNotApprovedFailed({
                errorMessage:
                  errorMessage ?? Message.FailedToNotApproveTransaction,
              })
            )
          )
        )
      )
    )
  );

  deleteTransaction$ = createEffect(() =>
    this.actions$.pipe(
      ofType(transactionDeleted),
      switchMap(() =>
        this.transactionService.deleteTransaction().pipe(
          map((transactionData) =>
            transactionDeletedSuccessful({ transactionData })
          ),
          catchError(({ error: { errorMessage } }) =>
            of(
              transactionDeletedFailed({
                errorMessage: errorMessage ?? Message.FailedToDeleteTransaction,
              })
            )
          )
        )
      )
    )
  );
}
