import {
  DeterminationCertificate,
  FDDateDayJSFormat,
  FisherHistory,
  FishingBusinessHistory,
} from '@fishonline2023/webapps/model/fd2023';
import {
  equals,
  isNil,
  isNilOrEmpty,
  path,
} from '@fishonline2023/shared/ramda';
import * as dayjs from 'dayjs';

export const getFishingBusinessList = (
  fishingBusinessHistoryList: FishingBusinessHistory[]
): string[] => {
  return fishingBusinessHistoryList.map(({ fishingBusiness }) =>
    getFishingBusiness(fishingBusiness.id)
  );
};

export const getFishingBusiness = (id: number | string): string => {
  return `FB ${id}`;
};

export const getFormattedDate = (date: string | Date | null): string => {
  const dateFormat = FDDateDayJSFormat;
  if (isNil(date)) {
    return 'Current';
  }
  return dayjs(date).format(dateFormat);
};

export const getDeterminationCertificateList = (
  fishingBusinessHistoryList: FishingBusinessHistory[],
  fishingBusinessValue: string
): DeterminationCertificate[] => {
  return (
    fishingBusinessHistoryList.find((fbHistory) => {
      const fbId = path(['fishingBusiness', 'id'], fbHistory);
      const fishingBusinessId = +fishingBusinessValue.split(' ')[1];
      return equals(fishingBusinessId, fbId);
    })?.determinationCertificateList || []
  );
};

export const sortDateRangeList = (
  dateMap: Record<string, Date>,
  dateRangeMap: Record<string, string>
): string[] => {
  const startDates = Object.keys(dateMap);
  const sortedDates = startDates.sort((a, b) => {
    return dayjs(a).isAfter(dayjs(b)) ? 1 : -1;
  });
  return sortedDates.map((date) => dateRangeMap[date]);
};

export const sortFisherHistoryList = (
  fisherHistoryList?: FisherHistory[]
): FisherHistory[] => {
  if (isNil(fisherHistoryList)) {
    return [];
  }
  return [...fisherHistoryList].sort((a: FisherHistory, b: FisherHistory) => {
    const authDateA = a.authorisationDate;
    const authDateB = b.authorisationDate;
    return dayjs(authDateA).isBefore(dayjs(authDateB)) ? 1 : -1;
  });
};

export const getFishingBusinessDeterminationDateList = (
  determinationCertList: DeterminationCertificate[]
): string[] => {
  const dateMap: Record<string, Date> = {};
  const dateRangeMap: Record<string, string> = {};
  if (!isNilOrEmpty(determinationCertList)) {
    determinationCertList?.map((determinationCert) => {
      const startDateObj = path(['issueDate'], determinationCert);
      const startDate = getFormattedDate(
        path(['issueDate'], determinationCert)
      );
      const endDate = getFormattedDate(
        path(['revokedDate'], determinationCert)
      );
      const dateRange = getDateRange(startDate, endDate);
      dateRangeMap[startDate] = dateRange;
      dateMap[startDate] = startDateObj;
      return dateRange;
    });
    return sortDateRangeList(dateMap, dateRangeMap) || [];
  }
  return [];
};

export const getDateRange = (startDate: string, endDate: string): string => {
  return `${startDate} - ${endDate}`;
};
