import { AdDetail } from './sale-and-trade.model';
import {
  Authorisation,
  FishingBusinessAuthorisations,
} from './authorisation.model';
import {
  CurrentlyOwnedFishingBusiness,
  ShareholderReport,
} from './shareholder-report.model';
import { FishingBusinessHistoryResponse } from './fishing-business-history.model';
import { LicenceDetail } from './licence-detail.model';
import { AccountSummary } from './account-summary.model';
import {
  DataLoaderData,
  dataLoaderMetadata,
  DataLoaderMetadata,
  DataLoaderType,
} from '@fishonline2023/shared/models';
import { Message } from './message.constant';
import { TransactionData } from './transaction.model';

export type FDDataLoaderData =
  | DataLoaderData
  | LicenceDetail
  | FishingBusinessHistoryResponse
  | AccountSummary
  | ShareholderReport
  | CurrentlyOwnedFishingBusiness
  | Authorisation[]
  | FishingBusinessAuthorisations[]
  | AdDetail
  | TransactionData;

export const FDDataLoaderMetadata: Partial<
  Record<DataLoaderType, DataLoaderMetadata>
> = {
  ...dataLoaderMetadata,
  [DataLoaderType.LicenceDetails]: {
    apiRoute: 'customer/licence',
    loadingMessage: Message.LoadingLicenceDetails,
  },
  [DataLoaderType.FishingBusinessHistory]: {
    apiRoute: 'user/fishing-business-history',
    loadingMessage: Message.LoadingFishingBusinessHistory,
  },
  [DataLoaderType.AccountSummary]: {
    apiRoute: 'user/account-summary',
    loadingMessage: Message.LoadingAccountSummary,
  },
  [DataLoaderType.ShareholderReport]: {
    apiRoute: 'share-extract/shareholder-report',
    loadingMessage: Message.LoadingAccountSummary,
  },
  [DataLoaderType.FishingBusinessReport]: {
    apiRoute: 'share-extract/fishing-business-report',
    loadingMessage: Message.LoadingFishingBusiness,
  },
  [DataLoaderType.MyPersonalAuthorisations]: {
    apiRoute: 'user/personal-authorisations',
    loadingMessage: Message.LoadingMyPersonalAuthorisations,
  },
  [DataLoaderType.MyFishingBusinessAuthorisations]: {
    apiRoute: 'user/business-authorisations',
    loadingMessage: Message.LoadingMyFishingBusinessAuthorisations,
  },
  [DataLoaderType.ForSaleAndTradeDetail]: {
    apiRoute: 'bulletin-board-ads',
    loadingMessage: Message.LoadingForSaleAndTradeDetail,
  },
  [DataLoaderType.MyReceiptsDetail]: {
    apiRoute: `e-receipt`,
    loadingMessage: Message.LoadingMyReceiptsDetail,
  },
};
