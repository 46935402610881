import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ReactiveFormsModule } from '@angular/forms';
import { startWith, takeUntil } from 'rxjs';
import { InputFieldTextDirective } from '../input-field-text-directive/input-field-text.directive';
import { formatAcn } from '../utils/format-abn-acn';

@Component({
  selector: 'sv-ui-input-field-acn',
  standalone: true,
  imports: [CommonModule, ReactiveFormsModule],
  templateUrl: '../input-field-text-directive/input-field-text.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class InputFieldACNComponent
  extends InputFieldTextDirective
  implements OnInit
{
  public override ngOnInit() {
    super.ngOnInit();
    this.form.valueChanges
      .pipe(startWith(this.form.value), takeUntil(this.destroy$))
      .subscribe((value) => {
        this.form.patchValue(formatAcn(value), { emitEvent: false });
      });
  }
}
