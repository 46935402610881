import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  inject,
  OnDestroy,
  OnInit,
} from '@angular/core';
import { CommonModule } from '@angular/common';
import { ToastService } from './toast.service';
import { Subject, takeUntil } from 'rxjs';
import { ToastConfig } from '@fishonline2023/webapps/model/fd2023';

@Component({
  selector: 'sv-ui-toast',
  standalone: true,
  imports: [CommonModule],
  templateUrl: './toast.component.html',
  styleUrls: ['./toast.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ToastComponent implements OnInit, OnDestroy {
  public toastService = inject(ToastService);
  private cdr = inject(ChangeDetectorRef);
  private destroy$ = new Subject<void>();

  public message?: string;
  public config?: ToastConfig;

  public ngOnDestroy() {
    this.destroy$.next();
    this.destroy$.complete();
  }

  public ngOnInit() {
    this.toastService.message$
      .pipe(takeUntil(this.destroy$))
      .subscribe((message) => {
        this.message = message;
        this.cdr.detectChanges();
      });
    this.toastService.config$
      .pipe(takeUntil(this.destroy$))
      .subscribe((config) => {
        this.config = config;
        this.cdr.detectChanges();
      });
  }

  get toastMessageClassName() {
    return `${this.config?.color} ${this.config?.position}`;
  }
}
