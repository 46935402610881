import { createAction, props } from '@ngrx/store';
import { Permission, UserProfile } from '@fishonline2023/webapps/model/fd2023';
import { AgentCustomer } from '@fishonline2023/shared/models';

export const loadUserProfile = createAction(`[User Profile] Load User Profile`);

export const loadUserProfileSuccess = createAction(
  `[User Profile] Load User Profile Success`,
  props<{ userProfile: UserProfile }>()
);

export const loadUserProfileFailure = createAction(
  `[User Profile] Load User Profile Failure`,
  props<{ errorMessage: string }>()
);

export const logout = createAction(`[User Profile] Logout`);

export const logoutSuccessful = createAction(
  `[User Profile] Logout Successful`
);

export const logoutFailure = createAction(
  `[User Profile] Logout Failure`,
  props<{ errorMessage: string }>()
);

export const agentRoleSwitched = createAction(
  `[User Profile] Agent Role Switched`,
  props<{ agentCustomerId: number }>()
);

export const agentRoleSwitchedSuccessful = createAction(
  `[User Profile] Agent Role Switched Successful`,
  props<{
    agentCustomerId: number;
    fisherDirectPermissionList: Permission[];
    myAgentList: AgentCustomer[];
  }>()
);

export const agentRoleSwitchedFailure = createAction(
  `[User Profile] Agent Role Switched Failure`,
  props<{ errorMessage: string }>()
);

export const defaultAgentCustomerProfileRequested = createAction(
  `[User Profile] Default Agent Customer Profile Requested`
);

export const patchDefaultAgentCustomerAsActAsCustomer = createAction(
  `[User Profile] Patch Default Agent Customer As Act As Customer`,
  props<{ actAsCustomer: AgentCustomer }>()
);
