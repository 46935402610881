import { inject, Injectable } from '@angular/core';
import { complement, isNil } from '@fishonline2023/shared/ramda';
import {
  homeMenu,
  Message,
  Permission,
  SystemStatus,
} from '@fishonline2023/webapps/model/fd2023';
import {
  agentRoleSwitchedSuccessful,
  getActAsCustomer,
  getCustomerPermissionList,
} from '@fishonline2023/webapps/user-portal/fd2023/store/user-profile';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import {
  catchError,
  filter,
  map,
  of,
  pipe,
  switchMap,
  take,
  withLatestFrom,
} from 'rxjs';
import { HomePageService } from './home-page.service';
import {
  loadAccountProfile,
  loadAccountProfileFailure,
  loadAccountProfileSuccess,
  menuListUpdated,
} from './home-page.actions';
import { getSystemStatus } from '@fishonline2023/webapps/user-portal/fd2023/feature/system-status';
import { createMenuFromSystemStatusAndPermissions } from '@fishonline2023/webapps/user-portal/fd2023/feature/permissions-menu-map';

@Injectable()
export class HomePageEffects {
  private store = inject(Store);
  private actions$ = inject(Actions);
  private homePageService = inject(HomePageService);

  public loadAccountProfile$ = createEffect(() =>
    this.actions$.pipe(
      ofType(loadAccountProfile, agentRoleSwitchedSuccessful),
      switchMap(() =>
        this.store
          .select(getActAsCustomer)
          .pipe(filter(complement(isNil)), take(1))
      ),
      switchMap(({ id }) =>
        this.homePageService.loadAccountProfile(id).pipe(
          map((accountProfile) => {
            return loadAccountProfileSuccess({
              accountProfile,
            });
          }),
          catchError(() => {
            return of(
              loadAccountProfileFailure({
                errorMessage: Message.TryAgainLater,
              })
            );
          })
        )
      )
    )
  );

  private updateMenuList = pipe(
    withLatestFrom<Permission[], [SystemStatus | undefined]>(
      this.store.select(getSystemStatus)
    ),
    map(([permissionList, systemStatus]) =>
      menuListUpdated({
        menuList: [
          homeMenu,
          ...createMenuFromSystemStatusAndPermissions(
            systemStatus,
            permissionList
          ),
        ],
      })
    )
  );

  public updateMenuListOnAgentRoleSwitchSuccessful$ = createEffect(() =>
    this.actions$.pipe(
      ofType(agentRoleSwitchedSuccessful),
      map(({ fisherDirectPermissionList }) => fisherDirectPermissionList),
      this.updateMenuList
    )
  );

  public updateMenuListOnLoadHomePageLoaded$ = createEffect(() =>
    this.actions$.pipe(
      ofType(loadAccountProfile),
      switchMap(() => this.store.select(getCustomerPermissionList)),
      this.updateMenuList
    )
  );
}
