// eslint-disable-next-line @nrwl/nx/enforce-module-boundaries
import { EventType } from '@fishonline2023/webapps/shared/feature/appsync';
import { Message } from './message.constant';
import { PrawnBallotRegionReport } from './print-prawn-ballot.model';

export type DataLoaderData = PrawnBallotRegionReport;

export enum DataLoaderType {
  LicenceDetails,
  PrawnBallot,
  FishingBusinessHistory,
  AccountSummary,
  ShareholderReport,
  FishingBusinessReport,
  MyPersonalAuthorisations,
  MyFishingBusinessAuthorisations,
  ForSaleAndTradeDetail,
  MyReceiptsDetail,
  CustomerDetail,
}

export interface DataLoaderActivityLog {
  eventType: EventType;
  detail?: string;
}

export interface DataLoaderMetadata {
  apiRoute: string;
  loadingMessage: string;
}

export const dataLoaderMetadata: Partial<
  Record<DataLoaderType, DataLoaderMetadata>
> = {
  [DataLoaderType.PrawnBallot]: {
    apiRoute: 'prawn-ballot',
    loadingMessage: Message.LoadingPrintPrawnBallotReport,
  },
};
