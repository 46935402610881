import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  OnChanges,
  Output,
  SimpleChanges,
} from '@angular/core';
import { CommonModule } from '@angular/common';
import { NextSortOrder, SortOrder } from '@fishonline2023/shared/models';
import { equals } from '@fishonline2023/shared/ramda';

@Component({
  selector: 'sv-ui-table-sorting-button',
  standalone: true,
  imports: [CommonModule],
  templateUrl: './table-sorting-button.component.html',
  styleUrls: ['./table-sorting-button.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TableSortingButtonComponent implements OnChanges {
  @Input() public column!: string;
  @Input() public columnSorted?: string | null;
  @Output() public sortButtonClicked = new EventEmitter<SortOrder>();
  protected sortDirection: SortOrder = SortOrder.NOT_SORTED;
  protected readonly SortOrder = SortOrder;

  public ngOnChanges(changes: SimpleChanges) {
    if (!equals(changes['columnSorted'].currentValue, this.column)) {
      this.sortDirection = SortOrder.NOT_SORTED;
    }
  }

  protected sortButtonClickedHandler() {
    const nextSortOrder = equals(this.columnSorted, this.column)
      ? NextSortOrder[this.sortDirection]
      : SortOrder.ASC;
    return this.setSortDirection(nextSortOrder);
  }

  private setSortDirection(sortDirection: SortOrder) {
    this.sortDirection = sortDirection;
    this.sortButtonClicked.emit(sortDirection);
  }
}
