import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ItemList } from '@fishonline2023/shared/models';

@Component({
  selector: 'sv-ui-full-width-item-list',
  standalone: true,
  imports: [CommonModule],
  templateUrl: './full-width-item-list.component.html',
  styleUrls: ['./full-width-item-list.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class FullWidthItemListComponent {
  @Input() public itemList!: ItemList;
  @Input() public isLongLabel = false;
}
