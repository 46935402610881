<div class="p-4 bg-gray">
  <div class="d-flex flex-wrap justify-content-between mb-2">
    <div>
      <h5>{{ title }}</h5>
    </div>
    <div>
      <a [routerLink]="'/' + FDRoute.Disclaimer" target="_blank">Disclaimer</a>
    </div>
  </div>
  <ng-content></ng-content>
  <a
    (click)="handleLinkClick()"
    *ngIf="quotaBalanceData.length > 3 && isShowAllLinkVisible"
    class="d-block link-brand-blue mt-4"
    href="javascript:void(0);"
    >Show all</a
  >
  <a
    (click)="handleLinkClick()"
    *ngIf="quotaBalanceData.length > 3 && !isShowAllLinkVisible"
    class="d-block link-brand-blue mt-4"
    href="javascript:void(0);"
    >Hide</a
  >
</div>
