export enum Permission {
  FOR_SALE_TRADE = 'FOR_SALE_TRADE',
  USER_ACTIVITY_MONITOR = 'USER_ACTIVITY_MONITOR',
  QUOTA_TRANSFERS = 'QUOTA_TRANSFERS',
  QUOTA_TRANSACTION_HISTORY_INQUIRIES = 'QUOTA_TRANSACTION_HISTORY_INQUIRIES',
  QUOTA_BALANCE_INQUIRIES = 'QUOTA_BALANCE_INQUIRIES',
  CATCH_EFFORT_HISTORY_INQUIRIES = 'CATCH_EFFORT_HISTORY_INQUIRIES',
  MY_FISHING_BUSINESS_AUTHORISATIONS = 'MY_FISHING_BUSINESS_AUTHORISATIONS',
  ENDORSEMENT_HISTORY_INQUIRIES = 'ENDORSEMENT_HISTORY_INQUIRIES',
  SUBMIT_CATCH_AND_EFFORT_REPORT = 'SUBMIT_CATCH_AND_EFFORT_REPORT',
  SUBMIT_LOST_AND_FOUND_GEAR_REPORT = 'SUBMIT_LOST_AND_FOUND_GEAR_REPORT',
  SUBMIT_THREATENED_PROTECTED_SPECIES_RECORD = 'SUBMIT_THREATENED_PROTECTED_SPECIES_RECORD',
  MESSAGES = 'MESSAGES',
  TAG_TRANSACTIONS = 'TAG_TRANSACTIONS',
  TAG_TRANSACTION_HISTORY_INQUIRIES = 'TAG_TRANSACTION_HISTORY_INQUIRIES',
  AGENT_MANAGEMENT = 'AGENT_MANAGEMENT',
  ACCOUNT_MANAGEMENT = 'ACCOUNT_MANAGEMENT',
  MY_PERSONAL_AUTHORISATIONS = 'MY_PERSONAL_AUTHORISATIONS',
  VIEW_LICENCE_DETAILS = 'VIEW_LICENCE_DETAILS',
  VIEW_DEMERIT_POINTS = 'VIEW_DEMERIT_POINTS',
  COMMERCIAL_FISHING_LICENCE_FUNCTIONS = 'COMMERCIAL_FISHING_LICENCE_FUNCTIONS',
  FISHING_BOAT_LICENCE_FUNCTIONS = 'FISHING_BOAT_LICENCE_FUNCTIONS',
  FISHING_BUSINESS_FUNCTIONS = 'FISHING_BUSINESS_FUNCTIONS',
  VIEW_EDIT_CONTACT_DETAILS = 'VIEW_EDIT_CONTACT_DETAILS',
  AUTHORISE_REVOKE_FISHER = 'AUTHORISE_REVOKE_FISHER',
  REVOKE_FISHER = 'REVOKE_FISHER',
  TRANSFER_FB_OFFER_COMPONENT_OFFER_RECEIVE = 'TRANSFER_FB_OFFER_COMPONENT_OFFER_RECEIVE',
  TRANSFER_FB_RECEIVE = 'TRANSFER_FB_RECEIVE',
  TRANSFER_FB_RECEIVE_AGENT = 'TRANSFER_FB_RECEIVE_AGENT',
  MANAGE_FINANCE = 'MANAGE_FINANCE',
}
