import { CommonModule } from '@angular/common';
import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
} from '@angular/core';
import {
  FDDataLoaderData,
  LicenceDetail,
} from '@fishonline2023/webapps/model/fd2023';
import {
  ButtonComponent,
  ErrorComponent,
  LicenceDetailConditionNotationComponent,
  LicenceDetailInfoComponent,
  LoadingComponent,
  ModalComponent,
} from '@fishonline2023/webapps/shared/ui/base-components';
import { filter, Observable, take } from 'rxjs';
import { EventType } from '@fishonline2023/webapps/shared/feature/appsync';
import { complement, isNil } from '@fishonline2023/shared/ramda';
import { DataLoaderActivityLog } from '@fishonline2023/shared/models';

@Component({
  selector: 'sv-ui-licence-details',
  standalone: true,
  imports: [
    CommonModule,
    ButtonComponent,
    ModalComponent,
    ErrorComponent,
    LoadingComponent,
    LicenceDetailConditionNotationComponent,
    LicenceDetailInfoComponent,
  ],
  templateUrl: './licence-details.component.html',
  styleUrls: ['./licence-details.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class LicenceDetailsComponent implements OnInit {
  @Input() public dataLoaderData$!: Observable<FDDataLoaderData>;
  @Output() public activityLogTriggered =
    new EventEmitter<DataLoaderActivityLog>();

  public get licenceDetails$() {
    return this.dataLoaderData$ as Observable<LicenceDetail>;
  }

  public ngOnInit() {
    this.licenceDetails$
      .pipe(filter(complement(isNil)), take(1))
      .subscribe((licenceDetails: LicenceDetail) => {
        this.activityLogTriggered.emit({
          eventType: EventType.ViewLicenceDetails,
          detail: JSON.stringify({
            'Licence Type': 'Commercial Fishing Licence',
            'Licence Holder': licenceDetails.header.number,
            'Licence Holder Name': licenceDetails.header.holder,
          }),
        });
      });
  }
}
