import { Action, createReducer, on } from '@ngrx/store';

import {
  SearchFieldValue,
  SearchResult,
  SearchResultItem,
  SearchType,
  ViewStatus,
} from '@fishonline2023/shared/models';
import {
  downloadFailed,
  downloadSuccessful,
  downloadTriggered,
  searchFailed,
  searchFieldValuesLoadFailed,
  searchFieldValuesLoadSuccessful,
  searchFilterPopulatedByQueryParams,
  searchMetadataSet,
  searchReset,
  searchResultCleared,
  searchResultDisplayColumnListUpdated,
  searchResultItemSelected,
  searchSuccessful,
  searchTriggered,
} from './search.actions';
import { equals } from '@fishonline2023/shared/ramda';
import { logoutSuccessful } from '@fishonline2023/webapps/user-portal/fd2023/store/user-profile';

export const SEARCH_FEATURE_KEY = 'search';

export interface SearchState {
  searchResult?: SearchResult;
  searchFilter?: Record<string, unknown>;
  searchType?: SearchType;
  searchResultDisplayColumnList: Array<string>;
  searchFieldValues?: Record<string, Array<SearchFieldValue>>;
  selectedSearchResultItem?: SearchResultItem;
  searchViewStatus: ViewStatus;
  downloadViewStatus: ViewStatus;
  loadSearchFilterOptionsViewStatus: ViewStatus;
  errorMessage?: string;
}

export const initialSearchState: SearchState = {
  searchViewStatus: ViewStatus.Initial,
  downloadViewStatus: ViewStatus.Initial,
  loadSearchFilterOptionsViewStatus: ViewStatus.Initial,
  searchResultDisplayColumnList: [],
};

const initSearch = (
  state: SearchState,
  {
    searchType,
    searchResultDisplayColumnList,
  }: { searchType: SearchType; searchResultDisplayColumnList?: Array<string> }
): SearchState => {
  if (equals(state.searchType, searchType)) {
    return state;
  }
  return {
    ...initialSearchState,
    searchType,
    loadSearchFilterOptionsViewStatus: ViewStatus.Loading,
    searchResultDisplayColumnList: searchResultDisplayColumnList ?? [],
  };
};

const reducer = createReducer(
  initialSearchState,
  on(searchReset, () => initialSearchState),
  on(searchTriggered, (state, { searchFilter }) => ({
    ...state,
    searchFilter,
    searchViewStatus: ViewStatus.Loading,
  })),
  on(downloadTriggered, (state) => ({
    ...state,
    downloadViewStatus: ViewStatus.Loading,
  })),
  on(searchFilterPopulatedByQueryParams, (state, { searchFilter }) => ({
    ...state,
    searchFilter,
  })),
  on(searchMetadataSet, initSearch),
  on(searchSuccessful, (state, { searchResult }) => ({
    ...state,
    searchResult,
    searchViewStatus: ViewStatus.Success,
  })),
  on(downloadSuccessful, (state) => ({
    ...state,
    downloadViewStatus: ViewStatus.Success,
  })),
  on(searchFieldValuesLoadSuccessful, (state, { searchFieldValues }) => ({
    ...state,
    searchFieldValues,
    loadSearchFilterOptionsViewStatus: ViewStatus.Success,
  })),
  on(searchFieldValuesLoadFailed, (state, { errorMessage }) => ({
    ...state,
    loadSearchFilterOptionsViewStatus: ViewStatus.Failure,
    errorMessage,
  })),
  on(searchFailed, (state, { errorMessage }) => ({
    ...state,
    errorMessage,
    searchViewStatus: ViewStatus.Failure,
    searchResult: undefined,
  })),
  on(downloadFailed, (state, { errorMessage }) => ({
    ...state,
    errorMessage,
    downloadViewStatus: ViewStatus.Failure,
  })),
  on(
    searchResultDisplayColumnListUpdated,
    (state, { searchResultDisplayColumnList }) => ({
      ...state,
      searchResultDisplayColumnList,
    })
  ),
  on(searchResultItemSelected, (state, { selectedSearchResultItem }) => ({
    ...state,
    selectedSearchResultItem,
  })),
  on(searchResultCleared, (state) => ({
    ...state,
    searchResult: undefined,
  })),
  on(logoutSuccessful, () => initialSearchState)
);

export function searchReducer(state: SearchState | undefined, action: Action) {
  return reducer(state, action);
}
