import {
  ChangeDetectionStrategy,
  Component,
  Input,
  OnInit,
} from '@angular/core';
import { CommonModule } from '@angular/common';
import { TableSortingButtonComponent } from '../table-sorting-button/table-sorting-button.component';
import {
  SortOrder,
  TableData,
  TableRowData,
} from '@fishonline2023/shared/models';
import { clone, equals } from '@fishonline2023/shared/ramda';
import * as dayjs from 'dayjs';
import { RouterLink } from '@angular/router';

@Component({
  selector: 'sv-ui-table',
  standalone: true,
  imports: [CommonModule, TableSortingButtonComponent, RouterLink],
  templateUrl: './table.component.html',
  styleUrls: ['./table.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TableComponent implements OnInit {
  @Input() public tableData!: TableData;
  @Input() public sortColumns: string[] = [];
  protected sortedColumn?: string;
  protected sortedTableData!: TableData;

  public ngOnInit() {
    this.sortedTableData = clone(this.tableData);
  }

  protected tableHeaders() {
    return Object.keys(this.tableData[0]);
  }

  protected tableValues(
    tableData: Record<string, TableRowData>
  ): TableRowData[] {
    return Object.values(tableData);
  }

  protected sortTable(sortOrder: SortOrder, header: string) {
    if (equals(sortOrder, SortOrder.NOT_SORTED)) {
      this.sortedColumn = undefined;
      return (this.sortedTableData = clone(this.tableData));
    }
    this.sortedColumn = header;
    const sortOrderCoefficient = equals(sortOrder, SortOrder.ASC) ? 1 : -1;
    return (this.sortedTableData = clone(this.tableData).sort((a, b) => {
      const isDate = dayjs(a[header].value).isValid();
      if (isDate) {
        return dayjs(a[header].value).isAfter(dayjs(b[header].value))
          ? sortOrderCoefficient
          : -sortOrderCoefficient;
      }
      return a[header] > b[header]
        ? sortOrderCoefficient
        : -sortOrderCoefficient;
    }));
  }
}
