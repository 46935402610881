import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  inject,
  Input,
  OnInit,
  Output,
} from '@angular/core';
import { CommonModule } from '@angular/common';
import { Observable } from 'rxjs';
import {
  FDDataLoaderData,
  FDDateTimeDayJSFormat,
  ShareholderReport,
} from '@fishonline2023/webapps/model/fd2023';
import {
  FullWidthItemListComponent,
  SearchDetailWrapperComponent,
  TableComponent,
} from '@fishonline2023/webapps/shared/ui/base-components';
import * as dayjs from 'dayjs';
import { FishingBusinessSectionComponent } from '../fishing-business-section/fishing-business-section.component';
import { EventType } from '@fishonline2023/webapps/shared/feature/appsync';
import { ActivatedRoute } from '@angular/router';
import {
  DataLoaderActivityLog,
  ItemList,
  TableData,
  tableRow,
} from '@fishonline2023/shared/models';

@Component({
  selector: 'sv-ui-shareholder-report',
  standalone: true,
  imports: [
    CommonModule,
    FullWidthItemListComponent,
    TableComponent,
    FishingBusinessSectionComponent,
    SearchDetailWrapperComponent,
  ],
  templateUrl: './shareholder-report.component.html',
  styleUrls: ['./shareholder-report.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ShareholderReportComponent implements OnInit {
  @Input() dataLoaderData$!: Observable<FDDataLoaderData>;
  @Output() public activityLogTriggered =
    new EventEmitter<DataLoaderActivityLog>();
  protected readonly window = window;
  private activatedRoute = inject(ActivatedRoute);

  public get shareholderReport$() {
    return this.dataLoaderData$ as Observable<ShareholderReport>;
  }

  public ngOnInit() {
    this.activityLogTriggered.emit({
      eventType: EventType.ViewShareExtractShareholder,
      detail: JSON.stringify({
        Shareholder:
          this.activatedRoute.snapshot.queryParams['shareholderNumber'],
      }),
    });
  }

  protected shareholderSummary(shareholderReport: ShareholderReport): ItemList {
    return [
      {
        key: 'Shareholder Name',
        value: shareholderReport.shareholderName,
      },
      {
        key: 'Shareholder Number',
        value: shareholderReport.shareholderNumber,
      },
      {
        key: 'Fishing Business(es) currently owned',
        value: shareholderReport.fishingBusinessCurrentlyOwned.join(', '),
      },
      {
        key: 'Fishing Business(es) previously owned',
        value: shareholderReport.fishingBusinessPreviouslyOwned.join(', '),
      },
    ];
  }

  protected fishingBusinessAuthorisedFor(
    shareholderReport: ShareholderReport
  ): TableData {
    return shareholderReport.currentAuthorisedFisherFor.map(
      (fishingBusiness) => ({
        'Date/Time': tableRow(
          dayjs(fishingBusiness.dateTime).format(FDDateTimeDayJSFormat)
        ),
        'Fishing Business No.': tableRow(
          fishingBusiness.fishingBusinessNumber ?? ''
        ),
        'Fisher Registration No.': tableRow(
          fishingBusiness.fisherRegistrationNumber
        ),
        'Fisher Name.': tableRow(fishingBusiness.fisherName),
      })
    );
  }

  protected shareHolderAuthorisationHistory(
    shareholderReport: ShareholderReport
  ): TableData {
    return shareholderReport.shareholderAuthorisationHistory.map(
      (fishingBusiness) => ({
        'Date/Time': tableRow(
          dayjs(fishingBusiness.dateTime).format(FDDateTimeDayJSFormat)
        ),
        'Transaction Type': tableRow(fishingBusiness.transactionType),
        'Fishing Business No.': tableRow(
          fishingBusiness.fishingBusinessNumber ?? ''
        ),
        'Fisher Registration No.': tableRow(
          fishingBusiness.fisherRegistrationNumber
        ),
        'Fisher Name.': tableRow(fishingBusiness.fisherName),
      })
    );
  }

  protected shareholderDemeritPointActivityList(
    shareholderReport: ShareholderReport
  ): TableData {
    return shareholderReport.shareholderDemeritPointActivityList.map(
      (demeritPointActivity) => ({
        'Date/Time': tableRow(
          dayjs(demeritPointActivity.dateTime).format(FDDateTimeDayJSFormat)
        ),
        'Transaction Type': tableRow(demeritPointActivity.transactionType),
        Offence: tableRow(demeritPointActivity.offence),
        'Demerit points': tableRow(demeritPointActivity.demeritPoints),
        'Conviction date': tableRow(
          dayjs(demeritPointActivity.convictionDate).format(
            FDDateTimeDayJSFormat
          )
        ),
        Balance: tableRow(demeritPointActivity.balance),
      })
    );
  }
}
