import { TransactionType } from './transaction.enum';

export const Message = {
  TryAgainLater: 'Please refresh the page to try again later.',
  LoadingSystemStatus: 'Loading System Status...Please wait!',
  LoadingUserProfile: 'Loading User Profile...Please wait!',
  LoadingHomePage: 'Loading Home Page...Please wait!',
  LoadAgentForCustomerProfileError: 'Error loading agent for customer profile',
  LogoutFailed: 'Logout failed.',
  LoadingAgentCustomerProfile: 'Loading Agent Customer Profile...Please wait!',
  TransactionCreateAmendErrorMessageHeader:
    'We found some errors that will prevent you from completing this transaction',
  TransactionCreateAmendInfoMessageHeader:
    'The following actions will be initiated',
  TransactionConfirmErrorMessageHeader:
    'We found some errors that will prevent you from completing this transaction',
  TransactionConfirmInfoMessageHeader: 'What happens when you confirm this',
  TransactionReceiptInfoMessageHeader:
    'The following actions have been initiated',
  LoadingLicenceDetails: 'Loading Licence Details...Please wait!',
  CreditCardFailedToValidate:
    'Please check if you have entered the correct credit card detail and try again later.',
  ValidatingCreditCard:
    'Validating credit card details, please wait for a moment...',
  ConfirmingAndMakingPayment:
    'Confirming and making payment, please wait for a moment...',
  validatingPayLater: 'Validating Pay Later, please wait for a moment...',
  LoadingPrintPrawnBallotReport:
    'Loading Print Prawn Ballot Report...Please wait!',
  TransactionPaymentFailureTitle:
    'We found some errors that will prevent you from completing this payment',
  TransactionPaymentFailureMessage: 'Please select one of the options below',
  LoadingFishingBusinessHistory:
    'Loading Fishing Business History...Please wait!',
  LoadingAccountSummary: 'Loading Account Summary...Please wait!',
  LoadingFishingBusiness: 'Loading Fishing Business...Please wait!',
  FailedToLoadTransactionData:
    'Failed to load transaction data...Please try again later',
  FailedToSubmitTransaction:
    'Failed to submit transaction...Please try again later',
  FailedToApproveTransaction:
    'Failed to approve transaction...Please try again later',
  FailedToAbandonTransaction:
    'Failed to abandon transaction...Please try again later',
  FailedToWithdrawTransaction:
    'Failed to withdraw transaction...Please try again later',
  FailedToRejectTransaction:
    'Failed to reject transaction...Please try again later',
  FailedToAcceptTransaction:
    'Failed to accept transaction...Please try again later',
  AbandoningTransaction: 'Abandoning transaction...Please wait!',
  AbandonTransactionSuccessful: 'Successfully abandon transaction',
  LoadingMyPersonalAuthorisations:
    'Loading My Personal Authorisations...Please wait!',
  LoadingMyFishingBusinessAuthorisations:
    'Loading My Fishing Business Authorisations...Please wait!',
  TransactionWithdrawingOfferMessage: 'Withdraw offer...Please wait!',
  TransactionAcceptingOfferMessage: 'Accepting offer...Please wait!',
  TransactionRejectingOfferMessage: 'Rejecting offer...Please wait!',
  AbandonTransactionNotAllowed: 'Abandon transaction is not allowed',
  LoadingFishingBusinesses: 'Loading Fishing Businesses...Please wait!',
  LoadingFishingBusinessesError:
    'Error loading fishing businesses...Please try again later!',
  LoadingCustomers: 'Loading Customers...Please wait!',
  LoadingCustomersError: 'Failed to load customers...Please try again later!',
  LoadingForSaleAndTradeDetail:
    'Loading For Sale And Trade detail...Please wait!',
  LoadingMyReceiptsDetail: 'Loading My Receipts detail...Please wait!',
  CreateFishingBusiness: 'Creating Fishing Business...Please wait!',
  CreateFishingBusinessError:
    'Failed to create fishing business...Please try again later!',
  CreatingNewAd: 'Creating new ad...Please wait!',
  CreatingNewAdError: 'Failed to create new ad...Please try again later!',
  UpdatingAd: 'Updating ad...Please wait!',
  UpdatingAdError: 'Failed to update ad...Please try again later!',
  UpdatingAdSuccess: 'Successfully updated ad',
  DeletingAd: 'Deleting ad...Please wait!',
  DeletingAdError: 'Failed to delete ad...Please try again later!',
  DeletingAdSuccess: 'Successfully deleted ad',
  LoadingAgents: 'Loading Agents...Please wait!',
  LoadingAgentCandidates: 'Loading Agent Candidates...Please wait!',
  LoadingNewAgent: 'Loading New Agent...Please wait!',
  SavingAgent: 'Saving Agent...Please wait!',
  SavingAgentSuccess: 'Successfully saved agent',
  SavingAgentError: 'Failed to save agent...Please try again later!',
  DeletingAgent: 'Deleting Agent...Please wait!',
  DeletingAgentSuccess: 'Successfully deleted agent',
  DeletingAgentError: 'Failed to delete agent...Please try again later!',
  TransferFBConfirmMessage: `<small>When you choose to submit your offer it will become available to the transferee to accept. You may withdraw this offer at any time up until the transferee Accepts or Rejects your offer. If the transferee accepts and finalises your offer, the current Fishing Business Determination will be revoked. You will no longer own this Fishing Business and/or any of its components. I accept that I have authority to make this transfer. I have read and understood the <a href="/terms-conditions" target="_blank">Terms and Conditions<a/>.</small>`,
  TransferComponentConfirmMessage: `<small>When you choose to submit your offer it will become available to the transferee to accept. You may withdraw this offer at any time up until the transferee Accepts or Rejects your offer. If the transferee accepts and finalises your offer, the current Fishing Business Determination will be revoked. I accept that I have authority to make this transfer. I have read and understood the <a href="/terms-conditions" target="_blank">Terms and Conditions<a/>.</small>`,
};

export const TransactionLoadingMessage = {
  [TransactionType.AmendCustomer]: 'Loading Customer Details...Please wait!',
  [TransactionType.NominateRevokeFisher]:
    'Loading Fisher Details...Please wait!',
  [TransactionType.RenewLicence]: 'Loading Fisher Details...Please wait!',
  // todo: update
  [TransactionType.TransferComponents]: 'Loading Transfer...Please wait!',
  [TransactionType.TransferFishingBusiness]: 'Loading Transfer...Please wait!',
  [TransactionType.TransferQuota]:
    'Loading Transfer Quota Details...Please wait!',
};

export const TransactionSubmittingMessage = {
  [TransactionType.AmendCustomer]: 'Submitting Customer Details...Please wait!',
  [TransactionType.NominateRevokeFisher]:
    'Submitting Fisher Details...Please wait!',
  [TransactionType.RenewLicence]: 'Submitting Fisher Details...Please wait!',
  [TransactionType.TransferComponents]: 'Submitting Transfer...Please wait!',
  [TransactionType.TransferFishingBusiness]:
    'Submitting Transfer...Please wait!',
  [TransactionType.TransferQuota]: 'Submitting Transfer Quota...Please wait!',
};

export const TransactionConfirmingMessage = {
  [TransactionType.AmendCustomer]: 'Confirming Customer Details...Please wait!',
  [TransactionType.NominateRevokeFisher]:
    'Confirming Fisher Details...Please wait!',
  [TransactionType.RenewLicence]: 'Confirming Fisher Details...Please wait!',
  [TransactionType.TransferQuota]: 'Confirming Transfer Quota...Please wait!',
  [TransactionType.TransferFishingBusiness]: 'Submitting offer...Please wait!',
  [TransactionType.TransferComponents]: 'Submitting offer...Please wait!',
};
