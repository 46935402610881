import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { EffectsModule } from '@ngrx/effects';
import { FAAnalyticsEffects } from './store/fa-analytics.effects';
import { FDAnalyticsService } from './services/fd-analytics.service';
import { FAAnalyticsService } from './services/fa-analytics.service';
import { FDAnalyticsEffects } from './store/fd-analytics.effects';
import { SharedAnalyticsService } from './services/shared-analytics.service';
import { SharedAnalyticsEffects } from './store/shared-analytics.effects';

@NgModule({
  imports: [
    CommonModule,
    EffectsModule.forFeature([
      FDAnalyticsEffects,
      FAAnalyticsEffects,
      SharedAnalyticsEffects,
    ]),
  ],
  providers: [FDAnalyticsService, FAAnalyticsService, SharedAnalyticsService],
})
export class WebappsSharedAnalyticsModule {}
