import { FACustomerDetail } from '@fishonline2023/shared/models';

export type AdditionalFAAmendCustomerDetail = {
  fisherDirectUserId?: string;
  fisherMobileUserId?: string;
  registrationDate?: string;
  fisheriesOfficePhoneNumber?: string;
};
export type CustomerDetailCommercialFishingLicence = {
  status: string;
  currentDigitalAuthority: number;
  issuedDate: string;
  expiryDate: string;
};
export type CustomerDetailFishingBusiness = {
  id: number;
  ownerName: string;
  typeDescription: string;
  currentDigitalAuthority: number;
};
export type CustomerDetailAuthorisedFishingBusiness =
  CustomerDetailFishingBusiness & {
    authorisationDate: string;
  };
export type CustomerDetailRecreationalExemptionCertificate = {
  id: number;
  type: string;
  boatId: number;
  expiryDate: string;
  numberExempt: number;
  tweedRiver: boolean;
};
export type PendingTransaction = {
  id: number;
  transactionType: string;
  fbLinNumber: number;
  lastModifiedDate: string;
  lastModifiedBy: string;
};
export type CustomerDetail = {
  faAmendCustomerDetail: FACustomerDetail;
  additionalFAAmendCustomerDetail: AdditionalFAAmendCustomerDetail;
  commercialFishingLicence?: CustomerDetailCommercialFishingLicence;
  authorisedFishingBusinessList: CustomerDetailAuthorisedFishingBusiness[];
  ownedFishingBusinessList: CustomerDetailFishingBusiness[];
  recreationalExemptionCertificateList: CustomerDetailRecreationalExemptionCertificate[];
  pendingTransactionList: PendingTransaction[];
};
