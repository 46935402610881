import {
  ChangeDetectionStrategy,
  Component,
  inject,
  Input,
} from '@angular/core';
import {
  Condition,
  DeterminationCertificate,
  FDDateTimeAngularPipeFormat,
} from '@fishonline2023/webapps/model/fd2023';
import { CommonModule } from '@angular/common';
import { equals } from '@fishonline2023/shared/ramda';
import {
  ModalComponent,
  ModalService,
} from '@fishonline2023/webapps/shared/ui/base-components';
import {
  FishingBusiness,
  ModalId,
  ModalSize,
} from '@fishonline2023/shared/models';

@Component({
  selector: 'sv-ui-fishing-business-history-determination-certificate',
  standalone: true,
  imports: [CommonModule, ModalComponent],
  templateUrl:
    './fishing-business-history-determination-certificate.component.html',
  styleUrls: [
    './fishing-business-history-determination-certificate.component.scss',
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class FishingBusinessHistoryDeterminationCertificateComponent {
  @Input() selectedFishingBusiness?: FishingBusiness;
  @Input() public determinationCertificate?: DeterminationCertificate;
  @Input() public fishingBusinessHistoryReference?: {
    conditionList: Condition[];
  };
  public FDDateTimeAngularPipeFormat = FDDateTimeAngularPipeFormat;
  public selectedCondition?: Condition;
  public selectedConditionList: string[] = [];
  protected readonly ModalId = ModalId;
  protected readonly ModalSize = ModalSize;
  private modalService = inject(ModalService);

  public onEndorsementConditionClick(conditionId: string): void {
    this.selectedCondition =
      this.fishingBusinessHistoryReference?.conditionList?.find((condition) => {
        return equals(condition.id, conditionId);
      });
    this.selectedConditionList =
      this.selectedCondition?.detail?.split('\r\n') || [];
    this.modalService.open(
      ModalId.FishingBusinessHistoryEndorsementConditionModal
    );
  }

  public dismissModal(): void {
    this.modalService.close();
  }
}
