<sv-ui-search-detail-wrapper>
  <div class="mt-4">
    <h2 class="mb-3">{{ transactionTitle }}</h2>
    <sv-ui-transaction-receipt-content
      *ngIf="transactionData$ | async as transactionData"
      [transactionData]="transactionData"
      [transactionType]="transactionData.transactionHeader.type"
      [userProfile]="userProfile$ | async"
    ></sv-ui-transaction-receipt-content>
  </div>
</sv-ui-search-detail-wrapper>
