import { Action, createReducer, on } from '@ngrx/store';

import {
  acceptOfferPaymentOptionClicked,
  initTransactionType,
  resetTransaction,
  transactionAbandon,
  transactionAbandonFailed,
  transactionAbandonSuccessful,
  transactionCreditCardDetailReset,
  transactionDataAccept,
  transactionDataAcceptFailed,
  transactionDataAcceptSuccessful,
  transactionDataConfirm,
  transactionDataConfirmFailed,
  transactionDataConfirmSuccessful,
  transactionDataFromGETSubmitReset,
  transactionDataLoadFailed,
  transactionDataLoadSuccessful,
  transactionDataPayAndConfirm,
  transactionDataPayAndConfirmFailed,
  transactionDataPayAndConfirmSuccessful,
  transactionDataPayLater,
  transactionDataPayLaterFailed,
  transactionDataPayLaterSuccessful,
  transactionDataReject,
  transactionDataRejectFailed,
  transactionDataRejectSuccessful,
  transactionDataRequested,
  transactionDataSubmitFailed,
  transactionDataSubmitSuccessful,
  transactionDataSubmitted,
  transactionDataWithDraw,
  transactionDataWithDrawFailed,
  transactionDataWithDrawSuccessful,
  transactionPaymentCreditCardDetailValidated,
  transactionPaymentCreditCardDetailValidatedFailed,
  transactionPaymentCreditCardDetailValidatedSuccessful,
  transactionPaymentCreditCardDetailValidationStatusReset,
  transactionRequestParamsPrePopulated,
} from './transaction.actions';
import {
  TransactionCreditCardDetail,
  TransactionData,
  TransactionPaymentConfirmError,
  TransactionRequestParams,
  TransactionType,
} from '@fishonline2023/webapps/model/fd2023';
import { ViewStatus } from '@fishonline2023/shared/models';
import { isNil } from '@fishonline2023/shared/ramda';

export const TRANSACTION_FEATURE_KEY = 'transaction';

export const TRANSACTION_FEATURE_PERSISTENCE_KEY = [
  'transactionType',
  'transactionDataFromGET',
  'transactionDataFromSubmit',
  'transactionRequestParams',
  'transactionDataFromPayLater',
  'transactionDataFromConfirm',
  'transactionDataFromWithdrawOffer',
  'transactionDataFromAcceptOffer',
  'transactionDataFromRejectOffer',
  'transactionCreditCardDetail',
  'errorMessage',
  'transactionPaymentConfirmError',
];

export interface TransactionState {
  transactionType?: TransactionType;
  /*
    transactionRequestParams will be set when pending transaction is clicked
    On page refresh, transactionRequestParams persists
  */
  transactionRequestParams?: TransactionRequestParams;
  /*
    transactionRequestParamsCreated is transactionRequestParams created when user create a new transaction
    On page refresh, transactionRequestParamsCreated is reset to undefined
    This is to avoid the complexity that transfer quota/FB/components needs detailed data other than just entityId to render the page
  */
  transactionRequestParamsCreated?: TransactionRequestParams;

  transactionDataFromGET?: TransactionData;
  transactionDataFromSubmit?: TransactionData;
  transactionDataFromPayLater?: TransactionData;
  transactionDataFromConfirm?: TransactionData;
  transactionDataFromWithdrawOffer?: TransactionData;
  transactionDataFromAcceptOffer?: TransactionData;
  transactionDataFromRejectOffer?: TransactionData;

  transactionCreditCardDetail?: TransactionCreditCardDetail;

  viewStatusGET: ViewStatus;
  viewStatusSubmit: ViewStatus;
  viewStatusPayLater: ViewStatus;
  viewStatusCreditCardValidation: ViewStatus;
  viewStatusConfirm: ViewStatus;
  viewStatusConfirmAndMakePayment: ViewStatus;
  viewStatusAbandon: ViewStatus;
  viewStatusWithdraw: ViewStatus;
  viewStatusReject: ViewStatus;
  viewStatusAccept: ViewStatus;

  errorMessage?: string;
  transactionPaymentConfirmError?: TransactionPaymentConfirmError;
}

export const initialTransactionState: TransactionState = {
  viewStatusGET: ViewStatus.Initial,
  viewStatusSubmit: ViewStatus.Initial,
  viewStatusCreditCardValidation: ViewStatus.Initial,
  viewStatusConfirm: ViewStatus.Initial,
  viewStatusConfirmAndMakePayment: ViewStatus.Initial,
  viewStatusPayLater: ViewStatus.Initial,
  viewStatusAbandon: ViewStatus.Initial,
  viewStatusWithdraw: ViewStatus.Initial,
  viewStatusReject: ViewStatus.Initial,
  viewStatusAccept: ViewStatus.Initial,
};

const transactionSubmitFailedHandler = (
  state: TransactionState,
  {
    errorMessage,
    transactionData,
  }: { errorMessage: string; transactionData?: TransactionData }
) => {
  const commonNextState = {
    ...state,
    errorMessage,
    transactionDataFromSubmit: transactionData,
    viewStatusSubmit: ViewStatus.Failure,
  };
  if (isNil(transactionData)) {
    return commonNextState;
  }
  const transactionDataFromGET =
    state.transactionDataFromGET as TransactionData;
  const nextTransactionDataFromGET = {
    transactionDataFromGET: {
      ...transactionDataFromGET,
      transactionDetail: transactionData.transactionDetail,
      transactionHeader: {
        ...transactionDataFromGET.transactionHeader,
        id: transactionData.transactionHeader.id,
      },
    },
  };
  return {
    ...commonNextState,
    ...nextTransactionDataFromGET,
  };
};

const reducer = createReducer(
  initialTransactionState,
  on(
    initTransactionType,
    ({ transactionRequestParams }, { transactionType }) => ({
      ...initialTransactionState,
      transactionRequestParams,
      transactionType,
    })
  ),
  on(transactionDataRequested, (state, { params }) => ({
    ...state,
    viewStatusGET: ViewStatus.Loading,
    transactionRequestParamsCreated: params,
  })),
  on(transactionDataFromGETSubmitReset, (state) => ({
    ...state,
    viewStatusGET: ViewStatus.Initial,
    transactionDataFromGET: undefined,
    transactionDataFromSubmit: undefined,
  })),
  on(transactionDataSubmitted, (state) => ({
    ...state,
    viewStatusSubmit: ViewStatus.Loading,
  })),
  on(transactionDataPayLater, (state) => ({
    ...state,
    viewStatusPayLater: ViewStatus.Loading,
  })),
  on(transactionPaymentCreditCardDetailValidated, (state) => ({
    ...state,
    viewStatusCreditCardValidation: ViewStatus.Loading,
  })),
  on(transactionDataConfirm, (state) => ({
    ...state,
    viewStatusConfirm: ViewStatus.Loading,
  })),
  on(transactionDataPayAndConfirm, (state) => ({
    ...state,
    viewStatusConfirmAndMakePayment: ViewStatus.Loading,
  })),
  on(transactionDataLoadSuccessful, (state, { transactionData }) => ({
    ...state,
    transactionDataFromGET: transactionData,
    viewStatusGET: ViewStatus.Success,
  })),
  on(transactionDataSubmitSuccessful, (state, { transactionData }) => ({
    ...state,
    transactionDataFromSubmit: transactionData,
    viewStatusSubmit: ViewStatus.Success,
    transactionRequestParams:
      state.transactionRequestParamsCreated || state.transactionRequestParams,
  })),
  on(transactionDataPayLaterSuccessful, (state, { transactionData }) => ({
    ...state,
    transactionDataFromPayLater: transactionData,
    viewStatusPayLater: ViewStatus.Success,
  })),
  on(
    transactionPaymentCreditCardDetailValidatedSuccessful,
    (state, { transactionCreditCardDetail }) => ({
      ...state,
      transactionCreditCardDetail,
      viewStatusCreditCardValidation: ViewStatus.Success,
    })
  ),
  on(transactionDataConfirmSuccessful, (state, { transactionData }) => ({
    ...state,
    transactionDataFromConfirm: transactionData,
    viewStatusConfirm: ViewStatus.Success,
  })),
  on(transactionDataPayAndConfirmSuccessful, (state, { transactionData }) => ({
    ...state,
    transactionDataFromConfirm: transactionData,
    viewStatusConfirmAndMakePayment: ViewStatus.Success,
  })),
  on(transactionDataLoadFailed, (state, { errorMessage }) => ({
    ...state,
    errorMessage,
    viewStatusGET: ViewStatus.Failure,
  })),
  on(transactionDataSubmitFailed, transactionSubmitFailedHandler),
  on(transactionDataPayLaterFailed, (state, { errorMessage }) => ({
    ...state,
    errorMessage,
    viewStatusPayLater: ViewStatus.Failure,
  })),
  on(
    transactionPaymentCreditCardDetailValidatedFailed,
    (state, { errorMessage }) => ({
      ...state,
      errorMessage,
      viewStatusCreditCardValidation: ViewStatus.Failure,
    })
  ),
  on(transactionPaymentCreditCardDetailValidationStatusReset, (state) => ({
    ...state,
    viewStatusCreditCardValidation: ViewStatus.Initial,
    errorMessage: undefined,
  })),
  on(transactionDataConfirmFailed, (state, { errorMessage }) => ({
    ...state,
    errorMessage,
    viewStatusConfirm: ViewStatus.Failure,
  })),
  on(
    transactionDataPayAndConfirmFailed,
    (state, { transactionPaymentConfirmError }) => ({
      ...state,
      transactionPaymentConfirmError,
      viewStatusConfirmAndMakePayment: ViewStatus.Failure,
    })
  ),
  on(
    transactionRequestParamsPrePopulated,
    (state, { transactionRequestParams }) => ({
      ...state,
      transactionRequestParams,
    })
  ),
  on(resetTransaction, () => initialTransactionState),
  on(transactionCreditCardDetailReset, (state) => ({
    ...state,
    transactionCreditCardDetail: undefined,
  })),
  on(transactionAbandon, (state) => ({
    ...state,
    viewStatusAbandon: ViewStatus.Loading,
  })),
  on(transactionAbandonSuccessful, (state) => ({
    ...state,
    viewStatusAbandon: ViewStatus.Success,
  })),
  on(transactionAbandonFailed, (state, { errorMessage }) => ({
    ...state,
    viewStatusAbandon: ViewStatus.Failure,
    errorMessage,
  })),
  on(transactionDataWithDraw, (state) => ({
    ...state,
    viewStatusWithdraw: ViewStatus.Loading,
  })),
  on(transactionDataWithDrawSuccessful, (state, { transactionData }) => ({
    ...state,
    transactionDataFromWithdrawOffer: transactionData,
    viewStatusWithdraw: ViewStatus.Success,
  })),
  on(transactionDataWithDrawFailed, (state, { errorMessage }) => ({
    ...state,
    viewStatusWithdraw: ViewStatus.Failure,
    errorMessage,
  })),
  on(transactionDataReject, (state) => ({
    ...state,
    viewStatusReject: ViewStatus.Loading,
  })),
  on(transactionDataRejectSuccessful, (state, { transactionData }) => ({
    ...state,
    transactionDataFromRejectOffer: transactionData,
    viewStatusReject: ViewStatus.Success,
  })),
  on(transactionDataRejectFailed, (state, { errorMessage }) => ({
    ...state,
    viewStatusReject: ViewStatus.Failure,
    errorMessage,
  })),
  on(transactionDataAccept, (state) => ({
    ...state,
    viewStatusAccept: ViewStatus.Loading,
  })),
  on(transactionDataAcceptSuccessful, (state, { transactionData }) => ({
    ...state,
    transactionDataFromAcceptOffer: transactionData,
    viewStatusAccept: ViewStatus.Success,
  })),
  on(transactionDataAcceptFailed, (state, { errorMessage }) => ({
    ...state,
    viewStatusAccept: ViewStatus.Failure,
    errorMessage,
  })),
  on(acceptOfferPaymentOptionClicked, (state) => ({
    ...state,
    transactionDataFromSubmit: state.transactionDataFromGET,
  }))
);

export function transactionReducer(
  state: TransactionState | undefined,
  action: Action
) {
  return reducer(state, action);
}
