<div class="confirmation-container round-secondary-border-box mb-3">
  <h4 class="mb-3">Contact details</h4>
  <div class="row">
    <div class="col-lg-6 col-md-12" *ngFor="let key of contactDetailKeys">
      <div class="detail-item">
        <h6 class="key">{{ LabelNameMap[key] }}:</h6>
        <p class="value m-0" [attr.data-cy]="LabelNameMap[key]">
          {{ contactDetailValue(key) }}
          <span
            class="fst-italic ms-1"
            *ngIf="shouldDisplayPreferredMethod(key)"
          >
            *Preferred
          </span>
        </p>
      </div>
    </div>
  </div>
</div>
<div class="round-secondary-border-box mb-3">
  <div class="row">
    <div class="col-lg-6 col-md-12 mb-3" *ngFor="let address of addressDetails">
      <h4 class="mb-3">{{ address.title }}</h4>
      <div
        class="detail-item"
        *ngFor="let paris of formGroupValueByName(address)"
      >
        <h6 class="key">{{ LabelNameMap[paris[0]] }}:</h6>
        <p class="value m-0">{{ paris[1] }}</p>
      </div>
    </div>
  </div>
</div>
