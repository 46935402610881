<div *ngIf="determinationCertificate" class="round-secondary-border-box mb-4">
  <div class="determination-certificate-details">
    <div class="row">
      <div class="col">Certificate No.</div>
      <div class="col">Determination Reason</div>
      <div class="col">Transaction Ref.</div>
      <div class="col">Issue Date</div>
      <div class="col">Determination Date</div>
      <div class="col">Amend/Revoke Date</div>
    </div>
    <div class="row determination-certificate-details-values">
      <div class="col">{{ determinationCertificate.id }}</div>
      <div class="col">{{ determinationCertificate.reason }}</div>
      <div class="col">{{ determinationCertificate.transactionId }}</div>
      <div class="col">
        {{
          determinationCertificate.issueDate
            | date : FDDateTimeAngularPipeFormat
        }}
      </div>
      <div class="col">
        {{
          determinationCertificate.determinationDate
            | date : FDDateTimeAngularPipeFormat
        }}
      </div>
      <div class="col">
        {{
          determinationCertificate.revokedDate
            ? (determinationCertificate.revokedDate
              | date : FDDateTimeAngularPipeFormat)
            : 'Current'
        }}
      </div>
    </div>
  </div>
  <div class="row mt-4">
    <div class="col">
      <h4>Fishing Business {{ selectedFishingBusiness?.id }}</h4>
      <p>Type: {{ selectedFishingBusiness?.group }}</p>
      <p>
        Owner: {{ selectedFishingBusiness?.owner?.fullName }} ({{
          selectedFishingBusiness?.owner?.id
        }})
      </p>
    </div>
  </div>
  <div class="row mt-1">
    <div class="col">
      <table class="table align-middle table-striped mt-3">
        <thead>
          <tr>
            <th scope="col" style="width: 35%">Description</th>
            <th scope="col">Mortgage/Interest Holding</th>
            <th scope="col">Endorsement Available</th>
            <th scope="col">Endorsement Conditions</th>
            <th scope="col">Non-Consumable Quota</th>
          </tr>
        </thead>
        <tbody class="table-group-divider">
          <tr *ngFor="let item of determinationCertificate.holdingList">
            <td>
              {{ item.shareClassName }}
              <br />
              <div class="row">
                <div class="col">{{ item.shareClassId }}</div>
                <div class="col">Type: {{ item.shareType }}</div>
                <div class="col">Holding: {{ item.holdingNumber }}</div>
              </div>
            </td>
            <td>{{ item.mortgageNumber }}</td>
            <td>{{ item.endorsementAvailable ? 'Yes' : 'No' }}</td>
            <td>
              <ng-container *ngIf="item.endorsementConditionList.length > 0">
                <ng-container
                  *ngFor="let conditionLink of item.endorsementConditionList"
                >
                  <a
                    (click)="onEndorsementConditionClick(conditionLink)"
                    class="link-primary"
                    href="javascript:void(0)"
                    >{{ conditionLink }}</a
                  >
                  <span>&nbsp;&nbsp;</span>
                </ng-container>
              </ng-container>
              <ng-container *ngIf="item.endorsementConditionList.length === 0"
                >N/A</ng-container
              >
            </td>
            <td>
              <ng-container *ngIf="item.nonConsumableQuotaList.length > 0">
                <span *ngFor="let quota of item.nonConsumableQuotaList">{{
                  quota
                }}</span>
              </ng-container>
              <ng-container *ngIf="item.nonConsumableQuotaList.length === 0"
                >N/A</ng-container
              >
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</div>

<!-- Modal for endorsement condition -->
<sv-ui-modal
  [modalId]="ModalId.FishingBusinessHistoryEndorsementConditionModal"
  [modalSize]="ModalSize.ExtraLarge"
>
  <div class="modal-header">
    <h5 class="modal-title">
      {{ selectedCondition?.abbreviation }} ({{ selectedCondition?.id }})
    </h5>
    <button
      (click)="dismissModal()"
      aria-label="Close"
      class="btn-close"
      type="button"
    ></button>
  </div>
  <div class="modal-body">
    <p *ngFor="let condition of selectedConditionList">{{ condition }}</p>
  </div>
  <div class="modal-footer">
    <button
      (click)="dismissModal()"
      aria-label="Cancel"
      class="btn btn-secondary"
      type="button"
    >
      Close
    </button>
  </div>
</sv-ui-modal>
