import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  inject,
  Input,
  OnInit,
  Output,
} from '@angular/core';
import { CommonModule } from '@angular/common';
import { Observable, take } from 'rxjs';
import {
  AccountSummary,
  DemeritPoints,
  FDDataLoaderData,
  FDDateAngularPipeFormat,
  FDDateDayJSFormat,
} from '@fishonline2023/webapps/model/fd2023';
import { EventType } from '@fishonline2023/webapps/shared/feature/appsync';
import { DualColumnItemsListComponent } from '@fishonline2023/webapps/shared/ui/base-components';
import * as dayjs from 'dayjs';
import { DataLoaderActivityLog, ItemList } from '@fishonline2023/shared/models';

@Component({
  selector: 'sv-ui-account-summary',
  standalone: true,
  imports: [CommonModule, DualColumnItemsListComponent],
  templateUrl: './account-summary.component.html',
  styleUrls: ['./account-summary.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AccountSummaryComponent implements OnInit {
  @Input()
  public dataLoaderData$!: Observable<FDDataLoaderData>;
  @Output() public activityLogTriggered =
    new EventEmitter<DataLoaderActivityLog>();
  public FDDateAngularPipeFormat = FDDateAngularPipeFormat;
  public customerSummaryItemsList!: ItemList;
  public licenceSummaryItemsList!: ItemList;
  public demeritPointsList!: DemeritPoints[];
  private cdr = inject(ChangeDetectorRef);
  private dateFormat = FDDateDayJSFormat;

  public get accountSummary$() {
    return this.dataLoaderData$ as Observable<AccountSummary>;
  }

  public ngOnInit(): void {
    this.accountSummary$.pipe(take(1)).subscribe((accountSummary) => {
      this.renderCustomerSummaryItemsList(accountSummary);
      this.renderLicenceSummaryItemsList(accountSummary);
      this.demeritPointsList = accountSummary.demeritPointsList || [];
      this.cdr.detectChanges();
      this.triggerActivityLog();
    });
  }

  public renderCustomerSummaryItemsList(accountSummary: AccountSummary): void {
    this.customerSummaryItemsList = [
      {
        key: 'Name',
        value: accountSummary.customerSummary.fullName,
      },
      {
        key: 'ABN',
        value: accountSummary.customerSummary.abn,
      },
      {
        key: 'Trading As',
        value: accountSummary.customerSummary.tradingAs,
      },
      {
        key: 'Power of Attorney',
        value: accountSummary.customerSummary.powerOfAttorney,
      },
      {
        key: 'Date of Birth',
        value: dayjs(accountSummary.customerSummary.dob).format(
          this.dateFormat
        ),
      },
      {
        key: 'Fisheries Office',
        value: accountSummary.customerSummary.fisheriesOffice,
      },
      {
        key: 'Gender',
        value: accountSummary.customerSummary.gender,
      },
    ];
  }

  public renderLicenceSummaryItemsList(accountSummary: AccountSummary): void {
    this.licenceSummaryItemsList = [
      {
        key: 'Licence Number',
        value: accountSummary.licenceSummary?.number,
      },
      {
        key: 'Expiry Date',
        value: accountSummary.licenceSummary?.expiryDate,
      },
      {
        key: 'Status',
        value: accountSummary.licenceSummary?.status,
      },
    ];
  }

  private triggerActivityLog(): void {
    this.activityLogTriggered.emit({
      eventType: EventType.ViewAccountSummary,
    });
  }
}
