import { createAction, props } from '@ngrx/store';
import {
  DataLoaderActivityLog,
  DataLoaderType,
} from '@fishonline2023/shared/models';
import { Params } from '@angular/router';
import { FDDataLoaderData } from '@fishonline2023/webapps/model/fd2023';
import { FADataLoaderData } from '@fishonline2023/webapps/model/fa2023';
import { Channel } from '@fishonline2023/webapps/shared/feature/appsync';

export const activityLogTriggered = createAction(
  `[Data Loader] Activity Log Triggered`,
  props<{ activityLog: DataLoaderActivityLog }>()
);

export const initDataLoader = createAction(
  `[Data Loader] Init Data Loader`,
  props<{ dataLoaderType: DataLoaderType; channel: Channel }>()
);

export const loadDataLoaderData = createAction(
  `[Data Loader] Load Data Loader Data`,
  props<{ params?: Params }>()
);

export const loadDataLoaderDataSuccess = createAction(
  `[Data Loader] Load Data Loader Data Success`,
  props<{ dataLoaderData: FDDataLoaderData | FADataLoaderData }>()
);

export const loadDataLoaderDataFailure = createAction(
  `[Data Loader] Load Data Loader Data Failure`,
  props<{ errorMessage: string }>()
);
