<div class="form-container p-3">
  <div class="row">
    <h5 class="col-lg-3 col-md-6">{{ label }}</h5>
    <p class="col-lg-5 col-md-6">{{ description }}</p>
    <ng-container
      *ngIf="transferDetailFormGroup"
      [formGroup]="transferDetailFormGroup"
    >
      <div class="col-lg-2 col-md-6">
        <sv-ui-input-field
          [form]="transferDetailFormGroup.controls.quantity"
          [inputField]="qtyToTransferInputField"
        ></sv-ui-input-field>
      </div>
      <div class="col-lg-2 col-md-6">
        <sv-ui-input-field
          [form]="transferDetailFormGroup.controls.totalSellPrice"
          [inputField]="
            totalSellPriceInputField(
              Number(transferDetailFormGroup.controls.quantity.value) === 0
            )
          "
        ></sv-ui-input-field>
      </div>
    </ng-container>
  </div>
</div>
