import {
  TransactionActionButton,
  TransactionActionButtonClassName,
  TransactionActionButtonPosition,
  TransactionButtonType,
} from './transaction-button.model';

export const DeleteButton = (disabled = false): TransactionActionButton => ({
  label: TransactionButtonType.Delete,
  className: TransactionActionButtonClassName.DangerButton,
  disabled,
  position: TransactionActionButtonPosition.Start,
  buttonType: TransactionButtonType.Delete,
});

export const ResetButton = (disabled = false): TransactionActionButton => ({
  label: TransactionButtonType.Reset,
  className: TransactionActionButtonClassName.WhiteButton,
  disabled,
  position: TransactionActionButtonPosition.Start,
  buttonType: TransactionButtonType.Reset,
});

export const SaveButton = (disabled = false): TransactionActionButton => ({
  label: TransactionButtonType.Save,
  className: TransactionActionButtonClassName.PrimaryButton,
  disabled,
  position: TransactionActionButtonPosition.End,
  buttonType: TransactionButtonType.Save,
});

export const ApproveAndSubmitButton = (
  disabled = false
): TransactionActionButton => ({
  label: TransactionButtonType.ApproveAndSubmit,
  className: TransactionActionButtonClassName.PrimaryButton,
  disabled,
  position: TransactionActionButtonPosition.End,
  buttonType: TransactionButtonType.ApproveAndSubmit,
});

export const NotApproveButton = (
  disabled = false
): TransactionActionButton => ({
  label: TransactionButtonType.NotApproved,
  className: TransactionActionButtonClassName.DangerButton,
  disabled,
  position: TransactionActionButtonPosition.End,
  buttonType: TransactionButtonType.NotApproved,
});

export const confirmButton = (disabled = false): TransactionActionButton => ({
  label: TransactionButtonType.Confirm,
  className: TransactionActionButtonClassName.PrimaryButton,
  disabled,
  position: TransactionActionButtonPosition.Start,
  buttonType: TransactionButtonType.Confirm,
});

export const goBackButton = (disabled = false): TransactionActionButton => ({
  label: TransactionButtonType.GoBack,
  className: TransactionActionButtonClassName.WhiteButton,
  disabled,
  position: TransactionActionButtonPosition.End,
  buttonType: TransactionButtonType.GoBack,
});

export const printReceiptButton = (
  disabled = false
): TransactionActionButton => ({
  label: TransactionButtonType.Print,
  className: TransactionActionButtonClassName.PrimaryButton,
  disabled,
  position: TransactionActionButtonPosition.Start,
  buttonType: TransactionButtonType.Print,
});

export const returnToHomeButton = (
  disabled = false
): TransactionActionButton => ({
  label: TransactionButtonType.ReturnToHome,
  className: TransactionActionButtonClassName.WhiteButton,
  disabled,
  position: TransactionActionButtonPosition.End,
  buttonType: TransactionButtonType.ReturnToHome,
});
