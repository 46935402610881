<div class="card h-100" (click)="onCardClick()">
  <div class="card-body">
    <h6 *ngIf="subTitle">{{ subTitle }}</h6>
    <h4 class="card-title">{{ title }}</h4>
    <p>{{ description }}</p>
    <div *ngIf="htmlInput" [innerHTML]="htmlInput"></div>
  </div>
  <div class="card-button-container">
    <button type="button" class="btn btn-link">
      <i class="material-icons pt-1">east</i>
    </button>
  </div>
</div>
