import { isNilOrEmpty, mergeAll } from '@fishonline2023/shared/ramda';
import { SearchFieldType } from '@fishonline2023/shared/models';

export const flattenSearchFilter = (
  searchFilter: Record<string, unknown>
): Record<string, string> => {
  return mergeAll<Record<string, string>>(
    Object.entries(searchFilter).map(([key, value]) => {
      switch (key) {
        case SearchFieldType.RadioButtonPreFilter:
          return radioButtonPreFilterFlatten(value as Record<string, string>);
        case SearchFieldType.Dependent:
          return value as Record<string, string>;
        default:
          return { [key]: value };
      }
    })
  );
};

const radioButtonPreFilterFlatten = (
  preFilterValue: Record<string, string>
) => {
  const radioButtonPreFilterKey = preFilterValue['filter'];
  const preFilter = preFilterValue[radioButtonPreFilterKey];
  if (isNilOrEmpty(preFilter)) {
    return {};
  }
  return {
    [radioButtonPreFilterKey]: preFilter,
  };
};
