<div
  class="message-container rounded-1 p-3 mb-3"
  [class.error]="isErrorMessage"
  data-cy="transaction-message"
>
  <div class="d-flex align-items-center gap-2 mb-3">
    <span class="material-icons-round fs-2 text-primary">info</span>
    <h4 class="m-0">{{ messageTitle }}</h4>
  </div>
  <div
    class="d-flex align-items-center gap-2 mb-2"
    *ngFor="let error of errorList"
  >
    <span
      class="material-icons-round fs-6 text-white bg-danger rounded-circle p-1 ms-1"
      >close</span
    >
    <p class="m-1">{{ error }}</p>
  </div>

  <div
    class="d-flex align-items-center gap-2 mb-2"
    *ngFor="let warning of warningList"
  >
    <span class="material-icons-round fs-3 text-warning">warning</span>
    <p class="m-0">{{ warning }}</p>
  </div>
  <div
    class="d-flex align-items-center gap-2 mb-2"
    *ngFor="let info of informationList"
  >
    <span class="material-icons-round fs-3 text-success">check_circle</span>
    <p class="m-0">{{ info }}</p>
  </div>
</div>
