import { inject, Injectable } from '@angular/core';
import { HubCallback } from '@aws-amplify/core';
import { Auth, Hub } from 'aws-amplify';
import { HubAuthEvent } from './auth.model';
import { Router } from '@angular/router';
import { FDRoute } from '@fishonline2023/shared/models';
import { Store } from '@ngrx/store';
import { loginSuccessful } from './store/auth.actions';
import { AuthenticatorService } from '@aws-amplify/ui-angular';

@Injectable({ providedIn: 'root' })
export class AuthService {
  private router = inject(Router);
  private store = inject(Store);
  private authenticatorService = inject(AuthenticatorService);
  private listener!: HubCallback;

  public initListener() {
    this.listener = (data) => {
      switch (data.payload.event) {
        case HubAuthEvent.SIGN_IN:
          this.store.dispatch(loginSuccessful());
          return this.router.navigateByUrl(`/${FDRoute.UserProfile}`);
        case HubAuthEvent.SIGN_OUT:
          this.authenticatorService.signOut();
          return this.router.navigateByUrl(`/${FDRoute.Login}`);
        default:
          // Ignore un-handled events
          return null;
      }
    };
    Hub.listen('auth', this.listener);
  }

  public async getUserName() {
    return (await Auth.currentUserInfo()).username;
  }

  public async getUserInfo() {
    return Auth.currentUserInfo();
  }

  public async getCurrentUserSession() {
    return Auth.currentSession();
  }

  public async logout() {
    await Auth.signOut();
  }
}
