import { inject, Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import {
  combineLatest,
  distinctUntilChanged,
  map,
  switchMap,
  take,
} from 'rxjs';
import { Store } from '@ngrx/store';
import { Data } from '@angular/router';
import { breadcrumbAdded, breadcrumbReset } from './breadcrumb.actions';
import { ROUTER_NAVIGATION } from '@ngrx/router-store';
import {
  selectRouteData,
  selectUrl,
} from '@fishonline2023/webapps/shared/feature/router-store';
import { Breadcrumb, RouteData } from '@fishonline2023/shared/models';

@Injectable()
export class BreadcrumbEffects {
  private actions$ = inject(Actions);
  private store = inject(Store);

  public updateBreadcrumbOnRouteChange$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(ROUTER_NAVIGATION),
      distinctUntilChanged(),
      switchMap(() =>
        combineLatest([
          this.store.select(selectUrl),
          this.store.select(selectRouteData),
        ]).pipe(take(1))
      ),
      map(([url, routerData]: [string, Data]) => ({
        ...routerData[RouteData.BreadCrumb],
        url,
      })),
      switchMap((breadcrumb: Breadcrumb) =>
        breadcrumb.reset
          ? [breadcrumbReset(), breadcrumbAdded({ breadcrumb })]
          : [breadcrumbAdded({ breadcrumb })]
      )
    );
  });
}
