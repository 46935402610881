import { TransactionHeader } from '@fishonline2023/webapps/model/fd2023';
import { complement, isNilOrEmpty } from '@fishonline2023/shared/ramda';
import { TransactionInfo } from '@fishonline2023/webapps/model/fa2023';

export const shouldDisplayTransactionMessage = (
  transactionHeader: TransactionHeader | TransactionInfo
) => {
  const { errorList, infoList, warningList } = transactionHeader;
  return [errorList, infoList, warningList].some(complement(isNilOrEmpty));
};
