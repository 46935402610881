import { inject, Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { AdDetail } from '@fishonline2023/webapps/model/fd2023';
import { filter, Observable, switchMap, take } from 'rxjs';
import { environment } from '@env/fd/environment';
import { Store } from '@ngrx/store';
import { complement, isNil } from '@fishonline2023/shared/ramda';
import { getActAsCustomer } from '@fishonline2023/webapps/user-portal/fd2023/store/user-profile';

@Injectable()
export class ForSaleAndTradeService {
  private http = inject(HttpClient);
  private store = inject(Store);

  public createNewAd(ad: AdDetail): Observable<number> {
    return this.store.select(getActAsCustomer).pipe(
      filter(complement(isNil)),
      take(1),
      switchMap((customer) => {
        const customerId = customer.id;
        const params = new HttpParams().set('entityId', customerId);
        return this.http.post<number>(
          `${environment.apiUrl}/bulletin-board-ads`,
          ad,
          { params }
        );
      })
    );
  }

  public updateAd(ad: AdDetail): Observable<number> {
    return this.store.select(getActAsCustomer).pipe(
      filter(complement(isNil)),
      switchMap((customer) => {
        const customerId = customer.id;
        const params = new HttpParams().set('entityId', customerId);
        return this.http.put<number>(
          `${environment.apiUrl}/bulletin-board-ads`,
          ad,
          { params }
        );
      })
    );
  }

  public deleteAd(adId: number): Observable<void> {
    return this.store.select(getActAsCustomer).pipe(
      filter(complement(isNil)),
      switchMap((customer) => {
        const customerId = customer.id;
        const params = new HttpParams()
          .set('id', adId)
          .set('entityId', customerId);
        return this.http.delete<void>(
          `${environment.apiUrl}/bulletin-board-ads`,
          { params }
        );
      })
    );
  }
}
