import {
  AfterViewInit,
  ChangeDetectorRef,
  Component,
  ElementRef,
  inject,
  OnDestroy,
  OnInit,
  ViewChild,
} from '@angular/core';
import { CommonModule } from '@angular/common';
import {
  FormControl,
  FormGroup,
  ReactiveFormsModule,
  Validators,
} from '@angular/forms';
import {
  complement,
  equals,
  has,
  includes,
  isEmpty,
  isNil,
  isNilOrEmpty,
  mergeAll,
  pickBy,
  sort,
} from '@fishonline2023/shared/ramda';
import {
  CompositeSearchField,
  CustomFormValidator,
  DependentSearchControls,
  FlatSearchField,
  FlatSearchFormControls,
  FlatSearchFormControlsWithFilter,
  Message,
  NestedSearchFieldValue,
  PreFilterSearchFormControls,
  RouteData,
  SearchField,
  SearchFieldDataType,
  SearchFieldType,
  SearchFieldValue,
  SearchFormControls,
  SearchMetadata,
  searchMetadata,
  SearchResultField,
  SearchResultItem,
  SearchSortOrderChangeEvent,
  SearchType,
  SortOrder,
  ViewStatus,
} from '@fishonline2023/shared/models';
import { ActivatedRoute, Params, Router } from '@angular/router';
import {
  BehaviorSubject,
  combineLatest,
  filter,
  map,
  merge,
  Observable,
  skip,
  Subject,
  take,
  takeUntil,
  tap,
} from 'rxjs';
import * as bootstrap from 'bootstrap';
import {
  ErrorComponent,
  LoadingComponent,
  TableSortingButtonComponent,
  ToastService,
} from '@fishonline2023/webapps/shared/ui/base-components';
import {
  SearchFieldComponent,
  SearchResultDisplayColumnSelectionComponent,
  SearchResultTableComponent,
} from '@fishonline2023/webapps/shared/ui/search';
import { Store } from '@ngrx/store';
import {
  downloadTriggered,
  searchFilterPopulatedByQueryParams,
  searchMetadataSet,
  searchResultCleared,
  searchResultDisplayColumnListUpdated,
  searchResultItemSelected,
  searchTriggered,
  selectDownloadViewStatus,
  selectLoadSearchFilterOptionsViewStatus,
  selectSearchFieldValues,
  selectSearchFilter,
  selectSearchResult,
  selectSearchResultDisplayColumnList,
  selectSearchViewStatus,
} from '@fishonline2023/webapps/shared/feature/search-store';
import {
  flattenSearchFilter,
  toggleCollapse,
} from '@fishonline2023/shared/utils';
import * as dayjs from 'dayjs';
import { ForSaleAndTradeComponent } from '@fishonline2023/webapps/notice-and-extract/fd2023/ui/for-sale-and-trade';
import { QuotaTransactionsComponent } from '@fishonline2023/webapps/quota-fisheries/fd2023/ui/quota-transactions';
import { findSearchFilterWithLabel } from '../utils/find-search-filter-with-label/find-search-filter-with-label';
import { searchFormValidator } from '../utils/search-form-validator';

@Component({
  selector: 'sv-feature-search',
  standalone: true,
  imports: [
    CommonModule,
    ReactiveFormsModule,
    LoadingComponent,
    TableSortingButtonComponent,
    SearchResultTableComponent,
    ErrorComponent,
    ForSaleAndTradeComponent,
    SearchFieldComponent,
    QuotaTransactionsComponent,
    SearchResultDisplayColumnSelectionComponent,
  ],
  templateUrl: './search.component.html',
  styleUrls: ['./search.component.scss'],
})
export class SearchComponent implements OnInit, OnDestroy, AfterViewInit {
  public searchMetadata!: SearchMetadata;
  public searchResultList?: SearchResultItem[];
  public totalSearchResult?: number;
  public searchResultDisplayColumnFormControl = new FormControl<string[]>([], {
    nonNullable: true,
  });
  protected searchType!: SearchType;
  protected readonly Array = Array;
  protected readonly Message = Message;
  protected readonly ViewStatus = ViewStatus;
  protected isSearchCriteriaShown$ = new BehaviorSubject(true);
  protected searchForm!: FormGroup<SearchFormControls>;
  protected searchFieldValues?: Record<
    string,
    Array<SearchFieldValue> | Array<NestedSearchFieldValue>
  >;
  @ViewChild('searchFieldsCollapse')
  protected searchFieldsCollapse!: ElementRef;
  protected readonly toggleCollapse = toggleCollapse;
  protected readonly SearchType = SearchType;
  private readonly store = inject(Store);
  protected readonly searchViewStatus$ = this.store.select(
    selectSearchViewStatus
  );
  protected readonly downloadViewStatus$ = this.store.select(
    selectDownloadViewStatus
  );
  protected readonly loadSearchFilterOptionsViewStatus$ = this.store.select(
    selectLoadSearchFilterOptionsViewStatus
  );
  private readonly cdr = inject(ChangeDetectorRef);
  private readonly activatedRoute = inject(ActivatedRoute);
  private readonly toastService = inject(ToastService);
  private readonly router = inject(Router);
  private readonly destroy$ = new Subject<void>();
  private readonly sort$ = new BehaviorSubject<SearchSortOrderChangeEvent>({
    sortOrder: SortOrder.NOT_SORTED,
  });

  public get columnSorted$() {
    return this.sort$.pipe(
      map(({ sortOrder, searchResultField }) =>
        equals(sortOrder, SortOrder.NOT_SORTED)
          ? undefined
          : searchResultField?.property
      )
    );
  }

  protected get searchSummary$() {
    return this.store.select(selectSearchFilter).pipe(
      filter(complement(isNil)),
      take(1),
      map(flattenSearchFilter),
      map((searchFilter) =>
        findSearchFilterWithLabel(
          searchFilter,
          this.searchMetadata,
          this.searchFieldValues
        )
      ),
      map(this.searchSummaryFromSearchFilter)
    );
  }

  protected get shouldDisplaySearchFooter() {
    return !isNil(this.searchResultList) && this.searchMetadata.requireExport;
  }

  protected get collapse() {
    try {
      return new bootstrap.Collapse(this.searchFieldsCollapse?.nativeElement, {
        toggle: false,
      });
    } catch (e) {
      return undefined;
    }
  }

  public ngOnInit() {
    this.initSearchMetadata();
    this.patchSearchResultDisplayColumnFormControl();
    this.syncSearchResultDisplayColumnList();
    this.initSearchForm();
    this.prePopulateSearchCriteriaFromQueryParams();
    this.prePopulateSearchFormValue();
    this.initSearchFieldValues();
  }

  public ngAfterViewInit() {
    this.subscribeToSearchResult();
  }

  public ngOnDestroy() {
    this.destroy$.next();
    this.destroy$.complete();
    this.isSearchCriteriaShown$.complete();
    this.sort$.complete();
  }

  protected initSearchMetadata() {
    const searchType: SearchType =
      this.activatedRoute.snapshot.data[RouteData.SearchType];
    this.searchType = searchType;
    this.searchMetadata = searchMetadata[searchType];
    this.store.dispatch(
      searchMetadataSet({
        searchType,
        searchResultDisplayColumnList:
          this.searchMetadata.defaultSearchResultColumnList,
      })
    );
  }

  protected search = () => {
    this.presentErrorToastWhenFailed({
      viewStatus$: this.searchViewStatus$,
      message: Message.LoadSearchResultFailed,
    });
    this.store.dispatch(
      searchTriggered({
        searchFilter: pickBy(complement(isEmpty), this.searchForm.value),
      })
    );
  };

  protected async presentSearchResultDetailView({
    selectedSearchResult,
    selectedField,
  }: {
    selectedSearchResult: SearchResultItem;
    selectedField: SearchResultField;
  }) {
    this.store.dispatch(
      searchResultItemSelected({
        selectedSearchResultItem: selectedSearchResult,
      })
    );
    await this.router.navigate([selectedField.link], {
      relativeTo: this.activatedRoute,
      queryParams: {
        [selectedField.property]: selectedSearchResult[selectedField.property],
        id: selectedSearchResult['id'],
      },
    });
  }

  protected sortSearchResults({
    sortOrder,
    searchResultField,
  }: SearchSortOrderChangeEvent) {
    this.sort$.next({
      sortOrder,
      searchResultField,
    });
  }

  protected clearSearchForm() {
    this.searchForm.reset();
    this.store.dispatch(searchResultCleared());
  }

  protected searchFieldForm(searchField: SearchField) {
    switch (searchField.fieldType) {
      case SearchFieldType.RadioButtonPreFilter:
        return this.searchForm.controls[
          SearchFieldType.RadioButtonPreFilter
        ] as FormGroup;
      case SearchFieldType.Dependent:
        return this.searchForm.controls[SearchFieldType.Dependent] as FormGroup;
      default:
        return this.searchForm;
    }
  }

  protected downloadCSV() {
    this.presentErrorToastWhenFailed({
      viewStatus$: this.downloadViewStatus$,
      message: Message.DownloadSearchResultFailed,
    });
    this.store.dispatch(
      downloadTriggered({
        downloadCSVName: this.searchMetadata.downloadCSVName,
      })
    );
  }

  private searchSummaryFromSearchFilter = (
    searchFilter: {
      label: string | undefined;
      value: string | number | undefined;
    }[]
  ) => {
    const resultsCount = (<SearchResultItem[]>this.searchResultList).length;
    const ofTotalValue = equals(resultsCount, this.totalSearchResult)
      ? ''
      : ` of ${this.totalSearchResult}`;
    const plurals = resultsCount > 1 ? 's' : '';
    return `Showing ${resultsCount}${ofTotalValue} search result${plurals} ${
      isEmpty(searchFilter)
        ? ''
        : `for your search <strong>"${searchFilter
            .map(({ label, value }) => `${label}: ${value}`)
            .join(', ')}"</strong>`
    }`;
  };

  private syncSearchResultDisplayColumnList() {
    this.searchResultDisplayColumnFormControl.valueChanges
      .pipe(takeUntil(this.destroy$))
      .subscribe((searchResultDisplayColumnList) => {
        this.store.dispatch(
          searchResultDisplayColumnListUpdated({
            searchResultDisplayColumnList,
          })
        );
      });
  }

  private patchSearchResultDisplayColumnFormControl() {
    this.store
      .select(selectSearchResultDisplayColumnList)
      .pipe(filter(complement(isNilOrEmpty)), take(1))
      .subscribe((searchResultDisplayColumnList) => {
        this.searchResultDisplayColumnFormControl.patchValue(
          searchResultDisplayColumnList
        );
      });
  }

  private subscribeToSearchResult() {
    combineLatest([this.store.select(selectSearchResult), this.sort$])
      .pipe(
        takeUntil(this.destroy$),
        tap(([searchResult]) => (this.totalSearchResult = searchResult?.total)),
        tap(([searchResult]) =>
          this.showHideSearchCriteriaBySearchResult(searchResult?.resultList)
        ),
        map(([searchResult, { sortOrder, searchResultField }]) => {
          if (isNil(searchResult)) {
            return undefined;
          }
          return this.sortedSearchResults(
            sortOrder,
            searchResult?.resultList,
            searchResultField
          );
        })
      )
      .subscribe((searchResults) => {
        this.searchResultList = searchResults;
        this.cdr.detectChanges();
      });
  }

  private showHideSearchCriteriaBySearchResult(
    searchResultList?: SearchResultItem[]
  ) {
    const hasSearchResult = !isNil(searchResultList);
    this.isSearchCriteriaShown$.next(!hasSearchResult);
    if (hasSearchResult) {
      return this.collapse?.hide();
    }
    this.collapse?.show();
  }

  private sortedSearchResults(
    sortOrder: SortOrder,
    results: Array<SearchResultItem>,
    searchResultField?: SearchResultField
  ) {
    if (isNil(searchResultField) || equals(sortOrder, SortOrder.NOT_SORTED)) {
      return results;
    }
    return sort((a, b) => {
      const aField = <string>a[searchResultField.property];
      const bField = <string>b[searchResultField.property];
      if (
        includes(searchResultField.dataType, [
          SearchFieldDataType.DateTime,
          SearchFieldDataType.Date,
        ])
      ) {
        return equals(sortOrder, SortOrder.ASC)
          ? dayjs(aField).diff(dayjs(bField))
          : dayjs(bField).diff(dayjs(aField));
      }
      if (equals(sortOrder, SortOrder.ASC)) {
        return aField > bField ? 1 : -1;
      }
      return aField < bField ? 1 : -1;
    }, results);
  }

  private initSearchFieldValues() {
    this.store
      .select(selectSearchFieldValues)
      .pipe(filter(complement(isNil)), take(1))
      .subscribe((values) => (this.searchFieldValues = values));
  }

  private initSearchForm() {
    this.searchForm = new FormGroup<SearchFormControls>(
      mergeAll(
        this.searchMetadata.searchFields.map((searchField) => {
          switch (searchField.fieldType) {
            case SearchFieldType.RadioButtonPreFilter:
              return this.radioButtonPreFilterFormControls(
                searchField as CompositeSearchField
              );
            case SearchFieldType.Dependent:
              return this.dependentFilterFormControls(
                searchField as CompositeSearchField
              );
            case SearchFieldType.DateRange:
              return this.dateRangeFormControls(
                searchField as CompositeSearchField
              );
            default:
              return this.searchFieldFormControl(
                searchField as FlatSearchField
              );
          }
        })
      ),
      searchFormValidator
    );
  }

  // Does not support SearchFieldType.Dependent
  private prePopulateSearchCriteriaFromQueryParams() {
    const queryParams = this.activatedRoute.snapshot.queryParams;
    if (isNilOrEmpty(queryParams)) {
      return;
    }
    this.store.dispatch(
      searchFilterPopulatedByQueryParams({
        searchFilter: this.searchFilterFromQueryParams(queryParams),
      })
    );
  }

  private searchFilterFromQueryParams(queryParams: Params) {
    const searchFormValue = this.searchForm.value;
    Object.entries(queryParams).forEach(([key, value]) => {
      if (has(key, searchFormValue)) {
        return (searchFormValue[key] = value);
      }
      if (
        !isNil(searchFormValue.preFilter) &&
        has(key, searchFormValue.preFilter)
      ) {
        searchFormValue.preFilter[key] = value;
        searchFormValue.preFilter.filter = key;
      }
    });
    return searchFormValue;
  }

  private prePopulateSearchFormValue() {
    this.store
      .select(selectSearchFilter)
      .pipe(take(1), filter(complement(isNil)))
      .subscribe((searchFilter) => {
        this.searchForm.patchValue(searchFilter);
        this.search();
      });
  }

  private radioButtonPreFilterFormControls = (
    searchField: CompositeSearchField
  ): PreFilterSearchFormControls => ({
    [SearchFieldType.RadioButtonPreFilter]:
      new FormGroup<FlatSearchFormControlsWithFilter>(
        mergeAll<FlatSearchFormControlsWithFilter>([
          {
            filter: new FormControl(searchField.fields[0].formControlName, {
              nonNullable: true,
            }),
          },
          ...searchField.fields.map((searchField) =>
            this.searchFieldFormControl(searchField)
          ),
        ])
      ),
  });

  private dependentFilterFormControls = (
    searchField: CompositeSearchField
  ): DependentSearchControls => ({
    [SearchFieldType.Dependent]: new FormGroup(
      mergeAll<Record<string, FormControl>>(
        searchField.fields.map((searchField) =>
          this.searchFieldFormControl(searchField, true)
        )
      )
    ),
  });

  private dateRangeFormControls = (
    searchField: CompositeSearchField
  ): FlatSearchFormControls => {
    const dateRangeFormControls: FlatSearchFormControls = mergeAll<
      Record<string, FormControl>
    >(
      searchField.fields.map((searchField) =>
        this.searchFieldFormControl(searchField)
      )
    );
    this.setupDateRangeFormControlsValidator(
      searchField,
      dateRangeFormControls
    );
    return dateRangeFormControls;
  };

  private setupDateRangeFormControlsValidator = (
    searchField: CompositeSearchField,
    dateRangeFormControls: FlatSearchFormControls
  ) => {
    const [fromField, toField] = searchField.fields;
    const fromFieldFormControl =
      dateRangeFormControls[fromField.formControlName];
    const toFieldFormControl = dateRangeFormControls[toField.formControlName];

    merge(fromFieldFormControl.valueChanges, toFieldFormControl.valueChanges)
      .pipe(takeUntil(this.destroy$))
      .subscribe(() => {
        [fromFieldFormControl, toFieldFormControl].forEach((control) => {
          control.setValidators([
            this.dateRangeFormControlsValidator(
              fromFieldFormControl,
              toFieldFormControl
            ),
          ]);
          control.updateValueAndValidity({ emitEvent: false });
        });
      });
  };

  private dateRangeFormControlsValidator =
    (
      fromFieldFormControl: FormControl<string | number>,
      toFieldFormControl: FormControl<string | number>
    ) =>
    () => {
      if (
        isNil(toFieldFormControl.value) ||
        isNil(fromFieldFormControl.value)
      ) {
        return null;
      }
      if (
        dayjs(fromFieldFormControl.value).isAfter(
          dayjs(toFieldFormControl.value)
        )
      ) {
        return { [CustomFormValidator.FromIsAfterTo]: true };
      }

      return null;
    };

  private searchFieldFormControl = (
    searchField: FlatSearchField,
    required = false
  ): FlatSearchFormControls => ({
    [searchField.formControlName]: new FormControl(searchField.initValue, {
      nonNullable: true,
      validators: required ? [Validators.required] : [],
    }),
  });

  private presentErrorToastWhenFailed({
    viewStatus$,
    message,
  }: {
    viewStatus$: Observable<ViewStatus>;
    message: string;
  }) {
    viewStatus$
      ?.pipe(skip(1), take(2), filter(equals<ViewStatus>(ViewStatus.Failure)))
      .subscribe(() => {
        this.toastService.showError(message);
      });
  }
}
