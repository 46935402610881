import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { CommonModule } from '@angular/common';
import { DynamicWidthItemList } from '@fishonline2023/shared/models';

@Component({
  selector: 'sv-ui-dynamic-width-item-list',
  standalone: true,
  imports: [CommonModule],
  templateUrl: './dynamic-width-item-list.component.html',
  styleUrls: ['./dynamic-width-item-list.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DynamicWidthItemListComponent {
  @Input() public dynamicWidthItemList!: DynamicWidthItemList;
}
