import {
  ChangeDetectionStrategy,
  Component,
  inject,
  Input,
  OnDestroy,
  OnInit,
} from '@angular/core';
import { CommonModule } from '@angular/common';
import {
  FishingBusinessTwoPartyTransactionEntitySelectionForm,
  Message,
  TwoPartyTransactionEntitySelectionType,
} from '@fishonline2023/webapps/model/fd2023';
import { FormGroup } from '@angular/forms';
import { BehaviorSubject, map, Subject, take, withLatestFrom } from 'rxjs';
import {
  AgentCustomer,
  FishingBusiness,
  ViewStatus,
} from '@fishonline2023/shared/models';
import {
  LoadingComponent,
  ToastService,
} from '@fishonline2023/webapps/shared/ui/base-components';
import { Store } from '@ngrx/store';
import { getFishingBusinessWithConsumableQuotaBalance } from '@fishonline2023/webapps/homepage/fd2023/store/home';
import { TwoPartyTransactionEntitySelectionFormComponent } from '../two-party-transaction-entity-selection-form/two-party-transaction-entity-selection-form.component';
import { TwoPartySelectionService } from '../two-party-selection.service';
import { equals } from '@fishonline2023/shared/ramda';

@Component({
  selector: 'sv-ui-two-party-transfer-fishing-business-selection',
  standalone: true,
  imports: [
    CommonModule,
    LoadingComponent,
    TwoPartyTransactionEntitySelectionFormComponent,
  ],
  templateUrl: './two-party-transfer-fishing-business-selection.component.html',
  styleUrls: ['./two-party-transfer-fishing-business-selection.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [TwoPartySelectionService],
})
export class TwoPartyTransferFishingBusinessSelectionComponent
  implements OnInit, OnDestroy
{
  @Input()
  public selectionFormGroup!: FormGroup<FishingBusinessTwoPartyTransactionEntitySelectionForm>;
  @Input() public isReadOnly: boolean | null = false;
  protected readonly message = Message.LoadingCustomers;
  protected readonly ViewStatus = ViewStatus;
  protected readonly TwoPartyTransactionEntitySelectionType =
    TwoPartyTransactionEntitySelectionType;
  protected fromFishingBusinessList: FishingBusiness[] = [];
  protected toCustomerList: AgentCustomer[] = [];
  protected viewStatus$ = new BehaviorSubject<ViewStatus>(ViewStatus.Initial);
  private destroy$ = new Subject<void>();
  private toastService = inject(ToastService);
  private store = inject(Store);
  private twoPartySelectionService = inject(TwoPartySelectionService);
  private getFishingBusinessWithConsumableQuotaBalance$ = this.store.select(
    getFishingBusinessWithConsumableQuotaBalance
  );

  public ngOnInit() {
    if (this.isReadOnly) {
      return;
    }
    this.populateFromFishingBusinessList();
    this.loadToCustomerList();
  }

  public ngOnDestroy() {
    this.destroy$.next();
    this.destroy$.complete();
  }

  private populateFromFishingBusinessList() {
    this.getFishingBusinessWithConsumableQuotaBalance$
      .pipe(take(1))
      .subscribe((fromFishingBusinessList) => {
        this.fromFishingBusinessList = fromFishingBusinessList;
        if (equals(this.fromFishingBusinessList.length, 1)) {
          this.selectionFormGroup.patchValue({
            from: this.fromFishingBusinessList[0],
          });
        }
      });
  }

  private loadToCustomerList() {
    this.viewStatus$.next(ViewStatus.Loading);
    return this.twoPartySelectionService
      .getToCustomerList()
      .pipe(
        withLatestFrom(this.getFishingBusinessWithConsumableQuotaBalance$),
        map(this.filterToCustomerNotEqualToFrom)
      )
      .subscribe({
        next: (toCustomerList) => {
          this.viewStatus$.next(ViewStatus.Success);
          this.toCustomerList = toCustomerList;
        },
        error: (error) => {
          this.viewStatus$.next(ViewStatus.Failure);
          this.toastService.showError(Message.LoadingFishingBusinessesError);
          throw error;
        },
      });
  }

  private filterToCustomerNotEqualToFrom = ([
    toCustomerList,
    fromFishingBusinessList,
  ]: [AgentCustomer[], FishingBusiness[]]) =>
    toCustomerList.filter((toCustomer) => {
      return !fromFishingBusinessList
        .map((fishingBusiness) => fishingBusiness.owner.id)
        .includes(toCustomer.id);
    });
}
