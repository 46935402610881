<div *ngIf="licenceDetails.conditionNotationList.length === 0">
  No data available
</div>
<div class="row mb-1 g-2">
  <div
    *ngFor="let notation of licenceDetails.conditionNotationList"
    class="col-md-3 col-sm-12"
  >
    <sv-ui-card
      (cardClick)="onNotationClick(notation)"
      [description]="notation.shortDescription"
      [title]="notation.code"
    ></sv-ui-card>
  </div>
</div>

<sv-ui-modal [modalId]="ModalId.LicenceDetailAnnotationModal">
  <div class="modal-header">
    <h5 class="modal-title">{{ selectedNotation?.code }}</h5>
    <button
      (click)="dismissModal()"
      aria-label="Close"
      class="btn-close"
      type="button"
    ></button>
  </div>
  <div class="modal-body">
    <p>{{ selectedNotation?.shortDescription }}</p>
    <p>{{ selectedNotation?.description }}</p>
  </div>
  <div class="modal-footer">
    <button
      (click)="dismissModal()"
      aria-label="Cancel"
      class="btn btn-secondary"
      type="button"
    >
      Close
    </button>
  </div>
</sv-ui-modal>
