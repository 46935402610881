import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  inject,
  Input,
  OnInit,
  Output,
} from '@angular/core';
import { CommonModule } from '@angular/common';
import { Observable } from 'rxjs';
import {
  CurrentlyOwnedFishingBusiness,
  FDDataLoaderData,
} from '@fishonline2023/webapps/model/fd2023';
import { FishingBusinessSectionComponent } from '../fishing-business-section/fishing-business-section.component';
import { EventType } from '@fishonline2023/webapps/shared/feature/appsync';
import { ActivatedRoute } from '@angular/router';
import { SearchDetailWrapperComponent } from '@fishonline2023/webapps/shared/ui/base-components';
import { DataLoaderActivityLog } from '@fishonline2023/shared/models';

@Component({
  selector: 'sv-ui-fishing-business-report',
  standalone: true,
  imports: [
    CommonModule,
    FishingBusinessSectionComponent,
    SearchDetailWrapperComponent,
  ],
  templateUrl: './fishing-business-report.component.html',
  styleUrls: ['./fishing-business-report.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class FishingBusinessReportComponent implements OnInit {
  @Input() dataLoaderData$!: Observable<FDDataLoaderData>;
  @Output() public activityLogTriggered =
    new EventEmitter<DataLoaderActivityLog>();
  protected readonly window = window;
  private activatedRoute = inject(ActivatedRoute);

  public get fishingBusinessReport$() {
    return this.dataLoaderData$ as Observable<CurrentlyOwnedFishingBusiness>;
  }

  public ngOnInit() {
    this.activityLogTriggered.emit({
      eventType: EventType.ViewShareExtractFishingBusiness,
      detail: JSON.stringify({
        'Fishing Business Number':
          this.activatedRoute.snapshot.queryParams['fishingBusinessNumber'],
      }),
    });
  }
}
