import {
  AddressFieldMetadata,
  AddressMetadata,
  ASICStatus,
  BillingAddressFieldMetadata,
  BillingAddressForm,
  ContactDetail,
  ContactFieldMetadata,
  CustomerFieldMetadata,
  CustomerType,
  Gender,
  PartnerFieldMetadata,
  RegistrationFieldMetadata,
  SharedAmendCustomerForm,
} from './amend-customer.model';
import { InputType } from './input.model';
import { SelectionOption } from './selection-option.model';
import * as dayjs from 'dayjs';

const registrationNumber = (
  isReadOnly: boolean = false
): RegistrationFieldMetadata => ({
  formControlName: 'registrationNumber',
  type: InputType.Number,
  label: 'Registration no',
  isReadOnly,
});

const registrationStatus = (
  isReadOnly: boolean = false
): RegistrationFieldMetadata => ({
  formControlName: 'registrationStatus',
  type: InputType.Text,
  label: 'Registration status',
  isReadOnly,
});

const customerType = (
  isReadOnly: boolean = false
): RegistrationFieldMetadata => ({
  formControlName: 'customerType',
  type: InputType.Select,
  label: 'Customer type',
  options: Object.entries(CustomerType).map(([label, value]) => ({
    label,
    value,
  })),
  isReadOnly,
});

const whfFolderNumber = (
  isReadOnly: boolean = false
): RegistrationFieldMetadata => ({
  formControlName: 'whfFolderNumber',
  type: InputType.Text,
  label: 'WHF folder no',
  isReadOnly,
});

const sapCustomerNumber = (
  isReadOnly: boolean = false
): RegistrationFieldMetadata => ({
  formControlName: 'sapCustomerNumber',
  type: InputType.Number,
  label: 'SAP customer no',
  isReadOnly,
});

const repositoryName = (
  isReadOnly: boolean = false
): RegistrationFieldMetadata => ({
  formControlName: 'repositoryName',
  type: InputType.Text,
  label: 'Repository name',
  isReadOnly,
  maxLength: 100,
  width: 12,
});

const mortgageAll = (
  isReadOnly: boolean = false
): RegistrationFieldMetadata => ({
  formControlName: 'mortgageAll',
  type: InputType.Checkbox,
  label: 'Mortgage ALL',
  isReadOnly,
});

const customerName = (
  isReadOnly: boolean = false
): RegistrationFieldMetadata => ({
  formControlName: 'customerName',
  type: InputType.Text,
  label: 'Customer name',
  isReadOnly,
  maxLength: 100,
  width: 12,
});

const tradingAs = (isReadOnly: boolean = false): RegistrationFieldMetadata => ({
  formControlName: 'tradingAs',
  type: InputType.Text,
  label: 'Trading as',
  maxLength: 100,
  isReadOnly,
});

const abn = (isReadOnly: boolean = false): RegistrationFieldMetadata => ({
  formControlName: 'abn',
  type: InputType.ABN,
  label: 'ABN',
  maxLength: 14,
  isReadOnly,
});

const acn = (isReadOnly: boolean = false): RegistrationFieldMetadata => ({
  formControlName: 'acn',
  type: InputType.ACN,
  label: 'ACN',
  maxLength: 11,
  isReadOnly,
});

const asic = (isReadOnly: boolean = false): RegistrationFieldMetadata => ({
  formControlName: 'asic',
  type: InputType.Select,
  label: 'ASIC',
  options: Object.entries(ASICStatus).map(([label, value]) => ({
    label,
    value,
  })),
  isReadOnly,
});

const fisheriesOffice = (
  fisheriesOfficeList: SelectionOption[],
  isReadOnly: boolean = false
): RegistrationFieldMetadata => ({
  formControlName: 'fisheriesOffice',
  type: InputType.Select,
  label: 'Fisheries office',
  options: fisheriesOfficeList,
  isReadOnly,
});

export const repositoryEditFieldsMetadataList = [
  registrationNumber(true),
  registrationStatus(true),
  customerType(true),
  whfFolderNumber(),
  repositoryName(),
];

export const repositoryCreateFieldsMetadataList = [
  registrationNumber(true),
  registrationStatus(true),
  customerType(),
  whfFolderNumber(),
  repositoryName(),
];

export const bodyEditFieldsMetadataList = [
  registrationNumber(true),
  registrationStatus(true),
  customerType(true),
  whfFolderNumber(),
  sapCustomerNumber(true),
  mortgageAll(),
  customerName(),
];

export const bodyCreateFieldsMetadataList = [
  registrationNumber(true),
  registrationStatus(true),
  customerType(),
  whfFolderNumber(),
  sapCustomerNumber(true),
  mortgageAll(),
  customerName(),
];

export const companyEditFieldsMetadataList = (
  fisheriesOfficeList: SelectionOption[]
) => [
  registrationNumber(true),
  registrationStatus(true),
  customerType(true),
  whfFolderNumber(),
  sapCustomerNumber(true),
  mortgageAll(),
  customerName(),
  tradingAs(),
  fisheriesOffice(fisheriesOfficeList),
  acn(),
  abn(),
  asic(),
];

export const companyCreateFieldsMetadataList = (
  fisheriesOfficeList: SelectionOption[]
) => [
  registrationNumber(true),
  registrationStatus(true),
  customerType(),
  whfFolderNumber(),
  sapCustomerNumber(),
  mortgageAll(),
  customerName(),
  tradingAs(),
  fisheriesOffice(fisheriesOfficeList),
  acn(),
  abn(),
  asic(),
];

export const partnershipEditFieldsMetadataList = (
  fisheriesOfficeList: SelectionOption[]
) => [
  registrationNumber(true),
  registrationStatus(true),
  customerType(true),
  whfFolderNumber(),
  sapCustomerNumber(true),
  mortgageAll(),
  customerName(),
  tradingAs(),
  abn(),
  fisheriesOffice(fisheriesOfficeList),
];

export const partnershipCreateFieldsMetadataList = (
  fisheriesOfficeList: SelectionOption[]
) => [
  registrationNumber(true),
  registrationStatus(true),
  customerType(),
  whfFolderNumber(),
  sapCustomerNumber(),
  mortgageAll(),
  customerName(),
  tradingAs(),
  abn(),
  fisheriesOffice(fisheriesOfficeList),
];

export const individualEditFieldsMetadataList = (
  fisheriesOfficeList: SelectionOption[]
) => [
  registrationNumber(true),
  registrationStatus(true),
  customerType(true),
  whfFolderNumber(),
  sapCustomerNumber(true),
  mortgageAll(),
  customerName(true),
  tradingAs(),
  abn(),
  fisheriesOffice(fisheriesOfficeList),
];

export const individualCreateFieldsMetadataList = (
  fisheriesOfficeList: SelectionOption[]
) => [
  registrationNumber(true),
  registrationStatus(true),
  customerType(),
  whfFolderNumber(),
  sapCustomerNumber(),
  mortgageAll(),
  customerName(true),
  tradingAs(),
  abn(),
  fisheriesOffice(fisheriesOfficeList),
];

export const customerTypeRegistrationDetailAmendFormMap = (
  fisheriesOfficeList: SelectionOption[]
): Record<CustomerType, RegistrationFieldMetadata[]> => ({
  [CustomerType.Individual]:
    individualEditFieldsMetadataList(fisheriesOfficeList),
  [CustomerType.Company]: companyEditFieldsMetadataList(fisheriesOfficeList),
  [CustomerType.Partnership]:
    partnershipEditFieldsMetadataList(fisheriesOfficeList),
  [CustomerType.Body]: bodyEditFieldsMetadataList,
  [CustomerType.Repository]: repositoryEditFieldsMetadataList,
});

export const customerTypeRegistrationDetailCreateFormMap = (
  fisheriesOfficeList: SelectionOption[]
): Record<CustomerType, RegistrationFieldMetadata[]> => ({
  [CustomerType.Individual]:
    individualCreateFieldsMetadataList(fisheriesOfficeList),
  [CustomerType.Company]: companyCreateFieldsMetadataList(fisheriesOfficeList),
  [CustomerType.Partnership]:
    partnershipCreateFieldsMetadataList(fisheriesOfficeList),
  [CustomerType.Body]: bodyCreateFieldsMetadataList,
  [CustomerType.Repository]: repositoryCreateFieldsMetadataList,
});

export const partnerFieldsMetadataList: PartnerFieldMetadata[] = [
  {
    formControlName: 'firstName',
    type: InputType.Text,
    label: 'First name',
    isRequired: true,
    maxLength: 100,
  },
  {
    formControlName: 'lastName',
    type: InputType.Text,
    label: 'Last name',
    isRequired: true,
    maxLength: 100,
  },
  {
    formControlName: 'otherName',
    type: InputType.Text,
    label: 'Other name',
    maxLength: 100,
  },
  {
    formControlName: 'dob',
    type: InputType.Date,
    label: 'DOB',
    isRequired: true,
    minDate: '1920-01-01',
    maxDate: dayjs().format('YYYY-MM-DD'),
  },
];

export const customerNameFieldsMetadataList: CustomerFieldMetadata[] = [
  ...partnerFieldsMetadataList,
  {
    formControlName: 'gender',
    type: InputType.Select,
    options: Object.entries(Gender).map(([value, label]) => ({
      label,
      value,
    })),
    label: 'Gender',
  },
];

export const contactFieldsMetadataList: ContactFieldMetadata[] = [
  {
    formControlName: ContactDetail.EmailAddress,
    shouldDynamicDecideIfRequired: true,
  },
  {
    formControlName: ContactDetail.HomePhone,
    shouldDynamicDecideIfRequired: true,
  },
  {
    formControlName: ContactDetail.WorkPhone,
    shouldDynamicDecideIfRequired: true,
  },
  {
    formControlName: ContactDetail.Mobile,
    shouldDynamicDecideIfRequired: true,
  },
  {
    formControlName: 'fax',
  },
  {
    formControlName: 'contactName',
    isRequired: true,
  },
];

export const preferredMethodMetadataList: Array<keyof SharedAmendCustomerForm> =
  [
    ContactDetail.EmailAddress,
    ContactDetail.HomePhone,
    ContactDetail.WorkPhone,
    ContactDetail.Mobile,
  ];

export const LabelNameMap: Record<
  keyof SharedAmendCustomerForm | keyof BillingAddressForm,
  string
> = {
  [ContactDetail.EmailAddress]: 'Email Address',
  [ContactDetail.HomePhone]: 'Home Phone',
  [ContactDetail.WorkPhone]: 'Work Phone',
  [ContactDetail.Mobile]: 'Mobile',
  fax: 'Fax',
  contactName: 'Contact Name',
  preferredMethod: 'Preferred Method',
  physicalAddress: 'Physical Address',
  postalAddress: 'Postal Address',
  billingAddress: 'Billing Address',
  streetAddress: 'Street Address',
  suburb: 'Suburb',
  postcode: 'Postcode',
  state: 'State',
  customerName: 'Customer Name',
  nameExtension: 'Name Extension',
  contactNumber: 'Contact Number',
};

const addressFieldMetadataList = (
  isRequired = false
): AddressFieldMetadata[] => [
  {
    formControlName: 'streetAddress',
    shouldDisplayTypeahead: false,
    maxLength: 60,
    isRequired,
  },
  {
    formControlName: 'suburb',
    shouldDisplayTypeahead: true,
    isRequired,
  },
  {
    formControlName: 'postcode',
    shouldDisplayTypeahead: true,
    isRequired,
  },
  {
    formControlName: 'state',
    shouldDisplayTypeahead: true,
    isRequired,
  },
];

const billingAddressFieldMetadataList: BillingAddressFieldMetadata[] = [
  ...addressFieldMetadataList(true),
  {
    formControlName: 'customerName',
    shouldDisplayTypeahead: false,
    isReadonly: true,
  },
  {
    formControlName: 'nameExtension',
    shouldDisplayTypeahead: false,
    isReadonly: true,
  },
  {
    formControlName: 'contactNumber',
    shouldDisplayTypeahead: false,
    isRequired: true,
  },
];

export const addressMetadataList = (
  copyToPostalAddress: () => void,
  copyToBillingAddress: () => void
): AddressMetadata[] => [
  {
    formGroupName: 'physicalAddress',
    title: 'Physical Address',
    shouldDisplayCopyButton: true,
    copyButtonLabel: 'Copy to Postal Address',
    copyMethod: copyToPostalAddress,
    fieldLists: addressFieldMetadataList(),
  },
  {
    formGroupName: 'postalAddress',
    title: 'Postal Address',
    shouldDisplayCopyButton: true,
    copyButtonLabel: 'Copy to Billing Address',
    copyMethod: copyToBillingAddress,
    fieldLists: addressFieldMetadataList(),
  },
  {
    formGroupName: 'billingAddress',
    title: 'Billing Address',
    shouldDisplayCopyButton: false,
    fieldLists: billingAddressFieldMetadataList,
  },
];

export const tooShortAddressInputLength = {
  suburb: 3,
  postcode: 2,
};
