import {
  ChangeDetectionStrategy,
  Component,
  ElementRef,
  Input,
  ViewChild,
} from '@angular/core';
import { CommonModule } from '@angular/common';
import { SearchMetadata } from '@fishonline2023/shared/models';
import { FormControl } from '@angular/forms';
import { without } from '@fishonline2023/shared/ramda';
import * as bootstrap from 'bootstrap';
import { BehaviorSubject } from 'rxjs';
import { toggleCollapse } from '@fishonline2023/shared/utils';

@Component({
  selector: 'sv-ui-search-result-display-column-selection',
  standalone: true,
  imports: [CommonModule],
  templateUrl: './search-result-display-column-selection.component.html',
  styleUrls: ['./search-result-display-column-selection.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SearchResultDisplayColumnSelectionComponent {
  @Input() public searchResultDisplayColumnFormControl!: FormControl<string[]>;
  @Input() public searchMetadata!: SearchMetadata;
  @ViewChild('searchResultColumnsCollapse')
  protected searchResultColumnsCollapse!: ElementRef;
  protected isSearchResultFilterShown$ = new BehaviorSubject(false);
  protected readonly toggleCollapse = toggleCollapse;

  protected get allColumns() {
    return this.searchMetadata.searchResultTableFields.map(
      ({ label }) => label
    );
  }

  protected get collapse() {
    return new bootstrap.Collapse(
      this.searchResultColumnsCollapse.nativeElement,
      {
        toggle: false,
      }
    );
  }

  protected toggleColumn(column: string) {
    const selectedColumns = this.searchResultDisplayColumnFormControl.value;
    if (selectedColumns.includes(column)) {
      return this.searchResultDisplayColumnFormControl.patchValue(
        without([column], selectedColumns)
      );
    }
    return this.searchResultDisplayColumnFormControl.patchValue([
      ...selectedColumns,
      column,
    ]);
  }
}
