<table *ngIf="tableData && tableData.length > 0" class="table table-striped">
  <thead>
    <tr>
      <th *ngFor="let header of tableHeaders()" scope="col">
        <div class="table-header-container">
          {{ header }}
          <sv-ui-table-sorting-button
            (sortButtonClicked)="sortTable($event, header)"
            *ngIf="sortColumns.includes(header)"
            [columnSorted]="sortedColumn"
            [column]="header"
          ></sv-ui-table-sorting-button>
        </div>
      </th>
    </tr>
  </thead>
  <tbody class="table-group-divider">
    <tr *ngFor="let tableData of sortedTableData">
      <td *ngFor="let row of tableValues(tableData)">
        <ng-container *ngIf="row.link; else value">
          <button [routerLink]="row.link" class="btn btn-link ps-0 pe-0">
            {{ row.value }}
          </button>
        </ng-container>
        <ng-template #value>
          {{ row.value }}
        </ng-template>
      </td>
    </tr>
  </tbody>
</table>
