import { LicenceDetail } from './licence-detail.model';
import { FormControl } from '@angular/forms';
import { TransactionAction } from './transaction.enum';
import { BPAYCode } from './transaction.model';

export interface RenewLicenceTransactionDetail {
  renewalTerm: TermData;
  action?: TransactionAction;
}

export interface RenewLicenceTransactionReference extends BPAYCode {
  licenceDetails: LicenceDetail;
  availableTermList: TermData[];
}

export interface TermData {
  renewalTermYear: number;
  renewalTermFee: number;
  startDate: string;
}

export interface RenewLicenceForm {
  renewalTerm: FormControl<TermData | undefined>;
}
