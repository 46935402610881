import {
  MANAGE_AGENTS_FEATURE_KEY,
  ManageAgentsState,
} from './manage-agent.reducers';
import { createFeatureSelector, createSelector } from '@ngrx/store';

export const selectManageAgentsState = createFeatureSelector<ManageAgentsState>(
  MANAGE_AGENTS_FEATURE_KEY
);

export const getManageAgentsViewStatus = createSelector(
  selectManageAgentsState,
  (state) => state.viewStatus
);

export const getManageAgents = createSelector(
  selectManageAgentsState,
  (state) => state.agentList
);

export const getManageAgentsErrorMessage = createSelector(
  selectManageAgentsState,
  (state) => state.errorMessage
);

export const getManageAgentCandidatesViewStatus = createSelector(
  selectManageAgentsState,
  (state) => state.candidatesViewStatus
);

export const getManageAgentCandidates = createSelector(
  selectManageAgentsState,
  (state) => state.agentCandidatesList
);

export const getManageAgentCandidatesErrorMessage = createSelector(
  selectManageAgentsState,
  (state) => state.candidatesErrorMessage
);

export const getManageNewAgentViewStatus = createSelector(
  selectManageAgentsState,
  (state) => state.newAgentViewStatus
);

export const getManageNewAgent = createSelector(
  selectManageAgentsState,
  (state) => state.newAgent
);

export const getManageNewAgentErrorMessage = createSelector(
  selectManageAgentsState,
  (state) => state.newAgentErrorMessage
);

export const getSaveAgentViewStatus = createSelector(
  selectManageAgentsState,
  (state) => state.saveAgentViewStatus
);

export const getSaveAgentErrorMessage = createSelector(
  selectManageAgentsState,
  (state) => state.saveAgentErrorMessage
);

export const getDeleteAgentViewStatus = createSelector(
  selectManageAgentsState,
  (state) => state.deleteAgentViewStatus
);

export const getDeleteAgentErrorMessage = createSelector(
  selectManageAgentsState,
  (state) => state.deleteAgentErrorMessage
);

export const getExistingAgentToUpdate = createSelector(
  selectManageAgentsState,
  (state) => state.existingAgentToUpdate
);
