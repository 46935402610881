import { inject, Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import {
  catchError,
  filter,
  map,
  of,
  switchMap,
  take,
  withLatestFrom,
} from 'rxjs';
import {
  resetTransaction,
  transactionAbandon,
  transactionAbandonFailed,
  transactionAbandonSuccessful,
  transactionDataAccept,
  transactionDataAcceptFailed,
  transactionDataAcceptSuccessful,
  transactionDataConfirm,
  transactionDataConfirmFailed,
  transactionDataConfirmSuccessful,
  transactionDataLoadFailed,
  transactionDataLoadSuccessful,
  transactionDataPayAndConfirm,
  transactionDataPayAndConfirmFailed,
  transactionDataPayAndConfirmSuccessful,
  transactionDataPayLater,
  transactionDataPayLaterFailed,
  transactionDataPayLaterSuccessful,
  transactionDataReject,
  transactionDataRejectFailed,
  transactionDataRejectSuccessful,
  transactionDataRequested,
  transactionDataSubmitFailed,
  transactionDataSubmitted,
  transactionDataWithDraw,
  transactionDataWithDrawFailed,
  transactionDataWithDrawSuccessful,
  transactionPaymentCreditCardDetailValidated,
  transactionPaymentCreditCardDetailValidatedFailed,
  transactionPaymentCreditCardDetailValidatedSuccessful,
} from './transaction.actions';
import { TransactionService } from './transaction.service';
import { ROUTER_REQUEST } from '@ngrx/router-store';
import { complement, isNil, path } from '@fishonline2023/shared/ramda';
import { Store } from '@ngrx/store';
import {
  selectConfirmPath,
  selectTransactionAppRoute,
  selectTransactionCreditCardDetail,
  selectTransactionRequestParams,
} from './transaction.selectors';
import { RouterRequestPayload } from '@ngrx/router-store/src/actions';
import {
  ConfirmPath,
  Error,
  Message,
} from '@fishonline2023/webapps/model/fd2023';

@Injectable()
export class TransactionEffects {
  private actions$ = inject(Actions);
  private transactionService = inject(TransactionService);
  submitTransactionData$ = createEffect(() =>
    this.actions$.pipe(
      ofType(transactionDataSubmitted),
      switchMap(({ transactionDetail }) =>
        this.transactionService.submitTransactionData(transactionDetail).pipe(
          map(this.transactionService.parseSubmitTransactionDataResponse),
          catchError(({ errorMessage }) =>
            of(
              transactionDataSubmitFailed({
                errorMessage: errorMessage ?? Message.FailedToSubmitTransaction,
              })
            )
          )
        )
      )
    )
  );
  submitTransactionPayLaterData$ = createEffect(() =>
    this.actions$.pipe(
      ofType(transactionDataPayLater),
      switchMap(() =>
        this.transactionService.submitTransactionDataPayLater().pipe(
          map((transactionData) =>
            transactionDataPayLaterSuccessful({ transactionData })
          ),
          catchError(({ errorMessage }) =>
            of(
              transactionDataPayLaterFailed({
                errorMessage: errorMessage ?? Message.TryAgainLater,
              })
            )
          )
        )
      )
    )
  );
  validateCreditCard$ = createEffect(() =>
    this.actions$.pipe(
      ofType(transactionPaymentCreditCardDetailValidated),
      switchMap(({ trustedFrame }) =>
        this.transactionService.validateCreditCard(trustedFrame).pipe(
          map((transactionCreditCardDetail) =>
            transactionPaymentCreditCardDetailValidatedSuccessful({
              transactionCreditCardDetail,
            })
          ),
          catchError(() =>
            of(
              transactionPaymentCreditCardDetailValidatedFailed({
                errorMessage: Message.CreditCardFailedToValidate,
              })
            )
          )
        )
      )
    )
  );
  abandonTransaction$ = createEffect(() =>
    this.actions$.pipe(
      ofType(transactionAbandon),
      switchMap(() =>
        this.transactionService.abandonTransaction().pipe(
          map(() => transactionAbandonSuccessful()),
          catchError((error: Error) =>
            of(
              transactionAbandonFailed({
                errorMessage:
                  this.transactionService.getAbandonTransactionErrorMessage(
                    error
                  ),
              })
            )
          )
        )
      )
    )
  );
  withdrawTransaction$ = createEffect(() =>
    this.actions$.pipe(
      ofType(transactionDataWithDraw),
      switchMap(() =>
        this.transactionService.withdrawTransaction().pipe(
          map((transactionData) =>
            transactionDataWithDrawSuccessful({ transactionData })
          ),
          catchError(({ errorMessage }) =>
            of(
              transactionDataWithDrawFailed({
                errorMessage:
                  errorMessage ?? Message.FailedToWithdrawTransaction,
              })
            )
          )
        )
      )
    )
  );
  rejectTransaction$ = createEffect(() =>
    this.actions$.pipe(
      ofType(transactionDataReject),
      switchMap(() =>
        this.transactionService.rejectTransaction().pipe(
          map((transactionData) =>
            transactionDataRejectSuccessful({ transactionData })
          ),
          catchError(({ errorMessage }) =>
            of(
              transactionDataRejectFailed({
                errorMessage: errorMessage ?? Message.FailedToRejectTransaction,
              })
            )
          )
        )
      )
    )
  );
  acceptTransaction$ = createEffect(() =>
    this.actions$.pipe(
      ofType(transactionDataAccept),
      switchMap(() =>
        this.transactionService.acceptTransaction().pipe(
          map((transactionData) =>
            transactionDataAcceptSuccessful({ transactionData })
          ),
          catchError(({ errorMessage }) =>
            of(
              transactionDataAcceptFailed({
                errorMessage: errorMessage ?? Message.FailedToAcceptTransaction,
              })
            )
          )
        )
      )
    )
  );
  private store = inject(Store);
  resetTransactionStoreWhenOutsideTransactionContext$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ROUTER_REQUEST),
      map<RouterRequestPayload, string | undefined>(
        path(['payload', 'event', 'url'])
      ),
      withLatestFrom(this.store.select(selectTransactionAppRoute)),
      filter(
        ([url, appRoute]) =>
          !isNil(url) && !isNil(appRoute) && !url.includes(appRoute)
      ),
      map(() => resetTransaction())
    )
  );
  loadTransactionData$ = createEffect(() =>
    this.actions$.pipe(
      ofType(transactionDataRequested),
      switchMap(() =>
        this.store.select(selectTransactionRequestParams).pipe(take(1))
      ),
      switchMap((params) =>
        this.transactionService.getTransactionData(params).pipe(
          map((transactionData) =>
            transactionDataLoadSuccessful({ transactionData })
          ),
          catchError(({ errorMessage }) =>
            of(
              transactionDataLoadFailed({
                errorMessage:
                  errorMessage ?? Message.FailedToLoadTransactionData,
              })
            )
          )
        )
      )
    )
  );
  confirmTransactionData$ = createEffect(() =>
    this.actions$.pipe(
      ofType(transactionDataConfirm),
      switchMap(() =>
        this.store
          .select(selectConfirmPath)
          .pipe(filter(complement(isNil)), take(1))
      ),
      switchMap((confirmPath: ConfirmPath) =>
        this.transactionService.confirmTransactionData(confirmPath).pipe(
          map((transactionData) =>
            transactionDataConfirmSuccessful({ transactionData })
          ),
          catchError(({ errorMessage }) =>
            of(
              transactionDataConfirmFailed({
                errorMessage:
                  errorMessage ?? Message.FailedToApproveTransaction,
              })
            )
          )
        )
      )
    )
  );
  confirmAndMakePaymentTransactionData$ = createEffect(() =>
    this.actions$.pipe(
      ofType(transactionDataPayAndConfirm),
      withLatestFrom(
        this.store
          .select(selectTransactionCreditCardDetail)
          .pipe(filter(complement(isNil)))
      ),
      switchMap(([{ paymentPath }, { singleUseToken }]) =>
        this.transactionService
          .confirmAndMakePaymentTransactionData(
            paymentPath,
            <string>singleUseToken
          )
          .pipe(
            map((transactionData) =>
              transactionDataPayAndConfirmSuccessful({
                transactionData,
              })
            ),
            catchError(({ error: { statusCode, errorMessage } }) =>
              of(
                transactionDataPayAndConfirmFailed({
                  transactionPaymentConfirmError: {
                    statusCode,
                    errorMessage: errorMessage
                      ? errorMessage
                      : Message.FailedToApproveTransaction,
                  },
                })
              )
            )
          )
      )
    )
  );
}
