import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { CommonModule } from '@angular/common';
import {
  FDDateAngularPipeFormat,
  TransactionData,
  UserProfile,
} from '@fishonline2023/webapps/model/fd2023';
import { ContactAddressDetailsComponent } from '../contact-address-details/contact-address-details.component';

@Component({
  selector: 'sv-ui-amend-customer-receipt',
  standalone: true,
  imports: [CommonModule, ContactAddressDetailsComponent],
  templateUrl: './amend-customer-receipt.component.html',
  styleUrls: [
    '../contact-address-details/contact-address-details.component.scss',
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AmendCustomerReceiptComponent {
  @Input() public transactionData!: TransactionData;
  @Input() public userProfile?: UserProfile | undefined | null;

  public FDDateAngularPipeFormat = FDDateAngularPipeFormat;
}
