import { createFeatureSelector, createSelector } from '@ngrx/store';
import {
  TRANSACTION_FEATURE_KEY,
  TransactionState,
} from './transaction.reducer';
import { isNil } from '@fishonline2023/shared/ramda';
import {
  TransactionData,
  TransactionMetadata,
} from '@fishonline2023/webapps/model/fd2023';

export const selectTransactionState = createFeatureSelector<TransactionState>(
  TRANSACTION_FEATURE_KEY
);

export const selectTransactionViewStatusGET = createSelector(
  selectTransactionState,
  (state) => state.viewStatusGET
);

export const selectTransactionViewStatusSubmit = createSelector(
  selectTransactionState,
  (state) => state.viewStatusSubmit
);

export const selectTransactionViewStatusAbandon = createSelector(
  selectTransactionState,
  (state) => state.viewStatusAbandon
);

export const selectTransactionViewStatusPayLater = createSelector(
  selectTransactionState,
  (state) => state.viewStatusPayLater
);

export const selectTransactionViewStatusCreditCardValidation = createSelector(
  selectTransactionState,
  (state) => state.viewStatusCreditCardValidation
);

export const selectTransactionCreditCardDetail = createSelector(
  selectTransactionState,
  (state) => state?.transactionCreditCardDetail
);

export const selectTransactionViewStatusConfirm = createSelector(
  selectTransactionState,
  (state) => state.viewStatusConfirm
);

export const selectTransactionViewStatusWithdraw = createSelector(
  selectTransactionState,
  (state) => state.viewStatusWithdraw
);

export const selectTransactionViewStatusReject = createSelector(
  selectTransactionState,
  (state) => state.viewStatusReject
);

export const selectTransactionViewStatusAccept = createSelector(
  selectTransactionState,
  (state) => state.viewStatusAccept
);

export const selectTransactionViewStatusConfirmAndMakePayment = createSelector(
  selectTransactionState,
  (state) => state.viewStatusConfirmAndMakePayment
);

export const selectTransactionPaymentConfirmError = createSelector(
  selectTransactionState,
  (state) => state.transactionPaymentConfirmError
);

export const selectTransactionErrorMessage = createSelector(
  selectTransactionState,
  (state) => state.errorMessage
);

export const selectTransactionDataFromGET = createSelector(
  selectTransactionState,
  (state) => state?.transactionDataFromGET
);

export const selectTransactionDataFromSubmit = createSelector(
  selectTransactionState,
  (state) => state?.transactionDataFromSubmit
);

export const selectTransactionDataFromSubmitWithReferenceFromGET =
  createSelector(selectTransactionState, (state) => {
    if (isNil(state)) {
      return undefined;
    }
    return {
      ...state.transactionDataFromSubmit,
      transactionReference: state.transactionDataFromGET?.transactionReference,
    };
  });

export const selectTransactionDataFromPayLater = createSelector(
  selectTransactionState,
  (state) => state.transactionDataFromPayLater
);

export const selectTransactionDataFromConfirm = createSelector(
  selectTransactionState,
  (state) => {
    if (isNil(state) || isNil(state.transactionDataFromConfirm)) {
      return undefined;
    }
    return {
      ...(<TransactionData>state.transactionDataFromConfirm),
      transactionReference: state.transactionDataFromGET?.transactionReference,
    };
  }
);

export const selectTransactionDataFromWithdrawOffer = createSelector(
  selectTransactionState,
  (state) => {
    if (isNil(state) || isNil(state.transactionDataFromWithdrawOffer)) {
      return undefined;
    }
    return {
      ...(<TransactionData>state.transactionDataFromWithdrawOffer),
      transactionReference: state.transactionDataFromGET?.transactionReference,
    };
  }
);

export const selectTransactionDataFromRejectOffer = createSelector(
  selectTransactionState,
  (state) => {
    if (isNil(state) || isNil(state.transactionDataFromRejectOffer)) {
      return undefined;
    }
    return {
      ...(<TransactionData>state.transactionDataFromRejectOffer),
      transactionReference: state.transactionDataFromGET?.transactionReference,
    };
  }
);

export const selectTransactionDataFromAcceptOffer = createSelector(
  selectTransactionState,
  (state) => {
    if (isNil(state) || isNil(state.transactionDataFromAcceptOffer)) {
      return undefined;
    }
    return {
      ...(<TransactionData>state.transactionDataFromAcceptOffer),
      transactionReference: state.transactionDataFromGET?.transactionReference,
    };
  }
);

export const selectTransactionType = createSelector(
  selectTransactionState,
  (state) => state.transactionType
);

export const selectConfirmPath = createSelector(
  selectTransactionType,
  (transactionType) => {
    if (isNil(transactionType)) {
      return undefined;
    }
    return TransactionMetadata[transactionType]?.confirmPath;
  }
);

export const selectTransactionRequestParams = createSelector(
  selectTransactionState,
  (state) =>
    state?.transactionRequestParamsCreated || state?.transactionRequestParams
);

export const selectTransactionAppRoute = createSelector(
  selectTransactionType,
  (transactionType) => {
    if (isNil(transactionType)) {
      return undefined;
    }
    return TransactionMetadata[transactionType].appRoute;
  }
);
