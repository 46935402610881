import { inject, Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Store } from '@ngrx/store';
import { environment } from '@env/fd/environment';
import { Observable } from 'rxjs';
import { Agent, AgentCandidate } from '@fishonline2023/webapps/model/fd2023';

@Injectable()
export class ManageAgentService {
  private httpClient = inject(HttpClient);
  private store = inject(Store);

  public getAgents(id: number): Observable<Agent[]> {
    return this.httpClient.get<Agent[]>(
      `${environment.apiUrl}/customer/agents/${id}`,
      {}
    );
  }

  public getCandidates(id: number): Observable<AgentCandidate[]> {
    return this.httpClient.get<AgentCandidate[]>(
      `${environment.apiUrl}/customer/agents/${id}/candidates`,
      {}
    );
  }

  public getNewAgent(customerId: number, agentId: number): Observable<Agent> {
    return this.httpClient.get<Agent>(
      `${environment.apiUrl}/customer/agents/${customerId}/new`,
      {
        params: { entityId: agentId },
      }
    );
  }

  public createNewAgent(customerId: number, agent: Agent): Observable<Agent> {
    return this.httpClient.post<Agent>(
      `${environment.apiUrl}/customer/agents/${customerId}?entityId=${agent.id}`,
      agent
    );
  }

  public deleteAgent(customerId: number, agentId: number): Observable<Agent> {
    return this.httpClient.delete<Agent>(
      `${environment.apiUrl}/customer/agents/${customerId}/${agentId}`
    );
  }
}
