import {
  ChangeDetectionStrategy,
  Component,
  inject,
  Input,
} from '@angular/core';
import { CommonModule } from '@angular/common';
import { ModalComponent } from '../modal/modal.component';
import { ModalId } from '@fishonline2023/shared/models';
import { ModalService } from '../modal/modal.service';
import {
  ConditionNotation,
  LicenceDetail,
} from '@fishonline2023/webapps/model/fd2023';
import { CardComponent } from '../card/card.component';

@Component({
  selector: 'sv-ui-licence-detail-condition-notation',
  standalone: true,
  imports: [CommonModule, ModalComponent, CardComponent],
  templateUrl: './licence-detail-condition-notation.component.html',
  styleUrls: ['./licence-detail-condition-notation.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class LicenceDetailConditionNotationComponent {
  private modalService = inject(ModalService);
  protected readonly ModalId = ModalId;
  @Input() public licenceDetails!: LicenceDetail;
  public selectedNotation?: ConditionNotation;

  public onNotationClick(notation: ConditionNotation): void {
    this.selectedNotation = notation;
    this.modalService.open(ModalId.LicenceDetailAnnotationModal);
  }

  public dismissModal(): void {
    this.modalService.close();
  }
}
