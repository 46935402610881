import { TransactionStatus } from './transaction.enum';

export enum Status {
  Issued = 'Issued',
  Suspended = 'Suspended',
}

export interface ConditionNotation {
  code: string;
  shortDescription: string;
  description: string;
}

export interface LicenceTransaction {
  id: number;
  type: string;
  status: TransactionStatus;
  date: string;
}

export interface LicenceHeader {
  holder: string;
  number: number;
  issuedDate: string;
  expiryDate: string;
  status: Status;
}

export interface LicenceDetail {
  header: LicenceHeader;
  conditionNotationList: ConditionNotation[];
  transactionList: LicenceTransaction[];
}
