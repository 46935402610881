import { By } from '@angular/platform-browser';
import { ComponentFixture } from '@angular/core/testing';
import { DebugElement } from '@angular/core';

export const findElementByTestId = <T>(
  fixture: ComponentFixture<T>,
  testId: string
): DebugElement => fixture.debugElement.query(By.css(`[data-cy="${testId}"]`));

export const findElementByTag = <T>(
  fixture: ComponentFixture<T>,
  tag: string
): DebugElement => fixture.debugElement.query(By.css(tag));
