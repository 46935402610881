import { Injectable } from '@angular/core';
import { equals, without } from '@fishonline2023/shared/ramda';
import { Modal, ModalId } from '@fishonline2023/shared/models';

@Injectable({ providedIn: 'root' })
export class ModalService {
  private modalList: Modal[] = [];

  public addModal(modal: Modal) {
    this.removeModal(modal);
    this.modalList.push(modal);
  }

  public removeModal(modal: Modal) {
    this.modalList = without([modal], this.modalList);
  }

  public open(id: ModalId): void {
    this.modalWithId(id)?.modal.show();
  }

  public close(): void {
    this.modalList.forEach(({ modal }) => {
      modal.hide();
    });
  }

  private modalWithId(id: ModalId) {
    return this.modalList.find(({ id: modalId }) => equals(modalId, id));
  }
}
