import { AccountProfile, Menu } from '@fishonline2023/webapps/model/fd2023';
import { createAction, props } from '@ngrx/store';

export const loadAccountProfile = createAction(
  `[Home Page] Load Account Profile`
);

export const loadAccountProfileSuccess = createAction(
  `[Home Page] Load Account Profile Success`,
  props<{ accountProfile: AccountProfile }>()
);

export const loadAccountProfileFailure = createAction(
  `[Home Page] Load Account Profile Failure`,
  props<{ errorMessage: string }>()
);

export const menuListUpdated = createAction(
  `[Home Page] Menu List Updated`,
  props<{ menuList: Menu[] }>()
);
