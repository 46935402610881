import { Injectable } from '@angular/core';
import { Subject, timer } from 'rxjs';
import {
  ToastColor,
  ToastConfig,
  ToastPosition,
} from '@fishonline2023/webapps/model/fd2023';
import { isNil } from '@fishonline2023/shared/ramda';

@Injectable({ providedIn: 'root' })
export class ToastService {
  public message$ = new Subject<string | undefined>();
  public config$ = new Subject<ToastConfig>();

  public show(
    message: string,
    config: ToastConfig = {
      shouldManuallyClose: false,
      duration: 3000,
      position: ToastPosition.TopRight,
      color: ToastColor.Light,
    }
  ) {
    const { duration } = config;
    this.message$?.next(message);
    this.config$.next(config);
    if (isNil(duration)) {
      return;
    }
    timer(duration).subscribe(() => {
      this.dismiss();
    });
  }

  public dismiss() {
    this.message$?.next(undefined);
  }

  public showError = (
    message: string,
    config: ToastConfig = {
      shouldManuallyClose: true,
      color: ToastColor.Danger,
      position: ToastPosition.TopRight,
    }
  ) => this.show(message, config);

  public showWarning = (
    message: string,
    config: ToastConfig = {
      shouldManuallyClose: false,
      duration: 5000,
      color: ToastColor.Warning,
      position: ToastPosition.TopRight,
    }
  ) => this.show(message, config);

  public showSuccess = (
    message: string,
    config: ToastConfig = {
      shouldManuallyClose: false,
      duration: 3000,
      color: ToastColor.Success,
      position: ToastPosition.TopRight,
    }
  ) => this.show(message, config);
}
