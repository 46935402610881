import { Actions, createEffect, ofType } from '@ngrx/effects';
import { inject } from '@angular/core';
import { catchError, filter, map, of, switchMap, withLatestFrom } from 'rxjs';
import {
  deleteAgent,
  deleteAgentFailure,
  deleteAgentSuccess,
  loadAgentCandidates,
  loadAgentCandidatesFailure,
  loadAgentCandidatesSuccess,
  loadAgents,
  loadAgentsFailure,
  loadAgentsSuccess,
  loadNewAgent,
  loadNewAgentFailure,
  loadNewAgentSuccess,
  saveAgent,
  saveAgentFailure,
  saveAgentSuccess,
} from './manage-agent.actions';
import { ManageAgentService } from './manage-agent.service';
import { complement, isNil } from '@fishonline2023/shared/ramda';
import { getActAsCustomer } from '@fishonline2023/webapps/user-portal/fd2023/store/user-profile';
import { Store } from '@ngrx/store';
import { ToastService } from '@fishonline2023/webapps/shared/ui/base-components';
import { Message } from '@fishonline2023/webapps/model/fd2023';

export class ManageAgentEffects {
  private actions$ = inject(Actions);
  private store = inject(Store);
  private manageAgentService = inject(ManageAgentService);
  public loadAgents$ = createEffect(() =>
    this.actions$.pipe(
      ofType(loadAgents, saveAgentSuccess, deleteAgentSuccess),
      withLatestFrom(
        this.store.select(getActAsCustomer).pipe(filter(complement(isNil)))
      ),
      switchMap(([, customer]) =>
        this.manageAgentService.getAgents(customer.id).pipe(
          map((agentList) => loadAgentsSuccess({ agentList })),
          catchError((error) => of(loadAgentsFailure({ errorMessage: error })))
        )
      )
    )
  );
  public loadAgentCandidates$ = createEffect(() =>
    this.actions$.pipe(
      ofType(loadAgentCandidates),
      withLatestFrom(
        this.store.select(getActAsCustomer).pipe(filter(complement(isNil)))
      ),
      switchMap(([, customer]) =>
        this.manageAgentService.getCandidates(customer.id).pipe(
          map((agentCandidatesList) =>
            loadAgentCandidatesSuccess({ agentCandidatesList })
          ),
          catchError((error) =>
            of(loadAgentCandidatesFailure({ errorMessage: error }))
          )
        )
      )
    )
  );
  public loadNewAgent$ = createEffect(() =>
    this.actions$.pipe(
      ofType(loadNewAgent),
      withLatestFrom(
        this.store.select(getActAsCustomer).pipe(filter(complement(isNil)))
      ),
      switchMap(([{ agentId }, customer]) =>
        this.manageAgentService.getNewAgent(customer.id, agentId).pipe(
          map((agent) => loadNewAgentSuccess({ agent })),
          catchError((error) =>
            of(loadNewAgentFailure({ errorMessage: error }))
          )
        )
      )
    )
  );
  private toastService = inject(ToastService);
  public saveAgent$ = createEffect(() =>
    this.actions$.pipe(
      ofType(saveAgent),
      withLatestFrom(
        this.store.select(getActAsCustomer).pipe(filter(complement(isNil)))
      ),
      switchMap(([{ agent, saveEventType }, customer]) =>
        this.manageAgentService.createNewAgent(customer.id, agent).pipe(
          map((agent) => {
            this.toastService.showSuccess(Message.SavingAgentSuccess);
            return saveAgentSuccess({ agent, saveEventType });
          }),
          catchError((error) => of(saveAgentFailure({ errorMessage: error })))
        )
      )
    )
  );

  public deleteAgent$ = createEffect(() =>
    this.actions$.pipe(
      ofType(deleteAgent),
      withLatestFrom(
        this.store.select(getActAsCustomer).pipe(filter(complement(isNil)))
      ),
      switchMap(([{ agent }, customer]) =>
        this.manageAgentService.deleteAgent(customer.id, agent.id).pipe(
          map(() => {
            this.toastService.showSuccess(Message.DeletingAgentSuccess);
            return deleteAgentSuccess({ agent });
          }),
          catchError((error) => of(deleteAgentFailure({ errorMessage: error })))
        )
      )
    )
  );
}
