<div class="round-secondary-border-box mb-3">
  <sv-ui-full-width-item-list
    [itemList]="itemList"
  ></sv-ui-full-width-item-list>
</div>
<div class="mb-3">
  <sv-ui-transfer-fishing-business-components-offer-detail
    [isAcceptOffer]="true"
    [transactionData]="transactionData"
  ></sv-ui-transfer-fishing-business-components-offer-detail>
</div>
