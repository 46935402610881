import {
  EventTypeName,
  SearchFieldDataType,
  SearchResultField,
  SearchResultItem,
} from '@fishonline2023/shared/models';
import * as dayjs from 'dayjs';
import {
  FDDateDayJSFormat,
  FDDateTimeDayJSFormat,
} from '@fishonline2023/webapps/model/fd2023';
import { EventType } from '@fishonline2023/webapps/shared/feature/appsync';
import { isNil } from '@fishonline2023/shared/ramda';

export const transformSearchResult = (
  field: SearchResultField,
  result: SearchResultItem
) => {
  const fieldValue = field.getValue(result);
  switch (field.dataType) {
    case SearchFieldDataType.Number:
      return fieldValue;
    case SearchFieldDataType.DateTime:
      return dayjs(fieldValue as string).format(FDDateTimeDayJSFormat);
    case SearchFieldDataType.Date:
      return dayjs(fieldValue as string).format(FDDateDayJSFormat);
    case SearchFieldDataType.String:
      return fieldValue;
    case SearchFieldDataType.EventType:
      return EventTypeName[fieldValue as EventType];
    case SearchFieldDataType.Currency:
      return !isNil(fieldValue)
        ? `$${(fieldValue as number).toFixed(2)}`
        : null;
  }
};
