<div class="error-container">
  <sv-ui-error
    (buttonAction)="loadAndSubscribeToDataLoaderDataStatus()"
    *ngIf="viewStatus === ViewStatus.Failure"
    [errorMessage]="errorMessage!"
    buttonLabel="Try again"
  >
  </sv-ui-error>
  <sv-ui-loading
    *ngIf="viewStatus === ViewStatus.Loading && DataLoaderMetadata"
    [message]="DataLoaderMetadata.loadingMessage"
  ></sv-ui-loading>
  <ng-container [ngSwitch]="dataLoaderType">
    <sv-ui-licence-details
      (activityLogTriggered)="dispatchActivityLogTriggered($event)"
      *ngSwitchCase="DataLoaderType.LicenceDetails"
      [dataLoaderData$]="FDDataLoaderData$"
    ></sv-ui-licence-details>
    <sv-ui-print-prawn-ballot
      (activityLogTriggered)="dispatchActivityLogTriggered($event)"
      *ngSwitchCase="DataLoaderType.PrawnBallot"
      [dataLoaderData$]="dataLoaderData$"
    ></sv-ui-print-prawn-ballot>
    <sv-ui-fishing-business-history
      (activityLogTriggered)="dispatchActivityLogTriggered($event)"
      *ngSwitchCase="DataLoaderType.FishingBusinessHistory"
      [dataLoaderData$]="FDDataLoaderData$"
      [queryParams]="queryParams"
    ></sv-ui-fishing-business-history>
    <sv-ui-account-summary
      (activityLogTriggered)="dispatchActivityLogTriggered($event)"
      *ngSwitchCase="DataLoaderType.AccountSummary"
      [dataLoaderData$]="FDDataLoaderData$"
    ></sv-ui-account-summary>
    <sv-ui-shareholder-report
      (activityLogTriggered)="dispatchActivityLogTriggered($event)"
      *ngSwitchCase="DataLoaderType.ShareholderReport"
      [dataLoaderData$]="FDDataLoaderData$"
    ></sv-ui-shareholder-report>
    <sv-ui-fishing-business-report
      (activityLogTriggered)="dispatchActivityLogTriggered($event)"
      *ngSwitchCase="DataLoaderType.FishingBusinessReport"
      [dataLoaderData$]="FDDataLoaderData$"
    ></sv-ui-fishing-business-report>
    <sv-ui-my-personal-authorisations
      (activityLogTriggered)="dispatchActivityLogTriggered($event)"
      *ngSwitchCase="DataLoaderType.MyPersonalAuthorisations"
      [dataLoaderData$]="FDDataLoaderData$"
    ></sv-ui-my-personal-authorisations>
    <sv-ui-my-fishing-business-authorisations
      (activityLogTriggered)="dispatchActivityLogTriggered($event)"
      *ngSwitchCase="DataLoaderType.MyFishingBusinessAuthorisations"
      [dataLoaderData$]="FDDataLoaderData$"
    ></sv-ui-my-fishing-business-authorisations>
    <sv-ui-for-sale-and-trade-view-edit
      (activityLogTriggered)="dispatchActivityLogTriggered($event)"
      *ngSwitchCase="DataLoaderType.ForSaleAndTradeDetail"
      [dataLoaderData$]="FDDataLoaderData$"
    ></sv-ui-for-sale-and-trade-view-edit>
    <sv-ui-recently-completed-transaction-receipt
      *ngSwitchCase="DataLoaderType.MyReceiptsDetail"
      [dataLoaderData$]="FDDataLoaderData$"
    ></sv-ui-recently-completed-transaction-receipt>
    <sv-ui-customer-detail
      *ngSwitchCase="DataLoaderType.CustomerDetail"
      [dataLoaderData$]="FADataLoaderData$"
    ></sv-ui-customer-detail>
  </ng-container>
</div>
