<div class="form-check">
  <input
    *ngIf="inputField.isReadOnly; else editable"
    [attr.aria-label]="inputField.formControlName"
    [attr.data-cy]="inputField.formControlName"
    [checked]="form.value"
    [disabled]="true"
    class="form-check-input"
    type="checkbox"
  />
  <ng-template #editable>
    <input
      [attr.aria-label]="inputField.formControlName"
      [attr.data-cy]="inputField.formControlName"
      [formControl]="form"
      class="form-check-input"
      type="checkbox"
    />
  </ng-template>
</div>
