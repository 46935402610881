import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormGroup, ReactiveFormsModule } from '@angular/forms';
import { TransferDetailForm } from '@fishonline2023/webapps/model/fd2023';
import { InputField, InputType } from '@fishonline2023/shared/models';
import { InputFieldComponent } from '@fishonline2023/webapps/shared/ui/input-field';

@Component({
  selector: 'sv-ui-transfer-detail-form-control',
  standalone: true,
  imports: [CommonModule, ReactiveFormsModule, InputFieldComponent],
  templateUrl: './transfer-detail-form-control.component.html',
  styleUrls: ['./transfer-detail-form-control.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TransferDetailFormControlComponent {
  @Input() public label!: string;
  @Input() public description!: string;
  @Input() public maximumQty!: number;
  @Input() public isReadOnly!: boolean;
  @Input() public transferDetailFormGroup?: FormGroup<TransferDetailForm>;
  protected readonly Number = Number;

  protected get qtyToTransferInputField(): InputField {
    return {
      formControlName: 'quantity',
      label: 'Qty to Transfer',
      type: InputType.Number,
      min: 0,
      max: this.maximumQty,
      isReadOnly: this.isReadOnly,
    };
  }

  protected totalSellPriceInputField = (isReadOnly: boolean): InputField => ({
    formControlName: 'totalSellPrice',
    label: 'Total Sell Price',
    type: InputType.Price,
    min: 0,
    isReadOnly,
  });
}
