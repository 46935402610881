import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { CommonModule } from '@angular/common';
import {
  TransactionData,
  TransactionType,
  TransferComponentReference,
  TransferComponentsForm,
  TransferComponentsTransactionDetail,
  TransferFishingBusinessComponentsTransactionReference,
  TransferFishingBusinessForm,
  TransferQuotaReference,
} from '@fishonline2023/webapps/model/fd2023';
import { FormGroup } from '@angular/forms';
import { equals, without } from '@fishonline2023/shared/ramda';
import { TransferDetailFormControlComponent } from '../transfer-detail-form-control/transfer-detail-form-control.component';
import { TransferDetailFormTotalComponent } from '../transfer-detail-form-total/transfer-detail-form-total.component';

@Component({
  selector: 'sv-ui-transfer-form-group',
  standalone: true,
  imports: [
    CommonModule,
    TransferDetailFormControlComponent,
    TransferDetailFormTotalComponent,
  ],
  templateUrl: './transfer-form-group.component.html',
  styleUrls: ['./transfer-form-group.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TransferFormGroupComponent {
  @Input() public transactionData!: TransactionData;
  @Input()
  public transferFormGroup!:
    | FormGroup<TransferFishingBusinessForm>
    | FormGroup<TransferComponentsForm>;

  protected get toFishingBusinessOwnerFullName() {
    return (
      this.transactionData
        .transactionDetail as TransferComponentsTransactionDetail
    ).to.owner.fullName;
  }

  protected get isQtyReadOnly() {
    return (
      this.transactionData.transactionHeader.type ===
      TransactionType.TransferFishingBusiness
    );
  }

  protected get transactionReference() {
    return <TransferFishingBusinessComponentsTransactionReference>(
      this.transactionData.transactionReference
    );
  }

  protected get transferableComponents() {
    return this.transactionReference.componentList.filter(
      ({ isTransferable }) => isTransferable
    );
  }

  protected get nonTransferableComponents() {
    return without(
      this.transferableComponents,
      this.transactionReference.componentList
    );
  }

  protected quotaDescription(quota: TransferQuotaReference) {
    if (equals(quota.amount, 0)) {
      return 'No quota left to transfer';
    }
    return `${quota.amount} ${quota.unit}`;
  }

  protected componentDescription(component: TransferComponentReference) {
    return `${component.availableAmount} of ${component.totalAmount} available`;
  }
}
