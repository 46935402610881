import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
} from '@angular/core';
import { CommonModule } from '@angular/common';
import {
  FormControl,
  FormGroup,
  FormsModule,
  ReactiveFormsModule,
  Validators,
} from '@angular/forms';
import { filter, Subject, takeUntil } from 'rxjs';
import {
  FDDateAngularPipeFormat,
  RenewLicenceForm,
  RenewLicenceTransactionDetail,
  RenewLicenceTransactionReference,
  TransactionData,
} from '@fishonline2023/webapps/model/fd2023';
import {
  FullWidthItemListComponent,
  LicenceDetailConditionNotationComponent,
  LicenceDetailInfoComponent,
} from '@fishonline2023/webapps/shared/ui/base-components';
import { complement, equals, isNil } from '@fishonline2023/shared/ramda';
import { getRenewalToDate } from '../utils/get-renewal-to-date';

@Component({
  selector: 'sv-ui-renew-licence',
  standalone: true,
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    FullWidthItemListComponent,
    LicenceDetailInfoComponent,
    LicenceDetailConditionNotationComponent,
  ],
  templateUrl: './renew-licence.component.html',
  styleUrls: ['./renew-licence.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class RenewLicenceComponent implements OnInit, OnDestroy {
  @Input() public transactionData!: TransactionData;
  @Output() public syncTransactionForm = new EventEmitter<FormGroup>();
  public FDDateAngularPipeFormat = FDDateAngularPipeFormat;
  public getRenewalToDate = getRenewalToDate;
  public transactionReference!: RenewLicenceTransactionReference;
  private destroy$ = new Subject<void>();
  public renewalTermYearControl = new FormControl<number | undefined>(
    undefined
  );

  public renewLicenceForm = new FormGroup<RenewLicenceForm>({
    renewalTerm: new FormControl(undefined, {
      validators: Validators.required,
      nonNullable: true,
    }),
  });

  public ngOnInit() {
    this.syncTransactionForm.emit(this.renewLicenceForm);
    this.transactionReference = this.transactionData
      .transactionReference as RenewLicenceTransactionReference;
    this.updateFormValueFromTransactionData();
    this.renewalTermYearControl.valueChanges
      .pipe(filter(complement(isNil)), takeUntil(this.destroy$))
      .subscribe((year: string) => {
        this.renewLicenceForm.patchValue({
          renewalTerm: this.transactionReference.availableTermList.find(
            ({ renewalTermYear }) => equals(renewalTermYear, Number(year))
          ),
        });
      });
  }

  private updateFormValueFromTransactionData() {
    const renewalTerm = (
      this.transactionData.transactionDetail as RenewLicenceTransactionDetail
    )?.renewalTerm;
    this.renewLicenceForm.patchValue({
      renewalTerm,
    });
    this.renewalTermYearControl.patchValue(renewalTerm?.renewalTermYear, {
      emitEvent: false,
    });
  }

  public ngOnDestroy() {
    this.destroy$.next();
    this.destroy$.complete();
  }
}
