import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  Output,
} from '@angular/core';
import { CommonModule } from '@angular/common';
import {
  ErrorComponent,
  LoadingComponent,
} from '@fishonline2023/webapps/shared/ui/base-components';

@Component({
  selector: 'sv-ui-system-status',
  standalone: true,
  imports: [CommonModule, ErrorComponent, LoadingComponent],
  templateUrl: './system-status.component.html',
  styleUrls: ['./system-status.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SystemStatusComponent {
  @Input() hasError = false;
  @Input() errorMessage?: string;
  @Input() buttonLabel?: string;
  @Output() buttonAction: EventEmitter<void> = new EventEmitter<void>();
  @Input() showLoading = false;
  @Input() message = '';

  onButtonClick(): void {
    this.buttonAction?.emit();
  }
}
