export enum RouteData {
  TransactionType = 'transactionType',
  SearchType = 'searchType',
  Channel = 'Channel',
  DataLoaderType = 'DataLoaderType',
  BreadCrumb = 'breadcrumb',
}

export enum FARoute {
  SystemStatus = 'system-status',
  Login = 'login',
  UserProfile = 'user-profile',
  Home = 'home',
  AmendCustomer = 'amend-customer',
  CreateCustomer = 'create-customer',
  UserActivityLog = 'account-activity',
  CustomerDetailSearch = 'customer-detail-search',
}

export enum FDRoute {
  Disclaimer = 'disclaimer',
  TermsConditions = 'terms-conditions',
  SystemStatus = 'system-status',
  Login = 'login',
  UserProfile = 'user-profile',
  Home = 'home',
  AccountActivity = 'account-activity',
  AmendCustomer = 'amend-customer',
  NominateRevokeFisher = 'nominate-revoke',
  RenewLicence = 'renew-licence',
  LicenceDetails = 'licence-details',
  PrintPrawnBallot = 'print-prawn-ballot',
  FishingBusinessHistory = 'fishing-business-history',
  AccountSummary = 'account-summary',
  PrintShareExtract = 'print-share-extract',
  MyPersonalAuthorisations = 'my-personal-authorisations',
  MyFishingBusinessAuthorisations = 'my-fishing-business-authorisations',
  TransferFishingBusiness = 'transfer-fishing-business',
  TransferComponents = 'transfer-components',
  TransferQuota = 'quota-transfer',
  ForSaleAndTradeSearch = 'for-sale-trade-search',
  QuotaHistory = 'quota-transactions',
  MyReceipts = 'my-receipts',
  ManageAgents = 'manage-agents',
  NewAd = 'new-ad',
}
