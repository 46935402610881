import { Component, Input } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormArray, FormGroup, ReactiveFormsModule } from '@angular/forms';
import { TransferDetailForm } from '@fishonline2023/webapps/model/fd2023';
import { sum } from '@fishonline2023/shared/ramda';

@Component({
  selector: 'sv-ui-transfer-detail-form-total',
  standalone: true,
  imports: [CommonModule, ReactiveFormsModule],
  templateUrl: './transfer-detail-form-total.component.html',
  styleUrls: ['./transfer-detail-form-total.component.scss'],
})
export class TransferDetailFormTotalComponent {
  @Input() public formArray!: FormArray<FormGroup<TransferDetailForm>>;
  @Input() public withoutTotalQty = false;

  protected get totalQty() {
    return sum(
      this.formArray.controls.map((formGroup) =>
        Number(formGroup.controls.quantity.value)
      )
    );
  }

  protected get totalSellPrice() {
    return sum(
      this.formArray.controls.map((formGroup) =>
        Number(formGroup.controls.totalSellPrice.value)
      )
    );
  }
}
