<div
  *ngIf="accountSummary$ | async as accountSummary"
  class="account-summary-container mt-4"
>
  <h2 class="mb-4">Account Summary</h2>
  <sv-ui-dual-column-items-list-component
    [itemList]="customerSummaryItemsList"
  ></sv-ui-dual-column-items-list-component>
  <!-- Commercial fishing licence -->
  <div
    class="round-secondary-border-box mb-4"
    *ngIf="accountSummary?.licenceSummary"
  >
    <h4>Commercial Fishing Licence</h4>
    <sv-ui-dual-column-items-list-component
      [itemList]="licenceSummaryItemsList"
    ></sv-ui-dual-column-items-list-component>
  </div>
  <!-- Fishing Businesses -->
  <div
    class="round-secondary-border-box mb-4"
    *ngIf="accountSummary?.fishingBusinessList"
  >
    <h4>Fishing Business(es)</h4>
    <div class="row mt-1">
      <div class="col">
        <table class="table align-middle table-striped mt-3">
          <thead>
            <tr>
              <th scope="col">FB Number</th>
              <th scope="col">Type</th>
            </tr>
          </thead>
          <tbody class="table-group-divider">
            <tr
              *ngFor="
                let fishingBusiness of accountSummary?.fishingBusinessList
              "
            >
              <td>{{ fishingBusiness.id }}</td>
              <td>{{ fishingBusiness.group }}</td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>
  <!-- Demerit points -->
  <div
    class="round-secondary-border-box mb-4"
    *ngIf="demeritPointsList.length > 0"
  >
    <h4>Demerit Points</h4>
    <div class="row mt-1">
      <div class="col">
        <table class="table align-middle table-striped mt-3">
          <thead>
            <tr>
              <th scope="col">Date</th>
              <th scope="col">Offence</th>
              <th scope="col">Points</th>
            </tr>
          </thead>
          <tbody class="table-group-divider">
            <tr *ngFor="let demeritPoint of demeritPointsList">
              <td>
                {{ demeritPoint.issueDate | date : FDDateAngularPipeFormat }}
              </td>
              <td>{{ demeritPoint.offence }}</td>
              <td>{{ demeritPoint.points }}</td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>
</div>
