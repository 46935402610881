<div class="input-group">
  <span class="input-group-text">$</span>
  <input
    *ngIf="inputField.isReadOnly; else editable"
    [attr.aria-label]="inputField.formControlName"
    [attr.data-cy]="inputField.formControlName"
    [disabled]="true"
    [placeholder]="inputField.placeholder || ''"
    [value]="form.value"
    class="form-control form-control-lg"
    type="number"
  />
  <ng-template #editable>
    <input
      (blur)="formatPrice()"
      [attr.aria-label]="inputField.formControlName"
      [attr.data-cy]="inputField.formControlName"
      [formControl]="form"
      [min]="inputField.min || 0"
      [placeholder]="inputField.placeholder || ''"
      class="form-control form-control-lg"
      maxlength="{{ inputField.maxLength }}"
      step="0.01"
      svUiTwoDecimals
      type="number"
    />
  </ng-template>
</div>
