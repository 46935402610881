import {
  ChangeDetectionStrategy,
  Component,
  OnDestroy,
  OnInit,
} from '@angular/core';
import { CommonModule } from '@angular/common';
import { BaseAuthorisationsDirective } from '@fishonline2023/webapps/authorised-fishers/fd2023/ui/base-authorisations';
import { Observable, Subject, take, takeUntil } from 'rxjs';
import {
  Authorisation,
  FishingBusinessAuthorisations,
} from '@fishonline2023/webapps/model/fd2023';
import { equals, isNil, path } from '@fishonline2023/shared/ramda';
import { FormControl, ReactiveFormsModule } from '@angular/forms';
import { AuthorisationsListComponent } from '@fishonline2023/webapps/shared/ui/base-components';
import { EventType } from '@fishonline2023/webapps/shared/feature/appsync';
import { transformKeysToTitleCase } from '@fishonline2023/shared/utils';
import { FishingBusiness } from '@fishonline2023/shared/models';

@Component({
  selector: 'sv-ui-my-fishing-business-authorisations',
  standalone: true,
  imports: [CommonModule, ReactiveFormsModule, AuthorisationsListComponent],
  templateUrl: './my-fishing-business-authorisations.component.html',
  styleUrls: ['./my-fishing-business-authorisations.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class MyFishingBusinessAuthorisationsComponent
  extends BaseAuthorisationsDirective
  implements OnInit, OnDestroy
{
  public ownerAuthorisations!: FishingBusinessAuthorisations[];
  public fishingBusinessList: string[] = [];
  public override authorisations: Authorisation[] = [];
  public fishingBusinessId: FormControl = new FormControl('', {
    nonNullable: true,
  });
  private destroy$ = new Subject<void>();

  protected get myFishingBusinessAuthorisations$() {
    return this.dataLoaderData$ as Observable<FishingBusinessAuthorisations[]>;
  }

  public override ngOnInit(): void {
    this.myFishingBusinessAuthorisations$
      .pipe(take(1))
      .subscribe((ownerAuthorisations: FishingBusinessAuthorisations[]) => {
        this.ownerAuthorisations = ownerAuthorisations;
        this.fishingBusinessList = this.getFishingBusinessList();
        if (this.fishingBusinessList.length === 1) {
          this.fishingBusinessId.patchValue(this.fishingBusinessList[0]);
        }
        this.cdr.detectChanges();
      });
    this.handleFishingBusinessSelectionChanges();
  }

  public ngOnDestroy() {
    this.destroy$.next();
    this.destroy$.complete();
  }

  private getFishingBusinessList(): string[] {
    this.ownerAuthorisations?.forEach((ownerAuthorisation) => {
      const fishingBusiness: FishingBusiness = path(
        ['fishingBusinessOwnerDto'],
        ownerAuthorisation
      );
      this.fishingBusinessList.push(
        `${fishingBusiness.id} - ${fishingBusiness.owner.fullName} (${fishingBusiness.owner.id})`
      );
    });
    return this.fishingBusinessList;
  }

  private handleFishingBusinessSelectionChanges(): void {
    this.fishingBusinessId.valueChanges
      .pipe(takeUntil(this.destroy$))
      .subscribe((fishingBusinessValue: string) => {
        const fishingBusinessId = fishingBusinessValue
          .substring(0, fishingBusinessValue.indexOf('-'))
          .trim();
        this.authorisations =
          this.getAuthorisationsForFishingBusiness(fishingBusinessId);
        this.triggerActivityLog(fishingBusinessId);
        this.cdr.detectChanges();
      });
  }

  private getAuthorisationsForFishingBusiness(
    fishingBusinessId: string
  ): Authorisation[] {
    const fishingBusinessAuthorisation = this.ownerAuthorisations?.find(
      (ownerAuthorisation) => {
        const fishingBusiness: FishingBusiness = path(
          ['fishingBusinessOwnerDto'],
          ownerAuthorisation
        );
        return equals(+fishingBusinessId, fishingBusiness.id);
      }
    );
    if (!isNil(fishingBusinessAuthorisation)) {
      return fishingBusinessAuthorisation.authorisations;
    }
    return [];
  }

  private triggerActivityLog(fishingBusinessId: string): void {
    this.activityLogTriggered.emit({
      eventType: EventType.ViewMyFishingBusinessAuthorisations,
      detail: JSON.stringify(
        transformKeysToTitleCase({
          fishingBusinessNo: fishingBusinessId,
        })
      ),
    });
  }
}
