<div class="row mt-1">
  <div class="col">
    <table class="table align-middle table-striped mt-3">
      <thead>
        <tr>
          <th *ngFor="let field of tableFields">
            <div class="table-header-container">
              {{ field.label }}
              <sv-ui-table-sorting-button
                *ngIf="field.sortable"
                [column]="field.property"
                [columnSorted]="columnSorted$ | async"
                (sortButtonClicked)="
                  handleAuthorisationsSort({
                    sortOrder: $event,
                    authorisationResultField: field
                  })
                "
              ></sv-ui-table-sorting-button>
            </div>
          </th>
        </tr>
      </thead>
      <tbody class="table-group-divider">
        <tr *ngFor="let result of authorisations">
          <td *ngFor="let field of tableFields">
            {{ transformTableResult(field, result) }}
          </td>
        </tr>
      </tbody>
    </table>
  </div>
</div>
