import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { EffectsModule } from '@ngrx/effects';
import { BreadcrumbEffects } from './breadcrumb.effects';
import { StoreModule } from '@ngrx/store';
import {
  BREADCRUMB_FEATURE_KEY,
  breadcrumbReducer,
} from './breadcrumb.reducer';

@NgModule({
  imports: [
    CommonModule,
    StoreModule.forFeature(BREADCRUMB_FEATURE_KEY, breadcrumbReducer),
    EffectsModule.forFeature([BreadcrumbEffects]),
  ],
})
export class BreadcrumbModule {}
