<div class="round-secondary-border-box licence-detail-container mb-3">
  <sv-ui-licence-detail-info
    [licenceHeader]="transactionReference.licenceDetails.header"
  ></sv-ui-licence-detail-info>
</div>
<div class="round-secondary-border-box mb-3">
  <h5>Conditions/notations</h5>
  <sv-ui-licence-detail-condition-notation
    [licenceDetails]="transactionReference.licenceDetails"
  ></sv-ui-licence-detail-condition-notation>
</div>
<div class="round-secondary-border-box renewal-detail-container mb-3">
  <h5>Renewal details</h5>
  <div class="w-50" data-cy="terms">
    <label class="col-form-label">Term</label>
    <select
      class="form-select form-select-lg mb-3"
      aria-label=".form-select-lg"
      [formControl]="renewalTermYearControl"
    >
      <option
        *ngFor="let term of transactionReference.availableTermList"
        [value]="term.renewalTermYear"
      >
        {{ term.renewalTermYear }} years
      </option>
    </select>
  </div>
  <div class="row" *ngIf="renewLicenceForm.value.renewalTerm as renewalTerm">
    <div class="col-md-6 col-sm-12">
      <h6>New licence valid</h6>
      <p>
        {{ renewalTerm.startDate | date : FDDateAngularPipeFormat }} to
        {{ getRenewalToDate(renewalTerm) }}
      </p>
    </div>
    <div class="col-md-6 col-sm-12">
      <h6>Renewal fee</h6>
      <p>{{ renewalTerm.renewalTermFee | currency }}</p>
    </div>
  </div>
</div>
