import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  Output,
} from '@angular/core';
import { CommonModule } from '@angular/common';
import { ButtonLinkComponent } from '../button-link/button-link.component';
import { Button } from '@fishonline2023/webapps/model/fd2023';
import { RouterLink } from '@angular/router';

@Component({
  selector: 'sv-ui-header',
  standalone: true,
  imports: [CommonModule, ButtonLinkComponent, RouterLink],
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class HeaderComponent {
  @Input() name = '...';
  @Input() agentCustomerName?: string = undefined;
  @Input() logo =
    'https://www.nsw.gov.au/themes/custom/dcs_theme/favicons/sets/standard/logo.svg';
  @Input() buttons: Button[] = [];
  @Output() buttonClicked: EventEmitter<number> = new EventEmitter();

  onButtonClick(buttonId: number) {
    this.buttonClicked.emit(buttonId);
  }
}
