import { createFeatureSelector, createSelector } from '@ngrx/store';
import {
  TRANSACTION_FEATURE_KEY,
  TransactionState,
} from './transaction.reducer';
import {
  TransactionMetadata,
  TransactionType,
} from '@fishonline2023/webapps/model/fa2023';
import { isNil } from '@fishonline2023/shared/ramda';

export const selectTransactionState = createFeatureSelector<TransactionState>(
  TRANSACTION_FEATURE_KEY
);

export const selectPendingTransactionId = createSelector(
  selectTransactionState,
  (state: TransactionState) => state.pendingTransactionId
);

export const selectTransactionType = createSelector(
  selectTransactionState,
  (state: TransactionState) => state.transactionType
);

export const selectTransactionRequestParams = createSelector(
  selectTransactionState,
  (state: TransactionState) => state.transactionRequestParams
);

export const selectTransactionViewEntityData = createSelector(
  selectTransactionState,
  (state) => state.viewStatusEntityData
);

export const selectTransactionViewStatusInit = createSelector(
  selectTransactionState,
  (state) => state.viewStatusInit
);

export const selectTransactionViewStatusSubmit = createSelector(
  selectTransactionState,
  (state) => state.viewStatusSubmit
);

export const selectTransactionViewStatusApprove = createSelector(
  selectTransactionState,
  (state) => state.viewStatusApprove
);

export const selectTransactionViewStatusNotApprove = createSelector(
  selectTransactionState,
  (state) => state.viewStatusNotApprove
);

export const selectTransactionViewStatusDelete = createSelector(
  selectTransactionState,
  (state) => state.viewStatusDelete
);

export const selectTransactionEntityData = createSelector(
  selectTransactionState,
  (state) => state.transactionEntityData
);

export const selectTransactionDataForEdit = createSelector(
  selectTransactionState,
  (state) => state.transactionDataForEdit
);

export const selectTransactionDataFromSubmit = createSelector(
  selectTransactionState,
  (state) => state.transactionDataFromSubmit
);

export const selectTransactionDataFromApprove = createSelector(
  selectTransactionState,
  (state) => state.transactionDataFromApprove
);

export const selectTransactionDataFromNotApprove = createSelector(
  selectTransactionState,
  (state) => state.transactionDataFromNotApprove
);

export const selectTransactionDataFromDelete = createSelector(
  selectTransactionState,
  (state) => state.transactionDataFromDelete
);

export const selectAPIBaseRoute = createSelector(
  selectTransactionType,
  (transactionType: TransactionType | undefined) =>
    transactionType ? TransactionMetadata[transactionType] : undefined
);

export const selectSaveActionFromButtonType = createSelector(
  selectTransactionState,
  (state) => state.submitActionFromButtonType
);

export const selectTransactionErrorMessage = createSelector(
  selectTransactionState,
  (state) => state.errorMessage
);

export const selectTransactionAppRoute = createSelector(
  selectTransactionType,
  (transactionType) => {
    if (isNil(transactionType)) {
      return undefined;
    }
    return TransactionMetadata[transactionType]?.appRoute;
  }
);
