import { Pipe, PipeTransform } from '@angular/core';
import { Purpose } from '@fishonline2023/webapps/model/fd2023';

@Pipe({
  name: 'enumToString',
  standalone: true,
})
export class EnumToStringPipe implements PipeTransform {
  transform(value: Purpose): string {
    const actionListNameMap: { [key in Purpose]: string } = {
      [Purpose.Sale]: 'For Sale',
      [Purpose.Buy]: 'Wanted to Buy',
    };
    return actionListNameMap[value];
  }
}
