import { createAction, props } from '@ngrx/store';
import {
  Agent,
  AgentCandidate,
  ManageAgentsButtonAction,
} from '@fishonline2023/webapps/model/fd2023';

export const loadAgents = createAction('[ManageAgent] Load Agents');

export const loadAgentsSuccess = createAction(
  '[ManageAgent] Load Agents Success',
  props<{ agentList: Agent[] }>()
);

export const loadAgentsFailure = createAction(
  '[ManageAgent] Load Agents Failure',
  props<{ errorMessage: string }>()
);

export const loadAgentCandidates = createAction(
  '[ManageAgent] Load Agent Candidates'
);

export const loadAgentCandidatesSuccess = createAction(
  '[ManageAgent] Load Agent Candidates Success',
  props<{ agentCandidatesList: AgentCandidate[] }>()
);

export const loadAgentCandidatesFailure = createAction(
  '[ManageAgent] Load Agent Candidates Failure',
  props<{ errorMessage: string }>()
);

export const loadNewAgent = createAction(
  '[ManageAgent] Load New Agent',
  props<{ agentId: number }>()
);

export const loadNewAgentSuccess = createAction(
  '[ManageAgent] Load New Agent Success',
  props<{ agent: Agent }>()
);

export const loadNewAgentFailure = createAction(
  '[ManageAgent] Load New Agent Failure',
  props<{ errorMessage: string }>()
);

export const saveAgent = createAction(
  '[ManageAgent] Save Agent',
  props<{ agent: Agent; saveEventType: ManageAgentsButtonAction }>()
);

export const saveAgentSuccess = createAction(
  '[ManageAgent] Save Agent Success',
  props<{ agent: Agent; saveEventType: ManageAgentsButtonAction }>()
);

export const saveAgentFailure = createAction(
  '[ManageAgent] Save Agent Failure',
  props<{ errorMessage: string }>()
);

export const deleteAgent = createAction(
  '[ManageAgent] Delete Agent',
  props<{ agent: Agent }>()
);

export const deleteAgentSuccess = createAction(
  '[ManageAgent] Delete Agent Success',
  props<{ agent: Agent }>()
);

export const deleteAgentFailure = createAction(
  '[ManageAgent] Delete Agent Failure',
  props<{ errorMessage: string }>()
);

export const patchNewAgent = createAction(
  '[ManageAgent] Patch New Agent',
  props<{ agent: Agent }>()
);

export const patchExistingAgent = createAction(
  '[ManageAgent] Patch Existing Agent',
  props<{ agent: Agent }>()
);
