<div class="mt-2 mb-2">
  <div class="message-container ps-2">
    <sv-ui-message
      type="SUCCESS"
      message="Transfer complete"
      size="SMALL"
    ></sv-ui-message>
  </div>
</div>
<div class="round-secondary-border-box mb-4">
  <h4>Receipt number</h4>
  <p>{{ transferQuotaHeader.receiptNumber }}</p>
  <h6>Transfer information</h6>
  <div class="d-inline-flex">
    <i class="material-icons text-success fs-3 me-2">check_circle</i>
    <span class="d-inline-block pt-1">
      <strong>{{ transferQuotaDetail.quantity }} {{ unit }}</strong>
      from
      <strong>{{ quotaRegime?.name }} </strong> for the
      <strong>{{ fishingPeriod?.name }}</strong>
      fishing period
    </span>
  </div>
</div>
<div class="round-secondary-border-box mb-4">
  <h4>From</h4>
  <p>
    <strong>{{ fromFishingBusiness.owner.fullName }}</strong>
  </p>
  <p>Fishing Business {{ fromFishingBusiness.id }}</p>
  <h4>To</h4>
  <p>
    <strong>{{ toFishingBusiness.owner.fullName }}</strong>
  </p>
  <p>Fishing Business {{ toFishingBusiness.id }}</p>
</div>
