import {
  AbstractControl,
  FormArray,
  FormControl,
  FormGroup,
  ValidationErrors,
  ValidatorFn,
  Validators,
} from '@angular/forms';
import {
  AddressDetailForm,
  AddressForm,
  AddressReferenceData,
  ASICStatus,
  BillingAddressForm,
  ContactDetail,
  ContactDetailForm,
  CustomerNameForm,
  CustomerType,
  FAAmendCustomerForm,
  Gender,
  PartnerForm,
  PartnerFormArray,
  PreferredMethodForm,
  RegistrationDetailForm,
  SharedAmendCustomerForm,
} from '@fishonline2023/shared/models';
import { equals, isNilOrEmpty } from '@fishonline2023/shared/ramda';
import { dateRangeValidator } from '@fishonline2023/shared/utils';
import * as dayjs from 'dayjs';

export const addressValidator: (
  AddressReferenceDataList: AddressReferenceData[]
) => ValidatorFn = (AddressReferenceDataList: AddressReferenceData[]) => {
  return (formGroup: AbstractControl): ValidationErrors | null => {
    const { streetAddress, suburb, postcode, state } = (
      formGroup as FormGroup<AddressForm | BillingAddressForm>
    ).value;
    const formValue = { streetAddress, suburb, postcode, state };
    if (Object.values(formValue).every(isNilOrEmpty)) {
      return null;
    }
    if (Object.values(formValue).some(isNilOrEmpty)) {
      return { incompleteAddress: true };
    }
    if (
      !AddressReferenceDataList.some(
        (address) =>
          equals(address.state.toLowerCase(), state?.toLowerCase()) &&
          equals(address.postcode?.toLowerCase(), postcode?.toLowerCase()) &&
          equals(address.suburb?.toLowerCase(), suburb?.toLowerCase())
      )
    ) {
      return { invalidAddress: true };
    }
    return null;
  };
};

export const registrationDetailFormControls: Record<
  keyof RegistrationDetailForm,
  FormControl
> = {
  registrationNumber: new FormControl('', { nonNullable: true }),
  registrationStatus: new FormControl('', {
    nonNullable: true,
  }),
  customerType: new FormControl(CustomerType.Individual, {
    nonNullable: true,
  }),
  whfFolderNumber: new FormControl('', {
    nonNullable: true,
  }),
  sapCustomerNumber: new FormControl('', {
    nonNullable: true,
  }),
  repositoryName: new FormControl('', {
    nonNullable: true,
  }),
  mortgageAll: new FormControl(true, {
    nonNullable: true,
  }),
  tradingAs: new FormControl('', {
    nonNullable: true,
    validators: [Validators.maxLength(100)],
  }),
  abn: new FormControl('', {
    nonNullable: true,
  }),
  acn: new FormControl('', {
    nonNullable: true,
  }),
  asic: new FormControl(ASICStatus.Registered, {
    nonNullable: true,
  }),
  fisheriesOffice: new FormControl('', {
    nonNullable: true,
  }),
  customerName: new FormControl('', {
    nonNullable: true,
    validators: [Validators.maxLength(100)],
  }),
};

export const partnerFormControls = (): Record<
  keyof PartnerForm,
  FormControl
> => ({
  firstName: new FormControl('', {
    nonNullable: true,
    validators: [Validators.maxLength(100), Validators.required],
  }),
  lastName: new FormControl('', {
    nonNullable: true,
    validators: [Validators.maxLength(100), Validators.required],
  }),
  otherName: new FormControl('', {
    nonNullable: true,
    validators: [Validators.maxLength(100)],
  }),
  dob: new FormControl('', {
    nonNullable: true,
    validators: [
      dateRangeValidator('1920-01-01', dayjs().format('YYYY-MM-DD')),
      Validators.required,
    ],
  }),
});

export const customerNameFormControls: Record<
  keyof CustomerNameForm,
  FormControl
> = {
  ...partnerFormControls(),
  gender: new FormControl(Gender.M, {
    nonNullable: true,
  }),
};

export const partnerFormGroup = () =>
  new FormGroup<PartnerForm>(partnerFormControls());

export const partnerFormArray: Record<keyof PartnerFormArray, FormArray> = {
  partnerList: new FormArray<FormGroup<PartnerForm>>([partnerFormGroup()]),
};

export const contactDetailFormControls: Record<
  keyof ContactDetailForm,
  FormControl
> = {
  [ContactDetail.EmailAddress]: new FormControl('', {
    nonNullable: true,
    validators: [Validators.email],
  }),
  [ContactDetail.HomePhone]: new FormControl('', { nonNullable: true }),
  [ContactDetail.WorkPhone]: new FormControl('', { nonNullable: true }),
  [ContactDetail.Mobile]: new FormControl('', { nonNullable: true }),
  fax: new FormControl('', { nonNullable: true }),
  contactName: new FormControl('', {
    nonNullable: true,
    validators: [Validators.required],
  }),
};

export const preferredMethodFormControls: Record<
  keyof PreferredMethodForm,
  FormControl
> = {
  preferredMethod: new FormControl(ContactDetail.WorkPhone, {
    nonNullable: true,
  }),
};

export const addressFormControls: Record<keyof AddressDetailForm, FormGroup> = {
  physicalAddress: new FormGroup<AddressForm>({
    streetAddress: new FormControl('', {
      nonNullable: true,
      validators: Validators.maxLength(60),
    }),
    suburb: new FormControl('', {
      nonNullable: true,
    }),
    postcode: new FormControl('', {
      nonNullable: true,
    }),
    state: new FormControl('', {
      nonNullable: true,
    }),
  }),
  postalAddress: new FormGroup<AddressForm>({
    streetAddress: new FormControl('', {
      nonNullable: true,
      validators: Validators.maxLength(60),
    }),
    suburb: new FormControl('', {
      nonNullable: true,
    }),
    postcode: new FormControl('', {
      nonNullable: true,
    }),
    state: new FormControl('', {
      nonNullable: true,
    }),
  }),
  billingAddress: new FormGroup<BillingAddressForm>({
    streetAddress: new FormControl('', {
      nonNullable: true,
      validators: [Validators.maxLength(60), Validators.required],
    }),
    suburb: new FormControl('', {
      nonNullable: true,
      validators: [Validators.required],
    }),
    postcode: new FormControl('', {
      nonNullable: true,
      validators: [Validators.required],
    }),
    state: new FormControl('', {
      nonNullable: true,
      validators: [Validators.required],
    }),
    customerName: new FormControl('', {
      nonNullable: true,
    }),
    nameExtension: new FormControl('', {
      nonNullable: true,
    }),
    contactNumber: new FormControl('', {
      nonNullable: true,
      validators: [Validators.required],
    }),
  }),
};

export const sharedAmendCustomerForm = () =>
  new FormGroup<SharedAmendCustomerForm>({
    ...contactDetailFormControls,
    ...preferredMethodFormControls,
    ...addressFormControls,
  });

export const faAmendCustomerForm = () =>
  new FormGroup<FAAmendCustomerForm>({
    ...registrationDetailFormControls,
    ...partnerFormArray,
    ...customerNameFormControls,
    ...contactDetailFormControls,
    ...preferredMethodFormControls,
    ...addressFormControls,
  });
