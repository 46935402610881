import {
  ChangeDetectorRef,
  Directive,
  EventEmitter,
  inject,
  Input,
  OnInit,
  Output,
} from '@angular/core';
import { BehaviorSubject, map, Observable, take } from 'rxjs';
import {
  Authorisation,
  AuthorisationSortOrderChangeEvent,
  FDDataLoaderData,
  TableFieldDataType,
  TableSortField,
} from '@fishonline2023/webapps/model/fd2023';
import {
  DataLoaderActivityLog,
  SortOrder,
} from '@fishonline2023/shared/models';
import { equals } from '@fishonline2023/shared/ramda';

@Directive()
export class BaseAuthorisationsDirective implements OnInit {
  @Input()
  public dataLoaderData$!: Observable<FDDataLoaderData>;
  @Output() public activityLogTriggered =
    new EventEmitter<DataLoaderActivityLog>();
  public authorisations!: Authorisation[];
  protected cdr = inject(ChangeDetectorRef);
  protected readonly sort$ =
    new BehaviorSubject<AuthorisationSortOrderChangeEvent>({
      sortOrder: SortOrder.NOT_SORTED,
    });

  protected tableFields: TableSortField[] = [
    {
      label: 'FB No.',
      property: 'fishingBusinessId',
      dataType: TableFieldDataType.string,
      sortable: true,
    },
    {
      label: 'Start Date',
      property: 'startDate',
      dataType: TableFieldDataType.Date,
      sortable: true,
    },
    {
      label: 'End Date',
      property: 'endDate',
      dataType: TableFieldDataType.Date,
      sortable: true,
    },
    {
      label: 'Fisher',
      property: 'fisher',
      dataType: TableFieldDataType.Customer,
      sortable: true,
    },
    {
      label: 'FB Owner',
      property: 'owner',
      dataType: TableFieldDataType.Customer,
      sortable: true,
    },
  ];

  public get columnSorted$() {
    return this.sort$.pipe(
      map(({ sortOrder, authorisationResultField }) =>
        equals(sortOrder, SortOrder.NOT_SORTED)
          ? undefined
          : authorisationResultField?.property
      )
    );
  }

  protected get myAuthorisations$() {
    return this.dataLoaderData$ as Observable<Authorisation[]>;
  }

  public ngOnInit(): void {
    this.myAuthorisations$.pipe(take(1)).subscribe((authorisations) => {
      this.authorisations = authorisations;
      this.cdr.detectChanges();
    });
  }

  public handleAuthorisationsSort({
    sortOrder,
    authorisationResultField,
  }: AuthorisationSortOrderChangeEvent): void {
    this.sort$.next({ sortOrder, authorisationResultField });
  }
}
