<sv-ui-search-detail-wrapper
  *ngIf="saleAndTradeDetail$ | async"
  [deleteSubject]="deleteSubject"
  [showDeleteButton]="!isNewAd && saleAndTradeDetails.isEditable"
  [showSubmitButton]="
    (isNewAd || saleAndTradeDetails.isEditable) &&
    agreeTermsAndConditionsClicked &&
    saleAndTradeEditForm.valid
  "
  [submitSubject]="submitSubject"
>
  <ng-container *ngIf="!saleAndTradeDetails.isEditable && !isNewAd">
    <div class="mt-4">
      <h4>View an Advertisement</h4>
    </div>
    <div class="mt-3 mb-3 container">
      <p>
        The Department does not guarantee the accuracy or reliability of any
        information published on this site. For more information and to view the
        <br />
        <a [routerLink]="'/' + FDRoute.TermsConditions" target="_blank"
          >terms and conditions</a
        >
        for using this site.
      </p>
    </div>
    <div>
      <sv-ui-full-width-item-list
        [isLongLabel]="true"
        [itemList]="saleAndTradeDetailsList"
      ></sv-ui-full-width-item-list>
    </div>
  </ng-container>
  <ng-container *ngIf="isNewAd || saleAndTradeDetails.isEditable">
    <sv-ui-create-edit-ad
      (agreeTermsAndConditionsClicked)="
        onAgreeTermsAndConditionsClicked($event)
      "
      [isNewAd]="isNewAd"
      [message]="message"
      [saleAndTradeEditForm]="saleAndTradeEditForm"
      [saleAndTradeViewStatus$$]="saleAndTradeViewStatus$$"
    ></sv-ui-create-edit-ad>
  </ng-container>
</sv-ui-search-detail-wrapper>
<sv-ui-confirmation-modal
  (confirm)="onDeleteConfirm()"
  confirmButtonLabel="Confirm"
  message="Do you wish to continue?"
  title="Confirm Delete"
>
</sv-ui-confirmation-modal>
