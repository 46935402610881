/**
 * rambda vs ramda
 * rambda is simply faster than ramda.
 * So whatever the function you find in rambda use it, otherwise use ramda
 * If you list in RB then if there is no function, then typescript will complain for you.
 * https://github.com/selfrefactor/rambda#benchmark
 */
import * as RB from 'rambda';
import * as R from 'ramda';

export const {
  and,
  or,
  startsWith,
  lensProp,
  lensPath,
  view,
  add,
  set,
  remove,
  over,
  identity,
  forEach,
  without,
  length,
  compose,
  when,
  anyPass,
  not,
  toString,
  propEq,
  take,
  update,
  join,
  trim,
  keys,
  ifElse,
  intersection,
  difference,
  differenceWith,
  curry,
  reverse,
  propOr,
  uniq,
  all,
  last,
  times,
  uniqBy,
  pickBy,
  comparator,
  gt,
  clone,
  lt,
  sort,
  eqProps,
  groupWith,
  mergeRight,
  indexBy,
  chain,
  map,
  partition,
  pipe,
  applySpec,
  juxt,
  mergeDeepLeft,
  mergeDeepRight,
  props,
  sortWith,
  ascend,
  descend,
  tail,
} = R;

export const {
  merge,
  isNil,
  is,
  prop,
  path,
  pathOr,
  equals,
  has,
  isEmpty,
  includes,
  both,
  either,
  multiply,
  toPairs,
  type,
  find,
  complement,
  flatten,
  defaultTo,
  range,
  groupBy,
  toLower,
  concat,
  sum,
  sortBy,
  filter,
  reduce,
  replace,
  flip,
  pluck,
  adjust,
  negate,
  assoc,
  dissoc,
  pick,
  omit,
  values,
  mergeAll,
  // eslint-disable-next-line @typescript-eslint/naming-convention, no-underscore-dangle, id-blacklist, id-match
  any,
  match,
} = RB;

const isStringNullOrNil = (val: string | number): boolean =>
  equals('null', val) || equals('undefined', val) || isNil(val);
export const isNilOrEmpty: any = either(isStringNullOrNil, isEmpty);

export const toNumber = (val: string): number | undefined =>
  isNilOrEmpty(val) ? undefined : +val;

export const isStrNumber = (val: string): boolean => /^\d+$/.test(val);

export const propOrZero = propOr(0);

export const uniqBySpeciesId = uniqBy(prop('speciesId'));

// https://github.com/ramda/ramda/wiki/Cookbook#sql-style-joins
// tslint:disable-next-line: max-func-args
export const joinInner = curry((field1, field2, table1, table2) => {
  const indexed = indexBy(field1, table1);

  return chain((t2row: object) => {
    const corresponding = indexed[field2(t2row)] as object;

    return corresponding ? [mergeRight(t2row, corresponding)] : [];
  }, table2);
});

// tslint:disable-next-line: max-func-args
export const joinRight = R.curry((mapper1, mapper2, t1, t2) => {
  const indexed = R.indexBy(mapper1, t1);
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  return t2.map((t2row) =>
    R.mergeRight(t2row, indexed[mapper2(t2row)] as object)
  );
});

// tslint:disable-next-line: max-func-args
export const joinOuter = curry((field1, field2, table1, table2) => {
  const o1 = indexBy(field1, table1);
  const o2 = indexBy(field2, table2);

  return values(R.mergeWith(mergeRight, o1, o2));
});

// tslint:disable-next-line: max-func-args
export const joinLeft = R.curry((f1, f2, t1, t2) => joinRight(f2, f1, t2, t1));

export const concatFlipped = flip(concat);

export const toFixed = (
  value: string | number,
  decimals?: number
): string | undefined => {
  if (isNilOrEmpty(value)) {
    return;
  }

  return Number(value).toFixed(decimals);
};
