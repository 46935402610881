import { HttpClient } from '@angular/common/http';
import { Injectable, inject } from '@angular/core';
import { environment } from '@env/fd/environment';
import { AccountProfile } from '@fishonline2023/webapps/model/fd2023';
import { Observable } from 'rxjs';

@Injectable({ providedIn: 'root' })
export class HomePageService {
  private http = inject(HttpClient);

  public loadAccountProfile(customerId: number): Observable<AccountProfile> {
    const url = `${environment.apiUrl}/user/${customerId}/account-profile`;
    return this.http.get<AccountProfile>(url);
  }
}
