import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { CommonModule } from '@angular/common';
import { InputField } from '@fishonline2023/shared/models';
import { FormControl, ReactiveFormsModule } from '@angular/forms';
import {
  AutoCompleteComponent,
  TypeaheadComponent,
} from '@fishonline2023/webapps/shared/ui/base-components';

@Component({
  selector: 'sv-ui-input-field-typeahead',
  standalone: true,
  imports: [
    CommonModule,
    ReactiveFormsModule,
    AutoCompleteComponent,
    TypeaheadComponent,
  ],
  templateUrl: './input-field-typeahead.component.html',
  styleUrls: ['./input-field-typeahead.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class InputFieldTypeaheadComponent {
  @Input() public inputField!: InputField;
  @Input() public form!: FormControl;
}
