import { inject, Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Store } from '@ngrx/store';
import {
  selectTransactionDataForEdit,
  selectTransactionDataFromSubmit,
  selectTransactionRequestParams,
  selectTransactionType,
} from './transaction.selectors';
import { combineLatest, filter, map, Observable, switchMap, take } from 'rxjs';
import { complement, isEmpty, isNil } from '@fishonline2023/shared/ramda';
import {
  TransactionData,
  TransactionEntityItem,
  TransactionMetadata,
  TransactionRequestParams,
  TransactionSubmitPayload,
  TransactionType,
} from '@fishonline2023/webapps/model/fa2023';
import { Message } from '@fishonline2023/shared/models';
import {
  transactionApprovedFailed,
  transactionApprovedSuccessful,
} from './transaction.actions';
import { environment } from '@env/fa/environment';

@Injectable()
export class TransactionService {
  private http = inject(HttpClient);
  private store = inject(Store);

  private transactionRequestParams$: Observable<
    Record<keyof TransactionRequestParams, number> | undefined
  > = this.store.select(selectTransactionRequestParams).pipe(take(1));
  private transactionDataForEdit$: Observable<number | null> = this.store
    .select(selectTransactionDataForEdit)
    .pipe(
      filter(complement(isNil)),
      map(
        ({ transactionHeader: { transactionNumber } }: TransactionData) =>
          transactionNumber
      ),
      take(1)
    );
  private transactionIdFromSubmit$: Observable<number> = this.store
    .select(selectTransactionDataFromSubmit)
    .pipe(
      filter(complement(isNil)),
      map(
        ({ transactionHeader: { transactionNumber } }: TransactionData) =>
          <number>transactionNumber
      ),
      take(1)
    );
  private transactionMetadata$ = this.store.select(selectTransactionType).pipe(
    filter(complement(isNil)),
    take(1),
    map(
      (transactionType: TransactionType) => TransactionMetadata[transactionType]
    )
  );

  public getEntityData(): Observable<Array<TransactionEntityItem>> {
    return this.transactionMetadata$.pipe(
      switchMap((transactionMetadata) =>
        this.http.get<TransactionEntityItem[]>(
          `${environment.apiUrl}/${transactionMetadata.entityBaseRoute}`,
          {}
        )
      )
    );
    // return timer(1000).pipe(map(() => mockCustomerList));
  }

  public createEntity(): Observable<TransactionData> {
    return this.transactionMetadata$.pipe(
      switchMap(
        (transactionMetadata) =>
          this.http.post<TransactionData>(
            `${environment.apiUrl}/${transactionMetadata.entityCreationAPIRoute}`,
            {}
          )
        // timer(1000).pipe(map(() => amendCustomerTransactionData))
      )
    );
  }

  public getTransactionData({
    queryParams,
    transactionId,
  }: {
    queryParams?: Record<keyof TransactionRequestParams, number>;
    transactionId: number | null;
  }): Observable<TransactionData> {
    return this.transactionMetadata$.pipe(
      switchMap((transactionMetadata) => {
        const url = !isNil(transactionId)
          ? `${environment.apiUrl}/transaction`
          : `${environment.apiUrl}/${transactionMetadata.apiBaseRoute}`;
        const params = !isNil(transactionId)
          ? { id: transactionId }
          : queryParams;
        return this.http.get<TransactionData>(url, {
          params,
        });
        // return timer(1000).pipe(map(() => amendCustomerTransactionData));
      })
    );
  }

  public submitTransaction(
    transactionSubmitPayload: TransactionSubmitPayload
  ): Observable<TransactionData> {
    return combineLatest([
      this.transactionMetadata$,
      this.transactionDataForEdit$,
      this.transactionRequestParams$,
    ]).pipe(
      switchMap(
        ([transactionMetadata, transactionId, params]) =>
          isNil(transactionId)
            ? this.http.post<TransactionData>(
                `${environment.apiUrl}/${transactionMetadata.apiBaseRoute}`,
                transactionSubmitPayload,
                { params }
              )
            : this.http.put<TransactionData>(
                `${environment.apiUrl}/${transactionMetadata.apiBaseRoute}/${transactionId}`,
                transactionSubmitPayload
              )
        // timer(1000).pipe(map(() => amendCustomerSubmitTransactionData))
      )
    );
  }

  public deleteTransaction(): Observable<TransactionData> {
    return combineLatest([
      this.transactionMetadata$,
      this.transactionIdFromSubmit$,
    ]).pipe(
      switchMap(
        ([transactionMetadata, transactionId]) =>
          this.http.delete<TransactionData>(
            `${environment.apiUrl}/${transactionMetadata.apiBaseRoute}/${transactionId}`,
            {}
          )
        // timer(1000).pipe(map(() => amendCustomerSubmitTransactionData))
      )
    );
  }

  public approveTransaction(): Observable<TransactionData> {
    return combineLatest([
      this.transactionMetadata$,
      this.transactionIdFromSubmit$,
    ]).pipe(
      switchMap(
        ([transactionMetadata, transactionId]) =>
          this.http.put<TransactionData>(
            `${environment.apiUrl}/${transactionMetadata.apiBaseRoute}/${transactionId}/approve`,
            {}
          )
        // timer(1000).pipe(map(() => amendCustomerSubmitTransactionData))
      )
    );
  }

  public notApproveTransaction(): Observable<TransactionData> {
    return combineLatest([
      this.transactionMetadata$,
      this.transactionIdFromSubmit$,
    ]).pipe(
      switchMap(
        ([transactionMetadata, transactionId]) =>
          this.http.put<TransactionData>(
            `${environment.apiUrl}/${transactionMetadata.apiBaseRoute}/${transactionId}/not-approve`,
            {}
          )
        // timer(1000).pipe(map(() => amendCustomerSubmitTransactionData))
      )
    );
  }

  public parseApproveTransactionResponse = (
    transactionData: TransactionData
  ) => {
    if (!isEmpty(transactionData.transactionInfo.errorList)) {
      return transactionApprovedFailed({
        errorMessage: Message.TransactionPreventingError,
      });
    }
    return transactionApprovedSuccessful({ transactionData });
  };
}
