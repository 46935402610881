import {
  ConfirmPath,
  PaymentPath,
  TransactionActionButton,
} from './transaction.model';
import { TransactionType } from './transaction.enum';
import { FDRoute } from '@fishonline2023/shared/models';
import {
  abandonTransactionButton,
  confirmButton,
  declineRenewalButton,
  renewConfirmButton,
  renewPaymentOptionsButton,
  submitButton,
  submitOfferButton,
} from './transaction-button';
import { Message } from './message.constant';

export interface TransactionMetadata {
  appRoute: FDRoute;
  transactionTitle: string;
  apiBaseRoute: string;
  createAmendStartActionButtonFunction: (
    disabled?: boolean
  ) => TransactionActionButton;
  createAmendActionButtonEndFunctions: Array<
    (disabled?: boolean) => TransactionActionButton
  >;
  confirmCheckboxList?: string[];
  confirmStartActionButtonFunction: (
    disabled?: boolean
  ) => TransactionActionButton;
  includePayment: boolean;
  confirmPath: ConfirmPath;
  paymentAmountPath?: string[];
  paymentAmountFormPath?: string[];
  paymentAmountLabel?: string;
  paymentPath?: PaymentPath;
}

export const TransactionMetadata: Record<TransactionType, TransactionMetadata> =
  {
    [TransactionType.AmendCustomer]: {
      appRoute: FDRoute.AmendCustomer,
      transactionTitle: 'Contact Details',
      apiBaseRoute: 'customer/amend',
      createAmendStartActionButtonFunction: abandonTransactionButton,
      createAmendActionButtonEndFunctions: [submitButton],
      confirmStartActionButtonFunction: confirmButton,
      includePayment: false,
      confirmPath: ConfirmPath.Approve,
    },
    [TransactionType.NominateRevokeFisher]: {
      appRoute: FDRoute.NominateRevokeFisher,
      transactionTitle: 'Nominate/Revoke Fishers',
      apiBaseRoute: 'fishing-business/nominate-revoke',
      createAmendStartActionButtonFunction: abandonTransactionButton,
      createAmendActionButtonEndFunctions: [submitButton],
      confirmStartActionButtonFunction: confirmButton,
      includePayment: false,
      confirmPath: ConfirmPath.Approve,
    },
    [TransactionType.RenewLicence]: {
      appRoute: FDRoute.RenewLicence,
      transactionTitle: 'Renew Commercial Fishing Licence',
      apiBaseRoute: 'licence/renew',
      createAmendStartActionButtonFunction: declineRenewalButton,
      createAmendActionButtonEndFunctions: [
        renewPaymentOptionsButton,
        renewConfirmButton,
      ],
      confirmStartActionButtonFunction: confirmButton,
      paymentAmountPath: ['transactionDetail', 'renewalTerm', 'renewalTermFee'],
      paymentAmountFormPath: ['renewalTerm', 'renewalTermFee'],
      paymentAmountLabel: 'Renewal Fee',
      paymentPath: PaymentPath.Approve,
      confirmPath: ConfirmPath.Approve,
      includePayment: true,
    },
    [TransactionType.TransferComponents]: {
      appRoute: FDRoute.TransferComponents,
      transactionTitle: 'Transfer Components',
      apiBaseRoute: 'fishing-business/component-transfer',
      createAmendStartActionButtonFunction: abandonTransactionButton,
      createAmendActionButtonEndFunctions: [submitButton],
      confirmStartActionButtonFunction: submitOfferButton,
      paymentAmountPath: ['transactionDetail', 'transferFee'],
      paymentAmountLabel: 'Transfer Fee',
      confirmCheckboxList: [Message.TransferComponentConfirmMessage],
      paymentPath: PaymentPath.Accept,
      confirmPath: ConfirmPath.Offer,
      includePayment: false,
    },
    [TransactionType.TransferFishingBusiness]: {
      appRoute: FDRoute.TransferFishingBusiness,
      transactionTitle: 'Transfer Fishing Business',
      apiBaseRoute: 'fishing-business/transfer',
      createAmendStartActionButtonFunction: abandonTransactionButton,
      createAmendActionButtonEndFunctions: [submitButton],
      confirmStartActionButtonFunction: submitOfferButton,
      paymentAmountPath: ['transactionDetail', 'transferFee'],
      paymentAmountLabel: 'Transfer Fee',
      confirmCheckboxList: [Message.TransferFBConfirmMessage],
      paymentPath: PaymentPath.Accept,
      confirmPath: ConfirmPath.Offer,
      includePayment: false,
    },
    [TransactionType.TransferQuota]: {
      appRoute: FDRoute.TransferQuota,
      transactionTitle: 'Transfer Quota',
      apiBaseRoute: 'fishing-business/quota-transfer',
      createAmendStartActionButtonFunction: abandonTransactionButton,
      confirmStartActionButtonFunction: confirmButton,
      createAmendActionButtonEndFunctions: [submitButton],
      includePayment: false,
      confirmPath: ConfirmPath.Approve,
    },
  };
