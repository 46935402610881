import {
  ChangeDetectionStrategy,
  Component,
  Input,
  Output,
  EventEmitter,
} from '@angular/core';
import { CommonModule } from '@angular/common';

export enum ButtonTypes {
  PRIMARY = 'primary',
  SECONDARY = 'secondary',
}

@Component({
  selector: 'sv-ui-button',
  standalone: true,
  imports: [CommonModule],
  templateUrl: './button.component.html',
  styleUrls: ['./button.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ButtonComponent {
  @Input() type: string = ButtonTypes.PRIMARY;
  @Input() text = '';
  @Output() onclick: EventEmitter<MouseEvent> = new EventEmitter();

  onButtonClick(): void {
    this.onclick.emit();
  }
}
