import { HttpClient } from '@angular/common/http';
import { inject, Injectable } from '@angular/core';
import {
  Error,
  Message,
  Permission,
  UserProfile,
} from '@fishonline2023/webapps/model/fd2023';
import { Observable, of } from 'rxjs';
import { environment } from '@env/fd/environment';
import { equals, isNil } from '@fishonline2023/shared/ramda';
import { AgentCustomer } from '@fishonline2023/shared/models';

@Injectable({ providedIn: 'root' })
export class UserProfileService {
  private http = inject(HttpClient);

  public loadUserProfile(): Observable<UserProfile> {
    const url = `${environment.apiUrl}/user/profile`;
    return this.http.get<UserProfile>(url);
  }

  public getErrorMessage(error: Error): string {
    if (error.statusCode === 403) {
      return error.errorMessage as string;
    }
    return Message.TryAgainLater;
  }

  public getLogoutErrorMessage(error: Error): string {
    if (!isNil(error.errorMessage)) {
      return error.errorMessage as string;
    }
    return Message.LogoutFailed;
  }

  public loadAgentCustomerProfile(
    agentCustomerId: number,
    userProfile: UserProfile
  ): Observable<{
    fisherDirectPermissionList: Permission[];
    myAgentList: AgentCustomer[];
  }> {
    if (equals(userProfile.id, agentCustomerId)) {
      const permissions = userProfile?.fisherDirectPermissionList || [];
      return of({ fisherDirectPermissionList: permissions, myAgentList: [] });
    }
    return this.http.get<{
      fisherDirectPermissionList: Permission[];
      myAgentList: AgentCustomer[];
    }>(`${environment.apiUrl}/agent/profile?customerId=${agentCustomerId}`);
  }
}
