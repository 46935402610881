import { createAction, props } from '@ngrx/store';
import {
  SearchFieldValue,
  SearchResult,
  SearchResultItem,
  SearchType,
} from '@fishonline2023/shared/models';

export const searchTriggered = createAction(
  '[Search] Search Triggered',
  props<{ searchFilter: Record<string, unknown> }>()
);

export const downloadTriggered = createAction(
  '[Search] Download Triggered',
  props<{ downloadCSVName?: string }>()
);

export const searchFilterPopulatedByQueryParams = createAction(
  '[Search] Search Filter Populated By Query Params',
  props<{ searchFilter: Record<string, unknown> }>()
);

export const searchMetadataSet = createAction(
  '[Search] Search Metadata Set',
  props<{
    searchType: SearchType;
    searchResultDisplayColumnList?: string[];
  }>()
);

export const searchFieldValuesLoadSuccessful = createAction(
  '[Search] Search Field Values Load Successful',
  props<{ searchFieldValues: Record<string, Array<SearchFieldValue>> }>()
);

export const searchFieldValuesLoadFailed = createAction(
  '[Search] Search Field Values Load Failed',
  props<{ errorMessage: string }>()
);

export const searchSuccessful = createAction(
  '[Search] Search Successful',
  props<{ searchResult: SearchResult }>()
);

export const searchFailed = createAction(
  '[Search] Search Failed',
  props<{ errorMessage: string }>()
);

export const downloadSuccessful = createAction(
  '[Search] Search Download Successful'
);

export const downloadFinished = createAction('[Search] Download Finished');

export const downloadFailed = createAction(
  '[Search] Search Download Failed',
  props<{ errorMessage: string }>()
);

export const searchResultDisplayColumnListUpdated = createAction(
  '[Search] Search Result Display Column List Updated',
  props<{ searchResultDisplayColumnList: string[] }>()
);

export const searchResultItemSelected = createAction(
  '[Search] Search Result Item selected',
  props<{ selectedSearchResultItem?: SearchResultItem }>()
);

export const searchReset = createAction('[Search] Search Reset');

export const searchResultCleared = createAction(
  '[Search] Search Result Cleared'
);
