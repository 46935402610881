import {
  AbstractControl,
  FormArray,
  FormControl,
  FormGroup,
  ValidationErrors,
  ValidatorFn,
  Validators,
} from '@angular/forms';
import {
  ComponentTwoPartyTransactionEntitySelectionForm,
  FishingBusinessTwoPartyTransactionEntitySelectionForm,
  TransferComponentReference,
  TransferComponentsForm,
  TransferDetailForm,
  TransferFishingBusinessComponentsForm,
  TransferFishingBusinessComponentsTransactionReference,
  TransferQuotaReference,
} from '@fishonline2023/webapps/model/fd2023';
import { defaultTo, isNil } from '@fishonline2023/shared/ramda';
import {
  NO_DECIMAL_POINT,
  TWO_DECIMAL_POINT,
} from '@fishonline2023/shared/utils';
import { FishingBusiness } from '@fishonline2023/shared/models';

export const fishingBusinessTwoPartyTransactionEntitySelectionForm = () =>
  new FormGroup<FishingBusinessTwoPartyTransactionEntitySelectionForm>({
    from: new FormControl(null, [Validators.required]),
    to: new FormControl(null, [Validators.required]),
  });

export const componentsTwoPartyTransactionEntitySelectionForm = () =>
  new FormGroup<ComponentTwoPartyTransactionEntitySelectionForm>({
    from: new FormControl(null, [Validators.required]),
    to: new FormControl(null, [
      Validators.required,
      fishingBusinessValidator(),
    ]),
  });

const fishingBusinessValidator =
  (): ValidatorFn =>
  (control: AbstractControl): ValidationErrors | null => {
    const fishingBusiness = control.value as FishingBusiness;
    const isIdNumber = !isNaN(Number(fishingBusiness?.id));
    const isOwnerCustomer =
      !isNil(fishingBusiness?.owner) &&
      !isNaN(Number(fishingBusiness?.owner?.id)) &&
      !isNil(fishingBusiness?.owner?.fullName);
    if (isIdNumber && isOwnerCustomer) {
      return null;
    }
    return { invalidFishingBusiness: true };
  };

export const atLeastOneComponentShouldHaveQuantityValidator = (
  formGroup: AbstractControl
): ValidationErrors | null => {
  const atLeastOneComponentShouldHaveQuantity = (
    formGroup as FormGroup<TransferComponentsForm>
  ).controls.componentList.controls.some(
    (control) => control.controls.quantity.value > 0
  );
  if (atLeastOneComponentShouldHaveQuantity) {
    return null;
  }
  return { atLeastOneComponentShouldHaveQuantity: true };
};

export const transferFishingBusinessComponentsForm = (
  {
    componentList,
    quotaList,
  }: TransferFishingBusinessComponentsTransactionReference,
  maximumDefaultValue = false
): TransferFishingBusinessComponentsForm => ({
  componentList: new FormArray<FormGroup<TransferDetailForm>>(
    componentList
      .filter(({ isTransferable }) => isTransferable)
      .map((transferReference) =>
        transferDetailForm(transferReference, maximumDefaultValue)
      )
  ),
  quotaList: new FormArray<FormGroup<TransferDetailForm>>(
    quotaList.map((transferReference) =>
      transferDetailForm(transferReference, maximumDefaultValue)
    )
  ),
});

const transferDetailForm = (
  transferReference: TransferComponentReference | TransferQuotaReference,
  maximumDefaultValue: boolean
) => {
  const max = defaultTo(
    (transferReference as TransferComponentReference).availableAmount,
    (transferReference as TransferQuotaReference).amount
  );
  return new FormGroup<TransferDetailForm>({
    id: new FormControl(transferReference.id, { nonNullable: true }),
    name: new FormControl(transferReference.name, { nonNullable: true }),
    quantity: new FormControl(maximumDefaultValue ? max : 0, {
      nonNullable: true,
      validators: [
        Validators.min(0),
        Validators.max(max),
        Validators.pattern(
          (transferReference as TransferQuotaReference).allowDecimals
            ? TWO_DECIMAL_POINT
            : NO_DECIMAL_POINT
        ),
      ],
    }),
    totalSellPrice: new FormControl(0, {
      nonNullable: true,
      validators: [Validators.min(0), Validators.required],
    }),
  });
};
