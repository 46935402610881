<h4>Businesses You are Acting as an Agent For</h4>
<p>
  Tap the Switch To button to change businesses. This will allow you to act as
  an agent for that business.
</p>
<div class="card" *ngFor="let agentCustomer of agentCustomersList">
  <div class="card-body d-flex">
    <div class="flex-grow-1">
      <span
        *ngIf="agentCustomer.id === selectedAgent?.id"
        class="selectedAgentCustomer"
      >
        <span class="material-icons text-success">check_circle</span>
      </span>
      <h5 class="card-title">{{ agentCustomer.fullName }}</h5>
      <p class="card-text">Registration {{ agentCustomer.id }}</p>
    </div>
    <div *ngIf="agentCustomer.id === selectedAgent?.id">Currently selected</div>
    <sv-ui-button
      *ngIf="agentCustomer.id !== selectedAgent?.id"
      text="Switch To"
      (click)="switchAgentCustomer(agentCustomer.id)"
    ></sv-ui-button>
  </div>
</div>
<sv-ui-confirmation-modal
  title="Switch Principal"
  [message]="confirmationMessage"
  confirmButtonLabel="Switch"
  (confirm)="onSwitchAgentForCustomerConfirm()"
>
</sv-ui-confirmation-modal>
