import {
  DataLoaderActivityLog,
  SearchActivityLogResultProperty,
  SearchFieldValue,
  searchMetadata,
  SearchType,
} from '@fishonline2023/shared/models';
import { combineLatest, filter, Observable, of, switchMap, take } from 'rxjs';
import {
  DownloadSearchCSVEventType,
  DownloadSearchCSVEventTypeToMap,
  SearchEventType,
  SearchEventTypeToMap,
} from '../models/shared-analytics.models';
import {
  Channel,
  EventType,
} from '@fishonline2023/webapps/shared/feature/appsync';
import { findSearchFilterWithLabel } from '@fishonline2023/webapps/shared/feature/search';
import { flattenSearchFilter } from '@fishonline2023/shared/utils';
import {
  complement,
  includes,
  isNil,
  mergeAll,
} from '@fishonline2023/shared/ramda';
import { inject, Injectable } from '@angular/core';
import { FDAnalyticsService } from './fd-analytics.service';
import { FAAnalyticsService } from './fa-analytics.service';
import {
  selectSearchFieldValues,
  selectSearchType,
} from '@fishonline2023/webapps/shared/feature/search-store';
import { Store } from '@ngrx/store';

type RecordSearchActivityLogMethod = (searchData: {
  searchType: SearchEventTypeToMap;
  channel: Channel;
  searchFilter: Record<string, unknown>;
  searchFieldValues: Record<string, Array<SearchFieldValue>>;
}) => Observable<unknown>;

@Injectable()
export class SharedAnalyticsService {
  private store = inject(Store);
  private fdAnalyticsService = inject(FDAnalyticsService);
  private faAnalyticsService = inject(FAAnalyticsService);
  private recordAnalyticsEventMap: Record<
    Channel,
    (data: Partial<SearchActivityLogResultProperty>) => Observable<unknown>
  > = {
    [Channel.FD]: this.fdAnalyticsService.recordAnalyticsEvent,
    [Channel.FA]: this.faAnalyticsService.recordAnalyticsEvent,
    // TODO: populate FM and FI analytics service
    [Channel.FM]: this.faAnalyticsService.recordAnalyticsEvent,
    [Channel.FI]: this.faAnalyticsService.recordAnalyticsEvent,
  };

  public recordSearchActivityLogWithMethod = (
    method: RecordSearchActivityLogMethod
  ) =>
    switchMap(
      ([searchFilter, searchType, searchFieldValues]: [
        { searchFilter: Record<string, unknown> },
        Exclude<
          SearchType,
          SearchType.AccountActivity | SearchType.UserActivityLog
        >,
        Record<string, Array<SearchFieldValue>>
      ]) =>
        method({
          searchType: searchType as SearchEventTypeToMap,
          channel: searchMetadata[searchType].channel,
          searchFilter: searchFilter,
          searchFieldValues: searchFieldValues,
        })
    );

  public withSearchTypeChannelAndFilterValues = () =>
    switchMap(({ searchFilter }) =>
      combineLatest([
        of(searchFilter),
        this.store.select(selectSearchType).pipe(
          filter(complement(isNil)),
          filter(
            (searchType) =>
              !includes(searchType, [
                SearchType.AccountActivity,
                SearchType.UserActivityLog,
              ])
          )
        ),
        this.store
          .select(selectSearchFieldValues)
          .pipe(filter(complement(isNil))),
      ]).pipe(take(1))
    );

  public recordSearchActivityLogEvent = (searchData: {
    searchType: SearchEventTypeToMap;
    channel: Channel;
    searchFilter: Record<string, unknown>;
    searchFieldValues: Record<string, Array<SearchFieldValue>>;
  }) => this.recordSearch(searchData, SearchEventType[searchData.searchType]);

  public recordDownloadSearchResultCSVActivityLogEvent = (searchData: {
    searchType: SearchEventTypeToMap;
    channel: Channel;
    searchFilter: Record<string, unknown>;
    searchFieldValues: Record<string, Array<SearchFieldValue>>;
  }) =>
    this.recordSearch(
      searchData,
      DownloadSearchCSVEventType[
        searchData.searchType as DownloadSearchCSVEventTypeToMap
      ]
    );

  public recordDataLoaderActivityLogEvent = (
    { eventType, detail }: DataLoaderActivityLog,
    channel: Channel
  ) => {
    return this.recordAnalyticsEventMap[channel]({
      userActivityLogEventTypeID: eventType,
      channel,
      detail,
    });
  };

  private recordSearch(
    {
      searchType,
      channel,
      searchFilter,
      searchFieldValues,
    }: {
      searchType: SearchEventTypeToMap;
      channel: Channel;
      searchFilter: Record<string, unknown>;
      searchFieldValues: Record<string, Array<SearchFieldValue>>;
    },
    userActivityLogEventTypeID: EventType
  ) {
    const searchValues = findSearchFilterWithLabel(
      flattenSearchFilter(searchFilter),
      searchMetadata[searchType],
      searchFieldValues
    );
    const searchValuesObject: Record<string, unknown> = mergeAll(
      searchValues.map((searchValue) => ({
        [searchValue.label as string]: searchValue.value,
      }))
    );
    const data = {
      userActivityLogEventTypeID,
      detail: JSON.stringify(searchValuesObject),
    };
    return this.recordAnalyticsEventMap[channel](data);
  }
}
