<input
  *ngIf="inputField.isReadOnly; else editable"
  [attr.aria-label]="inputField.formControlName"
  [attr.data-cy]="inputField.formControlName"
  [disabled]="true"
  [placeholder]="inputField.placeholder || ''"
  [type]="inputField.type"
  [value]="formValue$ | async"
  class="form-control form-control-lg"
/>
<ng-template #editable>
  <input
    (blur)="onBlur()"
    [attr.aria-label]="inputField.formControlName"
    [attr.data-cy]="inputField.formControlName"
    [formControl]="form"
    [max]="inputField.max"
    [min]="inputField.min"
    [placeholder]="inputField.placeholder || ''"
    [type]="inputField.type"
    class="form-control form-control-lg"
    maxlength="{{ inputField.maxLength }}"
  />
</ng-template>
