import { createFeatureSelector, createSelector } from '@ngrx/store';
import { HOME_PAGE_FEATURE_KEY, HomePageState } from './home-page.reducers';
import { equals, isNil } from '@fishonline2023/shared/ramda';
import { getActAsCustomer } from '@fishonline2023/webapps/user-portal/fd2023/store/user-profile';

export const homePageFeature = createFeatureSelector<HomePageState>(
  HOME_PAGE_FEATURE_KEY
);

export const getAccountProfile = createSelector(
  homePageFeature,
  (state: HomePageState) => state?.accountProfile
);

export const getFishingBusinessList = createSelector(
  getAccountProfile,
  (accountProfile) => accountProfile?.fishingBusinessDtoList
);

export const getMyFishingBusinessList = createSelector(
  getFishingBusinessList,
  getActAsCustomer,
  (fishingBusinessDtoList, customer) => {
    if (isNil(fishingBusinessDtoList) || isNil(customer)) {
      return [];
    }
    return fishingBusinessDtoList.filter((fishingBusiness) =>
      equals(fishingBusiness.owner.id, customer.id)
    );
  }
);

export const getQuotaBalance = createSelector(
  getAccountProfile,
  (accountProfile) => accountProfile?.quotaBalance
);

export const getFishingBusinessWithConsumableQuotaBalance = createSelector(
  getMyFishingBusinessList,
  getQuotaBalance,
  (fishingBusinessList, quotaBalance) => {
    if (isNil(fishingBusinessList) || isNil(quotaBalance)) {
      return [];
    }
    return fishingBusinessList.filter((fishingBusiness) =>
      quotaBalance.consumableQuotaBalanceList.some((consumableQuotaBalance) =>
        equals(consumableQuotaBalance.fishingBusinessId, fishingBusiness.id)
      )
    );
  }
);

export const getPendingTransactionList = createSelector(
  getAccountProfile,
  (accountProfile) => accountProfile?.pendingTransactionList
);

export const getViewStatus = createSelector(
  homePageFeature,
  (state: HomePageState) => state?.viewStatus
);

export const getErrorMessage = createSelector(
  homePageFeature,
  (state: HomePageState) => state?.errorMessage
);

export const getMenuList = createSelector(
  homePageFeature,
  (state: HomePageState) => state?.menuList
);
