import {
  AccountStatus,
  AccountStatusEnum,
  SystemStatus,
} from '@fishonline2023/webapps/model/fd2023';
import { createReducer, on } from '@ngrx/store';
import { ViewStatus } from '@fishonline2023/shared/models';
import {
  loadUserProfileFailure,
  loadUserProfileSuccess,
  logoutSuccessful,
} from '@fishonline2023/webapps/user-portal/fd2023/store/user-profile';
import {
  loadSystemStatus,
  loadSystemStatusFailure,
  loadSystemStatusSuccess,
} from './system-status.actions';

export const SYSTEM_STATUS_FEATURE_KEY = 'systemStatus';

export interface SystemStatusState {
  systemStatus?: SystemStatus;
  accountStatus?: AccountStatus;
  viewStatus: ViewStatus;
  errorMessage?: string;
}

export const initialState: SystemStatusState = {
  viewStatus: ViewStatus.Initial,
};

export const systemStatusReducer = createReducer(
  initialState,
  on(loadSystemStatus, (state) => ({
    ...state,
    viewStatus: ViewStatus.Loading,
  })),
  on(loadSystemStatusSuccess, (state, { systemStatus }) => ({
    ...state,
    systemStatus,
    error: undefined,
    viewStatus: ViewStatus.Success,
  })),
  on(loadSystemStatusFailure, (state, { errorMessage }) => ({
    ...state,
    errorMessage,
    viewStatus: ViewStatus.Failure,
  })),
  on(loadUserProfileSuccess, (state) => {
    return {
      ...state,
      errorMessage: undefined,
      accountStatus: {
        status: AccountStatusEnum.Enabled,
      },
      viewStatus: ViewStatus.Success,
    };
  }),
  on(loadUserProfileFailure, (state) => ({
    ...state,
    accountStatus: {
      status: AccountStatusEnum.Disabled,
    },
    viewStatus: ViewStatus.Failure,
  })),
  on(logoutSuccessful, () => initialState)
);
