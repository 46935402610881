import { Analytics, API } from 'aws-amplify';
import {
  Channel,
  EventType,
} from '@fishonline2023/webapps/shared/feature/appsync';
import {
  AgentCustomer,
  SearchActivityLogResultProperty,
} from '@fishonline2023/shared/models';
import { inject, Injectable } from '@angular/core';
import { environment } from '@env/fd/environment';
import {
  combineLatest,
  defer,
  filter,
  from,
  Observable,
  pipe,
  retry,
  switchMap,
  take,
} from 'rxjs';
import {
  Agent,
  AgentRole,
  ConfirmPath,
  ManageAgentsButtonAction,
  Permission,
  TransactionAction,
  TransactionButtonAction,
  TransactionCreditCardDetail,
  TransactionData,
  TransactionDetail,
  TransactionMetadata,
  TransactionReference,
  TransactionRequestParams,
  TransactionStatus,
} from '@fishonline2023/webapps/model/fd2023';
import { Store } from '@ngrx/store';
import {
  complement,
  equals,
  identity,
  isNil,
  sortBy,
} from '@fishonline2023/shared/ramda';
import {
  TransactionEventType,
  TransactionPaymentStatus,
  TransactionTypeReadable,
} from '../models/fd-analytics.models';
import { getUserType } from '../utils/analytics.util';
import {
  getActAsCustomer,
  getUserProfile,
} from '@fishonline2023/webapps/user-portal/fd2023/store/user-profile';
import { transformKeysToTitleCase } from '@fishonline2023/shared/utils';
import { selectTransactionDataFromGET } from '@fishonline2023/webapps/transaction/fd2023/feature/transaction-store';
import { getTransactionDetail } from '../utils/fd-transaction-analytics.util';

@Injectable()
export class FDAnalyticsService {
  private store = inject(Store);

  public recordLoginSuccessfulEvent = () =>
    this.recordAnalyticsEvent({
      userActivityLogEventTypeID: EventType.LoginSuccessful,
    });

  public recordViewContactDetailEvent = (actAsCustomer: AgentCustomer) =>
    this.recordEventWithCustomerDetail(
      EventType.ViewContactDetails,
      actAsCustomer
    );

  public recordAgentRoleSwitchedSuccessfulEvent = (
    actAsCustomer: AgentCustomer,
    permissionList: Permission[]
  ) =>
    this.recordEventWithCustomerDetail(
      EventType.SwitchPersona,
      actAsCustomer,
      permissionList
    );

  public recordAgentDeleteSuccessfulEvent = (
    agent: Agent,
    actAsCustomer: AgentCustomer
  ) =>
    this.recordManageEventWithCustomerDetail(
      EventType.ManageAgents,
      actAsCustomer,
      agent,
      ManageAgentsButtonAction.Delete
    );

  public recordAgentSaveSuccessfulEvent = (
    agent: Agent,
    saveEventType: ManageAgentsButtonAction,
    actAsCustomer: AgentCustomer
  ) =>
    this.recordManageEventWithCustomerDetail(
      EventType.ManageAgents,
      actAsCustomer,
      agent,
      saveEventType
    );

  public recordTransactionDataSubmitSuccessfulEvent = (
    transactionData: TransactionData,
    requestParam?: TransactionRequestParams,
    transactionDataFromGET?: TransactionData
  ) =>
    this.recordTransactionEvent({
      transactionData,
      buttonAction: TransactionButtonAction.Submit,
      transactionAction: TransactionAction.Save,
      transactionStatus: TransactionStatus.Pending,
      requestParam,
      transactionReference: transactionDataFromGET?.transactionReference,
    });

  public recordTransactionDataWithdrawSuccessfulEvent = (
    transactionData: TransactionData
  ) =>
    this.recordTransactionEvent({
      transactionData,
      buttonAction: TransactionButtonAction.Withdraw,
      transactionAction: TransactionAction.NotApprove,
      transactionStatus: TransactionStatus.Pending,
      transactionReference: transactionData.transactionReference,
    });

  public recordTransactionDataRejectSuccessfulEvent = (
    transactionData: TransactionData
  ) =>
    this.recordTransactionEvent({
      transactionData,
      buttonAction: TransactionButtonAction.Reject,
      transactionAction: TransactionAction.NotApprove,
      transactionStatus: TransactionStatus.NotApproved,
      transactionReference: transactionData.transactionReference,
    });

  public recordTransactionDataAcceptSuccessfulEvent = (
    transactionData: TransactionData
  ) =>
    this.recordTransactionEvent({
      transactionData,
      buttonAction: TransactionButtonAction.Accept,
      transactionAction: TransactionAction.Approve,
      transactionStatus: TransactionStatus.Approved,
      transactionReference: transactionData.transactionReference,
    });

  public recordTransactionPayLaterSuccessfulEvent = (
    transactionData: TransactionData
  ) =>
    this.recordTransactionEvent({
      transactionData,
      buttonAction: TransactionButtonAction.ConfirmPayLater,
      transactionAction: TransactionAction.Save,
      transactionStatus: TransactionStatus.Pending,
      isPayLater: true,
    });

  public recordTransactionProvideCardDetailEvent = (
    transactionData: TransactionData
  ) =>
    this.recordTransactionEvent({
      transactionData,
      buttonAction: TransactionButtonAction.ProvideCardDetails,
      transactionAction: TransactionAction.Save,
      transactionStatus: TransactionStatus.Pending,
      transactionReference: transactionData.transactionReference,
    });

  public recordTransactionConfirmPaymentDetailSuccessfulEvent = (
    transactionCreditCardDetail: TransactionCreditCardDetail,
    transactionData: TransactionData
  ) =>
    this.recordTransactionEvent({
      transactionData,
      buttonAction: TransactionButtonAction.ConfirmPaymentDetail,
      transactionAction: TransactionAction.Save,
      transactionStatus: TransactionStatus.Pending,
      transactionCreditCardDetail,
      transactionReference: transactionData.transactionReference,
    });

  public recordTransactionPayAndConfirmSuccessfulEvent = (
    transactionCreditCardDetail: TransactionCreditCardDetail,
    transactionData: TransactionData,
    transactionDataFromGET: TransactionData
  ) =>
    this.recordTransactionEvent({
      transactionData,
      buttonAction: TransactionButtonAction.ConfirmPaymentDetail,
      transactionAction: TransactionAction.Approve,
      transactionStatus: TransactionStatus.Approved,
      transactionCreditCardDetail,
      paymentStatus: TransactionPaymentStatus.Successful,
      transactionReference: transactionDataFromGET.transactionReference,
    });

  public recordTransactionPayAndConfirmFailedEvent = (
    transactionCreditCardDetail: TransactionCreditCardDetail,
    transactionData: TransactionData,
    paymentErrorMessage: string
  ) =>
    this.recordTransactionEvent({
      transactionData,
      buttonAction: TransactionButtonAction.ConfirmPaymentDetail,
      transactionAction: TransactionAction.Save,
      transactionStatus: TransactionStatus.Pending,
      transactionCreditCardDetail,
      paymentStatus: TransactionPaymentStatus.Failed,
      paymentErrorMessage,
      transactionReference: transactionData.transactionReference,
    });

  public recordTransactionPayLaterPrintEvent = (
    transactionData: TransactionData
  ) =>
    this.recordTransactionEvent({
      transactionData,
      buttonAction: TransactionButtonAction.PrintPayLaterConfirmation,
      transactionAction: TransactionAction.Save,
      transactionStatus: TransactionStatus.Pending,
      isPayLater: true,
    });

  public recordTransactionDataConfirmSuccessfulEvent = ({
    transactionData,
    confirmPath,
    requestParam,
    transactionDataFromGET,
  }: {
    transactionData: TransactionData;
    confirmPath?: ConfirmPath;
    requestParam?: TransactionRequestParams;
    transactionDataFromGET?: TransactionData;
  }) => {
    const isDeclineAction = equals(
      (
        transactionData.transactionDetail as TransactionDetail & {
          action?: TransactionAction;
        }
      ).action,
      TransactionAction.Decline
    );
    const isOffer = equals(confirmPath, ConfirmPath.Offer);
    const transactionAction = isDeclineAction
      ? TransactionAction.NotApprove
      : isOffer
      ? TransactionAction.Save
      : TransactionAction.Approve;
    const transactionStatus = isDeclineAction
      ? TransactionStatus.NotApproved
      : isOffer
      ? TransactionStatus.Pending
      : TransactionStatus.Approved;
    const shouldShowPayLater =
      TransactionMetadata[transactionData.transactionHeader.type]
        .includePayment;
    return this.recordTransactionEvent({
      transactionData,
      buttonAction: TransactionButtonAction.Confirm,
      transactionAction,
      transactionStatus,
      requestParam,
      ...(shouldShowPayLater ? { isPayLater: false } : {}),
      transactionReference: transactionDataFromGET?.transactionReference,
    });
  };

  public recordTransactionAbandonedEvent = (
    transactionData: TransactionData,
    requestParam?: TransactionRequestParams
  ) => {
    const transactionReference = transactionData.transactionReference;
    return this.recordTransactionEvent({
      transactionData,
      buttonAction: TransactionButtonAction.AbandonTransaction,
      transactionAction: TransactionAction.AbandonTransaction,
      transactionStatus: TransactionStatus.Deleted,
      transactionReference,
      requestParam,
    });
  };

  public withAcceptWithdrawRejectTransactionData = (
    recordEventMethod: (transactionData: TransactionData) => Observable<unknown>
  ) =>
    pipe(
      switchMap(() =>
        this.store
          .select(selectTransactionDataFromGET)
          .pipe(filter(complement(isNil)), take(1))
      ),
      switchMap((transactionData: TransactionData) =>
        recordEventMethod(transactionData)
      )
    );

  public recordAnalyticsEvent = (
    data: Partial<SearchActivityLogResultProperty>
  ) =>
    combineLatest([
      this.store.select(getUserProfile).pipe(filter(complement(isNil))),
      this.store.select(getActAsCustomer).pipe(filter(complement(isNil))),
      defer(() =>
        from(
          API.get('rest', '/ip-address', {
            headers: { 'Content-Type': 'application/json' },
          })
        )
      ).pipe(retry(3)),
    ]).pipe(
      take(1),
      switchMap(([userProfile, actAsCustomer, ip]) => {
        const activityLogData: Partial<SearchActivityLogResultProperty> = {
          ...data,
          channel: Channel.FD,
          userIPAddress: ip,
          userType: getUserType(Channel.FD, userProfile, actAsCustomer),
          userName: userProfile.userName,
          userCustomerId: userProfile.id.toString(),
          personaCustomerId: actAsCustomer.id.toString(),
        };
        return Analytics.record(
          {
            data: activityLogData,
            partitionKey: 'activity-log',
            streamName: environment.kinesisStreamName,
            immediate: true,
          },
          'AWSKinesis'
        );
      })
    );

  private recordTransactionEvent = ({
    transactionData,
    buttonAction,
    transactionAction,
    transactionStatus,
    requestParam,
    transactionCreditCardDetail,
    paymentStatus,
    paymentErrorMessage,
    isPayLater,
    transactionReference,
  }: {
    transactionData: TransactionData;
    buttonAction: TransactionButtonAction;
    transactionAction: TransactionAction;
    transactionStatus: TransactionStatus;
    requestParam?: TransactionRequestParams;
    transactionCreditCardDetail?: TransactionCreditCardDetail;
    paymentStatus?: TransactionPaymentStatus;
    paymentErrorMessage?: string;
    isPayLater?: boolean;
    transactionReference?: TransactionReference;
  }) =>
    this.recordAnalyticsEvent({
      userActivityLogEventTypeID:
        TransactionEventType[transactionData.transactionHeader.type],
      transaction: JSON.stringify({
        'Transaction ID': transactionData.transactionHeader.id,
        'Button action': buttonAction,
        'Transaction status': transactionStatus,
        'Transaction type':
          TransactionTypeReadable[transactionData.transactionHeader.type],
        'Transaction action': transactionAction,
      }),
      detail: getTransactionDetail({
        transactionData,
        requestParam,
        transactionCreditCardDetail,
        paymentStatus,
        paymentErrorMessage,
        isPayLater,
        transactionReference,
      }),
    });

  private recordEventWithCustomerDetail = (
    eventType: EventType,
    actAsCustomer: AgentCustomer,
    permissionList?: Permission[]
  ) => {
    const permissionListLog = isNil(permissionList)
      ? {}
      : { permissions: sortBy(identity)(permissionList) };
    return this.recordAnalyticsEvent({
      userActivityLogEventTypeID: eventType,
      detail: JSON.stringify(
        transformKeysToTitleCase({
          customerId: actAsCustomer.id,
          customerName: actAsCustomer.fullName,
          ...permissionListLog,
        })
      ),
    });
  };

  private recordManageEventWithCustomerDetail = (
    eventType: EventType,
    actAsCustomer: AgentCustomer,
    agent: Agent,
    buttonAction: ManageAgentsButtonAction
  ) => {
    return this.recordAnalyticsEvent({
      userActivityLogEventTypeID: eventType,
      detail: JSON.stringify(
        transformKeysToTitleCase({
          customerId: actAsCustomer.id,
          customerName: actAsCustomer.fullName,
          agentId: agent.id,
          agentName: agent.name,
          activePermissions: this.getActivePermissionsForAgent(agent),
          buttonAction,
        })
      ),
    });
  };

  private getActivePermissionsForAgent(agent: Agent) {
    const activePermissions = [
      agent.generalRole,
      agent.fisherRole,
      ...agent.fishingBusinessRoleList,
    ].flatMap((role?: AgentRole) => {
      if (isNil(role)) {
        return [];
      }
      return role.permissions
        .filter((permission) => permission.enabled)
        .map((permission) => permission.name);
    });
    return [...new Set(activePermissions)];
  }
}
