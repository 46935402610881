import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { CommonModule } from '@angular/common';
import { PrawnBallotEntry } from '@fishonline2023/shared/models';

@Component({
  selector: 'sv-ui-prawn-report-table',
  standalone: true,
  imports: [CommonModule],
  templateUrl: './prawn-report-table.component.html',
  styleUrls: ['./prawn-report-table.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PrawnReportTableComponent {
  @Input() reportList!: PrawnBallotEntry[];
}
