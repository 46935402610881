import { CommonModule } from '@angular/common';
import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  Output,
} from '@angular/core';

@Component({
  selector: 'sv-ui-card',
  standalone: true,
  imports: [CommonModule],
  templateUrl: './card.component.html',
  styleUrls: ['./card.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CardComponent {
  @Input() title = '';
  @Input() subTitle: string | null = '';
  @Input() description = '';
  @Input() htmlInput? = '';
  @Output() cardClick: EventEmitter<void> = new EventEmitter();

  public onCardClick(): void {
    this.cardClick.emit();
  }
}
