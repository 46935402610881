import {
  ChangeDetectionStrategy,
  Component,
  inject,
  Input,
} from '@angular/core';
import { CommonModule, Location } from '@angular/common';
import { Subject } from 'rxjs';

@Component({
  selector: 'sv-ui-search-detail-wrapper',
  standalone: true,
  imports: [CommonModule],
  templateUrl: './search-detail-wrapper.component.html',
  styleUrls: ['./search-detail-wrapper.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SearchDetailWrapperComponent {
  @Input() public submitSubject?: Subject<void>;
  @Input() public deleteSubject?: Subject<void>;
  @Input() public showDeleteButton?: boolean;
  @Input() public showSubmitButton?: boolean;
  @Input() public submitButtonTitle?: string = 'Submit';
  @Input() public backButtonTitle?: string = 'Back';

  protected location = inject(Location);
}
