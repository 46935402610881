import { Route } from '@angular/router';
import { DataLoaderComponent } from './data-loader/data-loader.component';
import { DataLoaderService } from './store/data-loader.service';
import { provideState } from '@ngrx/store';
import { provideEffects } from '@ngrx/effects';
import {
  DATA_LOADER_FEATURE_KEY,
  dataLoaderReducer,
} from './store/data-loader.reducers';
import { DataLoaderEffects } from './store/data-loader.effects';

export const dataLoaderRoutes: Route[] = [
  {
    path: '',
    providers: [
      provideState(DATA_LOADER_FEATURE_KEY, dataLoaderReducer),
      provideEffects(DataLoaderEffects),
      DataLoaderService,
    ],
    component: DataLoaderComponent,
  },
];
