import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  Output,
} from '@angular/core';
import { CommonModule } from '@angular/common';
import { ButtonComponent } from '../button/button.component';

@Component({
  selector: 'sv-ui-error',
  standalone: true,
  imports: [CommonModule, ButtonComponent],
  templateUrl: './error.component.html',
  styleUrls: ['./error.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ErrorComponent {
  @Input() errorMessage = '';
  @Input() isFullScreen = false;
  @Input() buttonLabel?: string | undefined;
  @Output() buttonAction: EventEmitter<void> = new EventEmitter<void>();

  onClick = (): void => {
    this.buttonAction?.emit();
  };
}
