import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  inject,
  Input,
  OnDestroy,
  OnInit,
} from '@angular/core';
import { CommonModule } from '@angular/common';
import {
  ComponentTwoPartyTransactionEntitySelectionForm,
  Message,
  TwoPartyTransactionEntitySelectionType,
} from '@fishonline2023/webapps/model/fd2023';
import { FormGroup } from '@angular/forms';
import { BehaviorSubject, filter, Subject, take, takeUntil } from 'rxjs';
import {
  AgentCustomer,
  FishingBusiness,
  ViewStatus,
} from '@fishonline2023/shared/models';
import {
  LoadingComponent,
  ToastService,
} from '@fishonline2023/webapps/shared/ui/base-components';
import { Store } from '@ngrx/store';
import { getFishingBusinessWithConsumableQuotaBalance } from '@fishonline2023/webapps/homepage/fd2023/store/home';
import { TwoPartyTransactionEntitySelectionFormComponent } from '../two-party-transaction-entity-selection-form/two-party-transaction-entity-selection-form.component';
import { complement, equals, isNil } from '@fishonline2023/shared/ramda';
import { TwoPartySelectionService } from '../two-party-selection.service';

@Component({
  selector: 'sv-ui-two-party-transfer-components-selection',
  standalone: true,
  imports: [
    CommonModule,
    LoadingComponent,
    TwoPartyTransactionEntitySelectionFormComponent,
  ],
  templateUrl: './two-party-transfer-components-selection.component.html',
  styleUrls: ['./two-party-transfer-components-selection.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [TwoPartySelectionService],
})
export class TwoPartyTransferComponentsSelectionComponent
  implements OnInit, OnDestroy
{
  @Input()
  public selectionFormGroup!: FormGroup<ComponentTwoPartyTransactionEntitySelectionForm>;
  @Input() public isReadOnly: boolean | null = false;
  protected readonly Message = Message;
  protected readonly ViewStatus = ViewStatus;
  protected readonly TwoPartyTransactionEntitySelectionType =
    TwoPartyTransactionEntitySelectionType;
  protected fromFishingBusinessList: FishingBusiness[] = [];
  protected toCustomerList: AgentCustomer[] = [];
  protected fishingBusinessByCustomerId: Record<string, FishingBusiness[]> = {};
  protected loadCustomerListViewStatus$ = new BehaviorSubject<ViewStatus>(
    ViewStatus.Initial
  );
  protected createFishingBusinessViewStatus$ = new BehaviorSubject<ViewStatus>(
    ViewStatus.Initial
  );
  private destroy$ = new Subject<void>();
  private cdr = inject(ChangeDetectorRef);
  private twoPartySelectionService = inject(TwoPartySelectionService);
  private toastService = inject(ToastService);
  private store = inject(Store);

  public ngOnInit() {
    this.loadToFishingBusinessListOnFromChange();
    this.detectChangeWhenSelectionFormGroupChange();
    this.populateFromFishingBusinessList();
  }

  public ngOnDestroy() {
    this.destroy$.next();
    this.destroy$.complete();
  }

  protected createFishingBusiness(customer: AgentCustomer) {
    this.createFishingBusinessViewStatus$.next(ViewStatus.Loading);
    return this.twoPartySelectionService
      .createNewFishingBusiness(
        customer.id,
        (<FishingBusiness>this.selectionFormGroup.controls.from.value).type
      )
      .subscribe({
        next: (fishingBusiness) => {
          this.createFishingBusinessViewStatus$.next(ViewStatus.Success);
          this.selectionFormGroup.controls.to.patchValue(fishingBusiness);
        },
        error: (error) => {
          this.createFishingBusinessViewStatus$.next(ViewStatus.Failure);
          this.toastService.showError(Message.CreateFishingBusinessError);
          throw error;
        },
      });
  }

  private detectChangeWhenSelectionFormGroupChange() {
    this.selectionFormGroup.valueChanges
      .pipe(takeUntil(this.destroy$))
      .subscribe(() => this.cdr.detectChanges());
  }

  private loadToFishingBusinessListOnFromChange() {
    this.selectionFormGroup.controls.from.valueChanges
      .pipe(filter(complement(isNil)))
      .subscribe(this.loadToFishingBusinessList);
  }

  private populateFromFishingBusinessList() {
    this.store
      .select(getFishingBusinessWithConsumableQuotaBalance)
      .pipe(take(1))
      .subscribe((fromFishingBusinessList) => {
        this.fromFishingBusinessList = fromFishingBusinessList;
        if (equals(this.fromFishingBusinessList.length, 1)) {
          this.selectionFormGroup.patchValue({
            from: this.fromFishingBusinessList[0],
          });
        }
      });
  }

  private loadToFishingBusinessList = (
    fromFishingBusiness: FishingBusiness
  ) => {
    this.loadCustomerListViewStatus$.next(ViewStatus.Loading);
    return this.twoPartySelectionService
      .getToFishingBusinessList(fromFishingBusiness.id)
      .subscribe({
        next: ({ customerList, fishingBusinessByCustomerId }) => {
          this.loadCustomerListViewStatus$.next(ViewStatus.Success);
          this.toCustomerList = customerList;
          this.fishingBusinessByCustomerId = fishingBusinessByCustomerId;
        },
        error: (error) => {
          this.loadCustomerListViewStatus$.next(ViewStatus.Failure);
          this.toastService.showError(Message.LoadingFishingBusinessesError);
          throw error;
        },
      });
  };
}
