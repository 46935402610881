import {
  ChangeDetectionStrategy,
  Component,
  Input,
  OnInit,
} from '@angular/core';
import { CommonModule } from '@angular/common';
import {
  FishingPeriod,
  QuotaRegime,
  QuotaTransferReference,
  TransactionData,
  TransactionHeader,
  TransferQuotaDetail,
} from '@fishonline2023/webapps/model/fd2023';
import { equals, isNil, prop } from '@fishonline2023/shared/ramda';
import { MessageComponent } from '@fishonline2023/webapps/shared/ui/base-components';
import { FishingBusiness } from '@fishonline2023/shared/models';

@Component({
  selector: 'sv-ui-transfer-quota-receipt',
  standalone: true,
  imports: [CommonModule, MessageComponent],
  templateUrl: './transfer-quota-receipt.component.html',
  styleUrls: ['./transfer-quota-receipt.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TransferQuotaReceiptComponent implements OnInit {
  @Input() public transactionData!: TransactionData;

  protected transferQuotaDetail!: TransferQuotaDetail;
  protected transferQuotaHeader!: TransactionHeader;
  protected transferQuotaReference!: QuotaTransferReference;
  protected fromFishingBusiness!: FishingBusiness;
  protected toFishingBusiness!: FishingBusiness;
  protected fishingPeriod!: FishingPeriod | undefined;

  public get unit(): string {
    const quotaRegime = this.quotaRegime;
    if (isNil(quotaRegime)) return '';
    this.fishingPeriod = this.getFishingPeriod(quotaRegime);
    return this.quotaRegime?.unit ?? '';
  }

  public get quotaRegime(): QuotaRegime | undefined {
    const quotaRegimeList = prop(
      'quotaRegimeList',
      this.transferQuotaReference
    ) as QuotaRegime[];
    return (
      quotaRegimeList.find((quotaRegime) =>
        equals(quotaRegime.id, this.transferQuotaDetail.quotaRegimeId)
      ) ?? undefined
    );
  }

  public ngOnInit() {
    this.transferQuotaHeader = prop(
      'transactionHeader',
      this.transactionData
    ) as TransactionHeader;
    this.transferQuotaReference = prop(
      'transactionReference',
      this.transactionData
    ) as QuotaTransferReference;
    this.transferQuotaDetail = prop(
      'transactionDetail',
      this.transactionData
    ) as TransferQuotaDetail;
    this.fromFishingBusiness = prop(
      'from',
      this.transferQuotaDetail
    ) as FishingBusiness;
    this.toFishingBusiness = prop(
      'to',
      this.transferQuotaDetail
    ) as FishingBusiness;
  }

  private getFishingPeriod(
    quotaRegime: QuotaRegime
  ): FishingPeriod | undefined {
    return (
      quotaRegime?.fishingPeriodList.find((fishingPeriod) =>
        equals(fishingPeriod.id, this.transferQuotaDetail.fishingPeriodId)
      ) ?? undefined
    );
  }
}
