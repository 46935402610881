import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TransactionData } from '@fishonline2023/webapps/model/fd2023';
import {
  FullWidthItemListComponent,
  LicenceDetailInfoComponent,
} from '@fishonline2023/webapps/shared/ui/base-components';
import { RenewLicenceConfirmReceiptComponent } from '../renew-licence-confirm-receipt/renew-licence-confirm-receipt.component';

@Component({
  selector: 'sv-ui-renew-licence-confirm',
  standalone: true,
  imports: [
    CommonModule,
    LicenceDetailInfoComponent,
    FullWidthItemListComponent,
    RenewLicenceConfirmReceiptComponent,
  ],
  templateUrl: './renew-licence-confirm.component.html',
  styleUrls: ['./renew-licence-confirm.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class RenewLicenceConfirmComponent {
  @Input() public transactionData!: TransactionData;
}
