import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  inject,
  Input,
  OnInit,
} from '@angular/core';
import { CommonModule } from '@angular/common';
import {
  FDDataLoaderData,
  TransactionData,
  TransactionMetadata,
} from '@fishonline2023/webapps/model/fd2023';
import { map, Observable, take } from 'rxjs';
import { Store } from '@ngrx/store';
import { SearchDetailWrapperComponent } from '@fishonline2023/webapps/shared/ui/base-components';
import { TransactionReceiptContentComponent } from '@fishonline2023/webapps/shared/ui/transaction';
import { getUserProfile } from '@fishonline2023/webapps/user-portal/fd2023/store/user-profile';

@Component({
  selector: 'sv-ui-recently-completed-transaction-receipt',
  standalone: true,
  imports: [
    CommonModule,
    SearchDetailWrapperComponent,
    TransactionReceiptContentComponent,
  ],
  templateUrl: './recently-completed-transaction-receipt.component.html',
  styleUrls: ['./recently-completed-transaction-receipt.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class RecentlyCompletedTransactionReceiptComponent implements OnInit {
  @Input() dataLoaderData$?: Observable<FDDataLoaderData>;
  protected transactionTitle!: string;
  private store = inject(Store);
  protected userProfile$ = this.store.select(getUserProfile);
  private cdr = inject(ChangeDetectorRef);

  public get transactionData$() {
    return this.dataLoaderData$ as Observable<TransactionData>;
  }

  public ngOnInit(): void {
    this.transactionData$
      .pipe(
        map(
          ({ transactionHeader: { type } }) =>
            `${TransactionMetadata[type].transactionTitle} Receipt`
        ),
        take(1)
      )
      .subscribe((title) => {
        this.transactionTitle = title;
        this.cdr.detectChanges();
      });
  }
}
