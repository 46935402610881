import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  inject,
  Input,
  OnChanges,
  OnDestroy,
  SimpleChanges,
} from '@angular/core';
import { CommonModule } from '@angular/common';
import {
  AbstractControl,
  FormControl,
  ReactiveFormsModule,
} from '@angular/forms';
import { AutoCompleteComponent } from '../auto-complete/auto-complete.component';
import { debounceTime, filter, startWith, Subscription, timer } from 'rxjs';
import { equals, isNilOrEmpty, omit, path } from '@fishonline2023/shared/ramda';
import { TypeaheadOption } from '@fishonline2023/shared/models';

@Component({
  selector: 'sv-ui-typeahead',
  standalone: true,
  imports: [CommonModule, ReactiveFormsModule, AutoCompleteComponent],
  templateUrl: './typeahead.component.html',
  styleUrls: ['./typeahead.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TypeaheadComponent<T> implements OnChanges, OnDestroy {
  @Input() public form!: FormControl | AbstractControl;
  @Input() public options!: TypeaheadOption<T>[];
  @Input() public valuePath: string[] = [];
  @Input() public isAllValueWithoutLabel = false;
  @Input() public placeholder = '';
  @Input() public withSearchIcon = false;

  protected filteredOptions: Array<TypeaheadOption<T>> = [];
  protected labelFormControl = new FormControl<string>('', {
    nonNullable: true,
  });
  private cdr = inject(ChangeDetectorRef);
  private optionSelectionFormValueChanged$$?: Subscription;
  private formValueChanged$$?: Subscription;

  public ngOnChanges(changes: SimpleChanges) {
    if (this.optionsNotChanged(changes)) {
      return;
    }
    this.updateLabelControl();
    this.resetLabelFormWhenFormValueIsReset();
  }

  public ngOnDestroy() {
    this.formValueChanged$$?.unsubscribe();
  }

  protected onOptionSelected(option: TypeaheadOption<T>) {
    this.form.patchValue(
      this.isAllValueWithoutLabel
        ? omit('label', option)
        : path(this.valuePath, option)
    );
    this.labelFormControl.patchValue(option.label);
  }

  protected updateSearchOptions() {
    this.optionSelectionFormValueChanged$$ = this.labelFormControl.valueChanges
      .pipe(startWith(this.labelFormControl.value), debounceTime(200))
      .subscribe((value) => {
        this.filteredOptions = (this.options as TypeaheadOption<T>[]).filter(
          (selection) =>
            selection?.label?.toLowerCase()?.includes(value.toLowerCase())
        );
        this.cdr.detectChanges();
      });
  }

  protected clearSearchOptions() {
    timer(300).subscribe(() => {
      this.filteredOptions = [];
      this.optionSelectionFormValueChanged$$?.unsubscribe();
      this.cdr.detectChanges();
    });
  }

  private optionsNotChanged(changes: SimpleChanges) {
    return equals(
      changes['options'].previousValue,
      changes['options'].currentValue
    );
  }

  private resetLabelFormWhenFormValueIsReset() {
    this.formValueChanged$$ = this.form.valueChanges
      .pipe(filter(isNilOrEmpty))
      .subscribe(() => {
        this.labelFormControl.reset();
        this.cdr.detectChanges();
      });
  }

  private updateLabelControl() {
    const searchFieldValueLabel = this.options.find((option) =>
      equals(path(this.valuePath, option), this.form.value)
    )?.label;
    this.labelFormControl.patchValue(searchFieldValueLabel ?? '');
  }
}
