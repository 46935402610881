import { Customer, FishingBusiness } from '@fishonline2023/shared/models';

export interface Condition {
  id: string;
  abbreviation: string;
  detail: string;
}

export interface FisherHistory {
  fisher: Customer;
  transactionId?: number;
  authorisationDate: Date;
  revokedDate: Date | null;
}

export interface DeterminationCertificateHolding {
  shareClassId: number;
  shareClassName: string;
  shareType: string;
  holdingNumber: number;
  mortgageNumber: number;
  endorsementAvailable: boolean;
  endorsementConditionList: string[];
  nonConsumableQuotaList: string[];
}

export interface DeterminationCertificate {
  id: number;
  reason: string;
  transactionId: number;
  issueDate: Date;
  revokedDate: Date;
  determinationDate: Date;
  holdingList: DeterminationCertificateHolding[];
  fisherHistoryList: FisherHistory[];
  currentFisher: FisherHistory;
}

export interface FishingBusinessHistory {
  fishingBusiness: FishingBusiness;
  determinationCertificateList: DeterminationCertificate[];
}

export interface FishingBusinessHistoryResponse {
  fishingBusinessHistoryList: FishingBusinessHistory[];
  fishingBusinessHistoryReference: {
    conditionList: Condition[];
  };
}
