import * as bootstrap from 'bootstrap';
import { BehaviorSubject } from 'rxjs';

export const toggleCollapse = (
  isCollapseShown$: BehaviorSubject<boolean>,
  collapse?: bootstrap.Collapse
) => {
  const isCollapseShown = isCollapseShown$.getValue();
  isCollapseShown$.next(!isCollapseShown);
  if (isCollapseShown) {
    return collapse?.hide();
  }
  collapse?.show();
};
