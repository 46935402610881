<div class="footer full-viewport-bg">
  <div class="container flex-container">
    <div class="flex-container">
      <span *ngFor="let link of links">
        <a href="{{ link.url }}" target="_blank">{{ link.title }}</a>
      </span>
    </div>
    <p class="m-0">Version: {{ version }}</p>
  </div>
</div>
