import { FormControl } from '@angular/forms';
import { FishingBusiness } from '@fishonline2023/shared/models';

export interface TransferQuotaDetail {
  from: FishingBusiness;
  to: FishingBusiness;
  fishingPeriodId: number;
  quotaRegimeId: number;
  transactionDescription: string;
  quantity: number;
  price: number;
  receiverDescription: string;
}

export interface QuotaRegime {
  id: number;
  name: string;
  allowDecimals: boolean;
  unit: string;
  fishingPeriodList: FishingPeriod[];
}

export interface FishingPeriod {
  id: number;
  name: string;
  availableBalance: number;
}

export interface TransferQuotaForm {
  from: FormControl<FishingBusiness | null>;
  to: FormControl<FishingBusiness | null>;
  quotaRegimeId: FormControl<number | undefined | null>;
  fishingPeriodId: FormControl<number | undefined | null>;
  transactionDescription?: FormControl<string | undefined | null>;
  quantity: FormControl<number | null>;
  price: FormControl<number | string | null>;
  receiverDescription: FormControl<string | undefined | null>;
}

export interface QuotaTransferReference {
  quotaRegimeList: QuotaRegime[];
}
