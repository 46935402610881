import { createFeatureSelector, createSelector } from '@ngrx/store';
import { SEARCH_FEATURE_KEY, SearchState } from './search.reducer';
import { isNil } from '@fishonline2023/shared/ramda';
import { searchMetadata } from '@fishonline2023/shared/models';

export const selectSearchState =
  createFeatureSelector<SearchState>(SEARCH_FEATURE_KEY);

export const selectSearchViewStatus = createSelector(
  selectSearchState,
  (state) => state.searchViewStatus
);

export const selectDownloadViewStatus = createSelector(
  selectSearchState,
  (state) => state.downloadViewStatus
);

export const selectLoadSearchFilterOptionsViewStatus = createSelector(
  selectSearchState,
  (state) => state.loadSearchFilterOptionsViewStatus
);

export const selectSearchFilter = createSelector(
  selectSearchState,
  (state) => state.searchFilter
);

export const selectSearchResult = createSelector(
  selectSearchState,
  (state) => state.searchResult
);

export const selectSearchFieldValues = createSelector(
  selectSearchState,
  (state) => state.searchFieldValues
);

export const selectSearchType = createSelector(
  selectSearchState,
  (state) => state.searchType
);

export const selectSearchResultDisplayColumnList = createSelector(
  selectSearchState,
  (state) => state.searchResultDisplayColumnList
);

export const selectSelectedSearchResultItem = createSelector(
  selectSearchState,
  (state) => state.selectedSearchResultItem
);

export const selectSearchAppRoute = createSelector(
  selectSearchType,
  (searchType) => {
    if (isNil(searchType)) {
      return undefined;
    }
    return searchMetadata[searchType].appRoute;
  }
);
