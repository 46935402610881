import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { CommonModule } from '@angular/common';
import {
  DualColumnItemsListComponent,
  SearchDetailWrapperComponent,
} from '@fishonline2023/webapps/shared/ui/base-components';
import {
  TransactionData,
  TransactionType,
  UserProfile,
} from '@fishonline2023/webapps/model/fd2023';
import { AmendCustomerReceiptComponent } from '@fishonline2023/webapps/my-account/fd2023/ui/amend-customer';
import { NominateRevokeFisherReceiptComponent } from '@fishonline2023/webapps/authorised-fishers/fd2023/ui/nominate-revoke-fisher-receipt';
import { RenewLicenceReceiptComponent } from '@fishonline2023/webapps/licence/fd2023/ui/renew-licence';
import { TransferQuotaReceiptComponent } from '@fishonline2023/webapps/quota-fisheries/fd2023/ui/transfer-quota-receipt';
import { TransferFishingBusinessComponentsReceiptComponent } from '@fishonline2023/webapps/fishing-business/fd2023/ui/shared';

@Component({
  selector: 'sv-ui-transaction-receipt-content',
  standalone: true,
  imports: [
    CommonModule,
    DualColumnItemsListComponent,
    AmendCustomerReceiptComponent,
    NominateRevokeFisherReceiptComponent,
    RenewLicenceReceiptComponent,
    TransferQuotaReceiptComponent,
    SearchDetailWrapperComponent,
    TransferFishingBusinessComponentsReceiptComponent,
  ],
  templateUrl: './transaction-receipt-content.component.html',
  styleUrls: ['./transaction-receipt-content.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TransactionReceiptContentComponent {
  @Input() public transactionData!: TransactionData;
  @Input() public transactionType: TransactionType | undefined;
  @Input() public userProfile?: UserProfile | undefined | null;
  protected readonly TransactionType = TransactionType;
}
