export enum TransactionActionButtonClassName {
  PrimaryButton = 'primary-button',
  DangerButton = 'danger-button',
  WhiteButton = 'white-button',
}

export enum TransactionActionButtonPosition {
  Start,
  End,
}

export interface TransactionActionButton {
  label: TransactionButtonType;
  className: TransactionActionButtonClassName;
  disabled: boolean;
  position: TransactionActionButtonPosition;
  buttonType: TransactionButtonType;
}
export enum TransactionButtonType {
  Delete = 'Delete',
  Reset = 'Reset',
  Save = 'Save',
  NotApproved = 'Not Approved',
  ApproveAndSubmit = 'Approve & Submit',
  GoBack = 'Go back',
  Confirm = 'Confirm',
  ReturnToHome = 'Return to home',
  Print = 'Print',
}

export type SubmitActionFromButtonType =
  | TransactionButtonType.Save
  | TransactionButtonType.Delete
  | TransactionButtonType.NotApproved
  | TransactionButtonType.ApproveAndSubmit;

export type SubmitActionFromButtonTypeInConfirmationPageContext = Exclude<
  SubmitActionFromButtonType,
  TransactionButtonType.Save
>;
