import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  Output,
} from '@angular/core';
import { CommonModule } from '@angular/common';
import { QuotaBalanceData } from '@fishonline2023/webapps/model/fd2023';
import { RouterLink } from '@angular/router';
import { FDRoute } from '@fishonline2023/shared/models';

@Component({
  selector: 'sv-ui-side-banner',
  standalone: true,
  imports: [CommonModule, RouterLink],
  templateUrl: './side-banner.component.html',
  styleUrls: ['./side-banner.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SideBannerComponent {
  @Input() title = '';
  @Input() quotaBalanceData!: QuotaBalanceData;
  @Output() showAllClicked: EventEmitter<boolean> = new EventEmitter<boolean>();
  protected readonly FDRoute = FDRoute;

  public isShowAllLinkVisible = true;

  public handleLinkClick(): void {
    this.isShowAllLinkVisible = !this.isShowAllLinkVisible;
    this.showAllClicked.emit(this.isShowAllLinkVisible);
  }
}
