import { createFeatureSelector, createSelector } from '@ngrx/store';
import {
  SystemStatusState,
  SYSTEM_STATUS_FEATURE_KEY,
} from './system-status.reducers';

export const userPortalFeature = createFeatureSelector<SystemStatusState>(
  SYSTEM_STATUS_FEATURE_KEY
);

export const getSystemStatus = createSelector(
  userPortalFeature,
  (state: SystemStatusState) => state?.systemStatus
);

export const getAccountStatus = createSelector(
  userPortalFeature,
  (state: SystemStatusState) => state?.accountStatus
);

export const getViewStatus = createSelector(
  userPortalFeature,
  (state: SystemStatusState) => state?.viewStatus
);

export const getErrorMessage = createSelector(
  userPortalFeature,
  (state: SystemStatusState) => state?.errorMessage
);

export const getSystemEnableStatus = createSelector(
  userPortalFeature,
  (state: SystemStatusState) => state?.systemStatus?.systemEnable
);
