<div
  #modalContainer
  [attr.aria-labelledby]="modalId"
  aria-hidden="true"
  class="modal fade"
  tabindex="-1"
>
  <div [class]="'modal-dialog modal-dialog-centered ' + modalSize">
    <div class="modal-content">
      <ng-content></ng-content>
    </div>
  </div>
</div>
