<h4 class="mt-4">My Fishing Business Authorisations</h4>
<form class="mb-4">
  <div class="row mt-3">
    <div class="col text-center">
      <select
        [formControl]="fishingBusinessId"
        class="form-select form-select-lg"
        aria-label=".form-select-lg example"
      >
        <option
          value=""
          disabled
          selected
          *ngIf="fishingBusinessList.length > 1"
        >
          Select fishing business from list
        </option>
        <option *ngFor="let fishingBusiness of fishingBusinessList">
          {{ fishingBusiness }}
        </option>
      </select>
    </div>
  </div>
</form>
<sv-ui-authorisations-list
  *ngIf="(dataLoaderData$ | async) && authorisations.length > 0"
  [tableFields]="tableFields"
  [authorisations]="authorisations"
  [columnSorted$]="columnSorted$"
  (sortAuthorisationResults)="handleAuthorisationsSort($event)"
></sv-ui-authorisations-list>
