<div class="row">
  <div
    *ngFor="let item of dynamicWidthItemList"
    [class]="'col-xl-' + item.width"
    class="col-md-12"
  >
    <p class="fw-semibold mb-1">{{ item.label }}</p>
    <p>{{ item.value }}</p>
  </div>
</div>
