import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { CommonModule } from '@angular/common';
import {
  CustomFormValidator,
  InputField,
  InputType,
} from '@fishonline2023/shared/models';
import { FormControl, ReactiveFormsModule } from '@angular/forms';
import { InputFieldTextComponent } from '../input-field-text/input-field-text.component';
import { InputFieldSelectComponent } from '../input-field-select/input-field-select.component';
import { InputFieldTypeaheadComponent } from '../input-field-typeahead/input-field-typeahead.component';
import { InputFieldDatetimeComponent } from '../input-field-datetime/input-field-datetime.component';
import { InputFieldDateComponent } from '../input-field-date/input-field-date.component';
import { InputFieldCheckboxComponent } from '../input-field-checkbox/input-field-checkbox.component';
import { InputFieldTextareaComponent } from '../input-field-textarea/input-field-textarea.component';
import { InputFieldPriceComponent } from '../input-field-price/input-field-price.component';
import { InputFieldABNComponent } from '../input-field-abn/input-field-abn.component';
import { InputFieldACNComponent } from '../input-field-acn/input-field-acn.component';

@Component({
  selector: 'sv-ui-input-field',
  standalone: true,
  imports: [
    CommonModule,
    ReactiveFormsModule,
    InputFieldTextComponent,
    InputFieldSelectComponent,
    InputFieldTypeaheadComponent,
    InputFieldDatetimeComponent,
    InputFieldDateComponent,
    InputFieldCheckboxComponent,
    InputFieldTextareaComponent,
    InputFieldPriceComponent,
    InputFieldABNComponent,
    InputFieldACNComponent,
  ],
  templateUrl: './input-field.component.html',
  styleUrls: ['./input-field.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class InputFieldComponent {
  @Input() public inputField!: InputField;
  @Input() public form!: FormControl;
  protected readonly InputType = InputType;
  protected readonly CustomFormValidator = CustomFormValidator;
}
