import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { CommonModule } from '@angular/common';
import {
  TransactionData,
  TransactionType,
  TransferComponentsTransactionDetail,
  TransferDetail,
  TransferFishingBusinessComponentsTransactionDetail,
  TransferFishingBusinessComponentsTransactionReference,
  TransferFishingBusinessTransactionDetail,
} from '@fishonline2023/webapps/model/fd2023';
import { isNilOrEmpty, prop, sum } from '@fishonline2023/shared/ramda';
import { TransferFishingBusinessComponentsTransferDetailComponent } from '../transfer-fishing-business-components-transfer-detail/transfer-fishing-business-components-transfer-detail.component';
import { TwoPartyTransferComponentsSelectionComponent } from '../two-party-transfer-components-selection/two-party-transfer-components-selection.component';
import { TwoPartyTransferFishingBusinessSelectionComponent } from '../two-party-transfer-fishing-business-selection/two-party-transfer-fishing-business-selection.component';
import {
  componentsTwoPartyTransactionEntitySelectionForm,
  fishingBusinessTwoPartyTransactionEntitySelectionForm,
} from '../transfer-fishing-business-components-form';

@Component({
  selector: 'sv-ui-transfer-fishing-business-components-offer-detail',
  standalone: true,
  imports: [
    CommonModule,
    TransferFishingBusinessComponentsTransferDetailComponent,
    TwoPartyTransferComponentsSelectionComponent,
    TwoPartyTransferFishingBusinessSelectionComponent,
  ],
  templateUrl:
    './transfer-fishing-business-components-offer-detail.component.html',
  styleUrls: [
    './transfer-fishing-business-components-offer-detail.component.scss',
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TransferFishingBusinessComponentsOfferDetailComponent {
  @Input() public transactionData!: TransactionData;
  @Input() public isAcceptOffer = false;

  protected readonly TransactionType = TransactionType;

  protected get transactionReference() {
    return this.transactionData
      .transactionReference as TransferFishingBusinessComponentsTransactionReference;
  }

  protected get twoPartyTransferFishingBusinessSelectionForm() {
    const formGroup = fishingBusinessTwoPartyTransactionEntitySelectionForm();
    const { from, to } = this.transactionData
      .transactionDetail as TransferFishingBusinessTransactionDetail;
    formGroup.patchValue({ from, to });
    return formGroup;
  }

  protected get twoPartyTransferComponentsSelectionForm() {
    const formGroup = componentsTwoPartyTransactionEntitySelectionForm();
    const { from, to } = this.transactionData
      .transactionDetail as TransferComponentsTransactionDetail;
    formGroup.patchValue({ from, to });
    return formGroup;
  }

  protected get transferFee() {
    return (
      this.transactionData.transactionDetail as
        | TransferFishingBusinessTransactionDetail
        | TransferComponentsTransactionDetail
    ).transferFee;
  }

  protected hasComponents() {
    return !isNilOrEmpty(this.components());
  }

  protected components() {
    const componentList = (<TransferFishingBusinessComponentsTransactionDetail>(
      this.transactionData.transactionDetail
    )).componentList;
    return this.sortComponentQuotaList(componentList);
  }

  protected hasQuotas() {
    return !isNilOrEmpty(this.quotas());
  }

  protected quotas() {
    const quotaList = (<TransferFishingBusinessComponentsTransactionDetail>(
      this.transactionData.transactionDetail
    )).quotaList;
    return this.sortComponentQuotaList(quotaList);
  }

  protected totalComponentsTransferByProperty(property: keyof TransferDetail) {
    const { componentList } = <
      TransferFishingBusinessComponentsTransactionDetail
    >this.transactionData.transactionDetail;
    return sum(componentList.map<number>(prop(property)));
  }

  protected totalQuotaTransferByProperty(property: keyof TransferDetail) {
    const { quotaList } = <TransferFishingBusinessComponentsTransactionDetail>(
      this.transactionData.transactionDetail
    );
    return sum(quotaList.map<number>(prop(property)));
  }

  private sortComponentQuotaList(list: TransferDetail[]) {
    return [...list].sort((a, b) => {
      if (a.quantity > b.quantity) {
        return -1;
      }
      if (a.quantity < b.quantity) {
        return 1;
      }
      return a.name.localeCompare(b.name);
    });
  }
}
