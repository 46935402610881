import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { CommonModule } from '@angular/common';
import {
  FullWidthItemListComponent,
  TableComponent,
} from '@fishonline2023/webapps/shared/ui/base-components';
import {
  CurrentlyOwnedFishingBusiness,
  FDDateDayJSFormat,
  FDDateTimeDayJSFormat,
  PreviouslyOwnedFishingBusiness,
} from '@fishonline2023/webapps/model/fd2023';
import * as dayjs from 'dayjs';
import { has } from '@fishonline2023/shared/ramda';
import { ItemList, TableData, tableRow } from '@fishonline2023/shared/models';

@Component({
  selector: 'sv-ui-fishing-business-section',
  standalone: true,
  imports: [CommonModule, FullWidthItemListComponent, TableComponent],
  templateUrl: './fishing-business-section.component.html',
  styleUrls: ['./fishing-business-section.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class FishingBusinessSectionComponent {
  @Input() public title!: string;
  @Input() public fishingBusiness!:
    | CurrentlyOwnedFishingBusiness
    | PreviouslyOwnedFishingBusiness;

  protected get fishingBusinessDetail(): ItemList {
    return [
      {
        key: 'Fishing Business Number',
        value: this.fishingBusiness.fishingBusinessNumber,
      },
      ...(this.hasProperty('certificateNumber')
        ? [
            {
              key: 'Certificate Number',
              value: (<CurrentlyOwnedFishingBusiness>this.fishingBusiness)
                .certificateNumber,
            },
          ]
        : []),
      ...(this.hasProperty('issueDate')
        ? [
            {
              key: 'Issue Date',
              value: dayjs(
                (<CurrentlyOwnedFishingBusiness>this.fishingBusiness).issueDate
              ).format(FDDateTimeDayJSFormat),
            },
          ]
        : []),
    ];
  }

  protected get currentShareHoldingList(): TableData {
    return (<CurrentlyOwnedFishingBusiness>(
      this.fishingBusiness
    )).currentShareholdingList.map((currentShareholding) => ({
      'Share Class Name': tableRow(currentShareholding.shareClassName),
      'Shareholder Name': tableRow(currentShareholding.shareholderName ?? ''),
      'Share Class Code': tableRow(currentShareholding.shareClassCode),
      'Share Type': tableRow(currentShareholding.shareType),
      'Expiry Date': tableRow(
        dayjs(currentShareholding.expiryDate).format(FDDateDayJSFormat)
      ),
      Holdings: tableRow(currentShareholding.holdings),
      'Mortgage Interest Holding': tableRow(
        currentShareholding.mortgageInterestHolding
      ),
    }));
  }

  protected get renewHistoryList(): TableData {
    return this.fishingBusiness.shareHistoryDetails.renewalHistoryList.map(
      (renewalHistory) => ({
        Date: tableRow(dayjs(renewalHistory.date).format(FDDateDayJSFormat)),
        'Share Class Code': tableRow(renewalHistory.shareClassCode),
        'Share Class Name': tableRow(renewalHistory.shareClassName),
      })
    );
  }

  protected get transactionHistoryList(): TableData {
    return this.fishingBusiness.shareHistoryDetails.transactionHistoryList.map(
      (transactionHistory) => ({
        'Date/Time': tableRow(
          dayjs(transactionHistory.dateTime).format(FDDateDayJSFormat)
        ),
        'Transaction Type': tableRow(transactionHistory.transactionType),
        Action: tableRow(transactionHistory.action),
        'Fishing Business No.': tableRow(
          transactionHistory.fishingBusinessNumber ?? ''
        ),
        'Shareholder Name': tableRow(transactionHistory.shareholderName ?? ''),
        'Share Class Code': tableRow(transactionHistory.shareClassCode),
        'Share Type': tableRow(transactionHistory.shareType),
        'Share Class Name': tableRow(transactionHistory.shareClassName),
        Quantity: tableRow(transactionHistory.quantity),
      })
    );
  }

  protected get mortgageRegisteredInterestHistoryDetails(): TableData {
    return this.fishingBusiness.mortgageRegisteredInterestHistoryDetailsList.map(
      (mortgageRegisteredInterestHistoryDetail) => ({
        'Date/Time': tableRow(
          dayjs(mortgageRegisteredInterestHistoryDetail.dateTime).format(
            FDDateDayJSFormat
          )
        ),
        'Transaction Type': tableRow(
          mortgageRegisteredInterestHistoryDetail.transactionType
        ),
        Action: tableRow(mortgageRegisteredInterestHistoryDetail.action),
        'Mortgagee/Registered Party Registration No.': tableRow(
          mortgageRegisteredInterestHistoryDetail.mortgageRegisteredPartyRegistrationNumber
        ),
        'Mortgagee/Registered Party Name': tableRow(
          mortgageRegisteredInterestHistoryDetail.mortgageRegisteredPartyName
        ),
        'Share Class Code': tableRow(
          mortgageRegisteredInterestHistoryDetail.shareClassCode
        ),
        'Share Type': tableRow(
          mortgageRegisteredInterestHistoryDetail.shareType
        ),
        'Share Class Name': tableRow(
          mortgageRegisteredInterestHistoryDetail.shareClassName
        ),
        Quantity: tableRow(mortgageRegisteredInterestHistoryDetail.quantity),
      })
    );
  }

  protected get currentAuthorisedFisherFor() {
    return (<CurrentlyOwnedFishingBusiness>this.fishingBusiness)
      .currentAuthorisedFisherFor;
  }

  protected get currentAuthorisedFisherForDetail(): TableData {
    return this.currentAuthorisedFisherFor.map((fishingBusiness) => ({
      'Date/Time': tableRow(
        dayjs(fishingBusiness.dateTime).format(FDDateTimeDayJSFormat)
      ),
      'Fishing Business No.': tableRow(
        fishingBusiness.fishingBusinessNumber ?? ''
      ),
      'Fisher Registration No.': tableRow(
        fishingBusiness.fisherRegistrationNumber
      ),
      'Fisher Name.': tableRow(fishingBusiness.fisherName),
    }));
  }

  protected get authorisationHistory(): TableData {
    return this.fishingBusiness.authorisationHistoryList.map(
      (authorisationHistory) => ({
        'Date/Time': tableRow(
          dayjs(authorisationHistory.dateTime).format(FDDateTimeDayJSFormat)
        ),
        'Transaction Type': tableRow(authorisationHistory.transactionType),
        'Fisher Registration No.': tableRow(
          authorisationHistory.fisherRegistrationNumber
        ),
        'Fisher Name.': tableRow(authorisationHistory.fisherName),
      })
    );
  }

  protected hasProperty(property: string) {
    return has(property, this.fishingBusiness);
  }
}
