import { createAction, props } from '@ngrx/store';
import {
  PaymentPath,
  TransactionCreditCardDetail,
  TransactionData,
  TransactionDetail,
  TransactionPaymentConfirmError,
  TransactionRequestParams,
  TransactionType,
  TrustedFrame,
} from '@fishonline2023/webapps/model/fd2023';

export const initTransactionType = createAction(
  '[Transaction/API] Init Transaction Type',
  props<{ transactionType: TransactionType }>()
);

export const transactionRequestParamsPrePopulated = createAction(
  '[Transaction/API] Transaction Request Params Pre Populated',
  props<{ transactionRequestParams: TransactionRequestParams }>()
);

export const transactionDataRequested = createAction(
  '[Transaction/API] Transaction Data Requested',
  props<{ params?: TransactionRequestParams }>()
);

export const transactionDataLoadSuccessful = createAction(
  '[Transaction/API] Transaction Data Load Successful',
  props<{ transactionData: TransactionData }>()
);

export const transactionDataLoadFailed = createAction(
  '[Transaction/API] Transaction Data Load Failed',
  props<{ errorMessage: string }>()
);

export const resetTransaction = createAction(
  '[Transaction/API] Reset Transaction'
);

export const transactionDataSubmitted = createAction(
  '[Transaction/API] Transaction Data Submitted',
  props<{ transactionDetail: TransactionDetail }>()
);

export const transactionDataSubmitSuccessful = createAction(
  '[Transaction/API] Transaction Data Submit Successful',
  props<{ transactionData: TransactionData }>()
);

export const transactionDataSubmitFailed = createAction(
  '[Transaction/API] Transaction Data Submit Failed',
  props<{ errorMessage: string; transactionData?: TransactionData }>()
);

export const transactionDataConfirm = createAction(
  '[Transaction/API] Transaction Data Confirm'
);

export const transactionDataConfirmSuccessful = createAction(
  '[Transaction/API] Transaction Data Confirm Successful',
  props<{ transactionData: TransactionData }>()
);

export const transactionDataConfirmFailed = createAction(
  '[Transaction/API] Transaction Data Confirm Failed',
  props<{ errorMessage: string }>()
);

export const transactionDataWithDraw = createAction(
  '[Transaction/API] Transaction Data WithDraw'
);

export const transactionDataWithDrawSuccessful = createAction(
  '[Transaction/API] Transaction Data WithDraw Successful',
  props<{ transactionData: TransactionData }>()
);

export const transactionDataWithDrawFailed = createAction(
  '[Transaction/API] Transaction Data WithDraw Failed',
  props<{ errorMessage: string }>()
);

export const transactionDataReject = createAction(
  '[Transaction/API] Transaction Data Reject'
);

export const transactionDataRejectSuccessful = createAction(
  '[Transaction/API] Transaction Data Reject Successful',
  props<{ transactionData: TransactionData }>()
);

export const transactionDataRejectFailed = createAction(
  '[Transaction/API] Transaction Data Reject Failed',
  props<{ errorMessage: string }>()
);

export const transactionDataAccept = createAction(
  '[Transaction/API] Transaction Data Accept'
);

export const transactionDataAcceptSuccessful = createAction(
  '[Transaction/API] Transaction Data Accept Successful',
  props<{ transactionData: TransactionData }>()
);

export const transactionDataAcceptFailed = createAction(
  '[Transaction/API] Transaction Data Accept Failed',
  props<{ errorMessage: string }>()
);

export const transactionPaymentCreditCardDetailValidationStatusReset =
  createAction(
    '[Transaction/API] Transaction Payment Credit Card Detail Validation Status Reset'
  );

export const transactionPaymentCreditCardDetailValidated = createAction(
  '[Transaction/API] Transaction Payment Credit Card Detail Validated',
  props<{ trustedFrame: TrustedFrame }>()
);

export const transactionPaymentCreditCardDetailValidatedSuccessful =
  createAction(
    '[Transaction/API] Transaction Payment Credit Card Detail Validated Successful',
    props<{ transactionCreditCardDetail: TransactionCreditCardDetail }>()
  );

export const transactionPaymentCreditCardDetailValidatedFailed = createAction(
  '[Transaction/API] Transaction Payment Credit Card Detail Validated Failed',
  props<{ errorMessage: string }>()
);

export const transactionDataPayAndConfirm = createAction(
  '[Transaction/API] Transaction Data Pay And Confirm',
  props<{ paymentPath: PaymentPath }>()
);

export const transactionDataPayAndConfirmSuccessful = createAction(
  '[Transaction/API] Transaction Data Pay And Confirm Successful',
  props<{ transactionData: TransactionData }>()
);

export const transactionDataPayAndConfirmFailed = createAction(
  '[Transaction/API] Transaction Data Pay And Confirm Failed',
  props<{ transactionPaymentConfirmError: TransactionPaymentConfirmError }>()
);

export const transactionDataPayLater = createAction(
  '[Transaction/API] Transaction Data Pay Later'
);

export const transactionProvideCardDetail = createAction(
  '[Transaction/API] Transaction Provide Card Detail'
);

export const transactionDataPayLaterSuccessful = createAction(
  '[Transaction/API] Transaction Data Pay Later Successful',
  props<{ transactionData: TransactionData }>()
);

export const transactionDataPayLaterFailed = createAction(
  '[Transaction/API] Transaction Data Pay Later Failed',
  props<{ errorMessage: string }>()
);

export const transactionCreditCardDetailReset = createAction(
  '[Transaction/API] Transaction Credit Card Detail Reset'
);

export const transactionPayLaterPrint = createAction(
  '[Transaction/API] Transaction Pay Later Print'
);

export const transactionAbandon = createAction(
  '[Transaction/API] Transaction Abandon'
);

export const transactionAbandonSuccessful = createAction(
  '[Transaction/API] Transaction Abandon Successful'
);

export const transactionAbandonFailed = createAction(
  '[Transaction/API] Transaction Abandon Failed',
  props<{ errorMessage: string }>()
);

export const transactionDataFromGETSubmitReset = createAction(
  '[Transaction/API] Transaction Data From GET Submit Reset'
);

export const acceptOfferPaymentOptionClicked = createAction(
  '[Transaction/API] Accept Offer Payment Option Clicked'
);
