<sv-ui-search-detail-wrapper
  *ngIf="fishingBusinessReport$ | async as fishingBusinessReport"
>
  <div class="d-flex mt-4 justify-content-between align-items-center mb-4">
    <h3>Fishing Business Extracts</h3>
    <button class="btn btn-primary d-print-none" (click)="window.print()">
      Print
    </button>
  </div>
  <sv-ui-fishing-business-section
    title=""
    [fishingBusiness]="fishingBusinessReport"
  ></sv-ui-fishing-business-section>
</sv-ui-search-detail-wrapper>
