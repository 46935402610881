import { Customer } from './customer.model';
import {
  CustomerTransactionReference,
  FACustomerDetail,
} from '@fishonline2023/shared/models';

export interface TransactionHeader {
  transactionType: TransactionType;
  transactionNumber: number | null;
  status: TransactionStatus;
  created: string;
  correction: boolean;
  initiatingChannel: string;
  lastModified: string;
}

export enum TransactionType {
  AmendCustomer = 'AMEND_CUSTOMER',
  CreateCustomer = 'CREATE_CUSTOMER',
}
export enum TransactionStatus {
  Pending = 'PENDING',
  Deleted = 'DELETED',
  Approved = 'APPROVED',
  NotApproved = 'NOT_APPROVED',
}

export type TransactionRequestParams =
  | SingleEntityRequestParams
  | TwoEntityRequestParams;

export interface SingleEntityRequestParams {
  entityId: number;
}

export interface TwoEntityRequestParams {
  entityId: number;
  toEntityId: number;
}

export type TransactionEntityItem = Customer;

export type TransactionEntityDetail = Customer;

export interface FaFdFee {
  [PaymentOption.FA_PAYMENT]: number;
  [PaymentOption.FD_PAYMENT]: number;
}
/*
  if a new non-pending transaction is licence renewal, can the FA_PAYMENT and FD_PAYMENT be the default licence term fee?
  if new non-pending transaction, manualInputFeeAmount = 0
  pending licence renewal transaction should return FA_PAYMENT and FD_PAYMENT as is
*/
export interface TransactionPayment extends FaFdFee {
  selectedPaymentOption: PaymentOption;
  [PaymentOption.USER_DEFINED_PAYMENT]: number;
  paymentHistoryList: PaymentHistory[];
}

export enum PaymentOption {
  FA_PAYMENT = 'FA_PAYMENT',
  FD_PAYMENT = 'FD_PAYMENT',
  USER_DEFINED_PAYMENT = 'USER_DEFINED_PAYMENT',
}

export const PaymentOptionDescriptionMap: Record<PaymentOption, string> = {
  [PaymentOption.FA_PAYMENT]: 'FisherAssist fee',
  [PaymentOption.FD_PAYMENT]: 'FisherDirect fee',
  [PaymentOption.USER_DEFINED_PAYMENT]: 'Input fee manually',
};

export interface PaymentHistory {
  date: string;
  amount: number;
  reference: string;
  method: PaymentMethod;
}

export enum PaymentMethod {
  Cash = 'Cash',
  Cheque = 'Cheque',
  CreditCard = 'Credit Card',
  Invoice = 'Invoice (Pay later)',
}

export type TransactionDetail = FACustomerDetail;

export interface TransactionData {
  transactionHeader: TransactionHeader;
  transactionEntityDetail: TransactionEntityDetail;
  transactionDetail: TransactionDetail;
  transactionCommentList: TransactionComment[];
  transactionPayment?: TransactionPayment;
  transactionReference?: TransactionReference;
  transactionInfo: TransactionInfo;
}

export interface TransactionComment {
  comment: string;
  createdAt: string;
  createdBy: string;
}

export type TransactionReference = CustomerTransactionReference;

export interface TransactionInfo {
  errorList: string[];
  warningList: string[];
  infoList: string[];
  permissionList: TransactionPermission[];
  isPaymentEnabled: boolean;
}

export interface TransactionSubmitPayload {
  transactionDetail: TransactionDetail;
  transactionComment?: string;
  transactionPayment?: TransactionPayment;
}

export enum TransactionPermission {
  ViewOnly = 'VIEW_ONLY',
  Edit = 'EDIT',
  Finalise = 'FINALISE',
}

export enum TransactionButtonAction {
  Save = 'SAVE',
  Delete = 'DELETE',
  Approve = 'APPROVE',
  NotApprove = 'NOT_APPROVE',
  Confirm = 'CONFIRM',
}

export enum TransactionAction {
  Save = 'SAVE',
  Approve = 'APPROVE',
  NotApprove = 'NOT_APPROVE',
  Delete = 'DELETE',
  AbandonTransaction = 'ABANDON_TRANSFER',
}
