import {
  TransactionAction,
  TransactionActionButtonClassName,
  TransactionActionButtonPosition,
  TransactionButtonType,
} from './transaction.enum';
import { TransactionActionButton } from './transaction.model';

export const backToTransactionButton = (
  label: string,
  disabled = false
): TransactionActionButton => ({
  label: `Back to: ${label}`,
  className: TransactionActionButtonClassName.WhiteButton,
  disabled,
  position: TransactionActionButtonPosition.Start,
  buttonType: TransactionButtonType.BackToTransaction,
});

export const nextProvideCardDetailsButton = (
  disabled = false
): TransactionActionButton => ({
  label: TransactionButtonType.NextProvideCardDetails,
  className: TransactionActionButtonClassName.PrimaryButton,
  disabled,
  position: TransactionActionButtonPosition.End,
  buttonType: TransactionButtonType.NextProvideCardDetails,
});

export const nextConfirmationPageButton = (
  disabled = false
): TransactionActionButton => ({
  label: TransactionButtonType.NextConfirmationPage,
  className: TransactionActionButtonClassName.PrimaryButton,
  disabled,
  position: TransactionActionButtonPosition.End,
  buttonType: TransactionButtonType.NextConfirmationPage,
});

export const backToPaymentOptionsButton = (
  disabled = false
): TransactionActionButton => ({
  label: TransactionButtonType.BackToPaymentOptions,
  className: TransactionActionButtonClassName.WhiteButton,
  disabled,
  position: TransactionActionButtonPosition.Start,
  buttonType: TransactionButtonType.BackToPaymentOptions,
});

export const nextConfirmPaymentDetailsButton = (
  disabled = false
): TransactionActionButton => ({
  label: TransactionButtonType.NextConfirmPaymentDetails,
  className: TransactionActionButtonClassName.PrimaryButton,
  disabled,
  position: TransactionActionButtonPosition.End,
  buttonType: TransactionButtonType.NextConfirmPaymentDetails,
});

export const backToEnterCardDetailsButton = (
  disabled = false
): TransactionActionButton => ({
  label: TransactionButtonType.BackToEnterCardDetails,
  className: TransactionActionButtonClassName.WhiteButton,
  disabled,
  position: TransactionActionButtonPosition.Start,
  buttonType: TransactionButtonType.BackToEnterCardDetails,
});

export const confirmAndMakePaymentButton = (
  disabled = false
): TransactionActionButton => ({
  label: TransactionButtonType.ConfirmAndMakePayment,
  className: TransactionActionButtonClassName.PrimaryButton,
  disabled,
  position: TransactionActionButtonPosition.End,
  buttonType: TransactionButtonType.ConfirmAndMakePayment,
});

export const resetButton = (disabled = false): TransactionActionButton => ({
  label: TransactionButtonType.Reset,
  className: TransactionActionButtonClassName.WhiteButton,
  disabled,
  position: TransactionActionButtonPosition.Start,
  buttonType: TransactionButtonType.Reset,
});

export const declineRenewalButton = (
  disabled = false
): TransactionActionButton => ({
  label: TransactionButtonType.DeclineRenewal,
  className: TransactionActionButtonClassName.WhiteButton,
  disabled,
  position: TransactionActionButtonPosition.Start,
  buttonType: TransactionButtonType.DeclineRenewal,
  transactionAction: TransactionAction.Decline,
});

export const abandonTransactionButton = (
  disabled = false
): TransactionActionButton => ({
  label: TransactionButtonType.CancelChanges,
  className: TransactionActionButtonClassName.WhiteButton,
  disabled,
  position: TransactionActionButtonPosition.Start,
  buttonType: TransactionButtonType.CancelChanges,
  transactionAction: TransactionAction.Decline,
});

export const confirmButton = (disabled = false): TransactionActionButton => ({
  label: TransactionButtonType.Confirm,
  className: TransactionActionButtonClassName.PrimaryButton,
  disabled,
  position: TransactionActionButtonPosition.Start,
  buttonType: TransactionButtonType.Confirm,
});

export const submitButton = (disabled = false): TransactionActionButton => ({
  label: TransactionButtonType.Submit,
  className: TransactionActionButtonClassName.PrimaryButton,
  disabled,
  position: TransactionActionButtonPosition.End,
  buttonType: TransactionButtonType.Submit,
});

export const renewPaymentOptionsButton = (
  disabled = false
): TransactionActionButton => ({
  label: TransactionButtonType.RenewPaymentOptions,
  className: TransactionActionButtonClassName.PrimaryButton,
  disabled,
  position: TransactionActionButtonPosition.End,
  buttonType: TransactionButtonType.RenewPaymentOptions,
  transactionAction: TransactionAction.Accept,
  isZeroPaymentConfirmButton: false,
});

export const transferPaymentOptionsButton = (
  disabled = false
): TransactionActionButton => ({
  label: TransactionButtonType.TransferPaymentOptions,
  className: TransactionActionButtonClassName.PrimaryButton,
  disabled,
  position: TransactionActionButtonPosition.Start,
  buttonType: TransactionButtonType.TransferPaymentOptions,
});

export const renewConfirmButton = (
  disabled = false
): TransactionActionButton => ({
  label: TransactionButtonType.RenewConfirm,
  className: TransactionActionButtonClassName.PrimaryButton,
  disabled,
  position: TransactionActionButtonPosition.End,
  buttonType: TransactionButtonType.RenewConfirm,
  transactionAction: TransactionAction.Accept,
  isZeroPaymentConfirmButton: true,
});

export const submitOfferButton = (
  disabled = false
): TransactionActionButton => ({
  label: TransactionButtonType.SubmitOffer,
  className: TransactionActionButtonClassName.PrimaryButton,
  disabled,
  position: TransactionActionButtonPosition.Start,
  buttonType: TransactionButtonType.SubmitOffer,
});

export const withdrawOfferButton = (
  disabled = false
): TransactionActionButton => ({
  label: TransactionButtonType.WithdrawOffer,
  className: TransactionActionButtonClassName.PrimaryButton,
  disabled,
  position: TransactionActionButtonPosition.Start,
  buttonType: TransactionButtonType.WithdrawOffer,
});

export const acceptOfferButton = (
  disabled = false
): TransactionActionButton => ({
  label: TransactionButtonType.AcceptOffer,
  className: TransactionActionButtonClassName.PrimaryButton,
  disabled,
  position: TransactionActionButtonPosition.Start,
  buttonType: TransactionButtonType.AcceptOffer,
});

export const rejectOfferButton = (
  disabled = false
): TransactionActionButton => ({
  label: TransactionButtonType.RejectOffer,
  className: TransactionActionButtonClassName.DangerButton,
  disabled,
  position: TransactionActionButtonPosition.End,
  buttonType: TransactionButtonType.RejectOffer,
});

export const goBackButton = (disabled = false): TransactionActionButton => ({
  label: TransactionButtonType.GoBack,
  className: TransactionActionButtonClassName.WhiteButton,
  disabled,
  position: TransactionActionButtonPosition.End,
  buttonType: TransactionButtonType.GoBack,
});

export const printReceiptButton = (
  disabled = false
): TransactionActionButton => ({
  label: TransactionButtonType.Print,
  className: TransactionActionButtonClassName.PrimaryButton,
  disabled,
  position: TransactionActionButtonPosition.Start,
  buttonType: TransactionButtonType.Print,
});

export const printPayLaterConfirmationButton = (
  disabled = false
): TransactionActionButton => ({
  label: TransactionButtonType.Print,
  className: TransactionActionButtonClassName.PrimaryButton,
  disabled,
  position: TransactionActionButtonPosition.End,
  buttonType: TransactionButtonType.Print,
});

export const returnToHomeButton = (
  disabled = false
): TransactionActionButton => ({
  label: TransactionButtonType.ReturnToHome,
  className: TransactionActionButtonClassName.WhiteButton,
  disabled,
  position: TransactionActionButtonPosition.End,
  buttonType: TransactionButtonType.ReturnToHome,
});
