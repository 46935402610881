import { FACustomerDetail } from '@fishonline2023/shared/models';
import {
  TransactionData,
  TransactionType,
} from '@fishonline2023/webapps/model/fa2023';
import { transformKeysToTitleCase } from '@fishonline2023/shared/utils';

export const getTransactionDetail = ({
  transactionData,
}: {
  transactionData: TransactionData;
}) => {
  const activityLogDetailMap: Record<TransactionType, () => object> = {
    [TransactionType.AmendCustomer]: () =>
      transactionData.transactionDetail as FACustomerDetail,
    [TransactionType.CreateCustomer]: () =>
      transactionData.transactionDetail as FACustomerDetail,
  };
  return JSON.stringify(
    transformKeysToTitleCase(
      activityLogDetailMap[transactionData.transactionHeader.transactionType]()
    )
  );
};
