import { Route } from '@angular/router';
import { LoginComponent } from './login/login.component';
import { provideEffects } from '@ngrx/effects';
import { AuthEffects } from './store/auth.effects';

export const webappsAuthFd2023FeatureLoginRoutes: Route[] = [
  {
    path: '',
    providers: [provideEffects(AuthEffects)],
    component: LoginComponent,
  },
];
