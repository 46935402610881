// eslint-disable-next-line @nrwl/nx/enforce-module-boundaries
import {
  Channel,
  EventType,
  SearchActivityLogsQuery,
} from '@fishonline2023/webapps/shared/feature/appsync';
import { SortOrder } from './sort-order.model';

import { FormControl, FormGroup } from '@angular/forms';
import { FARoute, FDRoute, RouteData } from './route.enum';
import { DataLoaderType } from './data-loader.model';
import { BootstrapWidth } from './bootstrap.model';

export type SearchFormControls = FlatSearchFormControls &
  DependentSearchControls &
  PreFilterSearchFormControls;

export type FlatSearchFormControls = Record<
  string,
  FormControl<string | number>
>;
export type FlatSearchFormControlsWithFilter = FlatSearchFormControls & {
  filter: FormControl<string>;
};
export type DependentSearchControls = {
  [SearchFieldType.Dependent]?: FormGroup<FlatSearchFormControls>;
};
export type PreFilterSearchFormControls = {
  [SearchFieldType.RadioButtonPreFilter]?: FormGroup<FlatSearchFormControlsWithFilter>;
};

export const CREATED_AT = 'createdAt';

export enum SearchType {
  AccountActivity = 'AccountActivity',
  UserActivityLog = 'UserActivityLog',
  ShareExtract = 'ShareExtract',
  ForSaleAndTrade = 'ForSaleAndTrade',
  QuotaHistory = 'QuotaHistory',
  MyReceipts = 'MyReceipts',
  CustomerDetailSearch = 'CustomerDetailSearch',
}

export enum SearchFieldType {
  Text = 'text',
  Select = 'select',
  Date = 'date',
  TypeAhead = 'typeAhead',
  DateRange = 'dateRange',
  RadioButtonGroup = 'radioButtonGroup',
  RadioButtonPreFilter = 'preFilter',
  Dependent = 'dependent',
}

export type SearchFieldValue = {
  label: string;
  id: string | number;
};

export interface NestedSearchFieldValue extends SearchFieldValue {
  children?: Array<NestedSearchFieldValue>;
}

export interface FlatSearchField {
  label: string;
  width: BootstrapWidth;
  formControlName: string;
  initValue: string | number;
  fieldType: SearchFieldType;
  values?: Array<SearchFieldValue>;
  valuesFrom?: SearchFieldValuesFrom;
  placeholder?: string;
}

export interface CompositeSearchField {
  label?: string;
  width: BootstrapWidth;
  fieldType: SearchFieldType;
  fields: Array<FlatSearchField>;
}

export type SearchField = FlatSearchField | CompositeSearchField;

export enum SearchFieldValuesFrom {
  MyAgent,
  APIResponseByFormControlName,
  DependentParent,
  FishingBusinesses,
  ActivityTypeByChannel,
}

export type SearchFieldValuesFromInDeriveValueContext = Exclude<
  SearchFieldValuesFrom,
  SearchFieldValuesFrom.DependentParent
>;

export interface SearchSortOrderChangeEvent {
  sortOrder: SortOrder;
  searchResultField?: SearchResultField;
}

export type SearchFilterOptionMap = Record<
  string,
  Array<SearchFieldValue | NestedSearchFieldValue>
>;

export type SearchActivityLogResultProperty = Exclude<
  SearchActivityLogsQuery['items'][number],
  null
>;

export enum SearchFieldDataType {
  DateTime,
  Date,
  EventType,
  String,
  Number,
  Currency,
}

export interface SearchResultField {
  label: string;
  property: string;
  getValue: (result: SearchResultItem) => unknown;
  dataType: SearchFieldDataType;
  link?: SearchDetailLoaderRoutePath;
  sortable?: boolean;
}

export const searchResultField = (
  searchResultField: Omit<SearchResultField, 'getValue'>,
  getValue?: (result: SearchResultItem) => unknown
): SearchResultField => ({
  ...searchResultField,
  getValue: getValue ?? ((result) => result[searchResultField.property]),
});

export type SearchResultItem = Record<string, unknown>;
export type SearchResult = {
  resultList: Array<SearchResultItem>;
  total: number;
};

export interface SearchMetadata {
  pageTitle: string;
  appRoute: FDRoute | FARoute;
  channel: Channel;
  shouldAllowSearchResultColumnFilter: boolean;
  requireExport: boolean;
  defaultSearchResultColumnList?: Array<string>;
  searchFilterOptionsEndpoint?: string;
  searchEndpoint?: string;
  shouldReturnLimitedNumberOfSearchResult: boolean;
  downloadCSVName?: string;
  searchFields: Array<SearchField>;
  searchResultTableFields: SearchResultField[];
}

export enum SearchPeriod {
  Last30Days = 'Last 30 days',
  Last60Days = 'Last 60 days',
  Last90Days = 'Last 90 days',
}

export enum SaleAndTradePurpose {
  ForSale = 'For Sale',
  WantedToBuy = 'Wanted to Buy',
}

export const SaleAndTradePurposeValue = {
  [SaleAndTradePurpose.ForSale]: 'Sale',
  [SaleAndTradePurpose.WantedToBuy]: 'Buy',
};

export enum SaleAndTradeType {
  Boat = 'Boat',
  BoatLicence = 'Boat licence',
  FishingBusiness = 'Fishing business',
  FishingGear = 'Fishing gear',
  InlandEndorsements = 'Inland endorsements',
  Misc = 'Misc',
  Shares = 'Shares',
  SouthernFisheriesEndorsements = 'Southern fisheries trawl endorsements',
  SutsEndorsements = 'SUTS endorsements',
  Quota = 'Quota',
}

export const EventTypeName: Record<EventType, string> = {
  [EventType.AmendCustomer]: 'Amend Customer',
  [EventType.CreateCustomer]: 'Create Customer',
  [EventType.CustomerSearch]: 'Customer Search',
  [EventType.CustomerSearchDownload]: 'Customer Search Download',
  [EventType.LoginSuccessful]: 'Login Successful',
  [EventType.LoginFailed]: 'Login Failed',
  [EventType.NominateRevokeFisher]: 'Nominate Revoke Fisher',
  [EventType.RenewCFL]: 'Renew CFL',
  [EventType.SearchShareExtract]: 'Search Share Extract',
  [EventType.SwitchPersona]: 'Switch Persona',
  [EventType.UpdateContact]: 'Update Contact',
  [EventType.ViewAccountSummary]: 'View Account Summary',
  [EventType.ViewContactDetails]: 'View Contact Details',
  [EventType.ViewCustomerDetails]: 'View Customer Details',
  [EventType.ViewFishingBusinessHistory]: 'View Fishing Business History',
  [EventType.ViewForSaleAndTrade]: 'View For Sale And Trade',
  [EventType.ViewLicenceDetails]: 'View Licence Details',
  [EventType.ViewPrawnBallot]: 'View Prawn Ballot',
  [EventType.ViewShareExtractFishingBusiness]:
    'View Share Extract Fishing Business',
  [EventType.ViewShareExtractShareholder]: 'View Share Extract Shareholder',
  [EventType.ViewMyPersonalAuthorisations]: 'View My Personal Authorisations',
  [EventType.ViewMyFishingBusinessAuthorisations]:
    'View My Fishing Business Authorisations',
  [EventType.TransferComponents]: 'Transfer Components',
  [EventType.TransferFishingBusiness]: 'Transfer Fishing Business',
  [EventType.TransferQuota]: 'Transfer Quota',
  [EventType.ViewQuotaHistory]: 'View Quota History',
  [EventType.ViewMyReceipts]: 'View My Receipts',
  [EventType.ManageAgents]: 'Manage Agents',
};

export const SearchPeriodValue = {
  [SearchPeriod.Last30Days]: '30',
  [SearchPeriod.Last60Days]: '60',
  [SearchPeriod.Last90Days]: '90',
};

export interface SearchDetailLoaderRoute {
  path: SearchDetailLoaderRoutePath;
  data: {
    [RouteData.DataLoaderType]: DataLoaderType;
    [RouteData.Channel]: Channel;
  };
}

export enum SearchDetailLoaderRoutePath {
  AccountActivity = 'detail/account-activity',
  ShareExtractShareholder = 'detail/shareholder',
  ShareExtractFishingBusiness = 'detail/fishing-business',
  ForSaleAndTrade = 'detail/ad',
  MyReceipts = 'detail/receipt',
  Customer = 'detail/customer',
}

export const SearchDetailLoaderRouteList: Array<SearchDetailLoaderRoute> = [
  {
    path: SearchDetailLoaderRoutePath.ShareExtractShareholder,
    data: {
      [RouteData.DataLoaderType]: DataLoaderType.ShareholderReport,
      [RouteData.Channel]: Channel.FD,
    },
  },
  {
    path: SearchDetailLoaderRoutePath.ShareExtractFishingBusiness,
    data: {
      [RouteData.DataLoaderType]: DataLoaderType.FishingBusinessReport,
      [RouteData.Channel]: Channel.FD,
    },
  },
  {
    path: SearchDetailLoaderRoutePath.ForSaleAndTrade,
    data: {
      [RouteData.DataLoaderType]: DataLoaderType.ForSaleAndTradeDetail,
      [RouteData.Channel]: Channel.FD,
    },
  },
  {
    path: SearchDetailLoaderRoutePath.MyReceipts,
    data: {
      [RouteData.DataLoaderType]: DataLoaderType.MyReceiptsDetail,
      [RouteData.Channel]: Channel.FD,
    },
  },
  {
    path: SearchDetailLoaderRoutePath.Customer,
    data: {
      [RouteData.DataLoaderType]: DataLoaderType.CustomerDetail,
      [RouteData.Channel]: Channel.FA,
    },
  },
];
