import {
  ChangeDetectionStrategy,
  Component,
  Input,
  OnInit,
} from '@angular/core';
import { CommonModule } from '@angular/common';
import {
  TransactionData,
  FDDateTimeAngularPipeFormat,
} from '@fishonline2023/webapps/model/fd2023';
import { prop } from '@fishonline2023/shared/ramda';
import { AgentCustomer } from '@fishonline2023/shared/models';

@Component({
  selector: 'sv-ui-nominate-revoke-fisher-receipt',
  standalone: true,
  imports: [CommonModule],
  templateUrl: './nominate-revoke-fisher-receipt.component.html',
  styleUrls: ['./nominate-revoke-fisher-receipt.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class NominateRevokeFisherReceiptComponent implements OnInit {
  @Input() public transactionData!: TransactionData;

  public previousFisher?: AgentCustomer;
  public nominatedFisher?: AgentCustomer;
  public effectiveDate?: string;
  protected readonly FDDateTimeAngularPipeFormat = FDDateTimeAngularPipeFormat;

  public ngOnInit(): void {
    const transactionDetail = prop('transactionDetail', this.transactionData);
    const transactionHeader = prop('transactionHeader', this.transactionData);
    this.previousFisher = prop('previousFisher', transactionDetail);
    this.nominatedFisher = prop('nominatedFisher', transactionDetail);
    this.effectiveDate = prop('effectiveDate', transactionHeader);
  }
}
