import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  inject,
  Input,
  OnDestroy,
  OnInit,
  Output,
} from '@angular/core';
import { CommonModule } from '@angular/common';
import { TwoPartyTransactionEntitySelectionType } from '@fishonline2023/webapps/model/fd2023';
import { FormControl, ReactiveFormsModule } from '@angular/forms';
import {
  AutoCompleteComponent,
  ButtonLinkComponent,
  TypeaheadComponent,
} from '@fishonline2023/webapps/shared/ui/base-components';
import { filter, Observable, Subject, takeUntil, tap } from 'rxjs';
import { complement, isNil } from '@fishonline2023/shared/ramda';
import { AgentCustomer, FishingBusiness } from '@fishonline2023/shared/models';

@Component({
  selector: 'sv-ui-two-party-transaction-entity-selection-form',
  standalone: true,
  imports: [
    CommonModule,
    AutoCompleteComponent,
    ReactiveFormsModule,
    ButtonLinkComponent,
    TypeaheadComponent,
  ],
  templateUrl: './two-party-transaction-entity-selection-form.component.html',
  styleUrls: ['./two-party-transaction-entity-selection-form.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TwoPartyTransactionEntitySelectionFormComponent
  implements OnInit, OnDestroy
{
  @Input() public label!: string;
  @Input() public description!: string;
  @Input() public secondaryDescription = '';
  @Input() public isReadOnly!: boolean | null;
  @Input() public selectionType!: TwoPartyTransactionEntitySelectionType;
  @Input() public optionList: (FishingBusiness | AgentCustomer)[] = [];
  @Input() public secondaryOptionList: Record<string, FishingBusiness[]> = {};
  @Input() public control!: FormControl<FishingBusiness | AgentCustomer | null>;
  @Output() public fishingBusinessCreated = new EventEmitter<AgentCustomer>();
  public labelFormControl: FormControl<string> = new FormControl('', {
    nonNullable: true,
  });
  public transferComponentsToCustomerFormControl =
    new FormControl<AgentCustomer | null>(null);
  protected readonly TwoPartyTransactionEntitySelectionType =
    TwoPartyTransactionEntitySelectionType;
  protected transferComponentsToCustomer$!: Observable<AgentCustomer>;
  private cdr = inject(ChangeDetectorRef);
  private destroy$$ = new Subject<void>();

  protected get getFishingBusiness() {
    return this.control.value as FishingBusiness;
  }

  protected get getCustomer() {
    return this.control.value as AgentCustomer;
  }

  public ngOnDestroy() {
    this.destroy$$.next();
    this.destroy$$.complete();
  }

  public ngOnInit() {
    this.resetLabelFormControlOnControlReset();
    this.transferComponentsToCustomer$ =
      this.transferComponentsToCustomerFormControl.valueChanges.pipe(
        filter(complement(isNil))
      );
  }

  protected optionListWithLabel(
    selection: (FishingBusiness | AgentCustomer)[]
  ) {
    return selection.map((selection) => ({
      ...selection,
      label: this.selectionLabel(selection),
    }));
  }

  protected fishingBusinessOptionList(toCustomer: AgentCustomer) {
    return this.secondaryOptionList[toCustomer.id];
  }

  protected changeSelection() {
    this.control.reset();
    this.labelFormControl.reset();
  }

  private resetLabelFormControlOnControlReset() {
    this.control.valueChanges
      .pipe(
        tap(() => this.cdr.detectChanges()),
        filter(isNil),
        takeUntil(this.destroy$$)
      )
      .subscribe(() => this.labelFormControl.reset());
  }

  private selectionLabel(selection: FishingBusiness | AgentCustomer) {
    return `${selection.id} - ${
      (<FishingBusiness>selection).owner?.fullName ||
      (<AgentCustomer>selection).fullName
    }`;
  }
}
