import {
  DataLoaderData,
  dataLoaderMetadata,
  DataLoaderMetadata,
  DataLoaderType,
} from '@fishonline2023/shared/models';
import { Message } from './message.constant';
import { CustomerDetail } from './customer-detail.model';

export type FADataLoaderData = DataLoaderData | CustomerDetail;

export const FADataLoaderMetadata: Partial<
  Record<DataLoaderType, DataLoaderMetadata>
> = {
  ...dataLoaderMetadata,
  [DataLoaderType.CustomerDetail]: {
    apiRoute: 'customer-detail',
    loadingMessage: Message.LoadingCustomerDetail,
  },
};
