import { inject, Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Store } from '@ngrx/store';
import {
  selectTransactionDataFromGET,
  selectTransactionDataFromSubmit,
  selectTransactionRequestParams,
  selectTransactionType,
} from './transaction.selectors';
import {
  combineLatest,
  filter,
  from,
  map,
  Observable,
  switchMap,
  take,
  withLatestFrom,
} from 'rxjs';
import {
  complement,
  equals,
  isEmpty,
  isNil,
  isNilOrEmpty,
} from '@fishonline2023/shared/ramda';
import { environment } from '@env/fd/environment';
import {
  ConfirmPath,
  Error,
  Message as FDMessage,
  PaymentPath,
  TransactionAction,
  TransactionCreditCardDetail,
  TransactionData,
  TransactionDetail,
  TransactionMetadata,
  TransactionRequestParams,
  TransactionType,
  TrustedFrame,
} from '@fishonline2023/webapps/model/fd2023';
import {
  transactionDataSubmitFailed,
  transactionDataSubmitSuccessful,
} from './transaction.actions';
import { promisify } from 'es6-promisify';
import { AuthService } from '@fishonline2023/webapps/auth/fd2023/feature/login';
import { Message } from '@fishonline2023/shared/models';

@Injectable()
export class TransactionService {
  private http = inject(HttpClient);
  private authService = inject(AuthService);
  private store = inject(Store);
  private transactionMetadata$ = this.store.select(selectTransactionType).pipe(
    filter(complement(isNil)),
    take(1),
    map(
      (transactionType: TransactionType) => TransactionMetadata[transactionType]
    )
  );
  private transactionRequestParams$: Observable<
    Record<keyof TransactionRequestParams, string | number>
  > = this.store
    .select(selectTransactionRequestParams)
    .pipe(filter(complement(isNil)));
  private transactionDataFromGET$: Observable<TransactionData> = this.store
    .select(selectTransactionDataFromGET)
    .pipe(filter(complement(isNil)));
  private transactionIdFromGET$: Observable<number | null> =
    this.transactionDataFromGET$.pipe(
      map(({ transactionHeader: { id } }) => id)
    );
  private transactionIdFromSubmit$: Observable<number> = this.store
    .select(selectTransactionDataFromSubmit)
    .pipe(
      filter(complement(isNil)),
      map(({ transactionHeader: { id } }) => id),
      take(1)
    );

  public getTransactionData(
    params?: Record<keyof TransactionRequestParams, string | number>
  ): Observable<TransactionData> {
    return this.transactionMetadata$.pipe(
      switchMap(
        (transactionMetadata) =>
          this.http.get<TransactionData>(
            `${environment.apiUrl}/${transactionMetadata.apiBaseRoute}`,
            {
              params,
            }
          )
        // of(transferComponentsExistTransactionData)
      )
    );
  }

  public submitTransactionData(
    transactionDetail: TransactionDetail
  ): Observable<TransactionData> {
    return this.transactionMetadata$.pipe(
      withLatestFrom(
        this.transactionDataFromGET$,
        this.transactionRequestParams$
      ),
      switchMap(([transactionMetadata, transactionData, params]) => {
        const { transactionReference, ...transactionDataWithoutReference } =
          transactionData;
        return isNilOrEmpty(transactionData.transactionHeader.id)
          ? this.http.post<TransactionData>(
              `${environment.apiUrl}/${transactionMetadata.apiBaseRoute}`,
              { ...transactionDataWithoutReference, transactionDetail },
              { params }
            )
          : this.http.put<TransactionData>(
              `${environment.apiUrl}/${transactionMetadata.apiBaseRoute}/${transactionData.transactionHeader.id}`,
              { ...transactionDataWithoutReference, transactionDetail }
            );
        // return of(transferComponentsExistTransactionData);
      })
    );
  }

  public submitTransactionDataPayLater(): Observable<TransactionData> {
    return this.transactionMetadata$.pipe(
      withLatestFrom(this.transactionDataFromGET$),
      switchMap(([{ apiBaseRoute }, transactionData]) => {
        const { transactionReference, ...transactionDataWithoutReference } =
          transactionData;
        return this.http.put<TransactionData>(
          `${environment.apiUrl}/${apiBaseRoute}/${transactionData.transactionHeader.id}`,
          {
            ...transactionDataWithoutReference,
            transactionDetail: {
              ...transactionData.transactionDetail,
              action: TransactionAction.PayLater,
            },
          }
        );
        // return of(transferComponentsExistTransactionData);
      })
    );
  }

  public parseSubmitTransactionDataResponse = (
    transactionData: TransactionData
  ) => {
    if (!isEmpty(transactionData.transactionHeader.errorList)) {
      return transactionDataSubmitFailed({
        errorMessage: Message.TransactionPreventingError,
        transactionData,
      });
    }
    return transactionDataSubmitSuccessful({ transactionData });
  };

  public validateCreditCard(
    trustedFrame: TrustedFrame
  ): Observable<TransactionCreditCardDetail> {
    const submitForm = promisify(trustedFrame.submitForm);
    return from(submitForm()).pipe(
      map(({ singleUseToken: { creditCard, singleUseTokenId } }) => ({
        singleUseToken: singleUseTokenId,
        cardNumber: creditCard.maskedCardNumber4Digits,
        holderName: creditCard.cardholderName,
        expiryDate: `${creditCard.expiryDateMonth}/${creditCard.expiryDateYear}`,
        surcharge: Number(creditCard.surchargePercentage),
      }))
    );
  }

  public confirmTransactionData(
    confirmPath: ConfirmPath
  ): Observable<TransactionData> {
    return this.transactionMetadata$.pipe(
      withLatestFrom(this.transactionIdFromSubmit$),
      switchMap(
        ([{ apiBaseRoute }, transactionId]) =>
          this.http.put<TransactionData>(
            `${environment.apiUrl}/${apiBaseRoute}/${transactionId}/${confirmPath}`,
            undefined
          )
        // of(transferComponentsExistTransactionData)
      )
    );
  }

  public withdrawTransaction(): Observable<TransactionData> {
    return this.transactionMetadata$.pipe(
      withLatestFrom(this.transactionIdFromGET$),
      switchMap(
        ([{ apiBaseRoute }, transactionId]) =>
          this.http.put<TransactionData>(
            `${environment.apiUrl}/${apiBaseRoute}/${transactionId}/withdraw`,
            undefined
          )
        // of(transferComponentsExistTransactionData)
      )
    );
  }

  public rejectTransaction(): Observable<TransactionData> {
    return this.transactionMetadata$.pipe(
      withLatestFrom(this.transactionIdFromGET$),
      switchMap(
        ([{ apiBaseRoute }, transactionId]) =>
          this.http.put<TransactionData>(
            `${environment.apiUrl}/${apiBaseRoute}/${transactionId}/reject`,
            undefined
          )
        // of(transferComponentsExistTransactionData)
      )
    );
  }

  public acceptTransaction(): Observable<TransactionData> {
    return this.transactionMetadata$.pipe(
      withLatestFrom(this.transactionIdFromGET$),
      switchMap(
        ([{ apiBaseRoute }, transactionId]) =>
          this.http.put<TransactionData>(
            `${environment.apiUrl}/${apiBaseRoute}/${transactionId}/accept`,
            undefined
          )
        // of(transferComponentsExistTransactionData)
      )
    );
  }

  public confirmAndMakePaymentTransactionData(
    paymentPath: PaymentPath,
    singleUseToken: string
  ): Observable<TransactionData> {
    return combineLatest([
      this.transactionMetadata$,
      this.transactionIdFromSubmit$,
      from(this.authService.getCurrentUserSession()),
    ]).pipe(
      take(1),
      switchMap(
        ([{ apiBaseRoute }, transactionId, session]) =>
          this.http.put<TransactionData>(
            `${environment.apiUrl}/${apiBaseRoute}/${transactionId}/${paymentPath}`,
            { singleUseToken },
            { headers: { 'X-ID-Token': session.getIdToken().getJwtToken() } }
          )
        // of(transferComponentsExistTransactionData)
      )
    );
  }

  public abandonTransaction() {
    return combineLatest([
      this.transactionDataFromGET$,
      this.transactionMetadata$,
    ]).pipe(
      take(1),
      switchMap(
        ([
          {
            transactionHeader: { id },
          },
          { apiBaseRoute },
        ]) =>
          this.http.delete<TransactionData>(
            `${environment.apiUrl}/${apiBaseRoute}/${id}`
          )
      )
    );
  }

  public getAbandonTransactionErrorMessage(error: Error): string {
    if (equals(error.statusCode, 405)) {
      return FDMessage.AbandonTransactionNotAllowed;
    }
    if (!isNil(error.errorMessage)) {
      return error.errorMessage as string;
    }
    return FDMessage.FailedToAbandonTransaction;
  }
}
