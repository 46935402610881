import { Action, createReducer, on } from '@ngrx/store';
import {
  SubmitActionFromButtonType,
  TransactionButtonType,
  TransactionData,
  TransactionEntityItem,
  TransactionRequestParams,
  TransactionType,
} from '@fishonline2023/webapps/model/fa2023';
import {
  entityCreated,
  transactionApproved,
  transactionApprovedFailed,
  transactionApprovedSuccessful,
  transactionDataForEditReset,
  transactionDataRequested,
  transactionDataRequestedFailed,
  transactionDataRequestedSuccessful,
  transactionDeleted,
  transactionDeletedFailed,
  transactionDeletedSuccessful,
  transactionEntityDataLoadFailed,
  transactionEntityDataLoadSuccessful,
  transactionEntityDataRequested,
  transactionInitialised,
  transactionNotApproved,
  transactionNotApprovedFailed,
  transactionNotApprovedSuccessful,
  transactionPendingTransactionIdPopulated,
  transactionReset,
  transactionSubmitted,
  transactionSubmittedFailed,
  transactionSubmittedSuccessful,
} from './transaction.actions';
import { ViewStatus } from '@fishonline2023/shared/models';
import { equals } from '@fishonline2023/shared/ramda';

export const TRANSACTION_FEATURE_KEY = 'transaction';

export const TRANSACTION_FEATURE_PERSISTENCE_KEY = [
  'transactionType',
  'pendingTransactionId',
  'transactionRequestParams',
  'transactionDataForEdit',
  'transactionDataFromSubmit',
  'submitActionFromButtonType',
  'transactionDataFromApprove',
  'transactionDataFromNotApprove',
  'transactionDataFromDelete',
  'errorMessage',
];

export interface TransactionState {
  transactionType?: TransactionType;
  // this is set when user click on the pending transaction outside the transaction context
  // also updated when at any stage of the transaction, transaction data contains transaction id
  // so when the user refresh the page after create customer, pending transaction will be fetched instead of a new customer being created
  pendingTransactionId: number | null;
  transactionRequestParams?: TransactionRequestParams;
  transactionEntityData: Array<TransactionEntityItem>;
  errorMessage?: string;
  transactionDataForEdit?: TransactionData;
  transactionDataFromSubmit?: TransactionData;
  transactionDataFromApprove?: TransactionData;
  transactionDataFromNotApprove?: TransactionData;
  transactionDataFromDelete?: TransactionData;
  submitActionFromButtonType?: SubmitActionFromButtonType;
  viewStatusEntityData: ViewStatus;
  viewStatusInit: ViewStatus;
  viewStatusSubmit: ViewStatus;
  viewStatusApprove: ViewStatus;
  viewStatusNotApprove: ViewStatus;
  viewStatusDelete: ViewStatus;
}

export const initialTransactionState: TransactionState = {
  pendingTransactionId: null,
  transactionEntityData: [],
  viewStatusEntityData: ViewStatus.Initial,
  viewStatusInit: ViewStatus.Initial,
  viewStatusSubmit: ViewStatus.Initial,
  viewStatusApprove: ViewStatus.Initial,
  viewStatusNotApprove: ViewStatus.Initial,
  viewStatusDelete: ViewStatus.Initial,
};

const onTransactionSubmitSuccessful = (
  state: TransactionState,
  {
    transactionData,
    fromButtonType,
  }: {
    transactionData: TransactionData;
    fromButtonType: SubmitActionFromButtonType;
  }
) => {
  const transactionDataFromSubmit = {
    ...transactionData,
    transactionReference: (state.transactionDataForEdit as TransactionData)
      .transactionReference,
  };
  const transactionDataForEdit = equals(
    fromButtonType,
    TransactionButtonType.Save
  )
    ? transactionDataFromSubmit
    : state.transactionDataForEdit;
  return {
    ...state,
    transactionDataFromSubmit,
    transactionDataForEdit,
    pendingTransactionId: transactionData.transactionHeader.transactionNumber,
    submitActionFromButtonType: fromButtonType,
    viewStatusSubmit: ViewStatus.Success,
  };
};

const reducer = createReducer(
  initialTransactionState,
  on(transactionPendingTransactionIdPopulated, (state, { transactionId }) => ({
    ...state,
    pendingTransactionId: transactionId,
  })),
  on(
    transactionInitialised,
    (
      { pendingTransactionId, transactionRequestParams },
      { transactionType }
    ) => ({
      ...initialTransactionState,
      pendingTransactionId,
      transactionRequestParams,
      transactionType,
    })
  ),
  on(transactionEntityDataRequested, (state) => ({
    ...state,
    viewStatusEntityData: ViewStatus.Loading,
  })),
  on(
    transactionEntityDataLoadSuccessful,
    (state, { transactionEntityData }) => ({
      ...state,
      transactionEntityData,
      viewStatusEntityData: ViewStatus.Success,
    })
  ),
  on(transactionEntityDataLoadFailed, (state, { errorMessage }) => ({
    ...state,
    errorMessage,
    viewStatusEntityData: ViewStatus.Failure,
  })),
  on(transactionDataRequested, (state, { queryParams }) => ({
    ...state,
    viewStatusInit: ViewStatus.Loading,
    transactionRequestParams: queryParams,
  })),
  on(entityCreated, (state) => ({
    ...state,
    viewStatusInit: ViewStatus.Loading,
  })),
  on(transactionDataRequestedSuccessful, (state, { transactionData }) => ({
    ...state,
    transactionDataForEdit: transactionData,
    pendingTransactionId: transactionData.transactionHeader.transactionNumber,
    viewStatusInit: ViewStatus.Success,
  })),
  on(transactionDataRequestedFailed, (state, { errorMessage }) => ({
    ...state,
    errorMessage,
    viewStatusInit: ViewStatus.Failure,
  })),
  on(transactionSubmitted, (state) => ({
    ...state,
    viewStatusSubmit: ViewStatus.Loading,
  })),
  on(transactionSubmittedSuccessful, onTransactionSubmitSuccessful),
  on(transactionSubmittedFailed, (state, { errorMessage }) => ({
    ...state,
    errorMessage,
    viewStatusSubmit: ViewStatus.Failure,
  })),
  on(transactionApproved, (state) => ({
    ...state,
    viewStatusApprove: ViewStatus.Loading,
  })),
  on(transactionApprovedSuccessful, (state, { transactionData }) => ({
    ...state,
    transactionDataFromApprove: transactionData,
    viewStatusApprove: ViewStatus.Success,
  })),
  on(transactionApprovedFailed, (state, { errorMessage, transactionData }) => ({
    ...state,
    errorMessage,
    transactionDataFromApprove: transactionData,
    viewStatusApprove: ViewStatus.Failure,
  })),
  on(transactionNotApproved, (state) => ({
    ...state,
    viewStatusNotApprove: ViewStatus.Loading,
  })),
  on(transactionNotApprovedSuccessful, (state, { transactionData }) => ({
    ...state,
    transactionDataFromNotApprove: transactionData,
    viewStatusNotApprove: ViewStatus.Success,
  })),
  on(transactionNotApprovedFailed, (state, { errorMessage }) => ({
    ...state,
    errorMessage,
    viewStatusNotApprove: ViewStatus.Failure,
  })),
  on(transactionDeleted, (state) => ({
    ...state,
    viewStatusDelete: ViewStatus.Loading,
  })),
  on(transactionDeletedSuccessful, (state, { transactionData }) => ({
    ...state,
    transactionDataFromDelete: transactionData,
    viewStatusDelete: ViewStatus.Success,
  })),
  on(transactionDeletedFailed, (state, { errorMessage }) => ({
    ...state,
    errorMessage,
    viewStatusDelete: ViewStatus.Failure,
  })),
  on(transactionReset, () => initialTransactionState),
  on(transactionDataForEditReset, (state) => ({
    ...state,
    transactionDataForEdit: undefined,
    transactionRequestParams: undefined,
  }))
);

export function transactionReducer(
  state: TransactionState | undefined,
  action: Action
) {
  return reducer(state, action);
}
