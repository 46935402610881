import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  OnChanges,
  Output,
  SimpleChanges,
} from '@angular/core';
import { CommonModule } from '@angular/common';
import {
  Authorisation,
  AuthorisationSortOrderChangeEvent,
  TableSortField,
} from '@fishonline2023/webapps/model/fd2023';
import {
  ascend,
  clone,
  descend,
  equals,
  prop,
  sortWith,
} from '@fishonline2023/shared/ramda';
import { TableSortingButtonComponent } from '../table-sorting-button/table-sorting-button.component';
import { Observable } from 'rxjs';
import { SortOrder } from '@fishonline2023/shared/models';
import {
  groupByFishingBusinessId,
  sortByCustomer,
  sortByEndDateDescending,
  transformTableResult,
} from './authorisations-list.utils';

@Component({
  selector: 'sv-ui-authorisations-list',
  standalone: true,
  imports: [CommonModule, TableSortingButtonComponent],
  templateUrl: './authorisations-list.component.html',
  styleUrls: ['./authorisations-list.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AuthorisationsListComponent implements OnChanges {
  @Input() public tableFields!: TableSortField[];
  @Input() public authorisations!: Authorisation[];
  @Input() public columnSorted$!: Observable<string | undefined>;
  @Output() public sortAuthorisationResults =
    new EventEmitter<AuthorisationSortOrderChangeEvent>();

  public originalAuthorisations: Authorisation[] = [];

  public ngOnChanges(changes: SimpleChanges): void {
    for (const propName in changes) {
      switch (propName) {
        case 'authorisations':
          this.sortAndGroupAuthorisations();
          break;
      }
    }
  }

  public transformTableResult(
    field: TableSortField,
    result: Authorisation
  ): string {
    return transformTableResult(field, result);
  }

  public handleAuthorisationsSort(
    event: AuthorisationSortOrderChangeEvent
  ): void {
    this.sortAuthorisationResults.emit(event);
    const sortOrder = event.sortOrder;
    const field = event.authorisationResultField;
    const propName = prop(field?.property as string);

    if (equals(sortOrder, SortOrder.NOT_SORTED)) {
      this.authorisations = this.originalAuthorisations;
      return;
    }
    if (['fisher', 'owner'].includes(<string>field?.property)) {
      this.authorisations = sortByCustomer(
        this.authorisations,
        sortOrder,
        <string>field?.property
      );
      return;
    }
    this.sortAndGroupAuthorisations();
    this.authorisations = sortWith<Authorisation>([
      (equals(sortOrder, SortOrder.ASC) ? ascend : descend)(propName),
    ])(this.authorisations);
  }

  private sortAndGroupAuthorisations(): void {
    this.originalAuthorisations = clone(this.authorisations);
    this.authorisations = sortByEndDateDescending(
      groupByFishingBusinessId(this.authorisations)
    );
  }
}
