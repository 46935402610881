import { SelectionOption } from './selection-option.model';

export enum InputType {
  Text = 'text',
  ABN = 'ABN',
  ACN = 'ACN',
  Price = 'price',
  Number = 'number',
  Select = 'select',
  DateTime = 'datetime',
  Date = 'date',
  Checkbox = 'checkbox',
  Typeahead = 'typeahead',
  Textarea = 'textarea',
}

export interface InputField {
  formControlName: string;
  label?: string;
  type: InputType;
  placeholder?: string;
  isReadOnly?: boolean;
  isRequired?: boolean;
  // InputType.Text InputType.Textarea
  maxLength?: number;
  // InputType.Select InputType.Typeahead
  options?: Array<SelectionOption>;
  // InputType.DateTime InputType.Date InputType.Number
  min?: number;
  max?: number;
  minDate?: string;
  maxDate?: string;
  // InputType.Textarea
  rows?: number;
}
