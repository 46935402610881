import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  inject,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  SimpleChanges,
} from '@angular/core';
import { CommonModule } from '@angular/common';
import {
  CompositeSearchField,
  FlatSearchField,
  InputField,
  InputType,
  NestedSearchFieldValue,
  SearchField,
  SearchFieldType,
  SearchFieldValue,
  SearchFormControls,
} from '@fishonline2023/shared/models';
import {
  AbstractControl,
  FormGroup,
  ReactiveFormsModule,
} from '@angular/forms';
import { equals, has, omit, tail } from '@fishonline2023/shared/ramda';
import {
  AutoCompleteComponent,
  TypeaheadComponent,
} from '@fishonline2023/webapps/shared/ui/base-components';
import { Subject, takeUntil } from 'rxjs';
import { InputFieldComponent } from '@fishonline2023/webapps/shared/ui/input-field';

@Component({
  selector: 'sv-ui-search-field',
  standalone: true,
  imports: [
    CommonModule,
    ReactiveFormsModule,
    AutoCompleteComponent,
    TypeaheadComponent,
    InputFieldComponent,
  ],
  templateUrl: './search-field.component.html',
  styleUrls: ['./search-field.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SearchFieldComponent implements OnInit, OnDestroy, OnChanges {
  @Input() public searchField!: SearchField;
  @Input() public searchForm!: FormGroup<SearchFormControls>;
  @Input() public searchFieldValues!: Record<
    string,
    Array<SearchFieldValue> | Array<NestedSearchFieldValue>
  >;
  protected SearchFieldType = SearchFieldType;
  private cdr = inject(ChangeDetectorRef);
  private destroy$ = new Subject<void>();

  protected get searchTextInputField(): InputField {
    return {
      formControlName: this.flatSearchField.formControlName,
      label: this.flatSearchField.label,
      placeholder: this.flatSearchField.placeholder,
      type: InputType.Text,
    };
  }

  protected get searchSelectInputField(): InputField {
    const searchFieldValuesOptions = this.searchFieldValues[
      this.flatSearchField.formControlName
    ].map(({ id, label }) => ({ value: id, label }));
    const defaultSearchOption = {
      label: this.selectSearchFieldDefaultLabel(
        this.flatSearchField.formControlName
      ),
      value: '',
    };
    return {
      formControlName: this.flatSearchField.formControlName,
      label: this.flatSearchField.label,
      type: InputType.Select,
      options: [defaultSearchOption, ...searchFieldValuesOptions],
    };
  }

  protected get preFilterSearchField() {
    return (this.searchField as CompositeSearchField).fields.find((field) =>
      equals(field.formControlName, this.searchForm.get('filter')?.value)
    ) as FlatSearchField;
  }

  protected get flatSearchField() {
    return this.searchField as FlatSearchField;
  }

  protected get compositeSearchField() {
    return this.searchField as CompositeSearchField;
  }

  protected get firstLevelDependentSearchFieldValues() {
    const formControlName = this.compositeSearchField.fields[0].formControlName;
    const nestedSearchFieldValues = this.searchFieldValues[
      formControlName
    ] as NestedSearchFieldValue[];
    return {
      [formControlName]: nestedSearchFieldValues.map(omit(['children'])),
    };
  }

  protected get isFirstLevelDependentFieldValid() {
    return this.searchForm.controls[
      this.compositeSearchField.fields[0].formControlName
    ].valid;
  }

  protected get nextCompositeFields(): FlatSearchField | CompositeSearchField {
    if (equals(this.compositeSearchField.fields.length, 2)) {
      return this.compositeSearchField.fields[1];
    }
    return {
      fieldType: SearchFieldType.Dependent,
      width: this.compositeSearchField.width,
      fields: tail(this.compositeSearchField.fields),
    };
  }

  protected get nextCompositeSearchFieldValues(): Record<
    string,
    NestedSearchFieldValue[]
  > {
    const firstLevelFormControlName =
      this.compositeSearchField.fields[0].formControlName;
    const firstLevelValue =
      this.searchForm.controls[firstLevelFormControlName].value;
    const firstLevelSearchFieldValue = this.searchFieldValues[
      firstLevelFormControlName
    ].find(({ id }) =>
      equals(String(id), String(firstLevelValue))
    ) as NestedSearchFieldValue;
    return {
      [this.compositeSearchField.fields[1].formControlName]:
        firstLevelSearchFieldValue.children as NestedSearchFieldValue[],
    };
  }

  protected get searchDateRangeInputField(): InputField {
    return {
      formControlName: this.flatSearchField.formControlName,
      label: this.flatSearchField.label,
      type: InputType.Date,
    };
  }

  public ngOnInit() {
    this.detectSearchFormChanges();
    this.resetNextFormControlWhenFirstLevelDependentFieldValueChange();
    this.preselectSingleOptionSelection();
  }

  public ngOnDestroy() {
    this.destroy$.next();
    this.destroy$.complete();
  }

  public ngOnChanges(changes: SimpleChanges): void {
    const onlySearchFieldValuesChanged =
      !has('searchForm', changes) &&
      !has('searchField', changes) &&
      !equals(
        changes['searchFieldValues'].previousValue,
        changes['searchFieldValues'].currentValue
      );
    if (onlySearchFieldValuesChanged) {
      this.preselectSingleOptionSelection();
    }
  }

  protected selectSearchFieldDefaultLabel(formControlName: string) {
    const formControl = this.searchForm.controls[formControlName];
    const validator = formControl.validator
      ? formControl.validator({} as AbstractControl)
      : null;
    const isFormControlRequired = validator && validator['required'];
    return isFormControlRequired ? 'Select from list' : 'All';
  }

  private detectSearchFormChanges() {
    this.searchForm.valueChanges
      .pipe(takeUntil(this.destroy$))
      .subscribe(() => this.cdr.detectChanges());
  }

  private preselectSingleOptionSelection() {
    if (!equals(this.searchField.fieldType, SearchFieldType.Select)) {
      return;
    }
    const formControlName = this.flatSearchField.formControlName;
    const selectValues = this.searchFieldValues[formControlName];
    if (equals(selectValues.length, 1)) {
      this.searchForm.controls[formControlName].patchValue(selectValues[0].id, {
        emitEvent: false,
      });
    }
  }

  private resetNextFormControlWhenFirstLevelDependentFieldValueChange() {
    if (equals(this.searchField.fieldType, SearchFieldType.Dependent)) {
      this.searchForm
        .get(this.compositeSearchField.fields[0].formControlName)
        ?.valueChanges.pipe(takeUntil(this.destroy$))
        .subscribe(() => {
          this.searchForm
            .get(this.compositeSearchField.fields[1].formControlName)
            ?.reset();
          this.cdr.detectChanges();
        });
    }
  }
}
