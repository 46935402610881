import { FishingBusiness } from '@fishonline2023/shared/models';
import { PendingTransaction } from './pending-transaction.model';
import { QuotaBalance } from './quota-balance.model';
import { RecentlyCompletedTransaction } from './recently-completed-transaction.model';

export interface AccountProfile {
  fishingBusinessDtoList: FishingBusiness[];
  pendingTransactionList?: PendingTransaction[];
  recentlyCompletedTransactionList?: RecentlyCompletedTransaction[];
  quotaBalance: QuotaBalance;
}
