<div class="fb-history-container mt-4">
  <h2 class="mb-3">Fishing Business History</h2>
  <h6 class="mb-3">Determination Certificate for</h6>
  <form [formGroup]="fishingBusinessHistoryForm" class="mb-4">
    <div class="row">
      <div class="col-6">
        <select
          aria-label=".form-select-lg example"
          class="form-select form-select-lg"
          formControlName="fishingBusinessId"
        >
          <option
            *ngFor="let fishingBusiness of fishingBusinessList"
            [value]="fishingBusiness"
          >
            {{ fishingBusiness }}
          </option>
        </select>
      </div>
      <div class="col-6">
        <select
          aria-label=".form-select-lg example"
          class="form-select form-select-lg"
          formControlName="determinationDate"
        >
          <option *ngFor="let fbDate of determinationDateList">
            {{ fbDate }}
          </option>
        </select>
      </div>
    </div>
  </form>
  <sv-ui-fishing-business-history-determination-certificate
    *ngIf="
      determinationCertificate &&
      selectedFishingBusiness &&
      fishingBusinessHistoryReference
    "
    [determinationCertificate]="determinationCertificate"
    [fishingBusinessHistoryReference]="fishingBusinessHistoryReference"
    [selectedFishingBusiness]="selectedFishingBusiness"
  ></sv-ui-fishing-business-history-determination-certificate>
  <sv-ui-fishing-business-history-fisher-history
    *ngIf="selectedFishingBusiness"
    [currentFisher]="currentFisher"
    [fisherHistoryList]="fisherHistoryList"
  ></sv-ui-fishing-business-history-fisher-history>
  <div class="fine-print">
    <ol type="i">
      <em>
        <li>
          The endorsements currently available in no way reflect the
          endorsements available upon transfer. Any respective buyer should make
          enquiries into endorsement availability based on the provisions of the
          Fisheries Management Act 1994 and relevant Regulations.
        </li>
        <li>
          Endorsement Conditions/Notations are subject to change. Full
          descriptions of the "Endorsement Condition/Notation" codes appearing
          on determination certificates (where applicable) are available on the
          NSW DPI website at
          http://www.dpi.nsw.gov.au/fisheries/commercial/licensing-forms (under
          "Additional Information" and the link "Additional endorsement/licence
          conditions register").
        </li>
        <li>
          The determination of non consumable quota (priority ballot, trap, hook
          and crew limits) is provided for under the Fisheries Management
          (Estuary General Share Management Plan) Regulation 2006, the Fisheries
          Management (Ocean Hauling General Share Management Plan) Regulation
          2006 and the Fisheries Management (Ocean Trap and Line Share
          Management Plan) Regulation 2006. This certificate is the instrument
          of the determination, and provides notice, of the relevant limits (as
          specified) for the fishing business identified on this certificate.
          (Non consumable quota is not included in this certificate as a
          component of the fishing business identified on this certificate)
        </li>
      </em>
    </ol>
    <p>
      <em>
        Fishing business determinations are provided for under section 34Q of
        the Fisheries Management Act 1994 (the Act). In accordance with section
        34Q(8) of the Act this certificate is taken to be notice in writing of
        the subject determination for the fishing business identified on the
        certificate. The information included in the certificate reflects the
        information recorded in the database held by NSW Department of Primary
        Industries (DPI). NSW DPI does not guarantee the accuracy of any such
        information provided after the "Date of Issue". NSW DPI accepts no
        liability for any act or omission if this information is incorrect or
        does not accurately reflect the true state of affairs with respect to a
        particular Fishing Business.
      </em>
    </p>
  </div>
</div>
