import { Status } from './licence-detail.model';
import { FishingBusiness } from '@fishonline2023/shared/models';

export interface CustomerSummary {
  fullName: string;
  tradingAs: string;
  dob: Date;
  gender: string;
  abn: string;
  powerOfAttorney: string;
  fisheriesOffice: string;
}

export interface LicenceSummary {
  number: number;
  status: Status;
  expiryDate: string;
}

export interface DemeritPoints {
  issueDate: Date;
  offence: string;
  points: number;
}

export interface AccountSummary {
  customerSummary: CustomerSummary;
  licenceSummary?: LicenceSummary;
  fishingBusinessList?: FishingBusiness[];
  demeritPointsList?: DemeritPoints[];
}
