import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { CommonModule } from '@angular/common';
import {
  FDDateDayJSFormat,
  TransactionData,
} from '@fishonline2023/webapps/model/fd2023';
import { TransferFishingBusinessComponentsOfferDetailComponent } from '../transfer-fishing-business-components-offer-detail/transfer-fishing-business-components-offer-detail.component';
import { FullWidthItemListComponent } from '@fishonline2023/webapps/shared/ui/base-components';
import * as dayjs from 'dayjs';

@Component({
  selector: 'sv-ui-transfer-fishing-business-components-receipt',
  standalone: true,
  imports: [
    CommonModule,
    TransferFishingBusinessComponentsOfferDetailComponent,
    FullWidthItemListComponent,
  ],
  templateUrl: './transfer-fishing-business-components-receipt.component.html',
  styleUrls: ['./transfer-fishing-business-components-receipt.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TransferFishingBusinessComponentsReceiptComponent {
  @Input() public transactionData!: TransactionData;

  protected get itemList() {
    return [
      {
        key: 'Transaction type',
        value: this.transactionData.transactionHeader.type,
      },
      {
        key: 'Transaction number',
        value: this.transactionData.transactionHeader.id,
      },
      ...(this.transactionData.transactionHeader.effectiveDate
        ? [
            {
              key: 'Effective date',
              value: dayjs(
                this.transactionData.transactionHeader.effectiveDate
              ).format(FDDateDayJSFormat),
            },
          ]
        : []),
    ];
  }
}
