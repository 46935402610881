import { bootstrapApplication } from '@angular/platform-browser';
import {
  provideRouter,
  withEnabledBlockingInitialNavigation,
} from '@angular/router';
import { appRoutes } from './app/app.routes';
import { AppComponent } from './app/app.component';
import { provideStore, StoreModule } from '@ngrx/store';
import { EffectsModule, provideEffects } from '@ngrx/effects';
import { provideStoreDevtools } from '@ngrx/store-devtools';
import { metaReducers, reducers } from './app/reducers';
import { importProvidersFrom } from '@angular/core';
import { HttpClientModule } from '@angular/common/http';
import { provideRouterStore } from '@ngrx/router-store';
import { Amplify, Analytics, AWSKinesisProvider } from 'aws-amplify';
import awsconfig from './aws-exports';
import { BreadcrumbModule } from '@fishonline2023/webapps/shared/feature/breadcrumb-store';
import { WebappsSharedAnalyticsModule } from '@fishonline2023/webapps/shared/feature/analytics';

Amplify.configure(awsconfig);
Analytics.addPluggable(new AWSKinesisProvider());
// Configure the plugin after adding it to the Analytics module
Analytics.configure({
  AWSKinesis: {
    // OPTIONAL -  Amazon Kinesis service region
    region: 'ap-southeast-2',
    // OPTIONAL - The buffer size for events in number of items.
    bufferSize: 1000,
    // OPTIONAL - The number of events to be deleted from the buffer when flushed.
    flushSize: 100,
    // OPTIONAL - The interval in milliseconds to perform a buffer check and flush if necessary.
    flushInterval: 5000, // 5s
    // OPTIONAL - The limit for failed recording retries.
    resendLimit: 5,
  },
});

bootstrapApplication(AppComponent, {
  providers: [
    importProvidersFrom(
      EffectsModule.forRoot([]),
      StoreModule.forRoot({}),
      HttpClientModule,
      BreadcrumbModule,
      WebappsSharedAnalyticsModule
    ),
    provideEffects(),
    provideStore(reducers, { metaReducers }),
    provideStoreDevtools(),
    provideRouterStore(),
    provideRouter(appRoutes, withEnabledBlockingInitialNavigation()),
  ],
}).catch((err) => console.error(err));
