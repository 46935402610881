import { Directive, Input, OnDestroy, OnInit } from '@angular/core';
import { InputField } from '@fishonline2023/shared/models';
import { FormControl } from '@angular/forms';
import { Observable, startWith, Subject } from 'rxjs';

@Directive()
export abstract class InputFieldTextDirective implements OnInit, OnDestroy {
  @Input() public inputField!: InputField;
  @Input() public form!: FormControl;
  protected formValue$!: Observable<string | number>;
  protected readonly destroy$ = new Subject<void>();

  public ngOnDestroy() {
    this.destroy$.next();
    this.destroy$.complete();
  }

  public ngOnInit() {
    this.formValue$ = this.form.valueChanges.pipe(startWith(this.form.value));
  }

  // eslint-disable-next-line @typescript-eslint/no-empty-function
  protected onBlur() {}
}
