import {
  ChangeDetectionStrategy,
  Component,
  Input,
  Output,
  EventEmitter,
} from '@angular/core';
import { CommonModule } from '@angular/common';

@Component({
  selector: 'sv-ui-button-link',
  standalone: true,
  imports: [CommonModule],
  templateUrl: './button-link.component.html',
  styleUrls: ['./button-link.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ButtonLinkComponent {
  @Input() text = '';
  @Output() onclick: EventEmitter<MouseEvent> = new EventEmitter();

  handleClick(event: MouseEvent) {
    this.onclick.emit(event);
  }
}
