import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  inject,
  Input,
  OnDestroy,
  OnInit,
  Output,
} from '@angular/core';
import { CommonModule } from '@angular/common';
import {
  FDDataLoaderData,
  FDDateTimeAngularPipeFormat,
} from '@fishonline2023/webapps/model/fd2023';
import {
  ButtonComponent,
  ErrorComponent,
  LoadingComponent,
} from '@fishonline2023/webapps/shared/ui/base-components';
import {
  debounceTime,
  Observable,
  Subject,
  take,
  takeUntil,
  withLatestFrom,
} from 'rxjs';
import { FormControl, ReactiveFormsModule } from '@angular/forms';
import { PrawnReportTableComponent } from './prawn-report-table/prawn-report-table.component';
import { EventType } from '@fishonline2023/webapps/shared/feature/appsync';
import {
  DataLoaderActivityLog,
  PrawnBallotRegionReport,
  PrawnBallotReport,
} from '@fishonline2023/shared/models';
import { FADataLoaderData } from '@fishonline2023/webapps/model/fa2023';

@Component({
  selector: 'sv-ui-print-prawn-ballot',
  standalone: true,
  imports: [
    CommonModule,
    ReactiveFormsModule,
    ButtonComponent,
    ErrorComponent,
    LoadingComponent,
    PrawnReportTableComponent,
  ],
  templateUrl: './prawn-ballot.component.html',
  styleUrls: ['./prawn-ballot.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PrawnBallotComponent implements OnInit, OnDestroy {
  @Input() dataLoaderData$!: Observable<FDDataLoaderData | FADataLoaderData>;
  @Output() public activityLogTriggered =
    new EventEmitter<DataLoaderActivityLog>();
  public shareClassList: string[] = [];
  public shareClassSelect: FormControl = new FormControl('', {
    nonNullable: true,
  });
  public today: number = Date.now();
  public prawnReport?: PrawnBallotReport;
  public FDDateTimeAngularPipeFormat = FDDateTimeAngularPipeFormat;
  private cdr = inject(ChangeDetectorRef);
  private destroy$ = new Subject<void>();

  public get prawnBallotReport$() {
    return this.dataLoaderData$ as Observable<PrawnBallotRegionReport>;
  }

  public ngOnInit(): void {
    this.handleSelectionChange();
    this.prawnBallotReport$.pipe(take(1)).subscribe((prawnBallotReport) => {
      this.shareClassList = Object.keys(prawnBallotReport).sort();
      this.shareClassSelect.patchValue(this.shareClassList[0]);
      this.cdr.detectChanges();
    });
  }

  public ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }

  public onPrintClick(): void {
    (window as Window).print();
  }

  private triggerActivityLog(selectedShareClass: string) {
    this.activityLogTriggered.emit({
      eventType: EventType.ViewPrawnBallot,
      detail: JSON.stringify({
        'Licence class': selectedShareClass,
      }),
    });
  }

  private handleSelectionChange(): void {
    const selectValue$ = this.shareClassSelect.valueChanges.pipe(
      debounceTime(200)
    );

    selectValue$
      .pipe(withLatestFrom(this.prawnBallotReport$), takeUntil(this.destroy$))
      .subscribe(([selectedShareClass, prawnBallotReport]) => {
        this.triggerActivityLog(selectedShareClass);
        this.prawnReport = prawnBallotReport[selectedShareClass];
        this.cdr.detectChanges();
      });
  }
}
