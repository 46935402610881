import { Injectable, inject } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { catchError, map, of, switchMap } from 'rxjs';
import { SystemStatusService } from '../services/system-status.service';
import { Error } from '@fishonline2023/webapps/model/fd2023';
import {
  loadSystemStatus,
  loadSystemStatusFailure,
  loadSystemStatusSuccess,
} from './system-status.actions';

@Injectable()
export class SystemStatusEffects {
  private actions$ = inject(Actions);
  private systemStatusService = inject(SystemStatusService);

  public loadSystemStatus$ = createEffect(() =>
    this.actions$.pipe(
      ofType(loadSystemStatus),
      switchMap(() =>
        this.systemStatusService.loadSystemStatus().pipe(
          map((systemStatus) => {
            return loadSystemStatusSuccess({
              systemStatus,
            });
          }),
          catchError((error: Error) =>
            of(
              loadSystemStatusFailure({
                errorMessage: this.systemStatusService.getErrorMessage(error),
              })
            )
          )
        )
      )
    )
  );
}
