import {
  CREATED_AT,
  EventTypeName,
  NestedSearchFieldValue,
  SaleAndTradePurpose,
  SaleAndTradePurposeValue,
  SaleAndTradeType,
  SearchDetailLoaderRoutePath,
  SearchFieldDataType,
  SearchFieldType,
  SearchFieldValuesFrom,
  SearchMetadata,
  SearchPeriod,
  SearchPeriodValue,
  searchResultField,
  SearchType,
} from './search.model';
// eslint-disable-next-line @nrwl/nx/enforce-module-boundaries
import {
  Channel,
  EventType,
} from '@fishonline2023/webapps/shared/feature/appsync';
import { FARoute, FDRoute } from './route.enum';
import { CustomerType, RegistrationStatus } from './amend-customer.model';

export const FAEventTypeList = [
  EventType.AmendCustomer,
  EventType.CreateCustomer,
  EventType.ViewPrawnBallot,
  EventType.CustomerSearch,
  EventType.CustomerSearchDownload,
];
export const FDEventTypeList = [
  EventType.LoginFailed,
  EventType.LoginSuccessful,
  EventType.NominateRevokeFisher,
  EventType.RenewCFL,
  EventType.SearchShareExtract,
  EventType.SwitchPersona,
  EventType.UpdateContact,
  EventType.ViewAccountSummary,
  EventType.ViewContactDetails,
  EventType.ViewCustomerDetails,
  EventType.ViewFishingBusinessHistory,
  EventType.ViewForSaleAndTrade,
  EventType.ViewLicenceDetails,
  EventType.ViewPrawnBallot,
  EventType.ViewQuotaHistory,
  EventType.ViewMyReceipts,
  EventType.ViewShareExtractFishingBusiness,
  EventType.ViewShareExtractShareholder,
  EventType.ViewMyPersonalAuthorisations,
  EventType.ViewMyFishingBusinessAuthorisations,
  EventType.TransferComponents,
  EventType.TransferFishingBusiness,
  EventType.TransferQuota,
  EventType.ManageAgents,
];

export const ActivityTypesByChannel: Array<NestedSearchFieldValue> = [
  // TODO: filter EventType by channel
  {
    id: Channel.FA,
    label: 'Fisher Assist',
    children: FAEventTypeList.map((eventType) => ({
      id: eventType,
      label: EventTypeName[eventType],
    })),
  },
  {
    id: Channel.FD,
    label: 'Fisher Direct',
    children: FDEventTypeList.map((eventType) => ({
      id: eventType,
      label: EventTypeName[eventType],
    })),
  },
];

const AccountActivity: SearchMetadata = {
  pageTitle: 'My FishOnline Account Activity',
  channel: Channel.FD,
  appRoute: FDRoute.AccountActivity,
  shouldAllowSearchResultColumnFilter: false,
  requireExport: false,
  shouldReturnLimitedNumberOfSearchResult: false,
  searchFields: [
    {
      label: 'Agent',
      width: 12,
      formControlName: 'userCustomerId',
      initValue: '',
      fieldType: SearchFieldType.Select,
      valuesFrom: SearchFieldValuesFrom.MyAgent,
    },
    {
      label: 'Activity',
      width: 12,
      formControlName: 'userActivityLogEventTypeID',
      initValue: '',
      fieldType: SearchFieldType.Select,
      values: FDEventTypeList.map((eventType) => ({
        id: eventType,
        label: EventTypeName[eventType],
      })),
    },
    {
      label: 'Period',
      width: 12,
      formControlName: CREATED_AT,
      initValue: SearchPeriodValue[SearchPeriod.Last30Days],
      fieldType: SearchFieldType.RadioButtonGroup,
      values: Object.values(SearchPeriod).map((searchPeriod) => ({
        id: SearchPeriodValue[searchPeriod],
        label: searchPeriod,
      })),
    },
  ],
  searchResultTableFields: [
    searchResultField({
      label: 'User Name',
      property: 'userName',
      dataType: SearchFieldDataType.String,
    }),
    searchResultField({
      label: 'User Type',
      property: 'userType',
      dataType: SearchFieldDataType.String,
    }),
    searchResultField({
      label: 'Activity',
      property: 'userActivityLogEventTypeID',
      dataType: SearchFieldDataType.EventType,
      link: SearchDetailLoaderRoutePath.AccountActivity,
    }),
    searchResultField({
      label: 'Date',
      property: 'createdAt',
      dataType: SearchFieldDataType.DateTime,
    }),
  ],
};
const UserActivityLog: SearchMetadata = {
  pageTitle: 'Search User Activity Log',
  channel: Channel.FA,
  appRoute: FARoute.UserActivityLog,
  shouldAllowSearchResultColumnFilter: false,
  requireExport: false,
  shouldReturnLimitedNumberOfSearchResult: false,
  searchFields: [
    {
      fieldType: SearchFieldType.Dependent,
      width: 12,
      fields: [
        {
          label: 'Channel',
          width: 12,
          formControlName: 'channel',
          initValue: '',
          fieldType: SearchFieldType.Select,
          valuesFrom: SearchFieldValuesFrom.ActivityTypeByChannel,
        },
        {
          label: 'Activity',
          width: 12,
          formControlName: 'userActivityLogEventTypeID',
          initValue: '',
          fieldType: SearchFieldType.Select,
          valuesFrom: SearchFieldValuesFrom.DependentParent,
        },
      ],
    },
    {
      label: 'Period',
      width: 12,
      formControlName: CREATED_AT,
      initValue: SearchPeriodValue[SearchPeriod.Last30Days],
      fieldType: SearchFieldType.RadioButtonGroup,
      values: Object.values(SearchPeriod).map((searchPeriod) => ({
        id: SearchPeriodValue[searchPeriod],
        label: searchPeriod,
      })),
    },
  ],
  searchResultTableFields: [
    searchResultField({
      label: 'User Name',
      property: 'userName',
      dataType: SearchFieldDataType.String,
    }),
    searchResultField({
      label: 'User Type',
      property: 'userType',
      dataType: SearchFieldDataType.String,
    }),
    searchResultField({
      label: 'Activity',
      property: 'userActivityLogEventTypeID',
      dataType: SearchFieldDataType.EventType,
      link: SearchDetailLoaderRoutePath.AccountActivity,
    }),
    searchResultField({
      label: 'Date',
      property: 'createdAt',
      dataType: SearchFieldDataType.DateTime,
    }),
  ],
};
const ShareExtract: SearchMetadata = {
  pageTitle: 'Print Share Extract',
  channel: Channel.FD,
  appRoute: FDRoute.PrintShareExtract,
  searchFilterOptionsEndpoint: 'share-extract/reference',
  searchEndpoint: 'share-extract',
  shouldAllowSearchResultColumnFilter: false,
  requireExport: false,
  shouldReturnLimitedNumberOfSearchResult: false,
  searchFields: [
    {
      fieldType: SearchFieldType.RadioButtonPreFilter,
      width: 12,
      fields: [
        {
          label: 'Shareholder',
          width: 12,
          formControlName: 'shareholderNumber',
          initValue: '',
          fieldType: SearchFieldType.TypeAhead,
          valuesFrom: SearchFieldValuesFrom.APIResponseByFormControlName,
        },
        {
          label: 'Fishing Business Number',
          width: 12,
          formControlName: 'fishingBusinessNumber',
          initValue: '',
          fieldType: SearchFieldType.TypeAhead,
          valuesFrom: SearchFieldValuesFrom.APIResponseByFormControlName,
        },
        {
          label: 'Share Class',
          width: 12,
          formControlName: 'shareClass',
          initValue: '',
          fieldType: SearchFieldType.TypeAhead,
          valuesFrom: SearchFieldValuesFrom.APIResponseByFormControlName,
        },
        {
          label: 'Fishery',
          width: 12,
          formControlName: 'fishery',
          initValue: '',
          fieldType: SearchFieldType.Select,
          valuesFrom: SearchFieldValuesFrom.APIResponseByFormControlName,
        },
      ],
    },
  ],
  searchResultTableFields: [
    searchResultField({
      label: 'Shareholder',
      property: 'shareholderNumber',
      dataType: SearchFieldDataType.String,
      link: SearchDetailLoaderRoutePath.ShareExtractShareholder,
      sortable: true,
    }),
    searchResultField({
      label: 'Shareholder Name',
      property: 'shareholderName',
      dataType: SearchFieldDataType.String,
      sortable: true,
    }),
    searchResultField({
      label: 'Fishing Business Number',
      property: 'fishingBusinessNumber',
      dataType: SearchFieldDataType.String,
      link: SearchDetailLoaderRoutePath.ShareExtractFishingBusiness,
      sortable: true,
    }),
    searchResultField({
      label: 'Fishery',
      property: 'fisheryName',
      dataType: SearchFieldDataType.String,
    }),
    searchResultField({
      label: 'Share Class',
      property: 'shareClassName',
      dataType: SearchFieldDataType.String,
    }),
    searchResultField({
      label: 'Share Type',
      property: 'shareType',
      dataType: SearchFieldDataType.String,
    }),
    searchResultField({
      label: 'Holding',
      property: 'holding',
      dataType: SearchFieldDataType.String,
      sortable: true,
    }),
  ],
};
const ForSaleAndTrade: SearchMetadata = {
  pageTitle: 'For Sale and Trade',
  channel: Channel.FD,
  appRoute: FDRoute.ForSaleAndTradeSearch,
  searchEndpoint: 'bulletin-board-ads/search',
  shouldAllowSearchResultColumnFilter: false,
  requireExport: false,
  shouldReturnLimitedNumberOfSearchResult: false,
  searchFields: [
    {
      label: 'Purpose',
      width: 12,
      formControlName: 'purpose',
      initValue: SaleAndTradePurposeValue[SaleAndTradePurpose.ForSale],
      fieldType: SearchFieldType.RadioButtonGroup,
      values: Object.values(SaleAndTradePurpose).map((purpose) => ({
        id: SaleAndTradePurposeValue[purpose],
        label: purpose,
      })),
    },
    {
      label: 'Keyword',
      width: 12,
      formControlName: 'keyword',
      initValue: '',
      fieldType: SearchFieldType.Text,
      placeholder: 'Enter keywords',
    },
    {
      label: 'Type',
      width: 12,
      formControlName: 'type',
      initValue: '',
      fieldType: SearchFieldType.Select,
      values: Object.entries(SaleAndTradeType)
        .map(([id, label]) => ({
          id,
          label,
        }))
        .sort((a, b) => a.label.localeCompare(b.label)),
    },
  ],
  searchResultTableFields: [
    searchResultField({
      label: 'Ad ID',
      property: 'id',
      dataType: SearchFieldDataType.Number,
    }),
    searchResultField({
      label: 'Date Posted',
      property: 'postedDate',
      dataType: SearchFieldDataType.DateTime,
      sortable: true,
    }),
    searchResultField({
      label: 'Item',
      property: 'title',
      dataType: SearchFieldDataType.String,
      link: SearchDetailLoaderRoutePath.ForSaleAndTrade,
    }),
    searchResultField({
      label: 'Type',
      property: 'type',
      dataType: SearchFieldDataType.String,
      sortable: true,
    }),
    searchResultField({
      label: 'Price',
      property: 'price',
      dataType: SearchFieldDataType.Currency,
    }),
  ],
};
const QuotaHistory: SearchMetadata = {
  pageTitle: 'Quota History',
  channel: Channel.FD,
  appRoute: FDRoute.QuotaHistory,
  searchFilterOptionsEndpoint: 'quota-transaction-history/search-criteria',
  searchEndpoint: 'quota-transaction-history/search',
  shouldAllowSearchResultColumnFilter: false,
  requireExport: false,
  shouldReturnLimitedNumberOfSearchResult: false,
  searchFields: [
    {
      fieldType: SearchFieldType.Dependent,
      width: 12,
      fields: [
        {
          label: 'Fishing Business',
          width: 12,
          formControlName: 'fishingBusiness',
          initValue: '',
          fieldType: SearchFieldType.Select,
          valuesFrom: SearchFieldValuesFrom.APIResponseByFormControlName,
        },
        {
          label: 'Quota Type',
          width: 12,
          formControlName: 'quotaRegime',
          initValue: '',
          fieldType: SearchFieldType.Select,
          valuesFrom: SearchFieldValuesFrom.DependentParent,
        },
        {
          label: 'Fishing Period',
          width: 12,
          formControlName: 'fishingPeriod',
          initValue: '',
          fieldType: SearchFieldType.Select,
          valuesFrom: SearchFieldValuesFrom.DependentParent,
        },
      ],
    },
  ],
  searchResultTableFields: [
    searchResultField({
      label: 'Date',
      property: 'date',
      dataType: SearchFieldDataType.DateTime,
    }),
    searchResultField({
      label: 'Transaction Type',
      property: 'transactionType',
      dataType: SearchFieldDataType.String,
    }),
    searchResultField(
      {
        label: 'Debit',
        property: 'debit',
        dataType: SearchFieldDataType.String,
      },
      (result) =>
        result['debit'] ? `${result['debit']} ${result['unit']}` : '-'
    ),
    searchResultField(
      {
        label: 'Credit',
        property: 'credit',
        dataType: SearchFieldDataType.String,
      },
      (result) =>
        result['credit'] ? `${result['credit']} ${result['unit']}` : '-'
    ),
  ],
};
const MyReceipts: SearchMetadata = {
  pageTitle: 'My Receipts',
  channel: Channel.FD,
  appRoute: FDRoute.MyReceipts,
  searchFilterOptionsEndpoint: 'e-receipt/transactions-types',
  searchEndpoint: 'e-receipt/search',
  shouldAllowSearchResultColumnFilter: false,
  requireExport: false,
  shouldReturnLimitedNumberOfSearchResult: false,
  searchFields: [
    {
      label: 'Transaction Type',
      width: 12,
      formControlName: 'transactionType',
      initValue: '',
      fieldType: SearchFieldType.Select,
      valuesFrom: SearchFieldValuesFrom.APIResponseByFormControlName,
    },
    {
      label: 'Fishing Business',
      width: 12,
      formControlName: 'fishingBusiness',
      initValue: '',
      fieldType: SearchFieldType.Select,
      valuesFrom: SearchFieldValuesFrom.FishingBusinesses,
    },
  ],
  searchResultTableFields: [
    searchResultField({
      label: 'Date',
      property: 'date',
      dataType: SearchFieldDataType.DateTime,
    }),
    searchResultField({
      label: 'Transaction Type',
      property: 'transactionType',
      dataType: SearchFieldDataType.String,
      link: SearchDetailLoaderRoutePath.MyReceipts,
    }),
    searchResultField({
      label: 'Fishing Business',
      property: 'fishingBusiness',
      dataType: SearchFieldDataType.String,
    }),
    searchResultField({
      label: 'Fishing Business Owner',
      property: 'fishingBusinessOwner',
      dataType: SearchFieldDataType.String,
    }),
    searchResultField({
      label: 'Fisher',
      property: 'fisher',
      dataType: SearchFieldDataType.String,
    }),
  ],
};
const CustomerDetailSearch: SearchMetadata = {
  pageTitle: 'Customer Detail Search',
  channel: Channel.FA,
  appRoute: FARoute.CustomerDetailSearch,
  searchFilterOptionsEndpoint: 'reference/customer',
  searchEndpoint: 'search/customer',
  downloadCSVName: 'CustomerDetailsDownload',
  shouldReturnLimitedNumberOfSearchResult: true,
  shouldAllowSearchResultColumnFilter: true,
  requireExport: true,
  searchFields: [
    {
      width: 12,
      fieldType: SearchFieldType.RadioButtonPreFilter,
      fields: [
        {
          label: 'Registration Number',
          width: 12,
          formControlName: 'registrationNumber',
          initValue: '',
          fieldType: SearchFieldType.TypeAhead,
          valuesFrom: SearchFieldValuesFrom.APIResponseByFormControlName,
        },
        {
          label: 'Fishing Business Number',
          width: 12,
          formControlName: 'fishingBusinessNumber',
          initValue: '',
          fieldType: SearchFieldType.TypeAhead,
          valuesFrom: SearchFieldValuesFrom.APIResponseByFormControlName,
        },
      ],
    },
    // {
    //   label: 'Issue Date',
    //   width: 6,
    //   fieldType: SearchFieldType.DateRange,
    //   fields: [
    //     {
    //       label: 'From',
    //       width: 6,
    //       formControlName: 'issueDateFrom',
    //       initValue: '',
    //       fieldType: SearchFieldType.Date,
    //     },
    //     {
    //       label: 'To',
    //       width: 6,
    //       formControlName: 'issueDateTo',
    //       initValue: '',
    //       fieldType: SearchFieldType.Date,
    //     },
    //   ],
    // },
    // {
    //   label: 'Expiry Date',
    //   width: 6,
    //   fieldType: SearchFieldType.DateRange,
    //   fields: [
    //     {
    //       label: 'From',
    //       width: 6,
    //       formControlName: 'expiryDateFrom',
    //       initValue: '',
    //       fieldType: SearchFieldType.Date,
    //     },
    //     {
    //       label: 'To',
    //       width: 6,
    //       formControlName: 'expiryDateTo',
    //       initValue: '',
    //       fieldType: SearchFieldType.Date,
    //     },
    //   ],
    // },
    {
      label: 'Customer Type',
      width: 4,
      formControlName: 'customerType',
      initValue: '',
      fieldType: SearchFieldType.Select,
      values: Object.entries(CustomerType).map(([label, id]) => ({
        id,
        label,
      })),
    },
    {
      label: 'Registration Status',
      width: 4,
      formControlName: 'registrationStatus',
      initValue: '',
      fieldType: SearchFieldType.Select,
      values: Object.entries(RegistrationStatus).map(([label, id]) => ({
        id,
        label,
      })),
    },
    {
      label: 'Fisheries Office',
      width: 4,
      formControlName: 'fisheriesOffice',
      initValue: '',
      fieldType: SearchFieldType.Select,
      valuesFrom: SearchFieldValuesFrom.APIResponseByFormControlName,
    },
  ],
  defaultSearchResultColumnList: [
    'Registration Number',
    'Customer Name',
    'Customer Type',
    'Date of Birth',
    'Fisheries Office',
    'FisherDirect User ID',
    'FisherMobile User ID',
    'WHF Folder Number',
  ],
  searchResultTableFields: [
    searchResultField({
      label: 'Registration Number',
      property: 'registrationNumber',
      dataType: SearchFieldDataType.String,
      sortable: true,
    }),
    searchResultField({
      label: 'Customer Name',
      property: 'customerName',
      dataType: SearchFieldDataType.String,
      sortable: true,
    }),
    searchResultField({
      label: 'Customer Type',
      property: 'customerType',
      dataType: SearchFieldDataType.String,
      sortable: true,
    }),
    searchResultField({
      label: 'Date of Birth',
      property: 'dateOfBirth',
      dataType: SearchFieldDataType.Date,
      sortable: true,
    }),
    searchResultField({
      label: 'Fisheries Office',
      property: 'fisheriesOffice',
      dataType: SearchFieldDataType.String,
      sortable: true,
    }),
    searchResultField({
      label: 'FisherDirect User ID',
      property: 'fisherDirectUserId',
      dataType: SearchFieldDataType.String,
      sortable: true,
    }),
    searchResultField({
      label: 'FisherMobile User ID',
      property: 'fisherMobileUserId',
      dataType: SearchFieldDataType.String,
      sortable: true,
    }),
    searchResultField({
      label: 'WHF Folder Number',
      property: 'whfFolderNumber',
      dataType: SearchFieldDataType.String,
      sortable: true,
    }),
    searchResultField({
      label: 'Registration Date',
      property: 'registrationDate',
      dataType: SearchFieldDataType.DateTime,
      sortable: true,
    }),
    searchResultField({
      label: 'Registration Status',
      property: 'registrationStatus',
      dataType: SearchFieldDataType.String,
      sortable: true,
    }),
    searchResultField({
      label: 'Trading As',
      property: 'tradingAs',
      dataType: SearchFieldDataType.String,
      sortable: true,
    }),
    searchResultField({
      label: 'ACN',
      property: 'acn',
      dataType: SearchFieldDataType.String,
      sortable: true,
    }),
    searchResultField({
      label: 'ABN',
      property: 'abn',
      dataType: SearchFieldDataType.String,
      sortable: true,
    }),
    searchResultField({
      label: 'ASIC Status',
      property: 'asicStatus',
      dataType: SearchFieldDataType.String,
      sortable: true,
    }),
    searchResultField({
      label: 'Fishing Business Number',
      property: 'fishingBusinessNumber',
      dataType: SearchFieldDataType.String,
    }),
    searchResultField({
      label: 'Mortgage All Flag',
      property: 'mortgageAll',
      dataType: SearchFieldDataType.String,
      sortable: true,
    }),
    searchResultField({
      label: 'Demerit Points',
      property: 'demeritPoints',
      dataType: SearchFieldDataType.String,
      sortable: true,
    }),
    searchResultField({
      label: 'Preferred Contact Name',
      property: 'preferredContactName',
      dataType: SearchFieldDataType.String,
      sortable: true,
    }),
    searchResultField({
      label: 'Preferred Contact Number',
      property: 'preferredContactNumber',
      dataType: SearchFieldDataType.String,
      sortable: true,
    }),
    searchResultField({
      label: 'Physical Address',
      property: 'physicalAddress',
      dataType: SearchFieldDataType.String,
      sortable: true,
    }),
    searchResultField({
      label: 'Postal Address',
      property: 'postalAddress',
      dataType: SearchFieldDataType.String,
      sortable: true,
    }),
    searchResultField({
      label: 'Billing Address',
      property: 'billingAddress',
      dataType: SearchFieldDataType.String,
      sortable: true,
    }),
    searchResultField({
      label: 'Email Address',
      property: 'emailAddress',
      dataType: SearchFieldDataType.String,
      sortable: true,
    }),
    searchResultField({
      label: 'Mobile Number',
      property: 'mobileNumber',
      dataType: SearchFieldDataType.String,
      sortable: true,
    }),
    searchResultField({
      label: 'Home Phone Number',
      property: 'homePhoneNumber',
      dataType: SearchFieldDataType.String,
      sortable: true,
    }),
    searchResultField({
      label: 'Work Phone Number',
      property: 'workPhoneNumber',
      dataType: SearchFieldDataType.String,
      sortable: true,
    }),
    searchResultField({
      label: 'Fax Number',
      property: 'faxNumber',
      dataType: SearchFieldDataType.String,
      sortable: true,
    }),
  ],
};

export const searchMetadata: Record<SearchType, SearchMetadata> = {
  [SearchType.AccountActivity]: AccountActivity,
  [SearchType.UserActivityLog]: UserActivityLog,
  [SearchType.ShareExtract]: ShareExtract,
  [SearchType.ForSaleAndTrade]: ForSaleAndTrade,
  [SearchType.QuotaHistory]: QuotaHistory,
  [SearchType.MyReceipts]: MyReceipts,
  [SearchType.CustomerDetailSearch]: CustomerDetailSearch,
};
