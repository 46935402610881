<table
  *ngIf="searchResults.length > 0"
  class="table align-middle table-striped mt-3"
  data-cy="search-result-table"
>
  <thead>
    <tr>
      <th *ngFor="let field of searchResultFields" scope="col">
        <div class="table-header-container">
          {{ field.label }}
          <sv-ui-table-sorting-button
            (sortButtonClicked)="
              sortSearchResults.emit({
                sortOrder: $event,
                searchResultField: field
              })
            "
            *ngIf="field.sortable"
            [columnSorted]="columnSorted$ | async"
            [column]="field.property"
          ></sv-ui-table-sorting-button>
        </div>
      </th>
    </tr>
  </thead>
  <tbody class="table-group-divider">
    <tr *ngFor="let result of searchResults; trackBy: trackByResult">
      <ng-container *ngIf="result">
        <td *ngFor="let field of searchResultFields">
          <ng-container *ngIf="field.link">
            <button
              (click)="
                presentSearchResultDetailView.emit({
                  selectedSearchResult: result,
                  selectedField: field
                })
              "
              class="btn btn-link ps-0 pe-0"
            >
              {{ transformSearchResult(field, result) }}
            </button>
          </ng-container>
          <ng-container *ngIf="!field.link">
            {{ transformSearchResult(field, result) }}
          </ng-container>
        </td>
      </ng-container>
    </tr>
  </tbody>
</table>
