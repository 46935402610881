import { createFeatureSelector, createSelector } from '@ngrx/store';
import {
  UserProfileState,
  USER_PROFILE_FEATURE_KEY,
} from './user-profile.reducers';

export const userProfileFeature = createFeatureSelector<UserProfileState>(
  USER_PROFILE_FEATURE_KEY
);

export const getCustomerAgentList = createSelector(
  userProfileFeature,
  (state: UserProfileState) => state?.customerAgentList
);

export const getUserProfile = createSelector(
  userProfileFeature,
  (state: UserProfileState) => state?.userProfile
);

export const getUsername = createSelector(
  getUserProfile,
  (userProfile) => userProfile?.userName
);

export const getFirstName = createSelector(
  getUserProfile,
  (userProfile) => userProfile?.firstName
);

export const getLastName = createSelector(
  getUserProfile,
  (userProfile) => userProfile?.lastName
);

export const getViewStatus = createSelector(
  userProfileFeature,
  (state: UserProfileState) => state?.viewStatus
);

export const getLogoutStatus = createSelector(
  userProfileFeature,
  (state: UserProfileState) => state?.logoutStatus
);

export const getErrorMessage = createSelector(
  userProfileFeature,
  (state: UserProfileState) => state?.errorMessage
);

export const getAgentCustomersList = createSelector(
  getUserProfile,
  (userProfile) => userProfile?.agentCustomerList
);

export const getActAsCustomer = createSelector(
  userProfileFeature,
  (state: UserProfileState) => state?.actAsCustomer
);

export const getCustomerPermissionList = createSelector(
  userProfileFeature,
  (state: UserProfileState) => state?.customerPermissionList
);

export const getEntityId = createSelector(
  getActAsCustomer,
  (customer) => customer?.id
);
