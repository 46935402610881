import { AgentCustomer, SortOrder } from '@fishonline2023/shared/models';
import {
  Authorisation,
  FDDateTimeDayJSFormat,
  TableSortField,
} from '@fishonline2023/webapps/model/fd2023';
import * as dayjs from 'dayjs';
import { flatten, groupBy, isNil, prop } from '@fishonline2023/shared/ramda';

export const transformTableResult = (
  field: TableSortField,
  result: Authorisation
): string => {
  switch (field.property) {
    case 'startDate':
      return dayjs(result.startDate).format(FDDateTimeDayJSFormat);
    case 'endDate':
      return !isNil(result.endDate)
        ? dayjs(result.endDate).format(FDDateTimeDayJSFormat)
        : 'Current';
    case 'fisher':
      return `${result.fisher.fullName} (${result.fisher.id})`;
    case 'fishingBusinessId':
      return `${result.fishingBusinessId}`;
    case 'owner':
      return `${result.owner.fullName} (${result.owner.id})`;
    default:
      return '';
  }
};

export const sortByCustomer = (
  authorisations: Authorisation[],
  sortOrder: SortOrder,
  fieldName: string
): Authorisation[] => {
  const sortedAuthorisations = groupByFishingBusinessId(
    sortByEndDateDescending(authorisations)
  );
  return [...sortedAuthorisations].sort((a, b) => {
    let customerA!: AgentCustomer;
    let customerB!: AgentCustomer;
    switch (fieldName) {
      case 'fisher':
        customerA = a.fisher;
        customerB = b.fisher;
        break;
      case 'owner':
        customerA = a.owner;
        customerB = b.owner;
        break;
    }
    const textA = `${customerA.fullName} (${customerA.id})`.toLowerCase();
    const textB = `${customerB.fullName} (${customerB.id})`.toLowerCase();
    return sortOrder === SortOrder.ASC
      ? textA < textB
        ? -1
        : textA > textB
        ? 1
        : 0
      : textA < textB
      ? 1
      : textA > textB
      ? -1
      : 0;
  });
};

export const groupByFishingBusinessId = (
  authorisations: Authorisation[]
): Authorisation[] => {
  return flatten(
    Object.values(groupBy(prop('fishingBusinessId'), authorisations))
  );
};

export const sortByEndDateDescending = (authorisations: Authorisation[]) => {
  return [...authorisations].sort((a: Authorisation, b: Authorisation) => {
    const endDateA = a.endDate;
    const endDateB = b.endDate;
    if (isNil(endDateA)) {
      return -1;
    }
    if (isNil(endDateB)) {
      return 1;
    }
    return dayjs(endDateA).isBefore(dayjs(endDateB)) ? 1 : -1;
  });
};
