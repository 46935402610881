import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  inject,
  OnInit,
} from '@angular/core';
import { CommonModule } from '@angular/common';
import { Store } from '@ngrx/store';
import { filter, Observable, skip, switchMap, take } from 'rxjs';
import {
  complement,
  equals,
  isEmpty,
  merge,
} from '@fishonline2023/shared/ramda';
import {
  DataLoaderActivityLog,
  DataLoaderMetadata,
  DataLoaderType,
  RouteData,
  ViewStatus,
} from '@fishonline2023/shared/models';
import {
  getDataLoaderData,
  getErrorMessage,
  getViewStatus,
} from '../store/data-loader.selectors';
import {
  activityLogTriggered,
  initDataLoader,
  loadDataLoaderData,
} from '../store/data-loader.actions';
import { ActivatedRoute } from '@angular/router';
import { LicenceDetailsComponent } from '@fishonline2023/webapps/licence/fd2023/ui/licence-details';
import { FishingBusinessHistoryComponent } from '@fishonline2023/webapps/fishing-business/fd2023/ui/fishing-business-history';
import {
  ErrorComponent,
  LoadingComponent,
} from '@fishonline2023/webapps/shared/ui/base-components';
import { AccountSummaryComponent } from '@fishonline2023/webapps/my-account/fd2023/ui/account-summary';
import {
  FishingBusinessReportComponent,
  ShareholderReportComponent,
} from '@fishonline2023/webapps/notice-and-extract/fd2023/ui/share-extract-reports';
import { MyPersonalAuthorisationsComponent } from '@fishonline2023/webapps/authorised-fishers/fd2023/ui/my-personal-authorisations';
import { MyFishingBusinessAuthorisationsComponent } from '@fishonline2023/webapps/authorised-fishers/fd2023/ui/my-fishing-business-authorisations';
import { ForSaleAndTradeViewEditComponent } from '@fishonline2023/webapps/notice-and-extract/fd2023/ui/for-sale-and-trade-view-edit';
import { PrawnBallotComponent } from '@fishonline2023/webapps/notice-and-extract/shared';
import {
  FDDataLoaderData,
  FDDataLoaderMetadata,
} from '@fishonline2023/webapps/model/fd2023';
import {
  FADataLoaderData,
  FADataLoaderMetadata,
} from '@fishonline2023/webapps/model/fa2023';
import { RecentlyCompletedTransactionReceiptComponent } from '@fishonline2023/webapps/transaction/fd2023/ui/recently-completed-transaction';
import { CustomerDetailComponent } from '@fishonline2023/webapps/my-account/fa2023/ui/customer-detail';

@Component({
  selector: 'sv-feature-data-loader',
  standalone: true,
  imports: [
    CommonModule,
    LicenceDetailsComponent,
    PrawnBallotComponent,
    FishingBusinessHistoryComponent,
    ErrorComponent,
    LoadingComponent,
    AccountSummaryComponent,
    ShareholderReportComponent,
    FishingBusinessReportComponent,
    MyPersonalAuthorisationsComponent,
    MyFishingBusinessAuthorisationsComponent,
    ForSaleAndTradeViewEditComponent,
    RecentlyCompletedTransactionReceiptComponent,
    CustomerDetailComponent,
  ],
  templateUrl: './data-loader.component.html',
  styleUrls: ['./data-loader.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DataLoaderComponent implements OnInit {
  public dataLoaderType!: DataLoaderType;
  public viewStatus: ViewStatus = ViewStatus.Loading;
  public errorMessage?: string;
  public DataLoaderType = DataLoaderType;
  public DataLoaderMetadata!: DataLoaderMetadata;
  public readonly ViewStatus = ViewStatus;
  public queryParams: Record<string, unknown> = {};
  private store = inject(Store);
  public dataLoaderData$: Observable<FADataLoaderData | FDDataLoaderData> =
    this.store
      .select(getDataLoaderData)
      .pipe(skip(1), filter(complement(isEmpty)), take(1));
  private activatedRoute = inject(ActivatedRoute);
  private cdr = inject(ChangeDetectorRef);

  protected get FDDataLoaderData$() {
    return this.dataLoaderData$ as Observable<FDDataLoaderData>;
  }

  protected get FADataLoaderData$() {
    return this.dataLoaderData$ as Observable<FADataLoaderData>;
  }

  public ngOnInit(): void {
    this.dataLoaderType =
      this.activatedRoute.snapshot.data[RouteData.DataLoaderType];
    const channel = this.activatedRoute.snapshot.data[RouteData.Channel];
    this.DataLoaderMetadata = merge(FDDataLoaderMetadata, FADataLoaderMetadata)[
      this.dataLoaderType
    ] as DataLoaderMetadata;
    this.queryParams = this.activatedRoute.snapshot.queryParams;
    this.store.dispatch(
      initDataLoader({ dataLoaderType: this.dataLoaderType, channel })
    );
    this.loadAndSubscribeToDataLoaderDataStatus();
  }

  public loadAndSubscribeToDataLoaderDataStatus(): void {
    this.store
      .select(getViewStatus)
      .pipe(skip(1), take(2))
      .subscribe((viewStatus) => {
        this.viewStatus = viewStatus;
        this.cdr.detectChanges();
      });

    this.store
      .select(getViewStatus)
      .pipe(
        skip(1),
        filter(equals<ViewStatus>(ViewStatus.Failure)),
        take(1),
        switchMap(() => this.store.select(getErrorMessage))
      )
      .subscribe((errorMessage) => {
        this.errorMessage = errorMessage;
        this.cdr.detectChanges();
      });

    this.store.dispatch(loadDataLoaderData({ params: this.queryParams }));
  }

  public dispatchActivityLogTriggered(activityLog: DataLoaderActivityLog) {
    this.store.dispatch(activityLogTriggered({ activityLog }));
  }
}
