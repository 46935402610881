<div class="mt-4" *ngIf="licenceDetails$ | async as licenceDetails">
  <h4>Commercial Fishing Licence</h4>
  <div class="mb-3 mt-3">
    <sv-ui-licence-detail-info
      [licenceHeader]="licenceDetails.header"
    ></sv-ui-licence-detail-info>
  </div>
  <div class="mb-5">
    <h6>Conditions / Notations</h6>
    <sv-ui-licence-detail-condition-notation
      [licenceDetails]="licenceDetails"
    ></sv-ui-licence-detail-condition-notation>
  </div>
  <div class="mb-3">
    <h6>Licence transactions</h6>
    <table class="table align-middle table-striped mt-3">
      <thead>
        <tr>
          <th scope="col">ID</th>
          <th scope="col">Type</th>
          <th scope="col">Status</th>
          <th scope="col">Date</th>
        </tr>
      </thead>
      <tbody class="table-group-divider">
        <tr
          *ngFor="
            let transaction of licenceDetails.transactionList | slice : 0 : 20
          "
        >
          <td>{{ transaction.id }}</td>
          <td>{{ transaction.type }}</td>
          <td>{{ transaction.status }}</td>
          <td>{{ transaction.date }}</td>
        </tr>
      </tbody>
    </table>
  </div>
</div>
