import { Customer, FishingBusiness } from '@fishonline2023/shared/models';
import { TransactionType } from './transaction.enum';

export interface RecentlyCompletedTransaction {
  entityId: number;
  toEntityId?: number;
  transactionId: number;
  type: TransactionType;
  date: string;
  fishingBusiness?: FishingBusiness;
  customer: Customer;
}

export interface RecentlyCompletedTransactionForCardInput
  extends RecentlyCompletedTransaction {
  title: string;
  description: string;
}
