<ng-container *ngIf="inputField.options">
  <ng-container *ngIf="inputField.isReadOnly; else editable">
    <select
      [attr.aria-label]="inputField.label"
      [attr.data-cy]="inputField.formControlName"
      [disabled]="true"
      [ngModel]="form.value"
      class="form-select form-select-lg"
    >
      <option *ngFor="let value of inputField.options" [ngValue]="value.value">
        {{ value.label }}
      </option>
    </select>
  </ng-container>
  <ng-template #editable>
    <select
      [attr.aria-label]="inputField.label"
      [attr.data-cy]="inputField.formControlName"
      [formControl]="form"
      class="form-select form-select-lg"
    >
      <option *ngFor="let value of inputField.options" [ngValue]="value.value">
        {{ value.label }}
      </option>
    </select>
  </ng-template>
</ng-container>
