import { CommonModule } from '@angular/common';
import {
  ChangeDetectionStrategy,
  Component,
  OnInit,
  inject,
  ChangeDetectorRef,
} from '@angular/core';
import { Router, RouterModule } from '@angular/router';
import { equals } from '@fishonline2023/shared/ramda';
import {
  ErrorComponent,
  LoadingComponent,
} from '@fishonline2023/webapps/shared/ui/base-components';
import { SystemStatusComponent as SystemStatusPresentationalComponent } from '@fishonline2023/webapps/user-portal/fd2023/ui/system-status';
import { Store } from '@ngrx/store';
import { filter, skip, switchMap, take } from 'rxjs';
import { ViewStatus } from '@fishonline2023/shared/models';
import { Message } from '@fishonline2023/webapps/model/fd2023';
import {
  getErrorMessage,
  getViewStatus,
} from './store/system-status.selectors';
import { loadSystemStatus } from './store/system-status.actions';

@Component({
  selector: 'sv-feature-fishonline2023-system-status',
  standalone: true,
  imports: [
    CommonModule,
    RouterModule,
    ErrorComponent,
    LoadingComponent,
    SystemStatusPresentationalComponent,
  ],
  templateUrl: './system-status.component.html',
  styleUrls: ['./system-status.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SystemStatusComponent implements OnInit {
  private store = inject(Store);
  private router = inject(Router);
  private cdr = inject(ChangeDetectorRef);

  public errorMessage?: string;
  public buttonLabel = 'Try again';
  public loadingMessage = Message.LoadingSystemStatus;
  public viewStatus: ViewStatus = ViewStatus.Loading;
  public readonly ViewStatus = ViewStatus;

  public ngOnInit() {
    this.loadSystemStatusAndSubscribeToStatus();
  }

  public loadSystemStatusAndSubscribeToStatus() {
    this.store
      .select(getViewStatus)
      .pipe(skip(1), take(2))
      .subscribe((viewStatus) => {
        this.viewStatus = viewStatus;
        if (equals(viewStatus, ViewStatus.Success)) {
          this.router.navigate(['/login']);
        }
        this.cdr.detectChanges();
      });
    this.store
      .select(getViewStatus)
      .pipe(
        skip(1),
        filter(equals<ViewStatus>(ViewStatus.Failure)),
        take(1),
        switchMap(() => this.store.select(getErrorMessage))
      )
      .subscribe((errorMessage) => {
        this.errorMessage = errorMessage;
        this.cdr.detectChanges();
      });
    this.store.dispatch(loadSystemStatus());
  }
}
