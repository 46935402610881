<input
  *ngIf="inputField.isReadOnly; else editable"
  [attr.aria-label]="inputField.label"
  [attr.data-cy]="inputField.formControlName"
  [disabled]="true"
  [placeholder]="inputField.placeholder || ''"
  [value]="form.value"
  class="form-control form-control-lg"
  type="date"
/>
<ng-template #editable>
  <input
    [attr.aria-label]="inputField.label"
    [attr.data-cy]="inputField.formControlName"
    [formControl]="form"
    [max]="inputField.maxDate"
    [min]="inputField.minDate"
    [placeholder]="inputField.placeholder || ''"
    class="form-control form-control-lg"
    type="date"
  />
</ng-template>
