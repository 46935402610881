import { AgentCustomer } from './agent-customer.model';
import { FormControl } from '@angular/forms';

export type Customer = AgentCustomer;

export interface FishingBusiness {
  id: number;
  owner: Customer;
  group?: string;
  type?: number;
}

export interface FishingBusinessesFromToForm {
  from: FormControl<FishingBusiness | null>;
  to: FormControl<FishingBusiness | null>;
}
