import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  inject,
  Input,
  Output,
} from '@angular/core';
import { CommonModule } from '@angular/common';
import { ModalComponent } from '../modal/modal.component';
import { ModalId } from '@fishonline2023/shared/models';
import { ModalService } from '../modal/modal.service';

@Component({
  selector: 'sv-ui-confirmation-modal',
  standalone: true,
  imports: [CommonModule, ModalComponent],
  templateUrl: './confirmation-modal.component.html',
  styleUrls: ['./confirmation-modal.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ConfirmationModalComponent {
  @Input() public title = '';
  @Input() public message = '';
  @Input() public cancelButtonLabel = 'Cancel';
  @Input() public confirmButtonLabel = 'Confirm';
  @Output() public confirm = new EventEmitter<void>();
  protected readonly ModalId = ModalId;
  private modalService = inject(ModalService);

  public dismissModal(): void {
    this.modalService.close();
  }

  public onConfirm(): void {
    this.dismissModal();
    this.confirm.emit();
  }
}
