import { EventType } from '@fishonline2023/webapps/shared/feature/appsync';
import { SearchType } from '@fishonline2023/shared/models';

export type SearchEventTypeToMap =
  | SearchType.ShareExtract
  | SearchType.ForSaleAndTrade
  | SearchType.QuotaHistory
  | SearchType.MyReceipts
  | SearchType.CustomerDetailSearch;

export type DownloadSearchCSVEventTypeToMap = SearchType.CustomerDetailSearch;

export const SearchEventType: Record<SearchEventTypeToMap, EventType> = {
  [SearchType.ShareExtract]: EventType.SearchShareExtract,
  [SearchType.ForSaleAndTrade]: EventType.ViewForSaleAndTrade,
  [SearchType.QuotaHistory]: EventType.ViewQuotaHistory,
  [SearchType.MyReceipts]: EventType.ViewMyReceipts,
  [SearchType.CustomerDetailSearch]: EventType.CustomerSearch,
};

export const DownloadSearchCSVEventType: Record<
  DownloadSearchCSVEventTypeToMap,
  EventType
> = {
  [SearchType.CustomerDetailSearch]: EventType.CustomerSearchDownload,
};
