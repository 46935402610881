export interface ToastConfig {
  duration?: number;
  position: ToastPosition;
  color: ToastColor;
  shouldManuallyClose: boolean;
}

export enum ToastPosition {
  TopLeft = 'top-left',
  TopRight = 'top-right',
  BottomLeft = 'bottom-left',
  BottomRight = 'bottom-right',
}

export enum ToastColor {
  Success = 'success',
  Danger = 'danger',
  Warning = 'warning',
  Primary = 'primary',
  Light = 'light',
  Dark = 'dark',
}
