export enum MenuName {
  CATCH_EFFORT_HISTORY = 'Catch & Effort History',
  FISHING_ACTIVITY_HISTORY = 'Fishing Activity History',
  CATCH_EFFORT = 'Catch & Effort',
  QUOTA_FISHERIES = 'Quota Fisheries',
  FISHING_BUSINESS = 'Fishing Business',
  AUTHORISED_FISHERS = 'Authorised Fishers',
  LICENCE = 'Licences',
  NOTICE_AND_EXTRACT = 'Notices & Extracts',
  MY_ACCOUNT = 'My Account Profile',
  QUOTA_TRANSFER = 'Transfer Quota',
  QUOTA_TRANSACTIONS = 'Quota Transactions',
  TAG_TRANSACTIONS = 'Tag Transactions',
  TAG_TRANSACTION_HISTORY = 'Tag Transaction History',
  FISHING_BUSINESS_HISTORY = 'Fishing Business History',
  TRANSFER_FISHING_BUSINESS = 'Transfer Fishing Business',
  TRANSFER_COMPONENTS = 'Transfer Components',
  NOMINATE_REVOKE_FISHERS = 'Nominate/Revoke Fishers',
  MY_PERSONAL_AUTHORISATIONS = 'My Personal Authorisations',
  MY_FISHING_BUSINESS_AUTHORISATIONS = 'My Fishing Business Authorisations',
  LICENCE_DETAILS = 'Commercial Fishing Licence',
  MY_RECEIPTS = 'My Receipts',
  FOR_SALE_TRADE = 'For Sale & Trade',
  PRINT_SHARE_EXTRACT = 'Print Share Extract',
  PRINT_PRAWN_BALLOT = 'Print Prawn Ballot',
  ACCOUNT_SUMMARY = 'Account Summary',
  CONTACT_DETAILS = 'Contact Details',
  MY_FISHONLINE_ACCOUNT_ACTIVITY = 'My FishOnline Account Activity',
  MANAGE_AGENTS = 'Manage Agents',
  CUSTOMER_FINANCE_DETAILS = 'Customer Finance Details',
  HOME = 'Home',
}
