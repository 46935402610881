import { inject, Injectable } from '@angular/core';
import { AuthService } from '@fishonline2023/webapps/auth/fd2023/feature/login';
import { Error } from '@fishonline2023/webapps/model/fd2023';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import {
  catchError,
  filter,
  from,
  map,
  of,
  switchMap,
  withLatestFrom,
} from 'rxjs';
import { UserProfileService } from './user-profile.service';
import { complement, isNil } from '@fishonline2023/shared/ramda';
import {
  agentRoleSwitched,
  agentRoleSwitchedFailure,
  agentRoleSwitchedSuccessful,
  loadUserProfile,
  loadUserProfileFailure,
  loadUserProfileSuccess,
  logout,
  logoutFailure,
  logoutSuccessful,
} from './user-profile.actions';
import { getUserProfile } from './user-profile.selectors';

@Injectable()
export class UserProfileEffects {
  private actions$ = inject(Actions);
  private userProfileService = inject(UserProfileService);
  public loadUserProfile$ = createEffect(() =>
    this.actions$.pipe(
      ofType(loadUserProfile),
      switchMap(() =>
        this.userProfileService.loadUserProfile().pipe(
          map((userProfile) => {
            return loadUserProfileSuccess({ userProfile });
          }),
          catchError((error: Error) => {
            return of(
              loadUserProfileFailure({
                errorMessage: this.userProfileService.getErrorMessage(error),
              })
            );
          })
        )
      )
    )
  );
  private authService = inject(AuthService);
  public logout$ = createEffect(() =>
    this.actions$.pipe(
      ofType(logout),
      switchMap(() => {
        return from(this.authService.logout()).pipe(
          map(() => logoutSuccessful()),
          catchError((error: Error) =>
            of(
              logoutFailure({
                errorMessage:
                  this.userProfileService.getLogoutErrorMessage(error),
              })
            )
          )
        );
      })
    )
  );
  private store = inject(Store);
  public agentRoleSwitched$ = createEffect(() =>
    this.actions$.pipe(
      ofType(agentRoleSwitched),
      withLatestFrom(
        this.store.select(getUserProfile).pipe(filter(complement(isNil)))
      ),
      switchMap(([{ agentCustomerId }, userProfile]) => {
        return this.userProfileService
          .loadAgentCustomerProfile(agentCustomerId, userProfile)
          .pipe(
            map(({ fisherDirectPermissionList, myAgentList }) => {
              return agentRoleSwitchedSuccessful({
                agentCustomerId,
                fisherDirectPermissionList,
                myAgentList,
              });
            }),
            catchError((error: Error) => {
              return of(
                agentRoleSwitchedFailure({
                  errorMessage: this.userProfileService.getErrorMessage(error),
                })
              );
            })
          );
      })
    )
  );
}
