export enum MenuName {
  CATCH_EFFORT_HISTORY = 'Catch & effort history',
  FISHING_ACTIVITY_HISTORY = 'Fishing activity history',
  CATCH_EFFORT = 'Catch & effort',
  QUOTA_FISHERIES = 'Quota fisheries',
  FISHING_BUSINESS = 'Fishing business',
  AUTHORISED_FISHERS = 'Authorised fishers',
  LICENCE = 'Licence',
  NOTICE_AND_EXTRACT = 'Notice & Extract',
  MY_ACCOUNT = 'My account',
  QUOTA_TRANSFER = 'Transfer quota',
  QUOTA_TRANSACTIONS = 'Quota transactions',
  TAG_TRANSACTIONS = 'Tag transactions',
  TAG_TRANSACTION_HISTORY = 'Tag transaction history',
  FISHING_BUSINESS_HISTORY = 'Fishing business history',
  TRANSFER_FISHING_BUSINESS = 'Transfer fishing business',
  TRANSFER_COMPONENTS = 'Transfer components',
  NOMINATE_REVOKE_FISHERS = 'Nominate/revoke fishers',
  MY_PERSONAL_AUTHORISATIONS = 'My personal authorisations',
  MY_FISHING_BUSINESS_AUTHORISATIONS = 'My fishing business authorisations',
  LICENCE_DETAILS = 'Licence details',
  MESSAGES = 'Messages',
  FOR_SALE_TRADE = 'For sale & trade',
  PRINT_SHARE_EXTRACT = 'Print share extract',
  PRINT_PRAWN_BALLOT = 'Print prawn ballot',
  ACCOUNT_SUMMARY = 'Account summary',
  AMEND_CUSTOMER = 'Amend customer',
  CREATE_CUSTOMER = 'Create customer',
  SEARCH_USER_ACTIVITY_LOG = 'Search user activity log',
  CUSTOMER_DETAIL_SEARCH = 'Customer detail search',
  MANAGE_AGENTS = 'Manage agents',
  CUSTOMER_FINANCE_DETAILS = 'Customer finance details',
  HOME = 'Home',
}
