export enum TransactionType {
  AmendCustomer = 'AMEND_CUSTOMER',
  NominateRevokeFisher = 'AUTHORISE_REVOKE_FISHER',
  RenewLicence = 'RENEW_CFL_LICENCE',
  TransferFishingBusiness = 'TRANSFER_FISHING_BUSINESS',
  TransferComponents = 'TRANSFER_COMPONENTS',
  TransferQuota = 'QUOTA_TRANSFER',
}

export enum TransactionActionButtonClassName {
  PrimaryButton = 'primary-button',
  DangerButton = 'danger-button',
  WhiteButton = 'white-button',
}

export enum TransactionActionButtonPosition {
  Start,
  End,
}
export enum TransactionButtonType {
  Submit = 'Submit',
  DeclineRenewal = 'Decline Renewal',
  CancelChanges = 'Cancel Changes',
  RenewPaymentOptions = 'Renew: Payment Options',
  TransferPaymentOptions = 'Transfer: Payment Options',
  RenewConfirm = 'Renew: Confirm',
  Confirm = 'Confirm',
  Cancel = 'Cancel',
  Reset = 'Reset',
  BackToTransaction = 'Back to: Transaction',
  NextProvideCardDetails = 'Next: Provide Card Details',
  NextConfirmationPage = 'Next: Confirmation Page',
  BackToPaymentOptions = 'Back to: Payment Options',
  NextConfirmPaymentDetails = 'Next: Confirm Payment Details',
  BackToEnterCardDetails = 'Back to: Enter Card Details',
  ConfirmAndMakePayment = 'Confirm and Make Payment',
  SubmitOffer = 'Submit Offer',
  WithdrawOffer = 'Withdraw Offer',
  AcceptOffer = 'Accept Offer',
  RejectOffer = 'Reject Offer',
  GoBack = 'Go back',
  ReturnToHome = 'Return to Home',
  Print = 'Print',
}

export enum TransactionStatus {
  Pending = 'PENDING',
  Approved = 'APPROVED',
  NotApproved = 'NOT_APPROVED',
  Deleted = 'DELETED',
}

export enum TransactionButtonAction {
  Submit = 'SUBMIT',
  Confirm = 'CONFIRM',
  Withdraw = 'WITHDRAW',
  Accept = 'ACCEPT',
  Reject = 'REJECT',
  ConfirmPaymentDetail = 'CONFIRM_PAYMENT_DETAIL',
  ConfirmPayLater = 'CONFIRM_PAY_LATER',
  PrintPayLaterConfirmation = 'PRINT_PAY_LATER_CONFIRMATION',
  ProvideCardDetails = 'PROVIDE_CARD_DETAILS',
  AbandonTransaction = 'ABANDON',
}

export enum TransactionAction {
  Save = 'SAVE',
  Approve = 'APPROVE',
  NotApprove = 'NOT_APPROVE',
  Accept = 'ACCEPT',
  Decline = 'DECLINE',
  PayLater = 'PAY_LATER',
  AbandonTransaction = 'ABANDON_TRANSFER',
}

export enum TransactionPaymentMethod {
  PayOnline,
  PayLater,
}
