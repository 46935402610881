import { Permission } from './permission.enum';
import { AgentCustomer } from '@fishonline2023/shared/models';

export interface UserProfile {
  id: number;
  userName: string;
  firstName: string;
  lastName: string;
  fisherDirectPermissionList: Permission[];
  myAgentList: AgentCustomer[];
  agentCustomerList?: AgentCustomer[];
}
