<div class="search-container">
  <div class="search-title mt-4 mb-3">
    <h2>{{ searchMetadata.pageTitle }}</h2>
  </div>
  <ng-container [ngSwitch]="searchType">
    <sv-ui-for-sale-and-trade
      *ngSwitchCase="SearchType.ForSaleAndTrade"
    ></sv-ui-for-sale-and-trade>
  </ng-container>
  <div class="content position-relative">
    <sv-ui-error
      (buttonAction)="initSearchMetadata()"
      *ngIf="
        (loadSearchFilterOptionsViewStatus$ | async) === ViewStatus.Failure
      "
      [errorMessage]="Message.LoadSearchFilterOptionsResultFailed"
      buttonLabel="Retry"
    ></sv-ui-error>
    <sv-ui-loading
      *ngIf="(searchViewStatus$ | async) === ViewStatus.Loading"
      [message]="Message.LoadingSearchResult"
    ></sv-ui-loading>
    <sv-ui-loading
      *ngIf="(downloadViewStatus$ | async) === ViewStatus.Loading"
      [message]="Message.DownloadingSearchResult"
    ></sv-ui-loading>
    <sv-ui-loading
      *ngIf="
        (loadSearchFilterOptionsViewStatus$ | async) === ViewStatus.Loading
      "
      [message]="Message.LoadingSearchFilterOptionsResult"
    ></sv-ui-loading>
    <div class="search-fields-container rounded-1 p-3">
      <div
        class="d-flex justify-content-between align-items-center search-header"
      >
        <h5 class="m-0">Search</h5>
        <button
          (click)="toggleCollapse(isSearchCriteriaShown$, collapse)"
          aria-controls="searchFieldsCollapse"
          aria-expanded="true"
          aria-label="Toggle search criteria"
          class="btn btn-link"
          type="button"
        >
          {{ isSearchCriteriaShown$.getValue() ? 'Hide' : 'Show' }} Search
        </button>
      </div>
      <div #searchFieldsCollapse *ngIf="searchFieldValues" class="show">
        <div class="row">
          <div
            *ngFor="let searchField of searchMetadata.searchFields"
            [class]="'mb-3 col-sm-12 col-lg-' + searchField.width"
          >
            <sv-ui-search-field
              [searchFieldValues]="searchFieldValues"
              [searchField]="searchField"
              [searchForm]="searchFieldForm(searchField)"
            ></sv-ui-search-field>
          </div>
        </div>
        <div class="d-flex justify-content-end">
          <button
            (click)="search()"
            [disabled]="searchForm.invalid"
            class="btn btn-primary"
            type="button"
          >
            Search
          </button>
        </div>
      </div>
    </div>
    <div
      *ngIf="searchResultList"
      class="search-result-summary-container rounded-1 mt-3 mb-3 p-3 gap-2 d-flex align-items-center justify-content-between"
    >
      <p [innerHTML]="searchSummary$ | async" class="m-0"></p>
      <button
        (click)="clearSearchForm()"
        class="btn btn-primary flex-shrink-0"
        type="button"
      >
        Clear search
      </button>
    </div>
    <sv-ui-search-result-display-column-selection
      *ngIf="
        searchResultList && searchMetadata.shouldAllowSearchResultColumnFilter
      "
      [searchMetadata]="searchMetadata"
      [searchResultDisplayColumnFormControl]="
        searchResultDisplayColumnFormControl
      "
    ></sv-ui-search-result-display-column-selection>
    <div *ngIf="searchResultList" class="table-responsive">
      <sv-ui-search-result-table
        (presentSearchResultDetailView)="presentSearchResultDetailView($event)"
        (sortSearchResults)="sortSearchResults($event)"
        [columnSorted$]="columnSorted$"
        [columnsToDisplay]="searchResultDisplayColumnFormControl.value"
        [searchMetadata]="searchMetadata"
        [searchResults]="searchResultList"
      ></sv-ui-search-result-table>
    </div>
    <div
      *ngIf="searchMetadata.requireExport && searchResultList"
      class="search-result-summary-container rounded-1 mt-3 mb-3 p-3 gap-2 d-flex align-items-center justify-content-between"
    >
      <p class="m-0">
        Download the result table to view all {{ totalSearchResult }} results
        from your search.
      </p>
    </div>
  </div>
  <div
    *ngIf="shouldDisplaySearchFooter"
    class="search-footer container p-2 d-flex align-items-center justify-content-between"
  >
    <button
      (click)="downloadCSV()"
      *ngIf="searchMetadata.requireExport"
      class="btn btn-white d-flex align-items-center"
      type="button"
    >
      <i class="material-icons">download</i>
      Download as CSV file
    </button>
  </div>
</div>
