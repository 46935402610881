<h4 class="mb-3">Components</h4>
<sv-ui-transfer-detail-form-control
  *ngFor="let component of transferableComponents; index as i"
  [description]="componentDescription(component)"
  [isReadOnly]="isQtyReadOnly"
  [label]="component.name"
  [maximumQty]="component.availableAmount"
  [transferDetailFormGroup]="transferFormGroup.controls.componentList.at(i)"
></sv-ui-transfer-detail-form-control>
<div
  *ngIf="nonTransferableComponents.length > 0"
  class="cannot-transfer-components-container p-3"
>
  <div class="d-flex align-items-center mb-2">
    <span class="material-icons-round fs-3 text-primary">info</span>
    <h5 class="m-0">Some components of this business cannot be transferred</h5>
  </div>
  <p class="m-0">
    <strong>{{ toFishingBusinessOwnerFullName }}</strong> does not own shares in
    the following classes and so these ones cannot be transferred to them.
  </p>
</div>
<sv-ui-transfer-detail-form-control
  *ngFor="let component of nonTransferableComponents"
  [description]="componentDescription(component)"
  [label]="component.name"
  [maximumQty]="component.availableAmount"
></sv-ui-transfer-detail-form-control>
<sv-ui-transfer-detail-form-total
  [formArray]="transferFormGroup.controls.componentList"
></sv-ui-transfer-detail-form-total>
<h4 class="mt-3 mb-3">Quota</h4>
<sv-ui-transfer-detail-form-control
  *ngFor="let quota of transactionReference.quotaList; index as i"
  [description]="quotaDescription(quota)"
  [isReadOnly]="isQtyReadOnly"
  [label]="quota.name"
  [maximumQty]="quota.amount"
  [transferDetailFormGroup]="transferFormGroup.controls.quotaList.at(i)"
></sv-ui-transfer-detail-form-control>
<sv-ui-transfer-detail-form-total
  [formArray]="transferFormGroup.controls.quotaList"
  [withoutTotalQty]="true"
></sv-ui-transfer-detail-form-total>
