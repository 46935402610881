import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  Output,
} from '@angular/core';
import { CommonModule } from '@angular/common';
import {
  TransactionActionButton as FDTransactionActionButton,
  TransactionActionButtonPosition,
  TransactionButtonType,
} from '@fishonline2023/webapps/model/fd2023';
import { equals, isNil } from '@fishonline2023/shared/ramda';
import { FormArray, FormControl, ReactiveFormsModule } from '@angular/forms';
import { TransactionActionButton as FATransactionActionButton } from '@fishonline2023/webapps/model/fa2023';

type TransactionActionButton =
  | FDTransactionActionButton
  | FATransactionActionButton;

@Component({
  selector: 'sv-ui-transaction-action-buttons',
  standalone: true,
  imports: [CommonModule, ReactiveFormsModule],
  templateUrl: './transaction-action-buttons.component.html',
  styleUrls: ['./transaction-action-buttons.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TransactionActionButtonsComponent {
  @Input()
  public transactionActionButtons: Array<TransactionActionButton> | null = [];
  @Input()
  public checkboxList?: Array<string>;
  @Input()
  public checkboxFormArray?: FormArray<FormControl<boolean>>;
  @Output() public transactionActionButtonClicked =
    new EventEmitter<TransactionActionButton>();
  protected readonly TransactionButtonType = TransactionButtonType;
  protected readonly Object = Object;

  get startTransactionActionButtons(): Array<TransactionActionButton> {
    if (isNil(this.transactionActionButtons)) {
      return [];
    }
    return this.transactionActionButtons.filter(({ position }) =>
      equals(position, TransactionActionButtonPosition.Start)
    );
  }

  get endTransactionActionButtons(): Array<TransactionActionButton> {
    if (isNil(this.transactionActionButtons)) {
      return [];
    }
    return this.transactionActionButtons.filter(({ position }) =>
      equals(position, TransactionActionButtonPosition.End)
    );
  }
}
