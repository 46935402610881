import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  inject,
  Input,
  OnDestroy,
  OnInit,
  Output,
} from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormGroup, ReactiveFormsModule } from '@angular/forms';
import {
  AddressReferenceData,
  FDCustomerDetail,
  SharedAmendCustomerForm,
} from '@fishonline2023/shared/models';
import { filter, Observable, Subject, take, takeUntil } from 'rxjs';
import { complement, isNil } from '@fishonline2023/shared/ramda';
import { AutoCompleteComponent } from '@fishonline2023/webapps/shared/ui/base-components';
import { TransactionData } from '@fishonline2023/webapps/model/fd2023';
import {
  AmendCustomerSharedComponent,
  sharedAmendCustomerForm,
} from '@fishonline2023/webapps/shared/ui/amend-customer';

@Component({
  selector: 'sv-ui-amend-customer',
  standalone: true,
  imports: [
    CommonModule,
    ReactiveFormsModule,
    AutoCompleteComponent,
    AmendCustomerComponent,
    AmendCustomerSharedComponent,
  ],
  templateUrl: './amend-customer.component.html',
  styleUrls: ['./amend-customer.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AmendCustomerComponent implements OnInit, OnDestroy {
  @Input() public transactionData$!: Observable<TransactionData | undefined>;
  @Input() public resetTransaction$?: Subject<void> | undefined;
  @Output() public syncTransactionForm = new EventEmitter<FormGroup>();
  @Input() public AddressReferenceDataList$?:
    | Observable<AddressReferenceData[]>
    | undefined;
  public AddressReferenceDataList?: AddressReferenceData[];
  public amendCustomerForm: FormGroup<SharedAmendCustomerForm> =
    sharedAmendCustomerForm();
  private cdr = inject(ChangeDetectorRef);
  private destroy$ = new Subject<void>();

  public ngOnInit() {
    this.syncTransactionForm.next(this.amendCustomerForm);
    this.loadAddressReferenceDatum();
    this.patchFormWhenTransactionDataIsLoaded();
    this.subscribeToResetTransactionFormData();
  }

  public ngOnDestroy() {
    this.destroy$.next();
    this.destroy$.complete();
  }

  private loadAddressReferenceDatum = () =>
    this.AddressReferenceDataList$?.subscribe((AddressReferenceDataList) => {
      this.AddressReferenceDataList = AddressReferenceDataList;
      this.cdr.detectChanges();
    });

  private patchFormWhenTransactionDataIsLoaded = () =>
    this.transactionData$
      ?.pipe(filter(complement(isNil)), take(1))
      .subscribe(({ transactionDetail }: TransactionData) =>
        this.amendCustomerForm.patchValue(transactionDetail as FDCustomerDetail)
      );

  private subscribeToResetTransactionFormData() {
    this.resetTransaction$
      ?.pipe(takeUntil(this.destroy$))
      .subscribe(this.patchFormWhenTransactionDataIsLoaded);
  }
}
