import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  inject,
  Input,
  OnDestroy,
  OnInit,
  Output,
} from '@angular/core';
import { CommonModule } from '@angular/common';
import {
  BehaviorSubject,
  catchError,
  Observable,
  Subject,
  takeUntil,
  tap,
  withLatestFrom,
} from 'rxjs';
import {
  AdDetail,
  AdType,
  FDDataLoaderData,
  FDDateDayJSFormat,
  FDDateTimeDayJSFormat,
  Message,
  SaleAndTradeEditForm,
} from '@fishonline2023/webapps/model/fd2023';
import { ActivatedRoute, Router, RouterLink } from '@angular/router';
import {
  ButtonLinkComponent,
  ConfirmationModalComponent,
  FullWidthItemListComponent,
  LoadingComponent,
  ModalService,
  SearchDetailWrapperComponent,
  ToastService,
} from '@fishonline2023/webapps/shared/ui/base-components';
import { isNilOrEmpty } from '@fishonline2023/shared/ramda';
import { FormGroup, ReactiveFormsModule } from '@angular/forms';
import { forSaleAndTradeEditForm } from './for-sale-and-trade-edit-form';
import { CreateEditAdComponent } from '../create-edit-ad/create-edit-ad.component';
import {
  DataLoaderActivityLog,
  FDRoute,
  ItemList,
  ModalId,
  ViewStatus,
} from '@fishonline2023/shared/models';
import { ForSaleAndTradeService } from '@fishonline2023/webapps/notice-and-extract/fd2023/store';
import * as dayjs from 'dayjs';

@Component({
  selector: 'sv-ui-for-sale-and-trade-view-edit',
  standalone: true,
  imports: [
    CommonModule,
    ReactiveFormsModule,
    FullWidthItemListComponent,
    CreateEditAdComponent,
    ButtonLinkComponent,
    ConfirmationModalComponent,
    SearchDetailWrapperComponent,
    LoadingComponent,
    RouterLink,
  ],
  templateUrl: './for-sale-and-trade-view-edit.component.html',
  styleUrls: ['./for-sale-and-trade-view-edit.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [ForSaleAndTradeService],
})
export class ForSaleAndTradeViewEditComponent implements OnInit, OnDestroy {
  @Input() dataLoaderData$!: Observable<FDDataLoaderData>;
  @Output() public activityLogTriggered =
    new EventEmitter<DataLoaderActivityLog>();
  public saleAndTradeEditForm: FormGroup<SaleAndTradeEditForm> =
    forSaleAndTradeEditForm();
  public saleAndTradeDetails!: AdDetail;
  public isNewAd = false;
  public deleteSubject = new Subject<void>();
  public submitSubject = new Subject<void>();
  public agreeTermsAndConditionsClicked = false;
  public saleAndTradeViewStatus$$ = new BehaviorSubject<ViewStatus>(
    ViewStatus.Initial
  );
  public message = '';
  protected readonly FDRoute = FDRoute;
  private activatedRoute = inject(ActivatedRoute);
  private cdr = inject(ChangeDetectorRef);
  private modalService = inject(ModalService);
  private readonly router = inject(Router);
  private saleAndTradeService = inject(ForSaleAndTradeService);
  private toastService = inject(ToastService);
  private destroy$ = new Subject<void>();
  private SaleAndTradeTypeMapping: Record<string, AdType> = {
    All: AdType.All,
    Boat: AdType.Boat,
    Misc: AdType.Misc,
    Shares: AdType.Shares,
    Quota: AdType.Quota,
    'Boat Licence': AdType.BoatLicence,
    'Fishing Business': AdType.FishingBusiness,
    'Fishing Gear': AdType.FishingGear,
    'Inland Endorsements': AdType.InlandEndorsements,
    'Southern Fisheries Trawl Endorsements':
      AdType.SouthernFisheriesEndorsements,
    'SUTS Endorsements': AdType.SutsEndorsements,
  };

  public get saleAndTradeDetail$() {
    return this.dataLoaderData$ as Observable<AdDetail>;
  }

  public get saleAndTradeDetailsList(): ItemList {
    return [
      {
        key: 'Title',
        value: this.saleAndTradeDetails?.title,
      },
      {
        key: 'Buy / Sell',
        value: this.saleAndTradeDetails?.purpose,
      },
      {
        key: 'Type',
        value: this.saleAndTradeDetails?.type,
      },
      {
        key: 'Price ($)',
        value: this.saleAndTradeDetails?.price?.toFixed(2) || '',
      },
      {
        key: 'Details',
        value: this.saleAndTradeDetails?.description,
      },
      {
        key: 'Contact Name (primary)',
        value: this.saleAndTradeDetails?.contactNamePrimary,
      },
      {
        key: 'Contact Number (primary)',
        value: this.saleAndTradeDetails?.contactNumberPrimary,
      },
      {
        key: 'Contact Name (secondary)',
        value: this.saleAndTradeDetails?.contactNameSecondary,
      },
      {
        key: 'Contact Number (secondary)',
        value: this.saleAndTradeDetails?.contactNumberSecondary,
      },
      {
        key: 'Expiry Date',
        value: dayjs(this.saleAndTradeDetails?.expiryDate).format(
          FDDateTimeDayJSFormat
        ),
      },
    ];
  }

  public ngOnInit() {
    this.saleAndTradeDetail$
      .pipe(withLatestFrom(this.activatedRoute.queryParams))
      .subscribe(([forSaleAndTradeDetail, params]) => {
        this.saleAndTradeDetails = forSaleAndTradeDetail;
        if (isNilOrEmpty(params)) {
          this.isNewAd = true;
          this.saleAndTradeEditForm.reset();
        }
        if (this.saleAndTradeDetails.isEditable && !this.isNewAd) {
          this.saleAndTradeEditForm.patchValue(this.saleAndTradeDetails);
          // Update the type field to match the type in the form
          const type = this.saleAndTradeDetails.type;
          this.saleAndTradeEditForm.patchValue({
            type: this.SaleAndTradeTypeMapping[type],
          });

          // The expiryDate needs to be set in the format yyyy-MM-dd
          const expiryDate = this.saleAndTradeDetails.expiryDate;
          const dateFields = dayjs(expiryDate as string)
            .format(FDDateDayJSFormat)
            .split('/');
          this.saleAndTradeEditForm.patchValue({
            expiryDate: `${dateFields[2]}-${dateFields[1]}-${dateFields[0]}`,
          });
        }
      });
    this.deleteSubject.pipe(takeUntil(this.destroy$)).subscribe(() => {
      this.handleDeleteClick();
    });
    this.submitSubject.pipe(takeUntil(this.destroy$)).subscribe(() => {
      this.handleSubmitClick();
    });
  }

  public onAgreeTermsAndConditionsClicked(value: boolean): void {
    this.agreeTermsAndConditionsClicked = value;
    this.cdr.detectChanges();
  }

  public ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }

  public handleSubmitClick(): void {
    const adDetail = this.saleAndTradeEditForm.value as AdDetail;
    adDetail.id = this.saleAndTradeDetails.id;
    adDetail.expiryDate = new Date(adDetail.expiryDate).getTime();
    this.message = Message.UpdatingAd;
    this.saleAndTradeViewStatus$$.next(ViewStatus.Loading);
    this.saleAndTradeService
      .updateAd(adDetail)
      .pipe(
        tap(async () => {
          this.saleAndTradeViewStatus$$.next(ViewStatus.Success);
          this.toastService.showSuccess(Message.UpdatingAdSuccess);
          await this.router.navigate([`/${FDRoute.ForSaleAndTradeSearch}`]);
        }),
        catchError((error) => {
          this.saleAndTradeViewStatus$$.next(ViewStatus.Failure);
          this.toastService.showError(Message.UpdatingAdError);
          this.cdr.detectChanges();
          throw error;
        })
      )
      .subscribe();
  }

  public handleDeleteClick(): void {
    this.modalService.open(ModalId.ConfirmationModal);
  }

  public onDeleteConfirm(): void {
    const id = this.saleAndTradeDetails.id;
    this.saleAndTradeService
      .deleteAd(id)
      .pipe(
        tap(async () => {
          await this.router.navigate([`/${FDRoute.ForSaleAndTradeSearch}`]);
          this.toastService.showSuccess(Message.DeletingAdSuccess);
        }),
        catchError((error) => {
          this.toastService.showError(Message.DeletingAdError);
          this.cdr.detectChanges();
          throw error;
        })
      )
      .subscribe();
  }
}
