import {
  Customer,
  FishingBusiness,
  SortOrder,
} from '@fishonline2023/shared/models';

export interface Authorisation {
  fishingBusinessId: number;
  startDate: Date;
  endDate: Date | null;
  owner: Customer;
  fisher: Customer;
}

export enum TableFieldDataType {
  string = 0,
  Date = 1,
  Customer = 2,
}

export interface TableSortField {
  label: string;
  property: string;
  dataType: TableFieldDataType;
  sortable?: boolean;
}

export interface AuthorisationSortOrderChangeEvent {
  sortOrder: SortOrder;
  authorisationResultField?: TableSortField;
}

export interface FishingBusinessAuthorisations {
  fishingBusinessOwnerDto: FishingBusiness;
  authorisations: Authorisation[];
}
