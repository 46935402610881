import { QuotaRegime } from './transfer-quota.model';
import { FishingBusiness } from '@fishonline2023/shared/models';

export enum QuotaTransactionType {
  Usage = 'Usage',
  Allocation = 'Allocation',
  Deallocation = 'Deallocation',
  Transfer = 'Transfer',
  Adjustment = 'Adjustment',
  Cancellation = 'Cancellation',
}

export interface QuotaTransaction {
  id: number;
  date: string | Date;
  transactionType: QuotaTransactionType;
  debit?: number;
  credit?: number;
}

export interface FishingBusinessQuota {
  fishingBusiness: FishingBusiness;
  quotaList: QuotaRegime[];
}

export interface SearchCriteriaReference {
  criteriaList: FishingBusinessQuota[];
}
