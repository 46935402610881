import { Action, createReducer, on } from '@ngrx/store';

import { Breadcrumb } from '@fishonline2023/shared/models';
import { breadcrumbAdded, breadcrumbReset } from './breadcrumb.actions';
import { equals, take, update } from '@fishonline2023/shared/ramda';

export const BREADCRUMB_FEATURE_KEY = 'breadcrumb';

const homeBreadcrumb: Breadcrumb = {
  label: 'Home',
  url: '/home',
  reset: true,
};

export interface BreadcrumbState {
  breadCrumbList: Breadcrumb[];
}

export const initialBreadcrumbState: BreadcrumbState = {
  breadCrumbList: [homeBreadcrumb],
};

const replaceAfterSameLabel = (
  breadcrumbList: Breadcrumb[],
  newBreadcrumb: Breadcrumb
) => {
  const index = breadcrumbList.findIndex(({ label }) =>
    equals(newBreadcrumb.label, label)
  );

  if (equals(index, -1)) {
    return [...breadcrumbList, newBreadcrumb];
  }

  return update(index, newBreadcrumb, take(index + 1, breadcrumbList));
};

const reducer = createReducer(
  initialBreadcrumbState,
  on(breadcrumbAdded, (state, { breadcrumb }) => ({
    ...state,
    breadCrumbList: replaceAfterSameLabel(state.breadCrumbList, breadcrumb),
  })),
  on(breadcrumbReset, () => initialBreadcrumbState)
);

export function breadcrumbReducer(
  state: BreadcrumbState | undefined,
  action: Action
) {
  return reducer(state, action);
}
