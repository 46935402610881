import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AuthorisationsListComponent } from '@fishonline2023/webapps/shared/ui/base-components';
import { BaseAuthorisationsDirective } from '@fishonline2023/webapps/authorised-fishers/fd2023/ui/base-authorisations';
import { EventType } from '@fishonline2023/webapps/shared/feature/appsync';

@Component({
  selector: 'sv-ui-my-personal-authorisations',
  standalone: true,
  imports: [CommonModule, AuthorisationsListComponent],
  templateUrl: './my-personal-authorisations.component.html',
  styleUrls: ['./my-personal-authorisations.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class MyPersonalAuthorisationsComponent
  extends BaseAuthorisationsDirective
  implements OnInit
{
  public override ngOnInit(): void {
    super.ngOnInit();
    this.triggerActivityLog();
  }

  private triggerActivityLog(): void {
    this.activityLogTriggered.emit({
      eventType: EventType.ViewMyPersonalAuthorisations,
    });
  }
}
