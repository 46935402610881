<div class="auto-complete-container" data-cy="auto-complete">
  <div class="input-group">
    <span class="input-group-text" *ngIf="withSearchIcon">
      <i class="material-icons-round">search</i>
    </span>
    <input
      #autoCompleteInput
      aria-label="typeahead input"
      [readOnly]="isReadOnly"
      (focus)="inputFocused.next()"
      (blur)="inputBlurred.next()"
      [placeholder]="placeholder"
      type="text"
      class="form-control form-control-lg"
      [value]="value"
      (input)="
        onChange(autoCompleteInput.value);
        this.writeValue(autoCompleteInput.value)
      "
    />
  </div>
  <ul
    class="list-group address-options"
    [class.top]="position === AutoCompletePosition.TOP"
    *ngIf="options.length > 0"
  >
    <li
      *ngFor="let option of options; trackBy: trackByLabel"
      class="list-group-item"
      (click)="selectionOption(option)"
      [attr.data-cy]="option.label"
    >
      {{ option.label }}
    </li>
  </ul>
</div>
