import { TransactionType } from './transaction.model';
import { EntityType } from './transaction-metadata';
import {
  SubmitActionFromButtonTypeInConfirmationPageContext,
  TransactionButtonType,
} from './transaction-button.model';

export const Message = {
  LoadingCustomerDetail: 'Loading Customer Detail...Please wait!',
  SaveTransactionSuccessful: 'Successfully save transaction',
  FailedToLoadEntityData: 'Failed to load entity data...Please try again later',
  FailedToLoadTransactionData:
    'Failed to load transaction data...Please try again later',
  FailedToSaveTransaction:
    'Failed to save transaction...Please try again later',
  FailedToApproveTransaction:
    'Failed to approve transaction...Please try again later',
  FailedToNotApproveTransaction:
    'Failed to not approve transaction...Please try again later',
  FailedToDeleteTransaction:
    'Failed to delete transaction...Please try again later',
  TransactionErrorMessageHeader: 'Preventing errors',
  TransactionInfoMessageHeader: 'Advisory warning',
};

export const TransactionLoadingMessage: Record<TransactionType, string> = {
  [TransactionType.AmendCustomer]: 'Loading Customer Details...Please wait!',
  [TransactionType.CreateCustomer]: 'Loading Customer Details...Please wait!',
};

export const TransactionEntityDataLoadingMessage: Record<EntityType, string> = {
  [EntityType.Customer]: 'Loading Customer list...Please wait!',
  [EntityType.FishingBusiness]: 'Loading Fishing business list...Please wait!',
};

export const TransactionSubmittingMessage: Record<TransactionType, string> = {
  [TransactionType.AmendCustomer]: 'Submitting Customer Details...Please wait!',
  [TransactionType.CreateCustomer]:
    'Submitting Customer Details...Please wait!',
};

export const TransactionConfirmingMessage: Record<
  TransactionType,
  Record<SubmitActionFromButtonTypeInConfirmationPageContext, string>
> = {
  [TransactionType.AmendCustomer]: {
    [TransactionButtonType.Delete]: 'Deleting Customer Details...Please wait!',
    [TransactionButtonType.ApproveAndSubmit]:
      'Approving Customer Details...Please wait!',
    [TransactionButtonType.NotApproved]:
      'Not Approving Customer Details...Please wait!',
  },
  [TransactionType.CreateCustomer]: {
    [TransactionButtonType.Delete]: 'Deleting Customer Details...Please wait!',
    [TransactionButtonType.ApproveAndSubmit]:
      'Approving Customer Details...Please wait!',
    [TransactionButtonType.NotApproved]:
      'Not Approving Customer Details...Please wait!',
  },
};
