<div class="search-result-column-filter-container rounded-1 p-3">
  <div class="d-flex justify-content-between align-items-center">
    <h5 class="m-0">
      Display in results ({{
        searchResultDisplayColumnFormControl.value.length
      }})
    </h5>
    <button
      (click)="toggleCollapse(isSearchResultFilterShown$, collapse)"
      aria-controls="searchResultColumnsCollapse"
      aria-expanded="false"
      class="btn btn-link"
      type="button"
    >
      {{ isSearchResultFilterShown$.getValue() ? 'Hide' : 'Show more' }}
    </button>
  </div>
  <div #searchResultColumnsCollapse class="collapse">
    <div class="column-container">
      <div *ngFor="let column of allColumns" class="form-check">
        <input
          (change)="toggleColumn(column)"
          [checked]="
            searchResultDisplayColumnFormControl.value.includes(column)
          "
          [id]="column"
          class="form-check-input"
          type="checkbox"
        />
        <label [for]="column" class="form-check-label"> {{ column }} </label>
      </div>
    </div>
  </div>
</div>
