import { inject, Injectable } from '@angular/core';
import { Message } from '@fishonline2023/webapps/model/fd2023';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { catchError, map, of, switchMap } from 'rxjs';
import {
  loadDataLoaderData,
  loadDataLoaderDataFailure,
  loadDataLoaderDataSuccess,
} from './data-loader.actions';
import { DataLoaderService } from './data-loader.service';

@Injectable()
export class DataLoaderEffects {
  private actions$ = inject(Actions);
  private dataLoaderService = inject(DataLoaderService);

  public loadDataLoaderData$ = createEffect(() =>
    this.actions$.pipe(
      ofType(loadDataLoaderData),
      switchMap(({ params }) =>
        this.dataLoaderService.loadDataLoaderData(params).pipe(
          map((dataLoaderData) => {
            return loadDataLoaderDataSuccess({
              dataLoaderData,
            });
          }),
          catchError(() => {
            return of(
              loadDataLoaderDataFailure({
                errorMessage: Message.TryAgainLater,
              })
            );
          })
        )
      )
    )
  );
}
