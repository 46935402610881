import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { CommonModule } from '@angular/common';
import { Observable } from 'rxjs';
import {
  CustomerDetail,
  FADataLoaderData,
} from '@fishonline2023/webapps/model/fa2023';

@Component({
  selector: 'sv-ui-customer-detail',
  standalone: true,
  imports: [CommonModule],
  templateUrl: './customer-detail.component.html',
  styleUrls: ['./customer-detail.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CustomerDetailComponent {
  @Input() dataLoaderData$!: Observable<FADataLoaderData>;

  protected get customerDetail$() {
    return this.dataLoaderData$ as Observable<CustomerDetail>;
  }
}
