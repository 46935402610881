import { ChangeDetectionStrategy, Component } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ReactiveFormsModule } from '@angular/forms';
import { InputFieldTextDirective } from '../input-field-text-directive/input-field-text.directive';
import { equals, isNilOrEmpty } from '@fishonline2023/shared/ramda';
import { InputType } from '@fishonline2023/shared/models';

@Component({
  selector: 'sv-ui-input-field-text',
  standalone: true,
  imports: [CommonModule, ReactiveFormsModule],
  templateUrl: '../input-field-text-directive/input-field-text.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class InputFieldTextComponent extends InputFieldTextDirective {
  protected override onBlur() {
    if (
      isNilOrEmpty(this.form.value) &&
      equals(this.inputField.type, InputType.Number)
    ) {
      this.form.patchValue(0, { emitEvent: false });
    }
  }
}
