<div
  class="error-message-container d-flex align-items-center justify-content-center text-center"
  [class.full-screen]="isFullScreen"
>
  <div>
    <div class="message-container mb-3">
      <span class="my-5">
        <i class="material-icons text-danger display-5">cancel</i>
        <span class="ps-2 my-2 fs-4 align-text-bottom"
          >Something went wrong!</span
        >
        <div>{{ errorMessage }}</div>
      </span>
    </div>
    <sv-ui-button
      *ngIf="buttonLabel"
      [text]="buttonLabel"
      (click)="onClick()"
    ></sv-ui-button>
  </div>
</div>
