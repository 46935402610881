<label *ngIf="inputField.label" class="form-label">
  {{ inputField.label }}
  <span *ngIf="inputField.isRequired" class="text-danger">*</span>
</label>
<ng-container [ngSwitch]="inputField.type">
  <sv-ui-input-field-text
    *ngSwitchCase="InputType.Text"
    [form]="form"
    [inputField]="inputField"
  ></sv-ui-input-field-text>
  <sv-ui-input-field-text
    *ngSwitchCase="InputType.Number"
    [form]="form"
    [inputField]="inputField"
  ></sv-ui-input-field-text>
  <sv-ui-input-field-abn
    *ngSwitchCase="InputType.ABN"
    [form]="form"
    [inputField]="inputField"
  ></sv-ui-input-field-abn>
  <sv-ui-input-field-acn
    *ngSwitchCase="InputType.ACN"
    [form]="form"
    [inputField]="inputField"
  ></sv-ui-input-field-acn>
  <sv-ui-input-field-select
    *ngSwitchCase="InputType.Select"
    [form]="form"
    [inputField]="inputField"
  ></sv-ui-input-field-select>
  <sv-ui-input-field-typeahead
    *ngSwitchCase="InputType.Typeahead"
    [form]="form"
    [inputField]="inputField"
  ></sv-ui-input-field-typeahead>
  <sv-ui-input-field-datetime
    *ngSwitchCase="InputType.DateTime"
    [form]="form"
    [inputField]="inputField"
  ></sv-ui-input-field-datetime>
  <sv-ui-input-field-date
    *ngSwitchCase="InputType.Date"
    [form]="form"
    [inputField]="inputField"
  ></sv-ui-input-field-date>
  <sv-ui-input-field-checkbox
    *ngSwitchCase="InputType.Checkbox"
    [form]="form"
    [inputField]="inputField"
  ></sv-ui-input-field-checkbox>
  <sv-ui-input-field-textarea
    *ngSwitchCase="InputType.Textarea"
    [form]="form"
    [inputField]="inputField"
  ></sv-ui-input-field-textarea>
  <sv-ui-input-field-price
    *ngSwitchCase="InputType.Price"
    [form]="form"
    [inputField]="inputField"
  ></sv-ui-input-field-price>
</ng-container>
<ng-container *ngIf="form.touched">
  <small *ngIf="form.hasError('email')" class="text-danger">
    Invalid email address
  </small>
  <small *ngIf="form.hasError('required')" class="text-danger">
    {{ inputField.label }} is required
  </small>
  <small *ngIf="form.hasError('maxlength')" class="text-danger">
    {{ inputField.label }} cannot be more than
    {{ inputField.maxLength }} characters
  </small>
  <small *ngIf="form.hasError('min')" class="text-danger">
    {{ inputField.label }} should be greater than {{ inputField.min }}
  </small>
  <small *ngIf="form.hasError('max')" class="text-danger">
    {{ inputField.label }} should be no more than {{ inputField.max }}
  </small>
  <small *ngIf="form.hasError('pattern')" class="text-danger">
    {{ inputField.label }} is invalid
  </small>
  <small
    *ngIf="form.hasError(CustomFormValidator.DateRange)"
    class="text-danger"
  >
    {{ inputField.label }} should be between {{ inputField.minDate }} and
    {{ inputField.maxDate }}
  </small>
  <small
    *ngIf="form.hasError(CustomFormValidator.FromIsAfterTo)"
    class="text-danger"
  >
    From date is greater than to date
  </small>
</ng-container>
