<div
  [class]="toastMessageClassName"
  class="d-flex justify-content-between align-items-top"
  *ngIf="message"
>
  <p>{{ message }}</p>
  <span
    *ngIf="config?.shouldManuallyClose"
    class="material-icons-round"
    (click)="toastService.dismiss()"
    >close</span
  >
</div>
