import { ChangeDetectionStrategy, Component, inject } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FDRoute } from '@fishonline2023/shared/models';
import { Router, RouterLink } from '@angular/router';

@Component({
  selector: 'sv-ui-for-sale-and-trade',
  standalone: true,
  imports: [CommonModule, RouterLink],
  templateUrl: './for-sale-and-trade.component.html',
  styleUrls: ['./for-sale-and-trade.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ForSaleAndTradeComponent {
  protected readonly FDRoute = FDRoute;
  private router = inject(Router);

  protected async newAd() {
    await this.router.navigate([`/${FDRoute.NewAd}`]);
  }
}
