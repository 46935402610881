import { FDCustomerDetail } from '@fishonline2023/shared/models';
import {
  NominateRevokeTransactionDetail,
  NominateRevokeTransactionReference,
} from './nominate-revoke-fisher.model';
import { TransactionButton } from './transaction-button.model';
import {
  TransactionAction,
  TransactionActionButtonClassName,
  TransactionActionButtonPosition,
  TransactionButtonType,
  TransactionType,
} from './transaction.enum';
import {
  RenewLicenceTransactionDetail,
  RenewLicenceTransactionReference,
} from './renew-licence.model';
import {
  TransferComponentsTransactionDetail,
  TransferFishingBusinessComponentsTransactionReference,
  TransferFishingBusinessTransactionDetail,
} from './transfer-fishing-business-component.model';
import {
  QuotaTransferReference,
  TransferQuotaDetail,
} from './transfer-quota.model';

export enum PaymentPath {
  Approve = 'approve',
  Accept = 'accept',
}

export enum ConfirmPath {
  Approve = 'approve',
  Offer = 'offer',
}

export interface TransactionHeader {
  id: number | null;
  receiptNumber?: number;
  effectiveDate?: string;
  hasPreventingError: boolean;
  type: TransactionType;
  errorList: string[];
  warningList: string[];
  infoList: string[];
}

export interface TransactionActionButton {
  label: string;
  className: TransactionActionButtonClassName;
  disabled: boolean;
  position: TransactionActionButtonPosition;
  buttonType: TransactionButtonType;
  transactionAction?: TransactionAction;
  isZeroPaymentConfirmButton?: boolean;
}

export interface SingleEntityRequestParams {
  entityId: number;
}

export interface TwoEntityRequestParams {
  entityId: number;
  toEntityId: number;
}

export type TransactionRequestParams =
  | SingleEntityRequestParams
  | TwoEntityRequestParams;

export type TransactionDetail =
  | FDCustomerDetail
  | NominateRevokeTransactionDetail
  | RenewLicenceTransactionDetail
  | TransferFishingBusinessTransactionDetail
  | TransferComponentsTransactionDetail
  | TransferQuotaDetail
  | null;

export type TransactionReference =
  | NominateRevokeTransactionReference
  | RenewLicenceTransactionReference
  | TransferFishingBusinessComponentsTransactionReference
  | QuotaTransferReference;

export interface TransactionData {
  transactionDetail: TransactionDetail;
  transactionHeader: TransactionHeader;
  transactionReference?: TransactionReference;
  transactionPayment?: TransactionPayment;
}

export interface TransactionCreditCardDetail {
  singleUseToken: string;
  cardNumber: string;
  holderName: string;
  expiryDate: string;
  surcharge: number;
}

export interface TransactionPayment {
  amount: number;
  receiptNumber: number;
  date: string;
}

export interface BPAYCode {
  bpayBillerCode?: number;
  bpayReferenceNumber?: number;
}

export interface Transaction {
  buttons: TransactionButton[];
  date: Date;
  description: string;
  title: string;
}

export interface TrustedFrame {
  submitForm(
    callback: (error: Error | null, data: TrustedFrameData) => void
  ): void;
}

export interface TrustedFrameData {
  singleUseToken: {
    links: Array<{ rel: string; href: string; requestMethod: string }>;
    singleUseTokenId: string;
    accountType: string;
    creditCard: {
      cardholderName: string;
      expiryDateMonth: string;
      expiryDateYear: string;
      cardScheme: string;
      cardType: string;
      surchargePercentage: string;
      maskedCardNumber4Digits: string;
    };
    threeDS2Status: string;
  };
  token: string;
}

export enum TransactionPaymentConfirmErrorCode {
  PaymentFailure = 422,
}

export interface TransactionPaymentConfirmError {
  statusCode: TransactionPaymentConfirmErrorCode;
  errorMessage: string;
}
