import { Agent, AgentCandidate } from '@fishonline2023/webapps/model/fd2023';
import { ViewStatus } from '@fishonline2023/shared/models';
import { createReducer, on } from '@ngrx/store';
import {
  deleteAgent,
  deleteAgentFailure,
  deleteAgentSuccess,
  loadAgentCandidates,
  loadAgentCandidatesFailure,
  loadAgentCandidatesSuccess,
  loadAgents,
  loadAgentsFailure,
  loadAgentsSuccess,
  loadNewAgent,
  loadNewAgentFailure,
  loadNewAgentSuccess,
  patchExistingAgent,
  patchNewAgent,
  saveAgent,
  saveAgentFailure,
  saveAgentSuccess,
} from './manage-agent.actions';
import { equals } from '@fishonline2023/shared/ramda';
import { logoutSuccessful } from '@fishonline2023/webapps/user-portal/fd2023/store/user-profile';

export const MANAGE_AGENTS_FEATURE_KEY = 'manageAgents';

export interface ManageAgentsState {
  agentList: Agent[];
  agentCandidatesList: AgentCandidate[];
  viewStatus: ViewStatus;
  errorMessage?: string;
  candidatesViewStatus?: ViewStatus;
  candidatesErrorMessage?: string;
  newAgent?: Agent;
  newAgentViewStatus?: ViewStatus;
  newAgentErrorMessage?: string;
  saveAgent?: Agent;
  saveAgentViewStatus?: ViewStatus;
  saveAgentErrorMessage?: string;
  deleteAgentViewStatus?: ViewStatus;
  deleteAgentErrorMessage?: string;
  existingAgentToUpdate?: Agent;
}

export const initialState: ManageAgentsState = {
  agentList: [],
  agentCandidatesList: [],
  viewStatus: ViewStatus.Initial,
};

export const manageAgentsReducer = createReducer(
  initialState,
  on(loadAgents, (state) => ({
    ...state,
    viewStatus: ViewStatus.Loading,
  })),
  on(loadAgentsSuccess, (state, { agentList }) => ({
    ...state,
    viewStatus: ViewStatus.Success,
    agentList,
    errorMessage: undefined,
    newAgent: undefined,
    existingAgentToUpdate: undefined,
    candidatesErrorMessage: undefined,
    newAgentErrorMessage: undefined,
    saveAgentErrorMessage: undefined,
    saveAgentViewStatus: ViewStatus.Initial,
  })),
  on(loadAgentsFailure, (state, { errorMessage }) => ({
    ...state,
    viewStatus: ViewStatus.Failure,
    agentList: [],
    errorMessage,
  })),
  on(loadAgentCandidates, (state) => ({
    ...state,
    candidatesViewStatus: ViewStatus.Loading,
  })),
  on(loadAgentCandidatesSuccess, (state, { agentCandidatesList }) => ({
    ...state,
    candidatesViewStatus: ViewStatus.Success,
    agentCandidatesList,
  })),
  on(loadAgentCandidatesFailure, (state, { errorMessage }) => ({
    ...state,
    candidatesViewStatus: ViewStatus.Failure,
    agentCandidatesList: [],
    candidatesErrorMessage: errorMessage,
  })),
  on(loadNewAgent, (state) => ({
    ...state,
    newAgentViewStatus: ViewStatus.Loading,
  })),
  on(loadNewAgentSuccess, (state, { agent }) => ({
    ...state,
    newAgentViewStatus: ViewStatus.Success,
    newAgent: agent,
    newAgentErrorMessage: undefined,
    saveAgentErrorMessage: undefined,
    saveAgentViewStatus: ViewStatus.Initial,
  })),
  on(loadNewAgentFailure, (state, { errorMessage }) => ({
    ...state,
    newAgentViewStatus: ViewStatus.Failure,
    newAgent: undefined,
    newAgentErrorMessage: errorMessage,
  })),
  on(saveAgent, (state) => ({
    ...state,
    saveAgentViewStatus: ViewStatus.Loading,
  })),
  on(saveAgentSuccess, (state, { agent }) => ({
    ...state,
    saveAgentViewStatus: ViewStatus.Success,
    saveAgent: agent,
    saveAgentErrorMessage: undefined,
  })),
  on(saveAgentFailure, (state, { errorMessage }) => ({
    ...state,
    saveAgentViewStatus: ViewStatus.Failure,
    saveAgent: undefined,
    saveAgentErrorMessage: errorMessage,
  })),
  on(deleteAgent, (state) => ({
    ...state,
    deleteAgentViewStatus: ViewStatus.Loading,
  })),
  on(deleteAgentSuccess, (state) => ({
    ...state,
    deleteAgentViewStatus: ViewStatus.Success,
    deleteAgentErrorMessage: undefined,
  })),
  on(deleteAgentFailure, (state, { errorMessage }) => ({
    ...state,
    deleteAgentViewStatus: ViewStatus.Failure,
    deleteAgentErrorMessage: errorMessage,
  })),
  on(patchNewAgent, (state, { agent }) => ({
    ...state,
    newAgent: agent,
  })),
  on(patchExistingAgent, (state, { agent }) => ({
    ...state,
    existingAgentToUpdate: agent,
    agentList: state.agentList.map((a) => {
      if (equals(a.id, agent.id)) {
        return agent;
      }
      return a;
    }),
  })),
  on(logoutSuccessful, () => initialState)
);
