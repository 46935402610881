import { HttpClient } from '@angular/common/http';
import { Injectable, inject } from '@angular/core';
import {
  Error,
  Message,
  SystemStatus,
} from '@fishonline2023/webapps/model/fd2023';
import { Observable } from 'rxjs';
import { environment } from '@env/fd/environment';

@Injectable({ providedIn: 'root' })
export class SystemStatusService {
  private http = inject(HttpClient);

  public loadSystemStatus(): Observable<SystemStatus> {
    const url = `${environment.apiUrl}/system-status`;
    return this.http.get<SystemStatus>(url);
  }

  public getErrorMessage(error: Error): string {
    if (error.statusCode === 403) {
      return error.errorMessage as string;
    }
    return Message.TryAgainLater;
  }
}
