<div class="detail-container d-flex flex-column overflow-hidden">
  <div class="flex-grow-1">
    <ng-content></ng-content>
  </div>
  <div
    class="footer-container d-flex justify-content-between align-items-center rounded-2 p-3 d-print-none"
  >
    <button class="btn btn-primary" (click)="location.back()" aria-label="Back">
      {{ backButtonTitle }}
    </button>
    <div class="d-flex justify-content-between align-items-center">
      <button
        class="btn btn-primary me-4"
        aria-label="Submit"
        (click)="submitSubject?.next()"
        *ngIf="showSubmitButton"
      >
        {{ submitButtonTitle }}
      </button>
      <button
        class="btn btn-secondary"
        aria-label="Delete"
        (click)="deleteSubject?.next()"
        *ngIf="showDeleteButton"
      >
        Delete
      </button>
    </div>
  </div>
</div>
