import { createReducer, on } from '@ngrx/store';
import { Permission, UserProfile } from '@fishonline2023/webapps/model/fd2023';
import { AgentCustomer, ViewStatus } from '@fishonline2023/shared/models';
import { isNilOrEmpty } from '@fishonline2023/shared/ramda';
import {
  agentRoleSwitched,
  agentRoleSwitchedFailure,
  agentRoleSwitchedSuccessful,
  loadUserProfile,
  loadUserProfileFailure,
  loadUserProfileSuccess,
  logout,
  logoutFailure,
  logoutSuccessful,
  patchDefaultAgentCustomerAsActAsCustomer,
} from './user-profile.actions';

export const USER_PROFILE_FEATURE_KEY = 'userProfile';
export interface UserProfileState {
  userProfile?: UserProfile;
  viewStatus: ViewStatus;
  errorMessage?: string;
  logoutStatus: ViewStatus;
  actAsCustomer?: AgentCustomer;
  customerAgentList: AgentCustomer[];
  customerPermissionList: Permission[];
}

export const initialState: UserProfileState = {
  viewStatus: ViewStatus.Initial,
  logoutStatus: ViewStatus.Initial,
  customerAgentList: [],
  customerPermissionList: [],
};

export const userProfileReducer = createReducer(
  initialState,
  on(loadUserProfile, (state) => ({
    ...state,
    viewStatus: ViewStatus.Loading,
  })),
  on(loadUserProfileSuccess, (state, { userProfile }) => ({
    ...state,
    userProfile,
    viewStatus: ViewStatus.Success,
    error: undefined,
    actAsCustomer: getDefaultAgentCustomer(userProfile),
    customerAgentList: userProfile.myAgentList,
    customerPermissionList: userProfile?.fisherDirectPermissionList,
  })),
  on(loadUserProfileFailure, (state, { errorMessage }) => ({
    ...state,
    userProfile: undefined,
    viewStatus: ViewStatus.Failure,
    errorMessage,
  })),
  on(logout, (state) => ({
    ...state,
    logoutStatus: ViewStatus.Loading,
  })),
  on(logoutSuccessful, () => initialState),
  on(logoutFailure, (state) => ({
    ...state,
    logoutStatus: ViewStatus.Failure,
  })),
  on(agentRoleSwitched, (state) => ({
    ...state,
    viewStatus: ViewStatus.Loading,
  })),
  on(
    agentRoleSwitchedSuccessful,
    (state, { agentCustomerId, fisherDirectPermissionList, myAgentList }) => ({
      ...state,
      viewStatus: ViewStatus.Success,
      actAsCustomer: getAgentCustomerFromUserProfile(
        state.userProfile,
        agentCustomerId
      ),
      customerAgentList: myAgentList,
      customerPermissionList: [...fisherDirectPermissionList],
    })
  ),
  on(agentRoleSwitchedFailure, (state, { errorMessage }) => ({
    ...state,
    viewStatus: ViewStatus.Failure,
    errorMessage,
  })),
  on(patchDefaultAgentCustomerAsActAsCustomer, (state, { actAsCustomer }) => ({
    ...state,
    actAsCustomer,
  }))
);

const isUserProfileIdEqualToAgentCustomerId = (
  id: number | undefined,
  userProfile: UserProfile | undefined
) => {
  return userProfile?.id === id;
};

const getAgentCustomerFromUserProfile = (
  userProfile: UserProfile | undefined,
  id?: number
): AgentCustomer | undefined => {
  if (
    isNilOrEmpty(userProfile?.agentCustomerList) ||
    isUserProfileIdEqualToAgentCustomerId(id, userProfile)
  ) {
    return getDefaultAgentCustomer(userProfile);
  }
  return userProfile?.agentCustomerList?.find(
    (agentCustomer) => agentCustomer.id === id
  );
};

const getDefaultAgentCustomer = (
  userProfile: UserProfile | undefined
): AgentCustomer => {
  return {
    id: userProfile?.id ?? 0,
    fullName: `${userProfile?.firstName} ${userProfile?.lastName}`,
  };
};
