<sv-ui-modal [modalId]="ModalId.ConfirmationModal">
  <div class="modal-header">
    <h1 class="fs-4">{{ title }}</h1>
    <button
      (click)="dismissModal()"
      aria-label="Close"
      class="btn-close"
      type="button"
    ></button>
  </div>
  <div class="modal-body">
    {{ message }}
  </div>
  <div class="modal-footer">
    <button
      (click)="dismissModal()"
      [attr.aria-label]="cancelButtonLabel"
      class="btn btn-secondary"
      type="button"
    >
      {{ cancelButtonLabel }}
    </button>
    <button
      (click)="onConfirm()"
      [attr.aria-label]="confirmButtonLabel"
      class="btn btn-primary"
      type="button"
    >
      {{ confirmButtonLabel }}
    </button>
  </div>
</sv-ui-modal>
