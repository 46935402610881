import { ActionReducer, ActionReducerMap, MetaReducer } from '@ngrx/store';
import { LocalStorageConfig, localStorageSync } from 'ngrx-store-localstorage';
import {
  TRANSACTION_FEATURE_KEY,
  TRANSACTION_FEATURE_PERSISTENCE_KEY,
  transactionReducer,
} from '@fishonline2023/webapps/transaction/fa2023/feature/transaction-store';
import {
  SEARCH_FEATURE_KEY,
  searchReducer,
} from '@fishonline2023/webapps/shared/feature/search-store';
import { routerReducer } from '@ngrx/router-store';
import { BREADCRUMB_FEATURE_KEY } from '@fishonline2023/webapps/shared/feature/breadcrumb-store';

// eslint-disable-next-line @typescript-eslint/no-empty-interface
export interface State {}

export const reducers: ActionReducerMap<State> = {
  router: routerReducer,
  [TRANSACTION_FEATURE_KEY]: transactionReducer,
  [SEARCH_FEATURE_KEY]: searchReducer,
};

const LOCAL_STORAGE_SYNC_CONFIG: LocalStorageConfig = {
  keys: [
    {
      [TRANSACTION_FEATURE_KEY]: TRANSACTION_FEATURE_PERSISTENCE_KEY,
    },
    SEARCH_FEATURE_KEY,
    BREADCRUMB_FEATURE_KEY,
  ],
  rehydrate: true,
  restoreDates: false,
  storageKeySerializer: (key: string) => `fa2023-${key}`,
};

const localStorageSyncReducer = (
  reducer: ActionReducer<any>
): ActionReducer<any> => {
  return localStorageSync(LOCAL_STORAGE_SYNC_CONFIG)(reducer);
};

export const metaReducers: MetaReducer<State>[] = [localStorageSyncReducer];
