<div class="form-total-container p-3">
  <div class="row align-items-center">
    <h5 class="m-0 col-3">Total</h5>
    <p class="m-0 col-5"></p>
    <div class="col-2 text-end">
      <h5 class="m-0" *ngIf="!withoutTotalQty">{{ totalQty }}</h5>
    </div>
    <div class="col-2 text-end">
      <h5 class="m-0">{{ totalSellPrice | currency }}</h5>
    </div>
  </div>
</div>
