import {
  FDDateDayJSFormat,
  FishingPeriod,
  NominateRevokeTransactionDetail,
  QuotaTransferReference,
  RenewLicenceTransactionDetail,
  RenewLicenceTransactionReference,
  SingleEntityRequestParams,
  TransactionCreditCardDetail,
  TransactionData,
  TransactionReference,
  TransactionRequestParams,
  TransactionType,
  TransferComponentsTransactionDetail,
  TransferDetail,
  TransferFishingBusinessComponentsTransactionReference,
  TransferFishingBusinessTransactionDetail,
  TransferQuotaDetail,
} from '@fishonline2023/webapps/model/fd2023';
import { equals, isEmpty, isNil } from '@fishonline2023/shared/ramda';
import { transformKeysToTitleCase } from '@fishonline2023/shared/utils';
import * as dayjs from 'dayjs';
import { getRenewalToDate } from '@fishonline2023/webapps/licence/fd2023/ui/renew-licence';
import { TransactionPaymentStatus } from '../models/fd-analytics.models';
import { FDCustomerDetail } from '@fishonline2023/shared/models';
import { formatTwoDecimalPlaces } from './analytics.util';

export const getTransactionDetail = ({
  transactionData,
  requestParam,
  transactionCreditCardDetail,
  paymentStatus,
  paymentErrorMessage,
  isPayLater,
  transactionReference,
}: {
  transactionData: TransactionData;
  requestParam?: TransactionRequestParams;
  transactionCreditCardDetail?: TransactionCreditCardDetail;
  paymentStatus?: TransactionPaymentStatus;
  paymentErrorMessage?: string;
  isPayLater?: boolean;
  transactionReference?: TransactionReference;
}) => {
  const activityLogDetailMap: Record<TransactionType, () => object> = {
    [TransactionType.AmendCustomer]: () =>
      transactionData.transactionDetail as FDCustomerDetail,
    [TransactionType.NominateRevokeFisher]: () =>
      getNominateRevokeFisherActivityLogDetail(transactionData, requestParam),
    [TransactionType.RenewLicence]: () =>
      getRenewLicenceActivityLogDetail(transactionData),
    [TransactionType.TransferQuota]: () =>
      getTransferQuotaActivityLogDetail(transactionData, transactionReference),
    [TransactionType.TransferFishingBusiness]: () =>
      getTransferFishingBusinessActivityLogDetail(
        transactionData,
        transactionReference as TransferFishingBusinessComponentsTransactionReference
      ),
    [TransactionType.TransferComponents]: () =>
      getTransferComponentsActivityLogDetail(
        transactionData,
        transactionReference as TransferFishingBusinessComponentsTransactionReference
      ),
  };
  const creditCardDetail = transactionCreditCardDetail
    ? getCreditCardActivityLogDetail(transactionCreditCardDetail)
    : {};
  const paymentStatusDetail = paymentStatus ? { paymentStatus } : {};
  const paymentErrorMessageDetail = paymentErrorMessage
    ? { paymentErrorMessage }
    : {};
  const isPayLaterDetail = isNil(isPayLater) ? {} : { payLater: isPayLater };
  return JSON.stringify(
    transformKeysToTitleCase({
      ...activityLogDetailMap[transactionData.transactionHeader.type](),
      ...creditCardDetail,
      ...paymentStatusDetail,
      ...paymentErrorMessageDetail,
      ...isPayLaterDetail,
    })
  );
};

const getNominateRevokeFisherActivityLogDetail = (
  transactionData: TransactionData,
  requestParam?: TransactionRequestParams
) => {
  const transactionDetail =
    transactionData.transactionDetail as NominateRevokeTransactionDetail;
  return {
    fishingBusiness: (requestParam as SingleEntityRequestParams).entityId,
    ...(isNil(transactionDetail.nominatedFisher)
      ? {
          revokedFisherId: transactionDetail.previousFisher.id,
          revokedFisherName: transactionDetail.previousFisher.fullName,
        }
      : {
          revokedFisherId: transactionDetail.previousFisher.id,
          revokedFisherName: transactionDetail.previousFisher.fullName,
          nominatedFisherId: transactionDetail.nominatedFisher.id,
          nominatedFisherName: transactionDetail.nominatedFisher.fullName,
        }),
  };
};

const getCreditCardActivityLogDetail = (
  creditCardDetail: TransactionCreditCardDetail
) => {
  return {
    creditCardHolder: creditCardDetail.holderName,
    creditCardNumber: creditCardDetail.cardNumber,
    creditCardExpiryDate: creditCardDetail.expiryDate,
  };
};

const getRenewLicenceActivityLogDetail = (transactionData: TransactionData) => {
  const transactionDetail =
    transactionData.transactionDetail as RenewLicenceTransactionDetail;
  const transactionReference =
    transactionData.transactionReference as RenewLicenceTransactionReference;
  const licenceDetail = {
    currentLicenceHolder: transactionReference.licenceDetails.header.holder,
    currentLicenceNumber: transactionReference.licenceDetails.header.number,
    currentLicenceIssueDate:
      transactionReference.licenceDetails.header.issuedDate,
    currentLicenceExpiryDate:
      transactionReference.licenceDetails.header.expiryDate,
    currentLicenceStatus: transactionReference.licenceDetails.header.status,
  };
  const renewalTermDetail = isNil(transactionDetail.renewalTerm)
    ? {}
    : {
        term: transactionDetail.renewalTerm.renewalTermYear,
        startDate: dayjs(transactionDetail.renewalTerm.startDate).format(
          FDDateDayJSFormat
        ),
        expireDate: getRenewalToDate(transactionDetail.renewalTerm),
        renewalFee: transactionDetail.renewalTerm.renewalTermFee,
      };
  return { ...licenceDetail, ...renewalTermDetail };
};

const getTransferQuotaActivityLogDetail = (
  transactionData: TransactionData,
  transactionReference: TransactionReference | undefined
) => {
  const transactionDetail =
    transactionData.transactionDetail as TransferQuotaDetail;
  const transferQuotaReference = transactionReference as QuotaTransferReference;
  const {
    from,
    to,
    quotaRegimeId,
    fishingPeriodId,
    quantity,
    price,
    transactionDescription,
    receiverDescription,
  } = transactionDetail;

  const quotaRegime = transferQuotaReference?.quotaRegimeList.find(
    (quotaRegime) => quotaRegime.id === quotaRegimeId
  );
  const fishingPeriodList = quotaRegime?.fishingPeriodList || [];
  const fishingPeriod: FishingPeriod =
    fishingPeriodList.find((fp) => fp.id === fishingPeriodId) ||
    fishingPeriodList[0];

  return {
    fishingBusinessFrom: from.id,
    fishingBusinessTo: to.id,
    fishingPeriod: fishingPeriod?.name || '',
    quotaType: quotaRegime?.name,
    quotaQuantity: quantity,
    price: formatTwoDecimalPlaces(price),
    supplierDescription: transactionDescription,
    receiverDescription,
  };
};

const getTransferFishingBusinessActivityLogDetail = (
  transactionData: TransactionData,
  transactionReference: TransferFishingBusinessComponentsTransactionReference
) => {
  const transactionDetail =
    transactionData.transactionDetail as TransferFishingBusinessTransactionDetail;
  const { from, to, componentList, quotaList } = transactionDetail;

  return {
    fromFishingBusinessOwnerId: from.owner.id,
    fromFishingBusinessOwner: from.owner.fullName,
    fromFishingBusiness: from.id,
    toCustomerId: to.id,
    toCustomer: to.fullName,
    ...componentQuotaTransactionDetail(
      componentList,
      quotaList,
      transactionReference
    ),
  };
};

const getTransferComponentsActivityLogDetail = (
  transactionData: TransactionData,
  transactionReference: TransferFishingBusinessComponentsTransactionReference
) => {
  const transactionDetail =
    transactionData.transactionDetail as TransferComponentsTransactionDetail;
  const { from, to, componentList, quotaList } = transactionDetail;

  return {
    fromFishingBusinessOwnerId: from.owner.id,
    fromFishingBusinessOwner: from.owner.fullName,
    fromFishingBusiness: from.id,
    toCustomerId: to.owner.id,
    toCustomer: to.owner.fullName,
    toFishingBusinessNumber: to.id,
    ...componentQuotaTransactionDetail(
      componentList,
      quotaList,
      transactionReference
    ),
  };
};

const componentQuotaTransactionDetail = (
  componentList: TransferDetail[],
  quotaList: TransferDetail[],
  transactionReference: TransferFishingBusinessComponentsTransactionReference
) => ({
  ...(isEmpty(componentList)
    ? {}
    : {
        components: componentList.map(
          (component) =>
            `Name: ${component.name}, qty available: ${
              transactionReference.componentList.find((componentReference) =>
                equals(componentReference.name, component.name)
              )?.availableAmount
            }, qty transferred: ${component.quantity}, sell price: ${
              component.totalSellPrice
            }`
        ),
      }),
  ...(isEmpty(quotaList)
    ? {}
    : {
        quota: quotaList.map(
          (quota) =>
            `Name: ${quota.name}, qty available: ${
              transactionReference.quotaList.find((quotaReference) =>
                equals(quotaReference.name, quota.name)
              )?.amount
            }, qty transferred: ${quota.quantity}, sell price: ${
              quota.totalSellPrice
            }`
        ),
      }),
});
