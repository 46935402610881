<h4>{{ title }}</h4>
<h4 class="shade">FISHING BUSINESS / CURRENT SHAREHOLDING DETAILS</h4>
<sv-ui-full-width-item-list
  [itemList]="fishingBusinessDetail"
  [isLongLabel]="true"
></sv-ui-full-width-item-list>
<ng-container *ngIf="hasProperty('currentShareholdingList')">
  <h4 class="shade">Current Shareholdings:</h4>
  <ng-container *ngIf="currentShareHoldingList.length > 0; else none">
    <sv-ui-table [tableData]="currentShareHoldingList"></sv-ui-table>
  </ng-container>
</ng-container>
<h4 class="shade">SHARE HISTORY DETAILS</h4>
<h5 class="shade">Renewal History</h5>
<ng-container *ngIf="renewHistoryList.length > 0; else none">
  <sv-ui-table [tableData]="renewHistoryList"></sv-ui-table>
</ng-container>
<h5 class="shade">Transaction History</h5>
<ng-container *ngIf="transactionHistoryList.length > 0; else none">
  <sv-ui-table [tableData]="transactionHistoryList"></sv-ui-table>
</ng-container>
<p class="shade">
  Note: The information shown in the “Share History Details” table above
  commences from 24/8/2012. Any prior share history details can be obtained by
  contacting the Department.
</p>
<hr />
<h4 class="shade">MORTGAGE / REGISTERED INTEREST HISTORY DETAILS</h4>
<ng-container
  *ngIf="
    this.fishingBusiness.mortgageRegisteredInterestHistoryDetailsList.length >
      0;
    else none
  "
>
  <sv-ui-table
    [tableData]="mortgageRegisteredInterestHistoryDetails"
  ></sv-ui-table>
</ng-container>
<p class="shade">
  Note: Mortgages and/or registered interests current from 24/8/2012 only are
  shown. Any mortgage registration information prior to this date can be
  obtained from the Department.
</p>
<hr />

<ng-container *ngIf="hasProperty('currentAuthorisedFisherFor')">
  <h4 class="shade">CURRENT AUTHORISED FISHER FOR THE FB</h4>
  <ng-container *ngIf="currentAuthorisedFisherFor.length > 0; else none">
    <sv-ui-table [tableData]="currentAuthorisedFisherForDetail"></sv-ui-table>
  </ng-container>
</ng-container>
<h4 class="shade">
  AUTHORISATION HISTORY FOR THE FB DURING SHAREHOLDERS OWNERSHIP
</h4>
<ng-container
  *ngIf="this.fishingBusiness.authorisationHistoryList.length > 0; else none"
>
  <sv-ui-table [tableData]="authorisationHistory"></sv-ui-table>
</ng-container>
<p class="shade">
  Note: The information shown in the “Authorisation History Details” table above
  commences from 24/8/2012. Any information prior to this date can be obtained
  from the Department.
</p>
<ng-template #none>
  <p>None</p>
</ng-template>
