import {
  FormGroup,
  FormControl,
  Validators,
  ValidatorFn,
  AbstractControl,
} from '@angular/forms';
import {
  SaleAndTradeEditForm,
  Purpose,
} from '@fishonline2023/webapps/model/fd2023';
import { TWO_DECIMAL_POINT } from '@fishonline2023/shared/utils';
import * as dayjs from 'dayjs';

export const forSaleAndTradeEditForm = () =>
  new FormGroup<SaleAndTradeEditForm>({
    title: new FormControl(null, {
      nonNullable: true,
      validators: [Validators.required, Validators.maxLength(200)],
    }),
    purpose: new FormControl(Purpose.Sale, {
      nonNullable: true,
      validators: [Validators.required],
    }),
    type: new FormControl(null, {
      nonNullable: true,
      validators: [Validators.required],
    }),
    price: new FormControl(null, {
      nonNullable: true,
      validators: [Validators.pattern(TWO_DECIMAL_POINT)],
      updateOn: 'change',
    }),
    description: new FormControl(null, {
      nonNullable: true,
    }),
    contactNamePrimary: new FormControl(null, {
      nonNullable: true,
      validators: [Validators.required],
    }),
    contactNumberPrimary: new FormControl(null, {
      nonNullable: true,
      validators: [Validators.required],
    }),
    contactNameSecondary: new FormControl(null, {
      nonNullable: true,
    }),
    contactNumberSecondary: new FormControl(null, {
      nonNullable: true,
    }),
    expiryDate: new FormControl(null, {
      nonNullable: true,
      validators: [Validators.required, ValidExpiryDate()],
      updateOn: 'change',
    }),
  });

export function ValidExpiryDate(): ValidatorFn {
  return (control: AbstractControl): { [key: string]: unknown } | null => {
    const givenDate = dayjs(control.value);
    const currentDate = dayjs();
    const daysDifference = givenDate.diff(currentDate, 'day', true);
    const valid = daysDifference < 60 && daysDifference > 0;
    return valid ? null : { invalidDate: { value: control.value } };
  };
}
