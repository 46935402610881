import { Injectable } from '@angular/core';
import {
  SubmitActionFromButtonType,
  TransactionAction,
  TransactionButtonAction,
  TransactionData,
  TransactionStatus,
} from '@fishonline2023/webapps/model/fa2023';
import {
  Channel,
  UserType,
} from '@fishonline2023/webapps/shared/feature/appsync';
import { SearchActivityLogResultProperty } from '@fishonline2023/shared/models';
import { defer, from, retry, switchMap, take } from 'rxjs';
import { Analytics, API } from 'aws-amplify';
import { environment } from '@env/fa/environment';
import { getTransactionDetail } from '../utils/fa-transaction-analytics.util';
import {
  SubmitButtonTypeButtonActionMap,
  TransactionEventType,
  TransactionTypeReadable,
} from '../models/fa-analytics.models';

@Injectable()
export class FAAnalyticsService {
  public recordTransactionSubmitSuccessfulEvent(
    transactionData: TransactionData,
    fromButtonType: SubmitActionFromButtonType
  ) {
    return this.recordTransactionEvent({
      transactionData,
      transactionAction: TransactionAction.Save,
      buttonAction: SubmitButtonTypeButtonActionMap[fromButtonType],
      transactionStatus: TransactionStatus.Pending,
    });
  }

  public recordTransactionDeleteSuccessfulEvent(
    transactionData: TransactionData
  ) {
    return this.recordTransactionEvent({
      transactionData,
      transactionAction: TransactionAction.Delete,
      buttonAction: TransactionButtonAction.Confirm,
      transactionStatus: TransactionStatus.Deleted,
    });
  }

  public recordTransactionApproveSuccessfulEvent(
    transactionData: TransactionData
  ) {
    return this.recordTransactionEvent({
      transactionData,
      transactionAction: TransactionAction.Approve,
      buttonAction: TransactionButtonAction.Confirm,
      transactionStatus: TransactionStatus.Approved,
    });
  }

  public recordTransactionNotApproveSuccessfulEvent(
    transactionData: TransactionData
  ) {
    return this.recordTransactionEvent({
      transactionData,
      transactionAction: TransactionAction.NotApprove,
      buttonAction: TransactionButtonAction.Confirm,
      transactionStatus: TransactionStatus.NotApproved,
    });
  }

  public recordAnalyticsEvent = (
    data: Partial<SearchActivityLogResultProperty>
  ) =>
    defer(() =>
      from(
        API.get('rest', '/ip-address', {
          headers: { 'Content-Type': 'application/json' },
        })
      )
    ).pipe(
      retry(3),
      take(1),
      switchMap((ip) => {
        const activityLogData: Partial<SearchActivityLogResultProperty> = {
          ...data,
          channel: Channel.FA,
          userType: UserType.Department,
          userIPAddress: ip,
        };
        return Analytics.record(
          {
            data: activityLogData,
            partitionKey: 'activity-log',
            streamName: environment.kinesisStreamName,
            immediate: true,
          },
          'AWSKinesis'
        );
      })
    );

  public recordTransactionEvent = ({
    transactionData,
    buttonAction,
    transactionAction,
    transactionStatus,
  }: {
    transactionData: TransactionData;
    buttonAction: TransactionButtonAction;
    transactionAction: TransactionAction;
    transactionStatus: TransactionStatus;
  }) =>
    this.recordAnalyticsEvent({
      userActivityLogEventTypeID:
        TransactionEventType[transactionData.transactionHeader.transactionType],
      transaction: JSON.stringify({
        'Transaction ID': transactionData.transactionHeader.transactionNumber,
        'Button action': buttonAction,
        'Transaction status': transactionStatus,
        'Transaction type':
          TransactionTypeReadable[
            transactionData.transactionHeader.transactionType
          ],
        'Transaction action': transactionAction,
      }),
      detail: getTransactionDetail({
        transactionData,
      }),
    });
}
