export interface ConsumableQuotaBalance {
  fishingBusinessId: number;
  quotaRegime: string;
  fishingPeriod: string;
  availableBalance: number;
  currentBalance?: number;
  pending: number;
  processed: number;
  unit: string;
}

export interface NonConsumableQuotaBalance {
  fishingBusinessId: number;
  endorsementShortName: string;
  availableBalance: number;
  unit: string;
}

export interface OPTRegisteredBoat {
  fishingBusinessId: number;
  boatDescription: string;
  amsaId: string;
  quotaDeductionRate: number;
}

export interface QuotaBalance {
  consumableQuotaBalanceList: ConsumableQuotaBalance[];
  nonConsumableQuotaBalanceList: NonConsumableQuotaBalance[];
  optRegisteredBoatList: OPTRegisteredBoat[];
}

export enum QuotaBalanceType {
  ConsumableQuotaBalance = 0,
  NonConsumableQuotaBalance = 1,
  OPTRegisteredBoat = 2,
}

export type QuotaBalanceData =
  | ConsumableQuotaBalance[]
  | NonConsumableQuotaBalance[]
  | OPTRegisteredBoat[];
