import { CommercialFishingLicence } from './commercial-fishing-licence.model';
import { Deal } from './deal.model';
import { Transaction } from './transaction.model';
import {
  ConsumableQuotaBalance,
  NonConsumableQuotaBalance,
  OPTRegisteredBoat,
} from './quota-balance.model';

export interface HomePage {
  cflList: CommercialFishingLicence[];
  cfbList: CommercialFishingLicence[];
  dealList: Deal[];
  consumableQuotaList: ConsumableQuotaBalance[];
  nonConsumableQuotaList: NonConsumableQuotaBalance[];
  optRegisteredBoatList: OPTRegisteredBoat[];
  transactionList: Transaction[];
}
