export const formatAcn = (value: string) => {
  // Remove any non-digits
  const digits = value.replace(/\D/g, '').slice(0, 9);

  // ACN format: Three digits, space, three digits, space, three digits
  // We will use the 'slice' method to divide the string into parts
  const part1 = digits.slice(0, 3);
  const part2 = digits.slice(3, 6);
  const part3 = digits.slice(6, 9);

  // Combine the parts, ensuring to add a space between them if they exist
  return [part1, part2, part3].filter(Boolean).join(' ').trim();
};

export const formatAbn = (value: string) => {
  // Remove any non-digits
  let digits = value.replace(/\D/g, '').slice(0, 11);

  // Break into ABN format: Two digits, space, then groups of three digits
  let formatted = digits.slice(0, 2); // Start with the first two digits

  // Process the remaining digits
  digits = digits.slice(2);
  while (digits) {
    // Take the next three digits and add a space
    formatted += (formatted ? ' ' : '') + digits.slice(0, 3);
    digits = digits.slice(3); // Remove the processed digits
  }
  return formatted.trim(); // Trim any excess whitespace from the ends
};
