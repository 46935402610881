import { FDCustomerDetail } from '@fishonline2023/shared/models';
import { contactDetailFormControls } from '@fishonline2023/webapps/shared/ui/amend-customer';

export type AddressFormGroupName =
  | 'physicalAddress'
  | 'postalAddress'
  | 'billingAddress';

export interface AddressDetail {
  title: string;
  formGroup: AddressFormGroupName;
  addressOrDetail: 'address' | 'detail';
}
export const contactDetailKeys = Object.keys(
  contactDetailFormControls
) as Array<keyof FDCustomerDetail>;

export const addressDetails: AddressDetail[] = [
  {
    title: 'Physical address',
    formGroup: 'physicalAddress',
    addressOrDetail: 'address',
  },
  {
    title: 'Postal address',
    formGroup: 'postalAddress',
    addressOrDetail: 'address',
  },
  {
    title: 'Billing address',
    formGroup: 'billingAddress',
    addressOrDetail: 'address',
  },
  {
    title: 'Billing details',
    formGroup: 'billingAddress',
    addressOrDetail: 'detail',
  },
];
