import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  forwardRef,
  inject,
  Input,
  Output,
} from '@angular/core';
import { CommonModule } from '@angular/common';
import {
  ControlValueAccessor,
  FormsModule,
  NG_VALUE_ACCESSOR,
  ReactiveFormsModule,
} from '@angular/forms';
import { AutoCompletePosition } from '@fishonline2023/shared/models';

@Component({
  selector: 'sv-ui-auto-complete',
  standalone: true,
  imports: [CommonModule, FormsModule, ReactiveFormsModule],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => AutoCompleteComponent),
      multi: true,
    },
  ],
  templateUrl: './auto-complete.component.html',
  styleUrls: ['./auto-complete.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AutoCompleteComponent<T> implements ControlValueAccessor {
  @Input() public options: Array<T & { label: string }> = [];
  @Input() public isReadOnly: boolean | undefined = false;
  @Input() public position = AutoCompletePosition.BOTTOM;
  @Input() public withSearchIcon = false;
  @Input() public placeholder = '';
  @Output() public optionSelected = new EventEmitter<T & { label: string }>();
  @Output() public inputFocused = new EventEmitter<void>();
  @Output() public inputBlurred = new EventEmitter<void>();

  public AutoCompletePosition = AutoCompletePosition;

  private cdr = inject(ChangeDetectorRef);

  public value = '';

  // eslint-disable-next-line @typescript-eslint/no-empty-function
  public onChange(value: string) {}

  public registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  // eslint-disable-next-line @typescript-eslint/no-empty-function
  public onTouched() {}

  public registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }

  public writeValue(value: string): void {
    this.value = value;
    this.cdr.detectChanges();
  }

  public trackByLabel(i: number, option: T & { label: string }) {
    return option.label;
  }

  protected selectionOption(option: T & { label: string }) {
    this.optionSelected.emit(option);
  }
}
