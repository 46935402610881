<div class="round-secondary-border-box mb-3">
  <h5>
    <i>{{ title }}</i>
  </h5>
  <sv-ui-full-width-item-list
    [itemList]="transactionDetail"
  ></sv-ui-full-width-item-list>
</div>
<div class="round-secondary-border-box mb-3" data-cy="current-licence">
  <h5>Current licence details</h5>
  <sv-ui-licence-detail-info
    [licenceHeader]="licenceHeader"
  ></sv-ui-licence-detail-info>
</div>
<div
  class="round-secondary-border-box mb-3"
  *ngIf="newLicenceDetails"
  data-cy="new-licence"
>
  <h5>New licence details</h5>
  <sv-ui-full-width-item-list
    [itemList]="newLicenceDetailsItemList"
  ></sv-ui-full-width-item-list>
</div>
