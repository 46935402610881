import { createAction, props } from '@ngrx/store';
import {
  SubmitActionFromButtonType,
  TransactionData,
  TransactionEntityItem,
  TransactionRequestParams,
  TransactionSubmitPayload,
  TransactionType,
} from '@fishonline2023/webapps/model/fa2023';

export const transactionInitialised = createAction(
  '[Transaction/API] Transaction Initialised',
  props<{ transactionType: TransactionType }>()
);

export const transactionPendingTransactionIdPopulated = createAction(
  '[Transaction/API] Transaction Pending Transaction Id Populated',
  props<{ transactionId: number }>()
);

export const transactionEntityDataRequested = createAction(
  '[Transaction/API] Transaction Entity Data Requested'
);

export const transactionEntityDataLoadSuccessful = createAction(
  '[Transaction/API] Transaction Entity Data Load Successful',
  props<{ transactionEntityData: Array<TransactionEntityItem> }>()
);

export const transactionEntityDataLoadFailed = createAction(
  '[Transaction/API] Transaction Entity Data Load Failed',
  props<{ errorMessage: string }>()
);

export const transactionDataRequested = createAction(
  '[Transaction/API] Transaction Data Requested',
  props<{
    transactionId: number | null;
    queryParams?: TransactionRequestParams;
  }>()
);

export const entityCreated = createAction('[Transaction/API] Entity Created');

export const transactionDataRequestedSuccessful = createAction(
  '[Transaction/API] Transaction Data Requested Successful',
  props<{ transactionData: TransactionData }>()
);

export const transactionDataRequestedFailed = createAction(
  '[Transaction/API] Transaction Data Requested Failed',
  props<{ errorMessage: string }>()
);

export const transactionSubmitted = createAction(
  '[Transaction/API] Transaction Submitted',
  props<{
    transactionSubmitPayload: TransactionSubmitPayload;
    fromButtonType: SubmitActionFromButtonType;
  }>()
);

export const transactionSubmittedSuccessful = createAction(
  '[Transaction/API] Transaction Submitted Successful',
  props<{
    transactionData: TransactionData;
    fromButtonType: SubmitActionFromButtonType;
  }>()
);

export const transactionSubmittedFailed = createAction(
  '[Transaction/API] Transaction Submitted Failed',
  props<{ errorMessage: string }>()
);

export const transactionDeleted = createAction(
  '[Transaction/API] Transaction Deleted'
);

export const transactionDeletedSuccessful = createAction(
  '[Transaction/API] Transaction Deleted Successful',
  props<{ transactionData: TransactionData }>()
);

export const transactionDeletedFailed = createAction(
  '[Transaction/API] Transaction Deleted Failed',
  props<{ errorMessage: string }>()
);

export const transactionApproved = createAction(
  '[Transaction/API] Transaction Approved'
);

export const transactionApprovedSuccessful = createAction(
  '[Transaction/API] Transaction Approved Successful',
  props<{ transactionData: TransactionData }>()
);

export const transactionApprovedFailed = createAction(
  '[Transaction/API] Transaction Approved Failed',
  props<{ errorMessage: string; transactionData?: TransactionData }>()
);

export const transactionNotApproved = createAction(
  '[Transaction/API] Transaction Not Approved'
);

export const transactionNotApprovedSuccessful = createAction(
  '[Transaction/API] Transaction Not Approved Successful',
  props<{ transactionData: TransactionData }>()
);

export const transactionNotApprovedFailed = createAction(
  '[Transaction/API] Transaction Not Approved Failed',
  props<{ errorMessage: string }>()
);

export const transactionDataForEditReset = createAction(
  '[Transaction/API] Transaction Data For Edit Reset'
);

export const transactionReset = createAction(
  '[Transaction/API] Transaction Reset'
);
