import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  inject,
  Input,
  Output,
} from '@angular/core';
import { CommonModule } from '@angular/common';
import { ModalComponent } from '../modal/modal.component';
import { DynamicWidthItemList, ModalId } from '@fishonline2023/shared/models';
import { ModalService } from '../modal/modal.service';
import { DynamicWidthItemListComponent } from '../dynamic-width-item-list/dynamic-width-item-list.component';

@Component({
  selector: 'sv-ui-info-modal',
  standalone: true,
  imports: [CommonModule, ModalComponent, DynamicWidthItemListComponent],
  templateUrl: './info-modal.component.html',
  styleUrls: ['./info-modal.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class InfoModalComponent {
  @Input() public title = '';
  @Input() public message = '';
  @Input() public body!: DynamicWidthItemList;
  @Output() public dismiss = new EventEmitter<void>();
  protected readonly ModalId = ModalId;
  private modalService = inject(ModalService);

  public dismissModal(): void {
    this.modalService.close();
    this.dismiss.emit();
  }
}
