export interface ShareholderReport {
  shareholderName: string;
  shareholderNumber: number;
  fishingBusinessCurrentlyOwned: string[];
  fishingBusinessPreviouslyOwned: string[];
  currentAuthorisedFisherFor: AuthorisedFisherForFishingBusiness[];
  shareholderAuthorisationHistory: ShareholderAuthorisationHistory[];
  shareholderDemeritPointActivityList: ShareholderDemeritPointActivity[];
  currentlyOwnedFishingBusinessList: CurrentlyOwnedFishingBusiness[];
  previouslyOwnedFishingBusiness: PreviouslyOwnedFishingBusiness[];
}

export interface AuthorisedFisherForFishingBusiness {
  dateTime: string;
  fishingBusinessNumber: number;
  fisherRegistrationNumber: number;
  fisherName: string;
}

export interface ShareholderAuthorisationHistory
  extends AuthorisedFisherForFishingBusiness {
  transactionType: string;
}

export interface ShareholderDemeritPointActivity {
  dateTime: string;
  transactionType: string;
  offence: string;
  demeritPoints: number;
  convictionDate: string;
  balance: number;
}

export interface PreviouslyOwnedFishingBusiness {
  fishingBusinessNumber: number;
  shareHistoryDetails: ShareHistoryDetails;
  mortgageRegisteredInterestHistoryDetailsList: MortgageRegisteredInterestHistoryDetails[];
  authorisationHistoryList: AuthorisationHistoryForFB[];
}

export interface CurrentlyOwnedFishingBusiness
  extends PreviouslyOwnedFishingBusiness {
  certificateNumber: string;
  issueDate: string;
  currentShareholdingList: CurrentShareholding[];
  currentAuthorisedFisherFor: AuthorisedFisherForFishingBusiness[];
}

export type AuthorisationHistoryForFB = Omit<
  ShareholderAuthorisationHistory,
  'fishingBusinessNumber'
>;

export interface CurrentShareholding {
  shareClassName: string;
  shareholderName: string;
  shareClassCode: number;
  shareType: string;
  expiryDate: string;
  holdings: number;
  mortgageInterestHolding: number;
}

export interface ShareHistoryDetails {
  renewalHistoryList: RenewalHistory[];
  transactionHistoryList: TransactionHistory[];
}

export interface RenewalHistory {
  date: string;
  shareClassCode: number;
  shareClassName: string;
}

export interface TransactionHistory {
  dateTime: string;
  transactionType: string;
  action: string;
  fishingBusinessNumber: number | null;
  shareholderName: string | null;
  shareClassCode: number;
  shareType: string;
  shareClassName: string;
  quantity: number;
}

export interface MortgageRegisteredInterestHistoryDetails {
  dateTime: string;
  transactionType: string;
  action: string;
  mortgageRegisteredPartyRegistrationNumber: number;
  mortgageRegisteredPartyName: string;
  shareClassCode: number;
  shareType: string;
  shareClassName: string;
  quantity: number;
}
