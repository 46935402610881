import { FormArray, FormControl, FormGroup } from '@angular/forms';
import { AgentCustomer, FishingBusiness } from '@fishonline2023/shared/models';

export interface TransferComponentReference {
  id: string;
  name: string;
  totalAmount: number;
  availableAmount: number;
  isTransferable: boolean;
}

export interface TransferQuotaReference {
  id: string;
  name: string;
  amount: number;
  allowDecimals: boolean;
  unit: string;
}

export interface TransferFishingBusinessComponentsTransactionReference {
  componentList: TransferComponentReference[];
  quotaList: TransferQuotaReference[];
}

export interface TransferFishingBusinessComponentsTransactionDetail {
  from: FishingBusiness;
  componentList: TransferDetail[];
  quotaList: TransferDetail[];
  transferFee?: number;
  termsConditionsStatus?: Record<TermCondition, boolean>;
}

export enum TermCondition {
  TermCondition = 'termCondition',
  ForeignAcquisition = 'foreignAcquisition',
  RiskAcceptance = 'riskAcceptance',
}

export const TermConditionDetailMap: Record<TermCondition, string> = {
  [TermCondition.TermCondition]: `<small>I have the authority to make this transfer. I have read and understood the <a href="/terms-conditions" target="_blank">Terms and Conditions<a/>.</small>`,
  [TermCondition.ForeignAcquisition]: `<small>I am an ordinarily a resident of Australia (within the meaning of the Foreign Acquisitions and Takeover Act 1975 of the Commonwealth) (or if applying as a Company), that the company has less than 20% foreign ownership (within the meaning of the Corporations Act 2001 of the Commonwealth.</small>`,
  [TermCondition.RiskAcceptance]: `<small>I confirm (if applying as a company) that the company registration is current. I further declare (if purchasing shares from the a company that I have obtained information from the Australian Securities and Investment Commission (ASIC) showing that the company from which shares are being transferred has not been de-registered. If a company has been deregistered, I accept all associated risks of pursuing that purchase.</small>`,
};

export interface TransferFishingBusinessTransactionDetail
  extends TransferFishingBusinessComponentsTransactionDetail {
  to: AgentCustomer;
}

export interface TransferComponentsTransactionDetail
  extends TransferFishingBusinessComponentsTransactionDetail {
  to: FishingBusiness;
}

export interface TransferDetail {
  id: string;
  name: string;
  quantity: number;
  totalSellPrice: number;
}

export interface TransferFishingBusinessComponentsForm {
  componentList: FormArray<FormGroup<TransferDetailForm>>;
  quotaList: FormArray<FormGroup<TransferDetailForm>>;
}

export type TransferFishingBusinessForm =
  TransferFishingBusinessComponentsForm &
    FishingBusinessTwoPartyTransactionEntitySelectionForm;

export type TransferComponentsForm = TransferFishingBusinessComponentsForm &
  ComponentTwoPartyTransactionEntitySelectionForm;

export interface FishingBusinessTwoPartyTransactionEntitySelectionForm {
  from: FormControl<FishingBusiness | null>;
  to: FormControl<AgentCustomer | null>;
}

export interface ComponentTwoPartyTransactionEntitySelectionForm {
  from: FormControl<FishingBusiness | null>;
  to: FormControl<FishingBusiness | null>;
}

export interface TransferDetailForm {
  id: FormControl<string>;
  name: FormControl<string>;
  quantity: FormControl<number>;
  totalSellPrice: FormControl<number>;
}

export enum TwoPartyTransactionEntitySelectionType {
  FishingBusiness,
  Customer,
  FishingBusinessAndCustomer,
}
