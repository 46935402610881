import { environment } from '@env/fd/environment';

const inputStyle = {
  height: '34px',
  padding: '0px 12px',
  'font-size': '14px',
  border: '1px solid #ccc',
  'border-radius': '2px',
};
export const options = {
  config: {
    supplierBusinessCode: environment.westPacSupplierBusinessCode,
  },
  iframe: {
    width: '100%',
    height: '100%',
    style: {
      'font-size': '14px',
      'line-height': '24px',
      border: '1px solid #dedede',
      'border-radius': '2px',
      'margin-bottom': '0.75rem',
      'min-height': '400px',
      padding: '1.5rem',
      width: '100%',
      'background-color': 'white',
    },
  },
  showAcceptedCards: true,
  cardholderName: {
    style: inputStyle,
    label: 'Name on card',
  },
  cardNumber: {
    style: inputStyle,
    label: 'Card number',
  },
  expiryDateMonth: {
    style: inputStyle,
  },
  expiryDateYear: {
    style: inputStyle,
  },
  cvn: {
    hidden: false,
    label: 'Security code',
  },
  body: {
    style: {},
  },
};
