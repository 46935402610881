import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ModalComponent } from '@fishonline2023/webapps/shared/ui/base-components';
import {
  FDDateTimeAngularPipeFormat,
  FisherHistory,
} from '@fishonline2023/webapps/model/fd2023';

@Component({
  selector: 'sv-ui-fishing-business-history-fisher-history',
  standalone: true,
  imports: [CommonModule, ModalComponent],
  templateUrl: './fishing-business-history-fisher-history.component.html',
  styleUrls: ['./fishing-business-history-fisher-history.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class FishingBusinessHistoryFisherHistoryComponent {
  @Input() currentFisher?: FisherHistory;
  @Input() public fisherHistoryList: FisherHistory[] = [];

  public FDDateTimeAngularPipeFormat = FDDateTimeAngularPipeFormat;
}
