<div class="fishing-business-container">
  <h3 class="mb-3">{{ label }}</h3>
  <ng-container *ngIf="control.value === null && !isReadOnly">
    <ng-container
      *ngIf="
        selectionType ===
          TwoPartyTransactionEntitySelectionType.FishingBusinessAndCustomer;
        else singleLevelSelection
      "
    >
      <p class="mb-1">{{ description }}</p>
      <sv-ui-typeahead
        [form]="transferComponentsToCustomerFormControl"
        [isAllValueWithoutLabel]="true"
        [options]="optionListWithLabel(this.optionList)"
        [withSearchIcon]="true"
      ></sv-ui-typeahead>
      <ng-container *ngIf="transferComponentsToCustomer$ | async as toCustomer">
        <p class="mt-3 mb-1">{{ secondaryDescription }}</p>
        <sv-ui-typeahead
          [form]="control"
          [isAllValueWithoutLabel]="true"
          [options]="optionListWithLabel(fishingBusinessOptionList(toCustomer))"
          [withSearchIcon]="true"
        ></sv-ui-typeahead>
        <!--   todo: put it back when create fishing business transaction is ready in FA     -->
        <!--        <p class="mt-2 mb-2">or</p>-->
        <!--        <button-->
        <!--          (click)="fishingBusinessCreated.emit(toCustomer)"-->
        <!--          class="btn btn-primary"-->
        <!--        >-->
        <!--          Create new fishing business-->
        <!--        </button>-->
      </ng-container>
    </ng-container>
    <ng-template #singleLevelSelection>
      <p class="mb-1">{{ description }}</p>
      <sv-ui-typeahead
        [form]="control"
        [isAllValueWithoutLabel]="true"
        [options]="optionListWithLabel(this.optionList)"
        [withSearchIcon]="true"
      ></sv-ui-typeahead>
    </ng-template>
  </ng-container>
  <ng-container *ngIf="control.value">
    <ng-container
      *ngIf="
        selectionType === TwoPartyTransactionEntitySelectionType.Customer;
        else fishingBusiness
      "
    >
      <h4 class="mb-2">{{ getCustomer.fullName }}</h4>
      <p class="mb-2">Registration Number {{ getCustomer.id }}</p>
    </ng-container>
    <ng-template #fishingBusiness>
      <h4 class="mb-2">{{ getFishingBusiness.owner.fullName }}</h4>
      <p class="mb-2">FB {{ getFishingBusiness.id }}</p>
      <p class="mb-2">Registration Number {{ getFishingBusiness.owner.id }}</p>
      <p class="mb-3">Type {{ getFishingBusiness.group }}</p>
    </ng-template>
    <sv-ui-button-link
      (click)="changeSelection()"
      *ngIf="!isReadOnly"
      class="mb-2"
      text="Change selection"
    ></sv-ui-button-link
  ></ng-container>
</div>
