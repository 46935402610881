import {
  ChangeDetectionStrategy,
  Component,
  inject,
  Input,
} from '@angular/core';
import { CommonModule, CurrencyPipe } from '@angular/common';
import {
  FullWidthItemListComponent,
  LicenceDetailInfoComponent,
} from '@fishonline2023/webapps/shared/ui/base-components';
import {
  FDDateDayJSFormat,
  FDDateTimeDayJSFormat,
  RenewLicenceTransactionDetail,
  RenewLicenceTransactionReference,
  TransactionAction,
  TransactionData,
} from '@fishonline2023/webapps/model/fd2023';
import * as dayjs from 'dayjs';
import { getRenewalToDate } from '../utils/get-renewal-to-date';
import { equals } from '@fishonline2023/shared/ramda';
import { ItemList } from '@fishonline2023/shared/models';

@Component({
  selector: 'sv-ui-renew-licence-confirm-receipt',
  standalone: true,
  imports: [
    CommonModule,
    FullWidthItemListComponent,
    LicenceDetailInfoComponent,
  ],
  providers: [CurrencyPipe],
  templateUrl: './renew-licence-confirm-receipt.component.html',
  styleUrls: ['./renew-licence-confirm-receipt.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class RenewLicenceConfirmReceiptComponent {
  @Input() public transactionData!: TransactionData;
  @Input() public isReceiptPage = false;
  private currencyPipe = inject(CurrencyPipe);

  public get newLicenceDetailsItemList(): ItemList {
    return [
      {
        key: 'Term',
        value: `${this.newLicenceDetails.renewalTermYear} Year(s)`,
      },
      {
        key: 'Issued date',
        value: dayjs(this.newLicenceDetails.startDate).format(
          FDDateDayJSFormat
        ),
      },
      {
        key: 'Expiry date',
        value: getRenewalToDate(this.newLicenceDetails),
      },
      {
        key: 'Renewal fee',
        value: this.currencyPipe.transform(
          this.newLicenceDetails.renewalTermFee
        ),
      },
    ];
  }

  public get newLicenceDetails() {
    return (
      this.transactionData.transactionDetail as RenewLicenceTransactionDetail
    ).renewalTerm;
  }

  public get transactionDetail(): ItemList {
    const transactionDetail = [
      {
        key: 'Transaction type',
        value: this.transactionData.transactionHeader.type,
      },
      {
        key: 'Transaction number',
        value: this.transactionData.transactionHeader.id,
      },
    ];
    const effectiveDate = this.isReceiptPage
      ? [
          {
            key: 'Effective date',
            value: dayjs(
              this.transactionData.transactionHeader.effectiveDate
            ).format(FDDateTimeDayJSFormat),
          },
        ]
      : [];
    const isDeclineAction = equals(
      (this.transactionData.transactionDetail as RenewLicenceTransactionDetail)
        .action,
      TransactionAction.Decline
    );
    const outcome = isDeclineAction
      ? [
          {
            key: 'Outcome',
            value: 'DECLINED',
          },
        ]
      : [];
    return [...transactionDetail, ...outcome, ...effectiveDate];
  }

  public get licenceHeader() {
    return (
      this.transactionData
        .transactionReference as RenewLicenceTransactionReference
    ).licenceDetails.header;
  }

  get title() {
    const transactionAction = (
      this.transactionData.transactionDetail as RenewLicenceTransactionDetail
    ).action;

    switch (transactionAction) {
      case TransactionAction.PayLater:
        return 'Your renewal will not be finalised until payment has been received';
      case TransactionAction.Decline:
        return 'Your renewal has been declined';
      case TransactionAction.Accept:
        return this.isReceiptPage
          ? 'Your renewal has been successfully completed'
          : '';
      default:
        return '';
    }
  }
}
