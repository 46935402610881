import { ViewStatus } from '@fishonline2023/shared/models';
import { AccountProfile, Menu } from '@fishonline2023/webapps/model/fd2023';
import { createReducer, on } from '@ngrx/store';
import {
  loadAccountProfile,
  loadAccountProfileFailure,
  loadAccountProfileSuccess,
  menuListUpdated,
} from './home-page.actions';
import {
  agentRoleSwitchedSuccessful,
  logoutSuccessful,
} from '@fishonline2023/webapps/user-portal/fd2023/store/user-profile';

export const HOME_PAGE_FEATURE_KEY = 'homePage';

export interface HomePageState {
  accountProfile?: AccountProfile;
  menuList: Menu[];
  viewStatus: ViewStatus;
  errorMessage?: string;
}

export const initialState: HomePageState = {
  menuList: [],
  viewStatus: ViewStatus.Initial,
};

export const homePageReducer = createReducer(
  initialState,
  on(loadAccountProfile, agentRoleSwitchedSuccessful, (state) => ({
    ...state,
    viewStatus: ViewStatus.Loading,
  })),
  on(loadAccountProfileSuccess, (state, { accountProfile }) => ({
    ...state,
    viewStatus: ViewStatus.Success,
    accountProfile,
    errorMessage: undefined,
  })),
  on(loadAccountProfileFailure, (state, { errorMessage }) => ({
    ...state,
    viewStatus: ViewStatus.Failure,
    accountProfile: undefined,
    errorMessage,
  })),
  on(menuListUpdated, (state, { menuList }) => ({
    ...state,
    menuList,
  })),
  on(logoutSuccessful, () => initialState)
);
