import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  Output,
} from '@angular/core';
import { CommonModule } from '@angular/common';
import { transformSearchResult } from '@fishonline2023/shared/utils';
import { TableSortingButtonComponent } from '@fishonline2023/webapps/shared/ui/base-components';
import {
  SearchMetadata,
  SearchResultField,
  SearchResultItem,
  SearchSortOrderChangeEvent,
} from '@fishonline2023/shared/models';
import { Observable } from 'rxjs';

@Component({
  selector: 'sv-ui-search-result-table',
  standalone: true,
  imports: [CommonModule, TableSortingButtonComponent],
  templateUrl: './search-result-table.component.html',
  styleUrls: ['./search-result-table.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SearchResultTableComponent {
  @Input() public searchResults!: Array<SearchResultItem>;
  @Input() public searchMetadata!: SearchMetadata;
  @Input() public columnSorted$!: Observable<string | undefined>;
  @Input() public columnsToDisplay!: string[];
  @Output() public sortSearchResults =
    new EventEmitter<SearchSortOrderChangeEvent>();
  @Output() public presentSearchResultDetailView = new EventEmitter<{
    selectedSearchResult: SearchResultItem;
    selectedField: SearchResultField;
  }>();
  protected readonly transformSearchResult = transformSearchResult;

  protected get searchResultFields(): SearchResultField[] {
    if (this.searchMetadata.shouldAllowSearchResultColumnFilter) {
      return this.searchMetadata.searchResultTableFields.filter(({ label }) =>
        this.columnsToDisplay.includes(label)
      );
    }
    return this.searchMetadata.searchResultTableFields;
  }

  protected trackByResult(i: number, result: SearchResultItem) {
    return Object.keys(result).join('-');
  }
}
