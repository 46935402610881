import { inject, Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { filter, map, switchMap, take, withLatestFrom } from 'rxjs';
import {
  activityLogTriggered,
  getDataLoaderChannel,
} from '@fishonline2023/webapps/shared/feature/data-loader';
import {
  downloadTriggered,
  searchTriggered,
  selectSearchFilter,
} from '@fishonline2023/webapps/shared/feature/search-store';
import { SharedAnalyticsService } from '../services/shared-analytics.service';
import { Store } from '@ngrx/store';
import { complement, isNil } from '@fishonline2023/shared/ramda';

@Injectable()
export class SharedAnalyticsEffects {
  private actions$ = inject(Actions);
  private store = inject(Store);
  private analyticsService = inject(SharedAnalyticsService);

  dataLoaderActivityLogTriggered$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(activityLogTriggered),
        withLatestFrom(
          this.store
            .select(getDataLoaderChannel)
            .pipe(filter(complement(isNil)))
        ),
        switchMap(([{ activityLog }, channel]) =>
          this.analyticsService.recordDataLoaderActivityLogEvent(
            activityLog,
            channel
          )
        )
      ),
    { dispatch: false }
  );

  search$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(searchTriggered),
        this.analyticsService.withSearchTypeChannelAndFilterValues(),
        this.analyticsService.recordSearchActivityLogWithMethod(
          this.analyticsService.recordSearchActivityLogEvent
        )
      ),
    { dispatch: false }
  );

  downloadCSV$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(downloadTriggered),
        switchMap(() =>
          this.store
            .select(selectSearchFilter)
            .pipe(filter(complement(isNil)), take(1))
        ),
        map((searchFilter) => ({ searchFilter })),
        this.analyticsService.withSearchTypeChannelAndFilterValues(),
        this.analyticsService.recordSearchActivityLogWithMethod(
          this.analyticsService.recordDownloadSearchResultCSVActivityLogEvent
        )
      ),
    { dispatch: false }
  );
}
