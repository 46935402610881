<div
  class="mt-4 prawn-ballot-report-container"
  *ngIf="prawnBallotReport$ | async"
>
  <h2 class="mb-4 d-print-none">Print Prawn Ballot</h2>
  <div class="d-print-none row mb-4 d-flex">
    <div class="col-2 mt-1">
      <label class="col-form-label"> Share Class </label>
    </div>
    <div class="col-4 mt-1">
      <select
        [formControl]="shareClassSelect"
        class="form-select"
        aria-label="Share class select"
      >
        <option *ngFor="let shareClass of shareClassList">
          {{ shareClass }}
        </option>
      </select>
    </div>
    <div class="col-1 mt-1 print-button">
      <button type="button" class="btn btn-primary" (click)="onPrintClick()">
        Print
      </button>
    </div>
  </div>
  <div class="row">
    <h4 class="d-flex-inline col-9 mb-2">
      Prawn Draw Priority Report -
      {{ today | date : FDDateTimeAngularPipeFormat }}
    </h4>
  </div>
  <h6>{{ shareClassSelect.value }}</h6>
  <sv-ui-prawn-report-table
    *ngIf="prawnReport"
    [reportList]="prawnReport.regionBallotList"
  ></sv-ui-prawn-report-table>
  <h4 class="mt-3">Outside Access</h4>
  <p>
    This section of the report identifies endorsement holders who hold an
    estuary general prawning share class for a different region with special
    access rights to undertake estuary general prawning activities in estuaries
    (as identified by the notation) that belong to a different region. The
    facilitator must consider the eligibility of these outside access
    endorsement holders for inclusion when running the report for the estuary of
    interest in addition to any priority held for the primary region.
  </p>
  <sv-ui-prawn-report-table
    *ngIf="prawnReport"
    [reportList]="prawnReport.outsideAccessBallotList"
  ></sv-ui-prawn-report-table>
</div>
