<ng-container [ngSwitch]="transactionType">
  <sv-ui-amend-customer-receipt
    *ngSwitchCase="TransactionType.AmendCustomer"
    [transactionData]="transactionData!"
    [userProfile]="userProfile"
  ></sv-ui-amend-customer-receipt>
  <sv-ui-nominate-revoke-fisher-receipt
    *ngSwitchCase="TransactionType.NominateRevokeFisher"
    [transactionData]="transactionData!"
  ></sv-ui-nominate-revoke-fisher-receipt>
  <sv-ui-renew-licence-receipt
    *ngSwitchCase="TransactionType.RenewLicence"
    [transactionData]="transactionData!"
  ></sv-ui-renew-licence-receipt>
  <sv-ui-transfer-quota-receipt
    *ngSwitchCase="TransactionType.TransferQuota"
    [transactionData]="transactionData!"
  ></sv-ui-transfer-quota-receipt>
  <sv-ui-transfer-fishing-business-components-receipt
    *ngSwitchCase="TransactionType.TransferComponents"
    [transactionData]="transactionData!"
  ></sv-ui-transfer-fishing-business-components-receipt>
  <sv-ui-transfer-fishing-business-components-receipt
    *ngSwitchCase="TransactionType.TransferFishingBusiness"
    [transactionData]="transactionData!"
  ></sv-ui-transfer-fishing-business-components-receipt>
</ng-container>
