import { DataLoaderType, ViewStatus } from '@fishonline2023/shared/models';
import { createReducer, on } from '@ngrx/store';
import {
  initDataLoader,
  loadDataLoaderData,
  loadDataLoaderDataFailure,
  loadDataLoaderDataSuccess,
} from './data-loader.actions';
import { FDDataLoaderData } from '@fishonline2023/webapps/model/fd2023';
import { FADataLoaderData } from '@fishonline2023/webapps/model/fa2023';
import { Channel } from '@fishonline2023/webapps/shared/feature/appsync';
import { logoutSuccessful } from '@fishonline2023/webapps/user-portal/fd2023/store/user-profile';

export const DATA_LOADER_FEATURE_KEY = 'dataLoader';

export interface DataLoaderState {
  dataLoaderData: FDDataLoaderData | FADataLoaderData;
  channel?: Channel;
  dataLoaderType?: DataLoaderType;
  viewStatus: ViewStatus;
  errorMessage?: string;
}

export const initialState: DataLoaderState = {
  dataLoaderData: {},
  viewStatus: ViewStatus.Initial,
};

export const dataLoaderReducer = createReducer(
  initialState,
  on(initDataLoader, (state, { dataLoaderType, channel }) => ({
    ...state,
    dataLoaderType,
    channel,
    viewStatus: ViewStatus.Loading,
  })),
  on(loadDataLoaderData, (state) => ({
    ...state,
    viewStatus: ViewStatus.Loading,
  })),
  on(loadDataLoaderDataSuccess, (state, { dataLoaderData }) => ({
    ...state,
    viewStatus: ViewStatus.Success,
    dataLoaderData,
  })),
  on(loadDataLoaderDataFailure, (state, { errorMessage }) => ({
    ...state,
    viewStatus: ViewStatus.Failure,
    dataLoaderData: {},
    errorMessage,
  })),
  on(logoutSuccessful, () => initialState)
);
