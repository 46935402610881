import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { CommonModule } from '@angular/common';
import { InputField } from '@fishonline2023/shared/models';
import { FormControl, ReactiveFormsModule } from '@angular/forms';

@Component({
  selector: 'sv-ui-input-field-textarea',
  standalone: true,
  imports: [CommonModule, ReactiveFormsModule],
  templateUrl: './input-field-textarea.component.html',
  styleUrls: ['./input-field-textarea.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class InputFieldTextareaComponent {
  @Input() public inputField!: InputField;
  @Input() public form!: FormControl;
}
