/* tslint:disable */
/* eslint-disable */
//  This file was automatically generated and should not be edited.
import { Injectable } from '@angular/core';
import API, { graphqlOperation, GraphQLResult } from '@aws-amplify/api-graphql';
import { GRAPHQL_AUTH_MODE } from '@aws-amplify/api';
import { Observable } from 'zen-observable-ts';

export interface SubscriptionResponse<T> {
  value: GraphQLResult<T>;
}

export type __SubscriptionContainer = {
  onCreateActivityLog: OnCreateActivityLogSubscription;
  onUpdateActivityLog: OnUpdateActivityLogSubscription;
  onDeleteActivityLog: OnDeleteActivityLogSubscription;
};

export type CreateActivityLogInput = {
  id?: string | null;
  channel: Channel;
  userIPAddress?: string | null;
  userName?: string | null;
  userType?: UserType | null;
  userCustomerId?: string | null;
  personaCustomerId?: string | null;
  userActivityLogEventTypeID: EventType;
  transaction?: string | null;
  detail?: string | null;
};

export enum Channel {
  FD = 'FD',
  FA = 'FA',
  FM = 'FM',
  FI = 'FI',
}

export enum UserType {
  Customer = 'Customer',
  Agent = 'Agent',
  Department = 'Department',
}

export enum EventType {
  AmendCustomer = 'AmendCustomer',
  CreateCustomer = 'CreateCustomer',
  CustomerSearch = 'CustomerSearch',
  CustomerSearchDownload = 'CustomerSearchDownload',
  LoginFailed = 'LoginFailed',
  LoginSuccessful = 'LoginSuccessful',
  NominateRevokeFisher = 'NominateRevokeFisher',
  RenewCFL = 'RenewCFL',
  SearchShareExtract = 'SearchShareExtract',
  SwitchPersona = 'SwitchPersona',
  UpdateContact = 'UpdateContact',
  ViewAccountSummary = 'ViewAccountSummary',
  ViewContactDetails = 'ViewContactDetails',
  ViewCustomerDetails = 'ViewCustomerDetails',
  ViewFishingBusinessHistory = 'ViewFishingBusinessHistory',
  ViewForSaleAndTrade = 'ViewForSaleAndTrade',
  ViewLicenceDetails = 'ViewLicenceDetails',
  ViewPrawnBallot = 'ViewPrawnBallot',
  ViewQuotaHistory = 'ViewQuotaHistory',
  ViewMyReceipts = 'ViewMyReceipts',
  ViewShareExtractFishingBusiness = 'ViewShareExtractFishingBusiness',
  ViewShareExtractShareholder = 'ViewShareExtractShareholder',
  ViewMyPersonalAuthorisations = 'ViewMyPersonalAuthorisations',
  ViewMyFishingBusinessAuthorisations = 'ViewMyFishingBusinessAuthorisations',
  TransferComponents = 'TransferComponents',
  TransferFishingBusiness = 'TransferFishingBusiness',
  TransferQuota = 'TransferQuota',
  ManageAgents = 'ManageAgents',
}

export type ModelActivityLogConditionInput = {
  channel?: ModelChannelInput | null;
  userIPAddress?: ModelStringInput | null;
  userName?: ModelStringInput | null;
  userType?: ModelUserTypeInput | null;
  userCustomerId?: ModelStringInput | null;
  personaCustomerId?: ModelStringInput | null;
  userActivityLogEventTypeID?: ModelEventTypeInput | null;
  transaction?: ModelStringInput | null;
  detail?: ModelStringInput | null;
  and?: Array<ModelActivityLogConditionInput | null> | null;
  or?: Array<ModelActivityLogConditionInput | null> | null;
  not?: ModelActivityLogConditionInput | null;
};

export type ModelChannelInput = {
  eq?: Channel | null;
  ne?: Channel | null;
};

export type ModelStringInput = {
  ne?: string | null;
  eq?: string | null;
  le?: string | null;
  lt?: string | null;
  ge?: string | null;
  gt?: string | null;
  contains?: string | null;
  notContains?: string | null;
  between?: Array<string | null> | null;
  beginsWith?: string | null;
  attributeExists?: boolean | null;
  attributeType?: ModelAttributeTypes | null;
  size?: ModelSizeInput | null;
};

export enum ModelAttributeTypes {
  binary = 'binary',
  binarySet = 'binarySet',
  bool = 'bool',
  list = 'list',
  map = 'map',
  number = 'number',
  numberSet = 'numberSet',
  string = 'string',
  stringSet = 'stringSet',
  _null = '_null',
}

export type ModelSizeInput = {
  ne?: number | null;
  eq?: number | null;
  le?: number | null;
  lt?: number | null;
  ge?: number | null;
  gt?: number | null;
  between?: Array<number | null> | null;
};

export type ModelUserTypeInput = {
  eq?: UserType | null;
  ne?: UserType | null;
};

export type ModelEventTypeInput = {
  eq?: EventType | null;
  ne?: EventType | null;
};

export type ActivityLog = {
  __typename: 'ActivityLog';
  id: string;
  channel: Channel;
  userIPAddress?: string | null;
  userName?: string | null;
  userType?: UserType | null;
  userCustomerId?: string | null;
  personaCustomerId?: string | null;
  userActivityLogEventTypeID: EventType;
  transaction?: string | null;
  detail?: string | null;
  createdAt: string;
  updatedAt: string;
};

export type UpdateActivityLogInput = {
  id: string;
  channel?: Channel | null;
  userIPAddress?: string | null;
  userName?: string | null;
  userType?: UserType | null;
  userCustomerId?: string | null;
  personaCustomerId?: string | null;
  userActivityLogEventTypeID?: EventType | null;
  transaction?: string | null;
  detail?: string | null;
};

export type DeleteActivityLogInput = {
  id: string;
};

export type ModelActivityLogFilterInput = {
  id?: ModelIDInput | null;
  channel?: ModelChannelInput | null;
  userIPAddress?: ModelStringInput | null;
  userName?: ModelStringInput | null;
  userType?: ModelUserTypeInput | null;
  userCustomerId?: ModelStringInput | null;
  personaCustomerId?: ModelStringInput | null;
  userActivityLogEventTypeID?: ModelEventTypeInput | null;
  transaction?: ModelStringInput | null;
  detail?: ModelStringInput | null;
  and?: Array<ModelActivityLogFilterInput | null> | null;
  or?: Array<ModelActivityLogFilterInput | null> | null;
  not?: ModelActivityLogFilterInput | null;
};

export type ModelIDInput = {
  ne?: string | null;
  eq?: string | null;
  le?: string | null;
  lt?: string | null;
  ge?: string | null;
  gt?: string | null;
  contains?: string | null;
  notContains?: string | null;
  between?: Array<string | null> | null;
  beginsWith?: string | null;
  attributeExists?: boolean | null;
  attributeType?: ModelAttributeTypes | null;
  size?: ModelSizeInput | null;
};

export type ModelActivityLogConnection = {
  __typename: 'ModelActivityLogConnection';
  items: Array<ActivityLog | null>;
  nextToken?: string | null;
};

export type SearchableActivityLogFilterInput = {
  id?: SearchableIDFilterInput | null;
  userIPAddress?: SearchableStringFilterInput | null;
  userName?: SearchableStringFilterInput | null;
  userCustomerId?: SearchableStringFilterInput | null;
  personaCustomerId?: SearchableStringFilterInput | null;
  transaction?: SearchableStringFilterInput | null;
  detail?: SearchableStringFilterInput | null;
  createdAt?: SearchableStringFilterInput | null;
  updatedAt?: SearchableStringFilterInput | null;
  channel?: SearchableStringFilterInput | null;
  userType?: SearchableStringFilterInput | null;
  userActivityLogEventTypeID?: SearchableStringFilterInput | null;
  and?: Array<SearchableActivityLogFilterInput | null> | null;
  or?: Array<SearchableActivityLogFilterInput | null> | null;
  not?: SearchableActivityLogFilterInput | null;
};

export type SearchableIDFilterInput = {
  ne?: string | null;
  gt?: string | null;
  lt?: string | null;
  gte?: string | null;
  lte?: string | null;
  eq?: string | null;
  match?: string | null;
  matchPhrase?: string | null;
  matchPhrasePrefix?: string | null;
  multiMatch?: string | null;
  exists?: boolean | null;
  wildcard?: string | null;
  regexp?: string | null;
  range?: Array<string | null> | null;
};

export type SearchableStringFilterInput = {
  ne?: string | null;
  gt?: string | null;
  lt?: string | null;
  gte?: string | null;
  lte?: string | null;
  eq?: string | null;
  match?: string | null;
  matchPhrase?: string | null;
  matchPhrasePrefix?: string | null;
  multiMatch?: string | null;
  exists?: boolean | null;
  wildcard?: string | null;
  regexp?: string | null;
  range?: Array<string | null> | null;
};

export type SearchableActivityLogSortInput = {
  field?: SearchableActivityLogSortableFields | null;
  direction?: SearchableSortDirection | null;
};

export enum SearchableActivityLogSortableFields {
  id = 'id',
  userIPAddress = 'userIPAddress',
  userName = 'userName',
  userCustomerId = 'userCustomerId',
  personaCustomerId = 'personaCustomerId',
  transaction = 'transaction',
  detail = 'detail',
  createdAt = 'createdAt',
  updatedAt = 'updatedAt',
}

export enum SearchableSortDirection {
  asc = 'asc',
  desc = 'desc',
}

export type SearchableActivityLogAggregationInput = {
  name: string;
  type: SearchableAggregateType;
  field: SearchableActivityLogAggregateField;
};

export enum SearchableAggregateType {
  terms = 'terms',
  avg = 'avg',
  min = 'min',
  max = 'max',
  sum = 'sum',
}

export enum SearchableActivityLogAggregateField {
  id = 'id',
  channel = 'channel',
  userIPAddress = 'userIPAddress',
  userName = 'userName',
  userType = 'userType',
  userCustomerId = 'userCustomerId',
  personaCustomerId = 'personaCustomerId',
  userActivityLogEventTypeID = 'userActivityLogEventTypeID',
  transaction = 'transaction',
  detail = 'detail',
  createdAt = 'createdAt',
  updatedAt = 'updatedAt',
}

export type SearchableActivityLogConnection = {
  __typename: 'SearchableActivityLogConnection';
  items: Array<ActivityLog | null>;
  nextToken?: string | null;
  total?: number | null;
  aggregateItems: Array<SearchableAggregateResult | null>;
};

export type SearchableAggregateResult = {
  __typename: 'SearchableAggregateResult';
  name: string;
  result?: SearchableAggregateGenericResult | null;
};

export type SearchableAggregateGenericResult =
  | SearchableAggregateScalarResult
  | SearchableAggregateBucketResult;

export type SearchableAggregateScalarResult = {
  __typename: 'SearchableAggregateScalarResult';
  value: number;
};

export type SearchableAggregateBucketResult = {
  __typename: 'SearchableAggregateBucketResult';
  buckets?: Array<SearchableAggregateBucketResultItem | null> | null;
};

export type SearchableAggregateBucketResultItem = {
  __typename: 'SearchableAggregateBucketResultItem';
  key: string;
  doc_count: number;
};

export type ModelSubscriptionActivityLogFilterInput = {
  id?: ModelSubscriptionIDInput | null;
  channel?: ModelSubscriptionStringInput | null;
  userIPAddress?: ModelSubscriptionStringInput | null;
  userName?: ModelSubscriptionStringInput | null;
  userType?: ModelSubscriptionStringInput | null;
  userCustomerId?: ModelSubscriptionStringInput | null;
  personaCustomerId?: ModelSubscriptionStringInput | null;
  userActivityLogEventTypeID?: ModelSubscriptionStringInput | null;
  transaction?: ModelSubscriptionStringInput | null;
  detail?: ModelSubscriptionStringInput | null;
  and?: Array<ModelSubscriptionActivityLogFilterInput | null> | null;
  or?: Array<ModelSubscriptionActivityLogFilterInput | null> | null;
};

export type ModelSubscriptionIDInput = {
  ne?: string | null;
  eq?: string | null;
  le?: string | null;
  lt?: string | null;
  ge?: string | null;
  gt?: string | null;
  contains?: string | null;
  notContains?: string | null;
  between?: Array<string | null> | null;
  beginsWith?: string | null;
  in?: Array<string | null> | null;
  notIn?: Array<string | null> | null;
};

export type ModelSubscriptionStringInput = {
  ne?: string | null;
  eq?: string | null;
  le?: string | null;
  lt?: string | null;
  ge?: string | null;
  gt?: string | null;
  contains?: string | null;
  notContains?: string | null;
  between?: Array<string | null> | null;
  beginsWith?: string | null;
  in?: Array<string | null> | null;
  notIn?: Array<string | null> | null;
};

export type CreateActivityLogMutation = {
  __typename: 'ActivityLog';
  id: string;
  channel: Channel;
  userIPAddress?: string | null;
  userName?: string | null;
  userType?: UserType | null;
  userCustomerId?: string | null;
  personaCustomerId?: string | null;
  userActivityLogEventTypeID: EventType;
  transaction?: string | null;
  detail?: string | null;
  createdAt: string;
  updatedAt: string;
};

export type UpdateActivityLogMutation = {
  __typename: 'ActivityLog';
  id: string;
  channel: Channel;
  userIPAddress?: string | null;
  userName?: string | null;
  userType?: UserType | null;
  userCustomerId?: string | null;
  personaCustomerId?: string | null;
  userActivityLogEventTypeID: EventType;
  transaction?: string | null;
  detail?: string | null;
  createdAt: string;
  updatedAt: string;
};

export type DeleteActivityLogMutation = {
  __typename: 'ActivityLog';
  id: string;
  channel: Channel;
  userIPAddress?: string | null;
  userName?: string | null;
  userType?: UserType | null;
  userCustomerId?: string | null;
  personaCustomerId?: string | null;
  userActivityLogEventTypeID: EventType;
  transaction?: string | null;
  detail?: string | null;
  createdAt: string;
  updatedAt: string;
};

export type GetActivityLogQuery = {
  __typename: 'ActivityLog';
  id: string;
  channel: Channel;
  userIPAddress?: string | null;
  userName?: string | null;
  userType?: UserType | null;
  userCustomerId?: string | null;
  personaCustomerId?: string | null;
  userActivityLogEventTypeID: EventType;
  transaction?: string | null;
  detail?: string | null;
  createdAt: string;
  updatedAt: string;
};

export type ListActivityLogsQuery = {
  __typename: 'ModelActivityLogConnection';
  items: Array<{
    __typename: 'ActivityLog';
    id: string;
    channel: Channel;
    userIPAddress?: string | null;
    userName?: string | null;
    userType?: UserType | null;
    userCustomerId?: string | null;
    personaCustomerId?: string | null;
    userActivityLogEventTypeID: EventType;
    transaction?: string | null;
    detail?: string | null;
    createdAt: string;
    updatedAt: string;
  } | null>;
  nextToken?: string | null;
};

export type SearchActivityLogsQuery = {
  __typename: 'SearchableActivityLogConnection';
  items: Array<{
    __typename: 'ActivityLog';
    id: string;
    channel: Channel;
    userIPAddress?: string | null;
    userName?: string | null;
    userType?: UserType | null;
    userCustomerId?: string | null;
    personaCustomerId?: string | null;
    userActivityLogEventTypeID: EventType;
    transaction?: string | null;
    detail?: string | null;
    createdAt: string;
    updatedAt: string;
  } | null>;
  nextToken?: string | null;
  total?: number | null;
  aggregateItems: Array<{
    __typename: 'SearchableAggregateResult';
    name: string;
    result:
      | (
          | {
              __typename: 'SearchableAggregateScalarResult';
              value: number;
            }
          | {
              __typename: 'SearchableAggregateBucketResult';
              buckets?: Array<{
                __typename: string;
                key: string;
                doc_count: number;
              } | null> | null;
            }
        )
      | null;
  } | null>;
};

export type OnCreateActivityLogSubscription = {
  __typename: 'ActivityLog';
  id: string;
  channel: Channel;
  userIPAddress?: string | null;
  userName?: string | null;
  userType?: UserType | null;
  userCustomerId?: string | null;
  personaCustomerId?: string | null;
  userActivityLogEventTypeID: EventType;
  transaction?: string | null;
  detail?: string | null;
  createdAt: string;
  updatedAt: string;
};

export type OnUpdateActivityLogSubscription = {
  __typename: 'ActivityLog';
  id: string;
  channel: Channel;
  userIPAddress?: string | null;
  userName?: string | null;
  userType?: UserType | null;
  userCustomerId?: string | null;
  personaCustomerId?: string | null;
  userActivityLogEventTypeID: EventType;
  transaction?: string | null;
  detail?: string | null;
  createdAt: string;
  updatedAt: string;
};

export type OnDeleteActivityLogSubscription = {
  __typename: 'ActivityLog';
  id: string;
  channel: Channel;
  userIPAddress?: string | null;
  userName?: string | null;
  userType?: UserType | null;
  userCustomerId?: string | null;
  personaCustomerId?: string | null;
  userActivityLogEventTypeID: EventType;
  transaction?: string | null;
  detail?: string | null;
  createdAt: string;
  updatedAt: string;
};

@Injectable({
  providedIn: 'root',
})
export class APIService {
  async CreateActivityLog(
    input: CreateActivityLogInput,
    condition?: ModelActivityLogConditionInput
  ): Promise<CreateActivityLogMutation> {
    const statement = `mutation CreateActivityLog($input: CreateActivityLogInput!, $condition: ModelActivityLogConditionInput) {
        createActivityLog(input: $input, condition: $condition) {
          __typename
          id
          channel
          userIPAddress
          userName
          userType
          userCustomerId
          personaCustomerId
          userActivityLogEventTypeID
          transaction
          detail
          createdAt
          updatedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {
      input,
    };
    if (condition) {
      gqlAPIServiceArguments.condition = condition;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <CreateActivityLogMutation>response.data.createActivityLog;
  }
  async UpdateActivityLog(
    input: UpdateActivityLogInput,
    condition?: ModelActivityLogConditionInput
  ): Promise<UpdateActivityLogMutation> {
    const statement = `mutation UpdateActivityLog($input: UpdateActivityLogInput!, $condition: ModelActivityLogConditionInput) {
        updateActivityLog(input: $input, condition: $condition) {
          __typename
          id
          channel
          userIPAddress
          userName
          userType
          userCustomerId
          personaCustomerId
          userActivityLogEventTypeID
          transaction
          detail
          createdAt
          updatedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {
      input,
    };
    if (condition) {
      gqlAPIServiceArguments.condition = condition;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <UpdateActivityLogMutation>response.data.updateActivityLog;
  }
  async DeleteActivityLog(
    input: DeleteActivityLogInput,
    condition?: ModelActivityLogConditionInput
  ): Promise<DeleteActivityLogMutation> {
    const statement = `mutation DeleteActivityLog($input: DeleteActivityLogInput!, $condition: ModelActivityLogConditionInput) {
        deleteActivityLog(input: $input, condition: $condition) {
          __typename
          id
          channel
          userIPAddress
          userName
          userType
          userCustomerId
          personaCustomerId
          userActivityLogEventTypeID
          transaction
          detail
          createdAt
          updatedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {
      input,
    };
    if (condition) {
      gqlAPIServiceArguments.condition = condition;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <DeleteActivityLogMutation>response.data.deleteActivityLog;
  }
  async GetActivityLog(id: string): Promise<GetActivityLogQuery> {
    const statement = `query GetActivityLog($id: ID!) {
        getActivityLog(id: $id) {
          __typename
          id
          channel
          userIPAddress
          userName
          userType
          userCustomerId
          personaCustomerId
          userActivityLogEventTypeID
          transaction
          detail
          createdAt
          updatedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {
      id,
    };
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <GetActivityLogQuery>response.data.getActivityLog;
  }
  async ListActivityLogs(
    filter?: ModelActivityLogFilterInput,
    limit?: number,
    nextToken?: string
  ): Promise<ListActivityLogsQuery> {
    const statement = `query ListActivityLogs($filter: ModelActivityLogFilterInput, $limit: Int, $nextToken: String) {
        listActivityLogs(filter: $filter, limit: $limit, nextToken: $nextToken) {
          __typename
          items {
            __typename
            id
            channel
            userIPAddress
            userName
            userType
            userCustomerId
            personaCustomerId
            userActivityLogEventTypeID
            transaction
            detail
            createdAt
            updatedAt
          }
          nextToken
        }
      }`;
    const gqlAPIServiceArguments: any = {};
    if (filter) {
      gqlAPIServiceArguments.filter = filter;
    }
    if (limit) {
      gqlAPIServiceArguments.limit = limit;
    }
    if (nextToken) {
      gqlAPIServiceArguments.nextToken = nextToken;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <ListActivityLogsQuery>response.data.listActivityLogs;
  }
  async SearchActivityLogs(
    filter?: SearchableActivityLogFilterInput,
    sort?: Array<SearchableActivityLogSortInput | null>,

    // !! keep it unchanged after codegen !!
    authMode?: GRAPHQL_AUTH_MODE,
    authToken?: string,

    limit?: number,
    nextToken?: string,
    from?: number,
    aggregates?: Array<SearchableActivityLogAggregationInput | null>
  ): Promise<SearchActivityLogsQuery> {
    const statement = `query SearchActivityLogs($filter: SearchableActivityLogFilterInput, $sort: [SearchableActivityLogSortInput], $limit: Int, $nextToken: String, $from: Int, $aggregates: [SearchableActivityLogAggregationInput]) {
        searchActivityLogs(
          filter: $filter
          sort: $sort
          limit: $limit
          nextToken: $nextToken
          from: $from
          aggregates: $aggregates
        ) {
          __typename
          items {
            __typename
            id
            channel
            userIPAddress
            userName
            userType
            userCustomerId
            personaCustomerId
            userActivityLogEventTypeID
            transaction
            detail
            createdAt
            updatedAt
          }
          nextToken
          total
          aggregateItems {
            __typename
            name
            result {
              __typename
              ... on SearchableAggregateScalarResult {
                value
              }
              ... on SearchableAggregateBucketResult {
                buckets {
                  __typename
                  key
                  doc_count
                }
              }
            }
          }
        }
      }`;
    const gqlAPIServiceArguments: any = {};
    if (filter) {
      gqlAPIServiceArguments.filter = filter;
    }
    if (sort) {
      gqlAPIServiceArguments.sort = sort;
    }
    if (limit) {
      gqlAPIServiceArguments.limit = limit;
    }
    if (nextToken) {
      gqlAPIServiceArguments.nextToken = nextToken;
    }
    if (from) {
      gqlAPIServiceArguments.from = from;
    }
    if (aggregates) {
      gqlAPIServiceArguments.aggregates = aggregates;
    }
    const response = (await API.graphql({
      ...graphqlOperation(statement, gqlAPIServiceArguments),
      // !! keep it unchanged after codegen !!
      authToken,
      authMode,
    })) as any;
    return <SearchActivityLogsQuery>response.data.searchActivityLogs;
  }
  OnCreateActivityLogListener(
    filter?: ModelSubscriptionActivityLogFilterInput
  ): Observable<
    SubscriptionResponse<Pick<__SubscriptionContainer, 'onCreateActivityLog'>>
  > {
    const statement = `subscription OnCreateActivityLog($filter: ModelSubscriptionActivityLogFilterInput) {
        onCreateActivityLog(filter: $filter) {
          __typename
          id
          channel
          userIPAddress
          userName
          userType
          userCustomerId
          personaCustomerId
          userActivityLogEventTypeID
          transaction
          detail
          createdAt
          updatedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {};
    if (filter) {
      gqlAPIServiceArguments.filter = filter;
    }
    return API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    ) as Observable<
      SubscriptionResponse<Pick<__SubscriptionContainer, 'onCreateActivityLog'>>
    >;
  }

  OnUpdateActivityLogListener(
    filter?: ModelSubscriptionActivityLogFilterInput
  ): Observable<
    SubscriptionResponse<Pick<__SubscriptionContainer, 'onUpdateActivityLog'>>
  > {
    const statement = `subscription OnUpdateActivityLog($filter: ModelSubscriptionActivityLogFilterInput) {
        onUpdateActivityLog(filter: $filter) {
          __typename
          id
          channel
          userIPAddress
          userName
          userType
          userCustomerId
          personaCustomerId
          userActivityLogEventTypeID
          transaction
          detail
          createdAt
          updatedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {};
    if (filter) {
      gqlAPIServiceArguments.filter = filter;
    }
    return API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    ) as Observable<
      SubscriptionResponse<Pick<__SubscriptionContainer, 'onUpdateActivityLog'>>
    >;
  }

  OnDeleteActivityLogListener(
    filter?: ModelSubscriptionActivityLogFilterInput
  ): Observable<
    SubscriptionResponse<Pick<__SubscriptionContainer, 'onDeleteActivityLog'>>
  > {
    const statement = `subscription OnDeleteActivityLog($filter: ModelSubscriptionActivityLogFilterInput) {
        onDeleteActivityLog(filter: $filter) {
          __typename
          id
          channel
          userIPAddress
          userName
          userType
          userCustomerId
          personaCustomerId
          userActivityLogEventTypeID
          transaction
          detail
          createdAt
          updatedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {};
    if (filter) {
      gqlAPIServiceArguments.filter = filter;
    }
    return API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    ) as Observable<
      SubscriptionResponse<Pick<__SubscriptionContainer, 'onDeleteActivityLog'>>
    >;
  }
}
