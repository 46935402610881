import { SystemControl } from './system-control.enum';

export interface SystemStatus {
  [SystemControl.AF_INQUIRIES]?: boolean;
  [SystemControl.AUTHORISE_REVOKE_FISHER]?: boolean;
  [SystemControl.BULLETIN_BOARD]?: boolean;
  [SystemControl.CATCH_HISTORY_INQUIRIES]?: boolean;
  [SystemControl.CUSTOMER_DETAILS]?: boolean;
  [SystemControl.FB_COMPONENT_TRANSFER]?: boolean;
  [SystemControl.FB_INQUIRIES]?: boolean;
  [SystemControl.FINANCE_DETAIL]?: boolean;
  [SystemControl.LICENCE_DETAILS]?: boolean;
  [SystemControl.MESSAGING]?: boolean;
  [SystemControl.PRINT_SHARE_EXTRACT]?: boolean;
  [SystemControl.QUOTA_INQUIRIES]?: boolean;
  [SystemControl.QUOTA_LOGSHEETS]?: boolean;
  [SystemControl.QUOTA_TRANSFER]?: boolean;
  [SystemControl.RENEW_CFL]?: boolean;
  [SystemControl.RENEW_FBL]?: boolean;
  [SystemControl.REPLACE_FB_CARD]?: boolean;
  [SystemControl.REPLACE_LICENSE_CARD]?: boolean;
  [SystemControl.REPORTS]?: boolean;
  [SystemControl.SYSTEM_ENABLE]?: boolean;
  [SystemControl.TAGS_TRANSACTIONS]?: boolean;
}
