<sv-ui-search-detail-wrapper
  *ngIf="isNewAd; else editAdTemplate"
  [showSubmitButton]="
    isNewAd && agreeTermsAndConditions.valid && saleAndTradeEditForm.valid
  "
  [submitSubject]="submitSubject"
>
  <sv-ui-loading
    *ngIf="saleAndTradeViewStatus === ViewStatus.Loading"
    [message]="message"
  ></sv-ui-loading>
  <ng-container [ngTemplateOutlet]="editAdTemplate"></ng-container>
</sv-ui-search-detail-wrapper>
<ng-template #editAdTemplate>
  <div class="mt-4 ms-3">
    <h4 *ngIf="isNewAd">Post an Advertisement</h4>
    <h4 *ngIf="!isNewAd">View/Edit an Advertisement</h4>
    <form [formGroup]="saleAndTradeEditForm" class="mt-4">
      <div class="row">
        <div class="col-md-6 col-sm-12">
          <label class="col-form-label">Item</label>
          <span class="text-danger"> *</span>
        </div>
      </div>
      <div class="row">
        <div class="col-md-6 col-sm-12">
          <input
            aria-label="Title"
            class="form-control form-control-lg mb-1"
            formControlName="title"
            type="text"
          />
          <small
            *ngIf="
              saleAndTradeEditForm.controls.title?.touched &&
              saleAndTradeEditForm.controls.title?.hasError('required')
            "
            class="text-danger"
            >This field is required</small
          >
          <small
            *ngIf="
              saleAndTradeEditForm.controls.title?.touched &&
              saleAndTradeEditForm.controls.title?.hasError('maxlength')
            "
            class="text-danger"
            >Maximum 200 characters allowed</small
          >
        </div>
      </div>
      <div class="row">
        <div class="col-md-6 col-sm-12">
          <label class="col-form-label">Purpose</label>
          <span class="text-danger"> *</span>
        </div>
      </div>
      <div class="row">
        <div class="col-md-6 col-sm-12">
          <div
            *ngFor="let purposeOption of purposeList"
            class="form-check form-check-inline form-control-lg"
          >
            <input
              [value]="purposeOption"
              aria-label="Purpose"
              class="form-check-input radio-button mb-1"
              formControlName="purpose"
              type="radio"
            />
            <label class="form-check-label">{{
              purposeOption | enumToString
            }}</label>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-md-6 col-sm-12">
          <label class="col-form-label">Type</label>
          <span class="text-danger"> *</span>
        </div>
      </div>
      <div class="row">
        <div class="col-md-6 col-sm-12">
          <select
            aria-label="Type"
            class="form-select form-select-lg mb-3"
            formControlName="type"
          >
            <option [ngValue]="null" disabled>Select type from list</option>
            <option *ngFor="let type of typeList" [ngValue]="type.id">
              {{ type.label }}
            </option>
          </select>
        </div>
      </div>
      <div class="row">
        <div class="col-md-6 col-sm-12">
          <sv-ui-input-field
            [form]="saleAndTradeEditForm.controls.price"
            [inputField]="adPriceInputField"
          ></sv-ui-input-field>
        </div>
      </div>
      <div class="row">
        <div class="col-md-6 col-sm-12">
          <label class="col-form-label">Details</label>
        </div>
      </div>
      <div class="row">
        <div class="col-md-6 col-sm-12">
          <textarea
            aria-label="Details"
            class="form-control form-control-lg mb-1"
            formControlName="description"
            type="text"
          ></textarea>
        </div>
      </div>
      <div class="row">
        <div class="col-md-6 col-sm-12">
          <label class="col-form-label">Contact Name (primary)</label>
          <span class="text-danger"> *</span>
        </div>
      </div>
      <div class="row">
        <div class="col-md-6 col-sm-12">
          <input
            aria-label="Primary contact name"
            class="form-control form-control-lg mb-1"
            formControlName="contactNamePrimary"
            type="text"
          />
          <small
            *ngIf="
              saleAndTradeEditForm.controls.contactNamePrimary?.touched &&
              saleAndTradeEditForm.controls.contactNamePrimary?.invalid
            "
            class="text-danger"
            >This field is required</small
          >
        </div>
      </div>
      <div class="row">
        <div class="col-md-6 col-sm-12">
          <label class="col-form-label">Contact Number (primary)</label>
          <span class="text-danger"> *</span>
        </div>
      </div>
      <div class="row">
        <div class="col-md-6 col-sm-12">
          <input
            aria-label="Primary contact number"
            class="form-control form-control-lg mb-1"
            formControlName="contactNumberPrimary"
            type="text"
          />
          <small
            *ngIf="
              saleAndTradeEditForm.controls.contactNumberPrimary?.touched &&
              saleAndTradeEditForm.controls.contactNumberPrimary?.invalid
            "
            class="text-danger"
            >This field is required</small
          >
        </div>
      </div>
      <div class="row">
        <div class="col-md-6 col-sm-12">
          <label class="col-form-label">Contact Name (secondary)</label>
        </div>
      </div>
      <div class="row">
        <div class="col-md-6 col-sm-12">
          <input
            aria-label="Secondary contact name"
            class="form-control form-control-lg mb-1"
            formControlName="contactNameSecondary"
            type="text"
          />
        </div>
      </div>
      <div class="row">
        <div class="col-md-6 col-sm-12">
          <label class="col-form-label">Contact Number (secondary)</label>
        </div>
      </div>
      <div class="row">
        <div class="col-md-6 col-sm-12">
          <input
            aria-label="Secondary contact number"
            class="form-control form-control-lg mb-1"
            formControlName="contactNumberSecondary"
            type="text"
          />
        </div>
      </div>
      <div class="row">
        <div class="col-md-6 col-sm-12">
          <label class="col-form-label">Expiry Date</label>
          <span class="text-danger"> *</span>
        </div>
      </div>
      <div class="row">
        <div class="col-md-6 col-sm-12">
          <input
            aria-label="Expiry date"
            class="form-control form-control-lg mb-1"
            formControlName="expiryDate"
            type="date"
          />
          <small
            *ngIf="
              saleAndTradeEditForm.controls.expiryDate?.touched &&
              saleAndTradeEditForm.controls.expiryDate?.hasError('required')
            "
            class="text-danger"
            >This field is required</small
          >
          <small
            *ngIf="
              saleAndTradeEditForm.controls.expiryDate?.touched &&
              saleAndTradeEditForm.controls.expiryDate?.hasError('invalidDate')
            "
            class="text-danger"
            >Should be less than 60 days in future and should not be a past or
            current date</small
          >
        </div>
      </div>
      <div class="row mt-3 mb-4">
        <div class="col-md-6 col-sm-12">
          <div class="form-check">
            <input
              [formControl]="agreeTermsAndConditions"
              aria-label="Agree terms and conditions"
              class="form-check-input"
              type="checkbox"
            />
            <label class="form-check-label ms-3"
              >I have read, understood and accept the
              <a [routerLink]="'/' + FDRoute.TermsConditions" target="_blank"
                >terms and conditions</a
              >
              of use for the Department's notice board and assure full
              responsibility for the content provided in this
              advertisement</label
            >
          </div>
        </div>
      </div>
    </form>
  </div>
</ng-template>
