import { inject, Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { SearchService } from './search.service';
import {
  catchError,
  filter,
  map,
  of,
  switchMap,
  take,
  withLatestFrom,
} from 'rxjs';
import { Message } from '@fishonline2023/webapps/model/fd2023';
import {
  downloadFailed,
  downloadSuccessful,
  downloadTriggered,
  searchFailed,
  searchFieldValuesLoadFailed,
  searchFieldValuesLoadSuccessful,
  searchMetadataSet,
  searchReset,
  searchSuccessful,
  searchTriggered,
} from './search.actions';
import { ROUTER_REQUEST } from '@ngrx/router-store';
import { RouterRequestPayload } from '@ngrx/router-store/src/actions';
import {
  complement,
  isNil,
  isNilOrEmpty,
  path,
} from '@fishonline2023/shared/ramda';
import {
  selectSearchAppRoute,
  selectSearchFieldValues,
  selectSearchFilter,
} from './search.selectors';
import { Store } from '@ngrx/store';

@Injectable()
export class SearchEffects {
  private actions$ = inject(Actions);
  private store = inject(Store);

  public resetSearchStoreWhenLeaveContext$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ROUTER_REQUEST),
      map<RouterRequestPayload, string | undefined>(
        path(['payload', 'event', 'url'])
      ),
      withLatestFrom(
        this.store.select(selectSearchAppRoute).pipe(filter(complement(isNil)))
      ),
      filter(([url, appRoute]) => !url?.includes(appRoute)),
      map(() => searchReset())
    )
  );

  private searchService = inject(SearchService);

  public search$ = createEffect(() =>
    this.actions$.pipe(
      ofType(searchTriggered),
      switchMap(({ searchFilter }) =>
        this.searchService.search(searchFilter).pipe(
          map((searchResult) => searchSuccessful({ searchResult })),
          catchError(() =>
            of(
              searchFailed({
                errorMessage: Message.TryAgainLater,
              })
            )
          )
        )
      )
    )
  );

  public download$ = createEffect(() =>
    this.actions$.pipe(
      ofType(downloadTriggered),
      withLatestFrom(
        this.store.select(selectSearchFilter).pipe(filter(complement(isNil)))
      ),
      switchMap(([{ downloadCSVName }, searchFilter]) =>
        this.searchService.download(searchFilter, downloadCSVName).pipe(
          map(() => downloadSuccessful()),
          catchError(() =>
            of(
              downloadFailed({
                errorMessage: Message.TryAgainLater,
              })
            )
          )
        )
      )
    )
  );

  public searchFieldValues$ = createEffect(() =>
    this.actions$.pipe(
      ofType(searchMetadataSet),
      switchMap(() => this.store.select(selectSearchFieldValues).pipe(take(1))),
      filter(isNilOrEmpty),
      switchMap(() =>
        this.searchService.searchFieldValues$().pipe(
          map((searchFieldValues) =>
            searchFieldValuesLoadSuccessful({
              searchFieldValues,
            })
          ),
          catchError((err) => {
            console.error(err);
            return of(
              searchFieldValuesLoadFailed({
                errorMessage: Message.TryAgainLater,
              })
            );
          })
        )
      )
    )
  );
}
