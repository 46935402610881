<div *ngIf="transactionData">
  <div class="d-flex align-items-center mb-3">
    <i class="material-icons me-2 text-primary fs-2">info</i>

    <h4 class="m-0">
      <strong *ngIf="!isAcceptOffer"
        >You're transferring
        {{
          totalComponentsTransferByProperty('quantity') > 0
            ? 'component(s)'
            : 'no component'
        }}
        and
        {{
          totalQuotaTransferByProperty('quantity') > 0 ? 'quota' : 'no quota'
        }}
      </strong>
      <strong *ngIf="isAcceptOffer">Your offer</strong>
    </h4>
  </div>
  <ng-container [ngSwitch]="transactionData.transactionHeader.type">
    <sv-ui-two-party-transfer-components-selection
      *ngSwitchCase="TransactionType.TransferComponents"
      [isReadOnly]="true"
      [selectionFormGroup]="twoPartyTransferComponentsSelectionForm"
    >
    </sv-ui-two-party-transfer-components-selection>
    <sv-ui-two-party-transfer-fishing-business-selection
      *ngSwitchCase="TransactionType.TransferFishingBusiness"
      [isReadOnly]="true"
      [selectionFormGroup]="twoPartyTransferFishingBusinessSelectionForm"
    >
    </sv-ui-two-party-transfer-fishing-business-selection>
  </ng-container>
  <div class="mb-3 components-container">
    <h6>Components</h6>
    <div *ngIf="hasComponents(); else noComponent">
      <sv-ui-transfer-fishing-business-components-transfer-detail
        *ngFor="let component of components()"
        [transferDetail]="component"
      ></sv-ui-transfer-fishing-business-components-transfer-detail>
    </div>
    <ng-template #noComponent>
      <strong> No component is being transferred</strong>
    </ng-template>
  </div>
  <div class="mb-3 quota-container">
    <h6>Quota</h6>
    <ng-container *ngIf="hasQuotas(); else noQuota">
      <sv-ui-transfer-fishing-business-components-transfer-detail
        *ngFor="let quota of quotas()"
        [transactionReference]="transactionReference"
        [transferDetail]="quota"
        [withUnit]="true"
      ></sv-ui-transfer-fishing-business-components-transfer-detail>
    </ng-container>
    <ng-template #noQuota>
      <strong>No quota is being transferred</strong>
    </ng-template>
  </div>
  <p class="fs-5">
    <strong
      >Agreed Transfer Value
      {{
        totalQuotaTransferByProperty('totalSellPrice') +
          totalComponentsTransferByProperty('totalSellPrice') | currency
      }}</strong
    >
  </p>

  <ng-container *ngIf="isAcceptOffer">
    <p class="fs-3">
      <strong>Transaction Fee {{ transferFee | currency }}</strong>
    </p>
    <em>
      The Transaction fee is the fee that must be paid in order to complete this
      transaction. If the Transaction fee is greater than $0.00 you will be
      taken through the payment options on Confirmation.
    </em>
  </ng-container>
</div>
