import {
  SubmitActionFromButtonType,
  TransactionButtonAction,
  TransactionButtonType,
  TransactionType,
} from '@fishonline2023/webapps/model/fa2023';
import { EventType } from '@fishonline2023/webapps/shared/feature/appsync';

export const TransactionEventType: Record<TransactionType, EventType> = {
  [TransactionType.AmendCustomer]: EventType.AmendCustomer,
  [TransactionType.CreateCustomer]: EventType.CreateCustomer,
};

export const TransactionTypeReadable: Record<TransactionType, string> = {
  [TransactionType.AmendCustomer]: 'Amend Customer',
  [TransactionType.CreateCustomer]: 'Create Customer',
};

export const SubmitButtonTypeButtonActionMap: Record<
  SubmitActionFromButtonType,
  TransactionButtonAction
> = {
  [TransactionButtonType.Save]: TransactionButtonAction.Save,
  [TransactionButtonType.ApproveAndSubmit]: TransactionButtonAction.Approve,
  [TransactionButtonType.NotApproved]: TransactionButtonAction.NotApprove,
  [TransactionButtonType.Delete]: TransactionButtonAction.Delete,
};
