import { Route } from '@angular/router';
import { provideEffects } from '@ngrx/effects';
import { provideState } from '@ngrx/store';
import { SystemStatusEffects } from './system-status/store/system-status.effects';
import {
  SYSTEM_STATUS_FEATURE_KEY,
  systemStatusReducer,
} from './system-status/store/system-status.reducers';
import { SystemStatusComponent } from './system-status/system-status.component';

export const systemStatusRoutes: Route[] = [
  {
    path: '',
    providers: [
      provideState(SYSTEM_STATUS_FEATURE_KEY, systemStatusReducer),
      provideEffects(SystemStatusEffects),
    ],
    component: SystemStatusComponent,
  },
];
