import { CommonModule } from '@angular/common';
import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  inject,
  Input,
  Output,
} from '@angular/core';
import { AgentCustomer, ModalId } from '@fishonline2023/shared/models';
import { ButtonComponent } from '../button/button.component';
import { ModalService } from '../modal/modal.service';
import { ModalComponent } from '../modal/modal.component';
import { equals } from '@fishonline2023/shared/ramda';
import { ConfirmationModalComponent } from '../confirmation-modal/confirmation-modal.component';

@Component({
  selector: 'sv-ui-agent-customers-list',
  standalone: true,
  imports: [
    CommonModule,
    ButtonComponent,
    ModalComponent,
    ConfirmationModalComponent,
  ],
  templateUrl: './agent-customers-list.component.html',
  styleUrls: ['./agent-customers-list.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AgentCustomersListComponent {
  public agentCustomerToSwitch?: AgentCustomer;
  @Input() selectedAgent: AgentCustomer | undefined;
  @Input() agentCustomersList: AgentCustomer[] = [];
  @Output() switchAgentForCustomer: EventEmitter<number> = new EventEmitter();
  protected confirmationMessage = '';
  private modalService = inject(ModalService);
  private selectedCustomerId?: number;

  public switchAgentCustomer(selectedCustomerId: number): void {
    this.selectedCustomerId = selectedCustomerId;
    this.agentCustomerToSwitch = this.agentCustomersList.find(({ id }) =>
      equals(selectedCustomerId, id)
    );
    this.confirmationMessage = `Are you sure you want to switch account to act for
    ${this.agentCustomerToSwitch?.fullName}?`;
    this.modalService.open(ModalId.ConfirmationModal);
  }

  public onSwitchAgentForCustomerConfirm(): void {
    this.switchAgentForCustomer.emit(this.selectedCustomerId);
  }
}
