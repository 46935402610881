import { TransactionType } from './transaction.model';
import { FARoute } from '@fishonline2023/shared/models';

export interface TransactionMetadata {
  appRoute: FARoute;
  transactionTitle: string;
  apiBaseRoute: string;
  entityBaseRoute?: string;
  entityCreationAPIRoute?: string;
  entityType?: EntityType;
}

export enum EntityType {
  Customer = 'Registration Number',
  FishingBusiness = 'Fishing Business',
}

export const TransactionMetadata: Record<TransactionType, TransactionMetadata> =
  {
    [TransactionType.AmendCustomer]: {
      appRoute: FARoute.AmendCustomer,
      transactionTitle: 'Amend Customer',
      apiBaseRoute: 'AMEND_CUSTOMER',
      entityBaseRoute: 'customers',
      entityType: EntityType.Customer,
    },
    [TransactionType.CreateCustomer]: {
      appRoute: FARoute.CreateCustomer,
      transactionTitle: 'Create Customer',
      apiBaseRoute: 'CREATE_CUSTOMER',
      entityCreationAPIRoute: 'CREATE_CUSTOMER/new',
    },
  };
