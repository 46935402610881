import { Route } from '@angular/router';
import { SearchComponent } from './search/search.component';
import { provideState } from '@ngrx/store';
import { provideEffects } from '@ngrx/effects';
import {
  SEARCH_FEATURE_KEY,
  SearchEffects,
  searchReducer,
  SearchService,
} from '@fishonline2023/webapps/shared/feature/search-store';
import {
  SearchDetailLoaderRouteList,
  SearchDetailLoaderRoutePath,
} from '@fishonline2023/shared/models';
import { searchDetailGuard } from './route.guard';

export const searchRoutes: Route[] = [
  {
    path: '',
    component: SearchComponent,
    providers: [
      provideState(SEARCH_FEATURE_KEY, searchReducer),
      provideEffects(SearchEffects),
      SearchService,
    ],
  },
  {
    path: SearchDetailLoaderRoutePath.AccountActivity,
    canActivate: [searchDetailGuard],
    loadChildren: () =>
      import(
        '@fishonline2023/webapps/my-account/fd2023/feature/account-activity-detail'
      ).then((mod) => mod.accountActivityDetailRoutes),
  },
  ...SearchDetailLoaderRouteList.map(({ path, data }) => ({
    path,
    data,
    canActivate: [searchDetailGuard],
    loadChildren: () =>
      import('@fishonline2023/webapps/shared/feature/data-loader').then(
        (mod) => mod.dataLoaderRoutes
      ),
  })),
];
