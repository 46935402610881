export enum SortOrder {
  DESC,
  ASC,
  NOT_SORTED,
}

export const NextSortOrder: Record<SortOrder, SortOrder> = {
  [SortOrder.NOT_SORTED]: SortOrder.ASC,
  [SortOrder.ASC]: SortOrder.DESC,
  [SortOrder.DESC]: SortOrder.NOT_SORTED,
};
