<div class="mt-1 mb-3 container">
  <p>
    Search for what is available for sale and trade using the options below or
    <button (click)="newAd()" aria-label="Submit" class="btn btn-primary ms-2">
      Post an Advertisement
    </button>
  </p>
</div>
<div class="mt-1 mb-3 container">
  <p>
    The Department does not guarantee the accuracy or reliability of any
    information published on this site. For more information and to view the
    Terms and Conditions
    <a [routerLink]="'/' + FDRoute.TermsConditions" target="_blank"
      >terms and conditions</a
    >
  </p>
</div>
